import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { hasOneOfRoles } from "utils/security/keycloakUtils";

const RenderIfRoles = ({ roles, children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    setShowComponent(
      keycloak &&
        initialized &&
        keycloak.authenticated &&
        hasOneOfRoles(keycloak, roles)
    );
  }, [keycloak, initialized, roles]);

  return showComponent ? children : null;
};

export default RenderIfRoles;
