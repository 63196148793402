import { withTheme } from "styled-components";

import {
  CollapsibleMenu,
  CTAButton,
  HeaderWrapper,
  LogoLink,
  MainMenu,
  NavigationContainer,
  StyledLink,
  MenuToggle,
  UserLink,
  MonEspace,
  UserHead,
} from "./headerStyles";

import { ReactComponent as LogoutIcon } from "assets/icons/logout2.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { useEffect, useState } from "react";

import { RenderIf, ComponentWrapper } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveUser, UserActions } from "redux/user/userReducer";
import { useLocation, useNavigate } from "react-router";
import { useKeycloak } from "@react-keycloak/web";

import { TYPE_ADB } from "utils/constantes";
import { selectAdministrateurBien } from "redux/administrateurBien/administrateurBienReducer";
import useOutsideClick from "hooks/useOutsideClick";

import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { abortRequestAndResetController } from "utils/api/axiosInstance";
import RenderIfRoles from "../../layout/renderIf/renderIfRoles";
import { ROLE_LOQK } from "utils/security/keycloakUtils";

const Header = () => {
  const [collapse, setCollapse] = useState(false);
  const [espaceOpen, setEspaceOpen] = useState(false);
  const clickRef = useOutsideClick(() => setEspaceOpen(false));

  const utilisateur = useSelector(selectActiveUser);
  const administrateurBien = useSelector(selectAdministrateurBien);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    setCollapse(false);
    document.querySelector("body").style.overflow = "auto";
  }, [location]);

  useEffect(() => {
    if (collapse) {
      window.scrollTo(0, 0);
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [collapse]);

  const logout = () => {
    dispatch(UserActions.logOutUser({}));

    keycloak.logout({ redirectUri: window.location.origin });
    dispatch({ type: "DESTROY_SESSION" });
  };

  const login = () => {
    keycloak.login({
      redirectUri: window.location.origin + "/dashboard",
    });
  };

  return (
    <HeaderWrapper>
      <ComponentWrapper>
        <NavigationContainer collapse={collapse}>
          <MainMenu collapse={collapse}>
            <LogoLink to="/">
              <LogoIcon />
              <h3>
                Louez <strong>+</strong> Vite
              </h3>
            </LogoLink>
            <MenuToggle
              collapse={collapse}
              onClick={() => setCollapse(!collapse)}
            >
              <span></span>
              <span></span>
              <span></span>
            </MenuToggle>
          </MainMenu>
          <RenderIf condition={initialized && !keycloak.authenticated}>
            <CollapsibleMenu collapse={collapse}>
              <StyledLink to="/qui-sommes-nous">Qui sommes-nous?</StyledLink>
              <StyledLink to="/en-savoir-plus">En savoir plus</StyledLink>
              <MonEspace
                ref={clickRef}
                collapse={collapse}
                isOpen={espaceOpen}
                onClick={() => setEspaceOpen(!espaceOpen)}
              >
                <p>
                  Mon espace
                  <Chevron />
                </p>
                <div>
                  <StyledLink onClick={login}>Se Connecter</StyledLink>
                  {/*
                    <StyledLink to="/monespace/login">
                      Créer mon compte
                    </StyledLink>
                    */}
                </div>
              </MonEspace>

              <CTAButton onClick={login}>
                Obtenir mon pass LO<strong>Q</strong>K
              </CTAButton>
            </CollapsibleMenu>
          </RenderIf>

          <RenderIf condition={initialized && keycloak.authenticated}>
            <CollapsibleMenu collapse={collapse}>
              <UserLink
                onClick={() => {
                  abortRequestAndResetController();
                  navigate("/qui-sommes-nous");
                }}
              >
                <p>Qui sommes-nous?</p>
              </UserLink>
              <UserLink
                onClick={() => {
                  abortRequestAndResetController();
                  navigate("/en-savoir-plus");
                }}
              >
                <p>En savoir plus</p>
              </UserLink>
              <RenderIfRoles roles={[ROLE_LOQK]}>
                {administrateurBien && (
                  <UserLink
                    onClick={() => {
                      abortRequestAndResetController();
                      navigate("/utilisateur/dashboard");
                    }}
                  >
                    <p>Mes Utilisateurs</p>
                  </UserLink>
                )}
              </RenderIfRoles>
              <UserHead>
                <UserLink
                  onClick={() => {
                    abortRequestAndResetController();
                    navigate(
                      `/${
                        utilisateur && utilisateur.typeCompte === TYPE_ADB
                          ? "candidate"
                          : "monespace"
                      }/dashboard`
                    );
                  }}
                >
                  <UserIcon />

                  <h3>
                    {utilisateur?.nom} {utilisateur?.prenom}
                  </h3>
                </UserLink>
              </UserHead>

              <UserLink onClick={logout}>
                <LogoutIcon />
                <p>Logout</p>
              </UserLink>
            </CollapsibleMenu>
          </RenderIf>
        </NavigationContainer>
      </ComponentWrapper>
    </HeaderWrapper>
  );
};

export default withTheme(Header);
