import salaireDocument from "assets//images/revenus.jpg";
import referenceDocument from "assets//images/reference.jpg";
import apeDocument from "assets//images/ape.jpg";
import { AVIS_IMPOSITION } from "utils/constantes";

export const mapAttachmentTypeToImage = (type) => {
  if (type.includes("SALAIRE_M")) {
    return salaireDocument;
  } else if (type === AVIS_IMPOSITION) {
    return referenceDocument;
  } else if (type === "codeNaf") {
    return apeDocument;
  }

  return false;
};

export const mapAttachmentTypeToText = (type) => {
  if (type.includes("SALAIRE_M")) {
    return false;
  } else if (type === AVIS_IMPOSITION) {
    return "Où trouver son revenu fiscal de référence ? Vous pouvez trouver le montant de votre revenu fiscal de référence sur votre dernier avis d'imposition. Vous le trouverez en première page, dans le cadre situé en haut à fauche";
  }
  return false;
};

export const mapAttachementToHeader = (type) => {
  if (type.includes("SALAIRE_M")) {
    return "Salaire Net fiscal Mensuel";
  } else if (type === AVIS_IMPOSITION) {
    return "Revenu fiscal de référence";
  }
};
