import { ReactComponent as FullscreenToggle } from "assets/icons/fullscreen.svg";
import { ReactComponent as ZoomOut } from "assets/icons/sub.svg";
import { ReactComponent as ZoomIn } from "assets/icons/add.svg";
import { ReactComponent as DisplayIcon } from "assets/icons/displaySettings.svg";
import {
  ActionsSeparator,
  MainDocumentActions,
  ZoomControls,
} from "../documentPreviewPopup/documentPreviewPopupStyles";
import StyledInput from "../../styledInput/styledInput";
import StyledSelect from "components/styledSelect/styledSelect";
import { useState } from "react";

const DocumentActions = ({
  modifyScale,
  scale,
  changePage,
  page,
  maxPages,
  documentOrientation,

  setShouldFullScreen,
}) => {
  const [showActions, setShowActions] = useState(true);

  return (
    <MainDocumentActions
      orientation={documentOrientation}
      showActions={showActions}
      scale={scale}
    >
      <DisplayIcon
        onClick={() => {
          setShowActions(!showActions);
        }}
      />
      <ActionsSeparator type="default" />
      <div>
        <div>
          <StyledInput
            name="page"
            type="number"
            defaultValue={page}
            action={changePage}
            min={1}
            max={maxPages}
            hasTooltip={false}
          />
          <span>/</span>
          <p>{maxPages !== null ? maxPages : "-"}</p>
        </div>
        <ActionsSeparator type="default" />
        <div>
          <FullscreenToggle onClick={() => setShouldFullScreen(true)} />
          <ActionsSeparator />
          <ZoomControls>
            <ZoomOut
              onClick={() => {
                if (scale > 1) modifyScale(scale - 0.25);
              }}
            />
            <StyledSelect
              options={[
                { value: "100%", key: 1 },
                { value: "125%", key: 1.25 },
                { value: "150%", key: 1.5 },
                { value: "175%", key: 1.75 },
                { value: "200%", key: 2 },
                { value: "225%", key: 2.25 },
                { value: "250%", key: 2.5 },
                { value: "275%", key: 2.75 },
                { value: "300%", key: 3 },
              ]}
              defaultValue={scale}
              action={(e) => modifyScale(e)}
            />
            <ZoomIn
              onClick={() => {
                if (scale < 3) modifyScale(scale + 0.25);
              }}
            />
          </ZoomControls>
        </div>
        {/* <ActionsSeparator type="default" /> */}
      </div>
      {/* <div>
        <VerticalIcon onClick={() => setDocumentOrientation("vertical")} />
        <HorizontalIcon onClick={() => setDocumentOrientation("horizontal")} />
      </div> */}
    </MainDocumentActions>
  );
};

export default DocumentActions;
