import { withTheme } from "styled-components";

import { withFormik } from "formik";
import { connect } from "react-redux";
import revenusForm from "./revenus/revenusForm";
import {
  AdvancedRevenusValidator,
  RevenusValidator,
} from "./utils/revenusValidator";
import { mapRevenusToFormValues } from "utils/helpers/dossierNumeriqueHelper";
import withNavigate from "utils/hoc/withNavigate";
import { UPDATE_REVENUS } from "utils/saga/actions";

const formikEnhancer = withFormik({
  validationSchema: RevenusValidator,
  validate: AdvancedRevenusValidator,
  validateOnChange: true,
  validateOnBlur: false,

  mapPropsToValues: (props) => {
    const { dossierRevenus } = props;
    const revenus = mapRevenusToFormValues(dossierRevenus?.revenus);
    const negligeRevenuFiscal = dossierRevenus?.negligeRevenuFiscal;

    return {
      ...revenus,
      negligeRevenuFiscal,
    };
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    const { dispatch, navigate } = props;

    dispatch({
      type: UPDATE_REVENUS,
      payload: {
        revenusForm: values,
        navigate: () => navigate("/monespace/dossier/status?category=synthese"),
        setSubmitting,
      },
    });
  },

  displayName: "REVENUS_FROM",
});

export default withNavigate(connect()(formikEnhancer(withTheme(revenusForm))));
