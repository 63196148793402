import { PopupContainer } from "./documentPreviewPopupStyles";

import MainPreviewSidebar from "../documentPreviewSections/mainSidebar";
import MainDocumentPreview from "../documentPreviewSections/mainDocumentPreview";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Spinner from "../../spinner/spinner";

export const DocumentPreviewPopup = ({
  documentRef,
  documentInfo,
  showPopup,
  isLoading,
  togglePopup = () => {},
  uploadAction = () => {},
  deleteAction = () => {},
  downloadAction = () => {},
  isFileBlocked = false,
}) => {
  const [shouldShowPopupItems, setShouldShowPopupItems] = useState(false);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);

  const debounceLoading = useDebouncedCallback(() => {
    setIsSkeletonLoading(false);
    setShouldShowPopupItems(true);
  }, 1200);

  const debouncedReset = useDebouncedCallback(() => {
    setShouldShowPopupItems(false);
    setIsSkeletonLoading(false);
  }, 600);

  useEffect(() => {
    if (showPopup) {
      setIsSkeletonLoading(true);
      debounceLoading();
      document.querySelector("body").style.overflow = "hidden";
    } else {
      debouncedReset();
      document.querySelector("body").style.overflow = "auto";
    }
  }, [showPopup]);

  return (
    <PopupContainer showPopup={showPopup}>
      {isSkeletonLoading && (
        <Spinner marginY="128px" message="Téléchargement du document" />
      )}
      {shouldShowPopupItems && (
        <>
          <MainPreviewSidebar
            togglePopup={togglePopup}
            uploadAction={uploadAction}
            deleteAction={deleteAction}
            downloadAction={downloadAction}
            file={documentRef}
            isLoading={isLoading}
            isFileBlocked={isFileBlocked}
            documentInfo={documentInfo}
            showPopup={showPopup}
          />

          <MainDocumentPreview
            file={documentRef}
            documentInfo={documentInfo}
            isLoading={isLoading}
            showPopup={showPopup}
          />
        </>
      )}
    </PopupContainer>
  );
};
