import styled from "styled-components";

export const RevenuItem = styled.div`
  width: 100%;

  h2 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    h2 {
      text-align: center;
    }
  }
`;

export const RevenuItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px !important;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
`;
