//  FilesUniqueContainer
//  Component to display unique files (files with only one piece)
//  Props:
//     - piece: object, the piece to display

import AttachmentContainer from "components/attachmentContainer/attachmentContainer";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import {
  FileCategoryContainer,
  FileCategoryHeading,
  FileInfoMessage,
} from "pages/dossierLocation/forms/formsStyles";
import { AUTRES_DOCUMENTS } from "utils/constantes";
import { useEffect, useState } from "react";
import { useMemo } from "react";

const FileUniqueContainer = ({
  piece,
  forceBlock,
  processRunning,
  dossierNumerique,
  updateProcessing = () => {},
  getAttachmentFileTitle = () => {},
  documentActions,
}) => {
  // default collapse only if type equals autres documents
  const [collapse, setCollapse] = useState(piece.type === AUTRES_DOCUMENTS);
  const pieceLabel = useMemo(() => getAttachmentFileTitle(piece), [piece]);

  useEffect(() => {
    if (piece.type === AUTRES_DOCUMENTS) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [piece]);

  return (
    <FileCategoryContainer collapse={collapse}>
      <FileCategoryHeading showOptions={collapse}>
        <h3>{pieceLabel}</h3>
        {piece.type === AUTRES_DOCUMENTS && (
          <div>
            <FileInfoMessage collapse={collapse}>
              <InfoIcon />
              <div>
                <InfoIcon />
                <h3>Information</h3>
                <p>
                  Ces documents ne sont pas nécessaires pour la validation du
                  dossier du locataire
                </p>
              </div>
            </FileInfoMessage>
            <Chevron
              onClick={() => {
                setCollapse(!collapse);
              }}
            />
          </div>
        )}
      </FileCategoryHeading>
      <div>
        <AttachmentContainer
          piece={piece}
          forceBlock={forceBlock}
          processRunning={processRunning}
          updateProcessing={updateProcessing}
          getAttachmentFileTitle={getAttachmentFileTitle}
          dossierNumeriqueId={dossierNumerique?.id}
          documentActions={documentActions}
        />
      </div>
    </FileCategoryContainer>
  );
};

export default FileUniqueContainer;
