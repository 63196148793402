import { axiosInstance } from "./axiosInstance";

export const getUtilisateurConnecte = () => {
  return axiosInstance.get("/utilisateurs/connected");
};

export const getAllUtilisateurs = (adbId, payload) => {
  return axiosInstance.get(
    `/administrateur-biens/${adbId}/utilisateurs`,
    {
      params: {
        ...payload,
      },
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const getUtilisateur = (id) => {
  return axiosInstance.get(`/utilisateurs/${id}`);
};

export const affecterSite = (adbId, siteId, utilisateurId, affected) => {
  return axiosInstance.put(
    `/administrateur-biens/${adbId}/sites/${siteId}/${
      affected ? "desaffecter-site-utilisateur" : "affecter-site-utilisateur"
    }
  `,
    {
      id: utilisateurId,
    }
  );
};

export const createUtilisateur = (adbId, utilisateur) => {
  return axiosInstance.post(
    `/administrateur-biens/${adbId}/utilisateurs`,
    utilisateur
  );
};

export const updateUtilisateur = (utilisateur) => {
  return axiosInstance.put(`/utilisateurs/${utilisateur.id}`, utilisateur);
};

export const changeUtilisateurActive = (id, actif) => {
  return axiosInstance.put(
    `/administrateur-biens/${id}/${actif ? "desactiver" : "activer"}`
  );
};

export const createSite = (adbId, site) => {
  return axiosInstance.post(`/administrateur-biens/${adbId}/sites`, site);
};

export const accepterCGU = () => {
  return axiosInstance.put(`/utilisateurs/accept-cgu`);
};
