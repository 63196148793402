import { axiosInstance } from "./axiosInstance";

export const getAdministrateursBiens = async (searchFilters) => {
  return axiosInstance.get(`/administrateur-biens`, {
    params: { ...searchFilters },
  });
};

export const getAdministrateurBien = async (adbId) => {
  return axiosInstance.get(`/administrateur-biens/${adbId}`);
};
