import { useDispatch } from "react-redux";
import {
  CollapsibleContainer,
  InformationHeading,
  SyntheseFile,
} from "pages/dossierLocation/forms/formsStyles";

import { FETCH_PIECES } from "utils/saga/actions";

import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { SyntheseItemContainer } from "./syntheseAttachmentStyles";
import AttachmentContainer from "components/attachmentContainer/attachmentContainer";
import { useSearchParams } from "react-router-dom";

const SyntheseFilesSection = ({
  collapseByDefault,
  dossierNumeriqueId,
  dossierNumerique,
  dossierLocataire,
  editLink = "/candidate/forms?category=pieces",
}) => {
  const [piecesIdentite, setPiecesIdentite] = useState([]);
  const [fichesPaie, setFichesPaie] = useState([]);
  const [autresPieces, setAutresPieces] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleReturnToPieces = () => {
    setSearchParams({ category: "pieces" });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (collapseByDefault) setCollapse(true);
  }, []);

  useEffect(() => {
    if (dossierLocataire) {
      dispatch({
        type: FETCH_PIECES,
        payload: {
          setPiecesIdentite,
          setFichesPaie,
          setAutresPieces,
          candidate: dossierLocataire,
          dossierNumerique: dossierNumerique,
          onChange: setLoading,
        },
      });
    }
  }, [dossierLocataire]);

  return (
    <CollapsibleContainer collapsed={collapse} ref={componentRef}>
      <InformationHeading collapsed={collapse}>
        <div>
          <div
            onClick={() => {
              setCollapse(!collapse);
              window.scrollTo(0, componentRef.current.offsetTop - 24);
            }}
          >
            <Chevron />
            <h3>Piéces</h3>
          </div>
          <EditIcon onClick={() => navigate(editLink)} />
        </div>
      </InformationHeading>

      <div>
        {piecesIdentite.length ? (
          <SyntheseFile>
            <div>
              <p>Piéces Identité</p>
            </div>
            <div>
              {piecesIdentite.map((piece, index) => {
                return (
                  <SyntheseItemContainer key={index}>
                    <AttachmentContainer
                      piece={piece}
                      shouldShowActions={false}
                      displayLabel={false}
                      uploadNotAllowed={true}
                      onClickAction={handleReturnToPieces}
                      dossierNumeriqueId={dossierNumeriqueId}
                    />
                  </SyntheseItemContainer>
                );
              })}
            </div>
          </SyntheseFile>
        ) : null}

        {fichesPaie.length ? (
          <SyntheseFile>
            <div>
              <p>Fiches de paie</p>
            </div>
            <div>
              {fichesPaie.map((piece, index) => {
                return (
                  <SyntheseItemContainer key={index}>
                    <AttachmentContainer
                      piece={piece}
                      shouldShowActions={false}
                      displayLabel={false}
                      uploadNotAllowed={true}
                      onClickAction={handleReturnToPieces}
                      dossierNumeriqueId={dossierNumeriqueId}
                    />
                  </SyntheseItemContainer>
                );
              })}
            </div>
          </SyntheseFile>
        ) : null}

        {autresPieces.length ? (
          <SyntheseFile>
            <div>
              <p>Autres pièces</p>
            </div>
            <div>
              {autresPieces.map((piece, index) => {
                return (
                  <SyntheseItemContainer key={index}>
                    <AttachmentContainer
                      piece={piece}
                      shouldShowActions={false}
                      displayLabel={false}
                      uploadNotAllowed={true}
                      onClickAction={handleReturnToPieces}
                      dossierNumeriqueId={dossierNumeriqueId}
                    />
                  </SyntheseItemContainer>
                );
              })}
            </div>
          </SyntheseFile>
        ) : null}
      </div>
    </CollapsibleContainer>
  );
};

export default SyntheseFilesSection;
