import { all } from "redux-saga/effects";

import {
  watchLoadAdministrateurBien,
  watchLoadAdministrateursBiens,
  watchRunSetCurrentADB,
} from "./administrateurBienSaga";
import {
  createClientCall,
  createSiteCall,
  loadClientsCall,
  updateClientCall,
} from "./clientSaga";
import { previewLocataire, callShowSynthese } from "./dashboardAction";
import {
  ajouterColocataireAction,
  createOrUpdateDossierLocataire,
  lancerParcoursNetheos,
  lancerRouvertureDossierNetheos,
  recupererDossiersLocation,
  runAccepterDossierLocation,
  runGetCapaciteDetails,
  runLoadSummary,
  runRejeterDossierLocation,
  runRouvrirDossierLocation,
  searchByPassLoqk,
  updateRevenus,
  updateTypePieceIdentite,
} from "./dossierLocationSaga";
import {
  deleteDocumentCall,
  downloadDocumentCall,
  fetchDocument,
  fetchFiles,
  fetchImage,
} from "./files";
import { uploadDocumentToAPI } from "./upload";
import { loadUser } from "./userSaga";
import {
  affectSiteToUtilisateurCall,
  createUtilisateurCall,
  getUtilisateurInfoCall,
  loadUtilisateursCall,
  saveUtilisateurModificationsCall,
  toggleUtilisateurActiveCall,
  utilisateurCreateSiteCall,
} from "./utilisateurSaga";
import {
  watchActivateCourtier,
  watchAffectCourtierSite,
  watchCreateCourtier,
  watchDeactivateCourtier,
  watchDesaffectCourtierSite,
  watchGetCourtierADBs,
  watchGetCourtierInfo,
  watchGetCourtiers,
  watchUpdateCourtier,
} from "./courtier";
import {
  watchCreateLOQKUtilisateur,
  watchGetLOQKUtilisateurs,
} from "./utilisateurLOQKSaga";
import {
  watchAffectCourtierUtilisateurSite,
  watchCreateCourtierUtilisateur,
  watchDesaffectCourtierUtilisateurSite,
  watchGetCourtierUtilisateurADBs,
  watchGetCourtierUtilisateurInfo,
  watchGetCourtierUtilisateurs,
} from "./utilisateurCourtierStaga";

function* watchAll() {
  yield all([
    loadUser(),
    watchLoadAdministrateursBiens(),
    recupererDossiersLocation(),
    watchLoadAdministrateurBien(),
    watchRunSetCurrentADB(),
    uploadDocumentToAPI(),
    fetchFiles(),
    fetchImage(),
    fetchDocument(),
    downloadDocumentCall(),
    searchByPassLoqk(),
    createOrUpdateDossierLocataire(),
    previewLocataire(),
    callShowSynthese(),
    updateRevenus(),
    updateTypePieceIdentite(),
    createClientCall(),
    updateClientCall(),
    createSiteCall(),
    loadUtilisateursCall(),
    createUtilisateurCall(),
    loadClientsCall(),
    saveUtilisateurModificationsCall(),
    toggleUtilisateurActiveCall(),
    watchCreateLOQKUtilisateur(),
    watchGetLOQKUtilisateurs(),
    lancerParcoursNetheos(),
    getUtilisateurInfoCall(),
    utilisateurCreateSiteCall(),
    affectSiteToUtilisateurCall(),
    lancerRouvertureDossierNetheos(),
    deleteDocumentCall(),
    ajouterColocataireAction(),
    runLoadSummary(),
    runAccepterDossierLocation(),
    runRejeterDossierLocation(),
    runRouvrirDossierLocation(),
    runGetCapaciteDetails(),
    watchGetCourtiers(),
    watchActivateCourtier(),
    watchDeactivateCourtier(),
    watchAffectCourtierSite(),
    watchDesaffectCourtierSite(),
    watchCreateCourtier(),
    watchUpdateCourtier(),
    watchGetCourtierInfo(),
    watchGetCourtierADBs(),
    watchAffectCourtierUtilisateurSite(),
    watchDesaffectCourtierUtilisateurSite(),
    watchGetCourtierUtilisateurADBs(),
    watchCreateCourtierUtilisateur(),
    watchGetCourtierUtilisateurs(),
    watchGetCourtierUtilisateurInfo(),
  ]);
}

export default watchAll;
