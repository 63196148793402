//  DashboardLine
//  Component used in adb dashboard to display basic info about a location and
//  a toggle to display the actions available for the location and also status of the location
//  Props:
//     - modifySortFilters: function, the function used to set the sort filters
//     - type: string, the type of the line, can be "regular" or "header" to distinguish
//             between a regular line and a header line
//     - dossierLocation: object, the data of the location to display
//     - sortFilters: object, the filters used to sort the locations
//  State:
//     - collapse: boolean, if false, the actions are displayed
//     - showSummary: boolean, if true, the summary of the location is displayed
//     - summaryData: object, the data of the summary
//     - isLoadingSummary: boolean, if true, the summary is loading
//     - summaryLoadError: boolean, if true, an error occured while loading the summary
//  Functions:
//     - handleSortFiltersModification: function, calls the modifySortFilters function to set the sort filters
//     - handlePreviewCall: function, calls the preview action
//  Refs:
//     - clickRef: ref, used to detect clicks outside the component and if so, close the menu
//     - lineRef: ref of the component, we use it to scroll into component when we toggle summary

import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { LOAD_DOSSIER_SUMMARY, PREVIEW_SYNTHESE } from "utils/saga/actions";
import { abortRequestAndResetController } from "utils/api/axiosInstance";
import useOutsideClick from "hooks/useOutsideClick";
import ActionsRow from "components/dashboards/dashboardCommon/dashboardAction/actionRow";
import DashboardAction from "components/dashboards/dashboardCommon/dashboardAction/dashboardAction";
import DossierSummary from "components/dossierSummary/dossierSummary";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { ReactComponent as Admin } from "assets/icons/admin.svg";
import { ReactComponent as Eye } from "assets/icons/preview.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as VisaleIcon } from "assets/icons/visale.svg";
import {
  AdminAction,
  HeaderSection,
  LineContainer,
  LineValue,
  LocataireShowToggle,
  VisaleIconContainer,
} from "./dashboardLineStyles";
import { STATUT_DOSSIER_LOCATION } from "utils/constantes";
import useDoubleClick from "hooks/useDoubleClick";
import { ROLE_ADMIN_LOQK } from "utils/security/keycloakUtils";
import { PopupOption } from "components/dashboards/dashboardCommon/dashboardAction/dashboardActionStyles";
import { Action } from "components/dashboards/dashboardCommon/dashboardAction/actionRowStyles";

const DashboardLine = ({
  modifySortFilters = () => {},
  type = "regular",
  dossierLocation,
  sortFilters,
}) => {
  const [collapse, setCollapse] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [summaryLoadError, setSummaryLoadError] = useState(false);

  const lineRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clickRef = useOutsideClick(() => {
    setCollapse(false);
    setShowSummary(false);
    setSummaryData({});
  });

  useEffect(() => {
    if (showSummary) {
      window.scrollTo(0, lineRef.current.offsetTop - 100);
      dispatch({
        type: LOAD_DOSSIER_SUMMARY,
        payload: {
          dossierLocation,
          handleChange: (bool) => setIsLoadingSummary(bool),
          handleData: (data) => {
            setSummaryData(data);
            setSummaryLoadError(false);
          },
          handleError: (bool) => setSummaryLoadError(bool),
        },
      });
    } else {
      // abortRequestAndResetController();
      setSummaryData({});
    }
  }, [showSummary]);

  const handleSortFiltersModification = (category) => {
    if (sortFilters.sortBy === category) {
      if (sortFilters.sortDirection === "DESC") {
        modifySortFilters({
          sortBy: undefined,
          sortDirection: undefined,
        });
      } else {
        modifySortFilters({
          sortBy: category,
          sortDirection: sortFilters.sortDirection === "ASC" ? "DESC" : "ASC",
        });
      }
    } else {
      modifySortFilters({
        sortBy: category,
        sortDirection: "ASC",
      });
    }
  };

  const handlePreviewCall = () => {
    dispatch({
      type: PREVIEW_SYNTHESE,
      payload: {
        locationData: dossierLocation,
        navigateAction: () => {
          navigate("/candidate/forms?category=synthese");
        },
      },
    });
  };

  useDoubleClick({
    ref: lineRef,
    onDoubleClick: () => {
      handlePreviewCall("/candidate/forms?category=synthese");
    },
  });

  return (
    <div ref={clickRef}>
      <LineContainer
        type={type}
        columns={18}
        showSummary={showSummary}
        collapse={collapse}
        ref={lineRef}
        status={dossierLocation?.statut}
      >
        {type === "header" ? (
          <>
            <HeaderSection
              sortDirection={sortFilters.sortDirection}
              colSpan={3}
              active={
                sortFilters.sortBy === "nom locataire" ? sortFilters : null
              }
              onClick={() => handleSortFiltersModification("nom locataire")}
            >
              <p>Nom Locataire</p>
              <div>
                <Chevron />
                <Chevron />
              </div>
            </HeaderSection>
            <HeaderSection
              sortDirection={sortFilters.sortDirection}
              colSpan={4}
              active={
                sortFilters.sortBy === "référence candidature"
                  ? sortFilters
                  : null
              }
              onClick={() =>
                handleSortFiltersModification("référence candidature")
              }
            >
              <p>Référence Locataire</p>
              <div>
                <Chevron />
                <Chevron />
              </div>
            </HeaderSection>
            <HeaderSection
              sortDirection={sortFilters.sortDirection}
              colSpan={3}
              active={sortFilters.sortBy === "site" ? sortFilters : null}
              onClick={() => handleSortFiltersModification("site")}
            >
              <p>Site</p>
              <div>
                <Chevron />
                <Chevron />
              </div>
            </HeaderSection>
            <HeaderSection
              sortDirection={sortFilters.sortDirection}
              colSpan={3}
              active={
                sortFilters.sortBy === "statusDossier" ? sortFilters : null
              }
              onClick={() => handleSortFiltersModification("statusDossier")}
            >
              <p>Statut du dossier</p>
              <div>
                <Chevron />
                <Chevron />
              </div>
            </HeaderSection>
            {/* <HeaderSection
              sortDirection={sortFilters.sortDirection}
              colSpan={2}
              active={sortFilters.sortBy === "loqkPass" ? sortFilters : null}
              onClick={() => handleSortFiltersModification("loqkPass")}
            >
              <p>
                N° Pass LO<strong>Q</strong>K
              </p>
              <div>
                <Chevron />
                <Chevron />
              </div>
            </HeaderSection> */}
            <HeaderSection
              sortDirection={sortFilters.sortDirection}
              colSpan={3}
              active={
                sortFilters.sortBy === "capaciteLocative" ? sortFilters : null
              }
              onClick={() => handleSortFiltersModification("capaciteLocative")}
            >
              <p>Capacité Locativé</p>
              <div>
                <Chevron />
                <Chevron />
              </div>
            </HeaderSection>
            <HeaderSection colSpan={2} justifyContent="center">
              {/* Actions */}
            </HeaderSection>
          </>
        ) : (
          <>
            <LocataireShowToggle
              showSummary={showSummary}
              onClick={() => setShowSummary(!showSummary)}
            />
            <LineValue colSpan={3}>
              <p></p>
              <p>
                {dossierLocation.locataireReferent.nom +
                  " " +
                  dossierLocation.locataireReferent.prenom}
              </p>
            </LineValue>
            <LineValue colSpan={4}>
              <p>Référence Locataire</p>
              <p>{dossierLocation.reference}</p>
            </LineValue>
            <LineValue colSpan={3}>
              <p>Site</p>
              <p>{dossierLocation.site.nom}</p>
            </LineValue>
            <LineValue colSpan={3}>
              <p>Statut</p>
              <p>
                {
                  STATUT_DOSSIER_LOCATION.find(
                    (it) => it.key === dossierLocation.statut
                  )?.value
                }
              </p>
              {dossierLocation.validateur &&
                ROLE_ADMIN_LOQK.includes(dossierLocation.validateur.profil) && (
                  <AdminAction>
                    <Admin />
                  </AdminAction>
                )}
            </LineValue>
            {/* <LineValue colSpan={2}>
              <p>
                N° Pass LO<strong>Q</strong>K
              </p>
              <p>-</p>
            </LineValue> */}
            <LineValue colSpan={3}>
              <p>Capacité Locativé</p>
              <p>
                {dossierLocation.capaciteLocative
                  ? dossierLocation.capaciteLocative + "€"
                  : "-"}
              </p>
            </LineValue>
            <LineValue colSpan={2} justifyContent="center">
              {dossierLocation.visalEligible ? (
                <VisaleIconContainer>
                  <VisaleIcon />
                </VisaleIconContainer>
              ) : (
                <div
                  style={{
                    minWidth: "24px",
                    width: "24px",
                    height: "24px",
                    display: "block",
                  }}
                ></div>
              )}
              <DashboardAction
                locationData={dossierLocation}
                status={dossierLocation.statut}
              >
                <PopupOption
                  onClick={() =>
                    handlePreviewCall("/candidate/forms?category=synthese")
                  }
                >
                  <p>Visualiser</p>
                  <Eye />
                </PopupOption>
                <PopupOption>
                  <p>Supprimer</p>
                  <Delete />
                </PopupOption>
              </DashboardAction>
            </LineValue>
            <LineValue
              style={{ position: "relative", zIndex: 500 }}
              colSpan={1}
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              {dossierLocation.visalEligible ? (
                <div
                  style={{
                    marginRight: "0.5rem",
                  }}
                >
                  <VisaleIconContainer>
                    <VisaleIcon />
                  </VisaleIconContainer>
                </div>
              ) : (
                <div
                  style={{
                    minWidth: "24px",
                    width: "24px",
                    height: "24px",
                    display: "block",
                  }}
                ></div>
              )}
              <Chevron />
            </LineValue>
            <ActionsRow
              parentCollapse={collapse}
              parentCollapseAction={setCollapse}
              locationData={dossierLocation}
            >
              <Action
                onClick={() =>
                  handlePreviewCall("/candidate/forms?category=synthese")
                }
              >
                Visualiser
                <Eye />
              </Action>
              <Action>
                Supprimer
                <Delete />
              </Action>
            </ActionsRow>
          </>
        )}
      </LineContainer>

      {type !== "header" && (
        <DossierSummary
          showSummary={showSummary}
          isLoading={isLoadingSummary}
          dossierData={summaryData}
          handlePreviewCall={handlePreviewCall}
          summaryLoadError={summaryLoadError}
        />
      )}
    </div>
  );
};

export default DashboardLine;
