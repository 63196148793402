import { axiosInstance } from "./axiosInstance";

const BASE_URL = "/courtiers";

export const getCourtierUtilisateurInfo = async (utilisateurId) => {
  return axiosInstance.get(`/utilisateurs/${utilisateurId}`);
};

export const getCourtierUtilisateurs = async (courtierId, query) => {
  return axiosInstance.get(`${BASE_URL}/${courtierId}/utilisateurs`, {
    params: { nomClient: query?.nomUtilisateur },
  });
};

export const getCourtierUtilisateurADBs = async (
  courtierId,
  filters,
  paginationData
) => {
  const { nomClient, referenceClient, ville, referenceCourtier } = filters;
  const { page, size } = paginationData;

  return axiosInstance.get(
    `${BASE_URL}/${courtierId}/administrateur-biens?nomClient=${nomClient}&referenceClient=${referenceClient}&ville=${ville}&referenceCourtier=${referenceCourtier}&page=${page}&size=${size}`
  );
};

export const createCourtierUtilisateur = async (courtierId, utilisateur) => {
  return axiosInstance.post(
    `${BASE_URL}/${courtierId}/utilisateurs`,
    utilisateur
  );
};

export const affectCourtierUtilisateurSite = async (
  courtierId,
  utilisateurId,
  sites
) => {
  return axiosInstance.put(
    `${BASE_URL}/${courtierId}/utilisateurs/${utilisateurId}/affecter-sites`,
    sites,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const desaffectCourtierUtilisateurSite = async (
  courtierId,
  utilisateurId,
  sites
) => {
  return axiosInstance.put(
    `${BASE_URL}/${courtierId}/utilisateurs/${utilisateurId}/desaffecter-sites`,
    sites,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
