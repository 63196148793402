import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  selectAdministrateurBien,
  setCurrentAdministrateurBien,
} from "redux//administrateurBien/administrateurBienReducer";
import {
  CLIENTS_LOAD,
  CLIENT_ADD_SITE,
  CLIENT_CREATE,
  CLIENT_UPDATE,
} from "./actions";

import * as clientApi from "../api/customer";
import { toast } from "react-toastify";

function* loadClients(action) {
  const { onLoadChange, onLoadSuccess } = action;
  try {
    onLoadChange(true);
    const clients = yield call(clientApi.getClients);
    if (clients.status === 200) {
      yield put(onLoadSuccess(clients.data));
    }
  } catch (error) {
    toast.error("Erreur lors du chargement des clients");
  }
}

function* createClient(action) {
  const { onCreateChange = () => {} } = action;
  try {
    const { client, onCreateSuccess = () => {} } = action;

    const clientData = {
      nom: client.nom,
      adresseLigne1: client.adresseLigne1,
      adresseLigne2: client.adresseLigne2,
      ville: client.ville,
      codePostal: client.codePostal,
      sp: client.sp,
      siret: client.siret,
    };

    const responseClientCreate = yield call(clientApi.createClient, clientData);

    yield put(setCurrentAdministrateurBien(responseClientCreate.data));
    onCreateSuccess(responseClientCreate.data);
    onCreateChange(true);
    toast.success("Client ajouté avec succès");
  } catch (err) {
    console.log(err);
    toast.error("Erreur lors de l'ajout du client");
  } finally {
    onCreateChange(false);
  }
}

function* updateClient(action) {
  const { onUpdateChange = () => {} } = action;
  try {
    onUpdateChange(true);
    const { client, onUpdateSuccess } = action;
    const { id: adbId } = yield select(selectAdministrateurBien);
    const clientData = {
      nom: client.nom,
      adresseLigne1: client.adresseLigne1,
      adresseLigne2: client.adresseLigne2,
      ville: client.ville,
      codePostal: client.codePostal,
      sp: client.sp,
      siret: client.siret,
    };

    yield call(clientApi.updateClient, adbId, clientData);
    onUpdateSuccess();
    toast.success("Client mis à jour avec succès");
  } catch (err) {
    toast.error(
      "Un problème est survenu lors de la mise à jour des informations sur le client"
    );
  } finally {
    onUpdateChange(false);
  }
}

function* createSite(action) {
  const { onChange = () => {} } = action;
  try {
    const { nom } = action.payload;
    const { onAddSuccess = () => {} } = action;
    onChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);

    const site = yield call(clientApi.createSite, adbId, nom);
    onAddSuccess(site.data);
    toast.success("Site ajouté avec succès");
  } catch (err) {
    toast.error("Erreur lors de l'ajout du site");
  } finally {
    onChange(false);
  }
}

function* createClientCall() {
  yield takeLatest(CLIENT_CREATE, createClient);
}

function* updateClientCall() {
  yield takeLatest(CLIENT_UPDATE, updateClient);
}

function* createSiteCall() {
  yield takeLatest(CLIENT_ADD_SITE, createSite);
}

function* loadClientsCall() {
  yield takeLatest(CLIENTS_LOAD, loadClients);
}

export { createClientCall, updateClientCall, createSiteCall, loadClientsCall };
