import styled from "styled-components";

export const StatusPageContainer = styled.div`
  padding: 8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 860px;
    margin: 0 auto;
    text-align: center;
    width: 80%;
    h2 {
      font: ${({ theme }) => theme.fonts.montserrat.heading};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
      margin-bottom: 0.75rem;
    }
    p {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      color: ${({ theme }) => theme.colors.primaryOpaque};
      margin-bottom: 2rem;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 4.5rem 0;
  }
`;
