import { CapaciteWrapper } from "./capaciteLocativeStyles";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useState } from "react";
import RenderIfRoles from "components/layout/renderIf/renderIfRoles";
import { ROLE_ADMIN_LOQK } from "utils/security/keycloakUtils";
import ConfirmationPopup from "components/popups/confirmationPopup/confirmationPopup";
import CurrencyInput from "react-currency-input-field";

const CapaciteLocative = ({ capaciteLocative, dossierStatut }) => {
  const [capaciteLocativeValue, setCapaciteLocativeValue] =
    useState(capaciteLocative);
  const [editMode, setEditMode] = useState(false);
  const [confirmationTriggered, setConfirmationTriggered] = useState(false);
  const [confirmationBuffer, setConfirmationBuffer] =
    useState(capaciteLocative);

  return (
    <CapaciteWrapper editMode={editMode} dossierStatut={dossierStatut}>
      <ConfirmationPopup
        confirmTitle="Êtes-vous sûr de vouloir modifier la capacité locative ?"
        confirmMessage="Cette modification aura un impact sur le calcul de votre capacité d'emprunt."
        confirmButtonText="Modifier"
        cancelButtonText="Annuler"
        handleConfirm={(reason) => {
          setConfirmationTriggered(false);
          setCapaciteLocativeValue(confirmationBuffer);
          setEditMode(false);
        }}
        handleCancel={() => {
          setEditMode(false);
          setConfirmationTriggered(false);
          setConfirmationBuffer(capaciteLocativeValue);
        }}
        showPopup={confirmationTriggered}
        hasPromptRequest={true}
      />

      <div>
        <CurrencyInput
          id={"capaciteLocative"}
          name={"capaciteLocative"}
          placeholder="0€"
          value={editMode ? confirmationBuffer : capaciteLocativeValue}
          decimalsLimit={0}
          onValueChange={(value) => setConfirmationBuffer(value)}
          suffix="€"
          allowDecimals={false}
          allowNegativeValue={false}
          maxLength={8}
          disabled={!editMode}
        />
      </div>
      <RenderIfRoles roles={[ROLE_ADMIN_LOQK]}>
        {editMode ? (
          <SaveIcon
            onClick={() => {
              if (capaciteLocativeValue !== confirmationBuffer) {
                setConfirmationTriggered(true);
              } else {
                setEditMode(false);
              }
            }}
          />
        ) : (
          <EditIcon
            onClick={() => {
              setConfirmationBuffer(capaciteLocativeValue);
              setEditMode(true);
            }}
          />
        )}
      </RenderIfRoles>
    </CapaciteWrapper>
  );
};

export default CapaciteLocative;
