import { SidebarActionsCategory } from "../documentPreviewPopup/documentPreviewPopupStyles";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { useState } from "react";

const SidebarCategory = ({ title, children }) => {
  const [show, setShow] = useState(true);
  return (
    <SidebarActionsCategory show={show}>
      <div onClick={() => setShow(!show)}>
        <p>{title}</p>
        <Chevron />
      </div>
      <div>{children}</div>
    </SidebarActionsCategory>
  );
};

export default SidebarCategory;
