//  ActionRow
//  Component used to display the actions available for a location
//  displayed only in lower screen sizes
//  Props:
//     - parentCollapse: boolean, if true, the component will be collapsed
//     - parentCollapseAction: function, function to call when the component is collapsed
//     - locationData: object, the data of the location to display
//  State:
//     - collapse: boolean, if false, the component is collapsed
//  Functions:
//     - handlePreviewCall: function, calls the preview action

import { useEffect, useState } from "react";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { ActionsLine } from "./actionRowStyles";

const ActionsRow = ({ parentCollapse, children }) => {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    // checks if the parent component is collapsed and if so, collapses this component
    if (!parentCollapse) {
      setCollapse(false);
    }
  }, [parentCollapse]);

  return (
    <ActionsLine collapse={collapse}>
      <div onClick={() => setCollapse(!collapse)}>
        <h3>Actions</h3>
        <Chevron />
      </div>
      <div>{children}</div>
    </ActionsLine>
  );
};

export default ActionsRow;
