import { IsAuthWrapper } from "components";
import { Outlet, useLocation } from "react-router";
import { DemandesContextProvider } from "utils/context/demandeContext";
import styled from "styled-components";

const DemandeLayoutContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ ignoreTopMargin }) => (ignoreTopMargin ? "24px" : "96px")};
  }
`;

export const DemandeLayout = ({ roles, fallbackLink }) => {
  const { pathname } = useLocation();

  return (
    <DemandesContextProvider>
      <IsAuthWrapper roles={roles} fallbackLink={fallbackLink}>
        <DemandeLayoutContainer
          ignoreTopMargin={pathname.includes("dashboard")}
        >
          <Outlet />
        </DemandeLayoutContainer>
      </IsAuthWrapper>
    </DemandesContextProvider>
  );
};
