import {
  AllDocumentPages,
  PagesPreview,
  SidebarToggle,
  SmallPagePreview,
} from "../documentPreviewPopup/documentPreviewPopupStyles";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { Document, Page, pdfjs } from "react-pdf";
import Spinner from "../../spinner/spinner";
import { NoDataInDocument } from "../popupStyles";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const PagesPreviewSidebar = ({
  file,
  collapsePages,
  setCollapsePages,
  page,
  setPage,
  maxPages,
  isLoading,
  showPopup,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [allowLoad, setAllowLoad] = useState(false);

  const debounceReset = useDebouncedCallback(() => {
    setAllowLoad(false);
  }, 600);

  useEffect(() => {
    if (!collapsePages && !allowLoad) {
      setAllowLoad(true);
    }
    if (!showPopup) {
      debounceReset();
    }
  }, [collapsePages, showPopup]);

  return (
    <PagesPreview isCollapsed={collapsePages}>
      <SidebarToggle
        isCollapsed={collapsePages}
        onClick={() => setCollapsePages(!collapsePages)}
      >
        <Chevron />
        <p>{collapsePages ? "Afficher pages" : "Masquer"}</p>
      </SidebarToggle>
      {isLoading ? (
        <Spinner message={"chargement en cours"} />
      ) : (
        <>
          <AllDocumentPages>
            <h2>Pages</h2>
            <div>
              {allowLoad && maxPages && (
                <>
                  <Document
                    file={file}
                    options={{ workerSrc: "pdf.worker.js" }}
                    error={
                      <NoDataInDocument>
                        <WarningIcon />
                        <h3>
                          Une erreur est survenue
                          <br />
                          Veuillez réessayer
                        </h3>
                      </NoDataInDocument>
                    }
                    noData={
                      <NoDataInDocument>
                        <WarningIcon />
                        <h3>
                          Aucune donnée trouvée
                          <br />
                          Veuillez réessayer
                        </h3>
                      </NoDataInDocument>
                    }
                    loading={<Spinner message="Chargement du document" />}
                  >
                    {Array(maxPages)
                      .fill(0)
                      .map((_, index) => {
                        return (
                          <SmallPagePreview
                            key={index}
                            isSelected={page === index + 1}
                          >
                            <Page
                              pageNumber={index + 1}
                              key={index}
                              onClick={() => setPage(index + 1)}
                              width={210}
                            />
                          </SmallPagePreview>
                        );
                      })}
                  </Document>
                </>
              )}
            </div>
          </AllDocumentPages>
        </>
      )}
    </PagesPreview>
  );
};

export default PagesPreviewSidebar;
