import { useState } from "react";
import { withTheme } from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import {
  ActivePage,
  MaxPage,
  NavigationItem,
  Pages,
  PagesNavigation,
  PerPage,
  PerPageContainer,
  Separator,
} from "./paginationStyles";

const Pagination = ({
  totalPages,
  pagination,
  onPaginationChange = function () {},
  sizesForPagination = [10, 25, 50],
  scrollRef,
}) => {
  const [paginationBuffer, setPaginationBuffer] = useState(pagination.page);

  // handle input to input field to change page after 500ms of no input

  const handleInput = (e) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    setPaginationBuffer(value);
    debounceInput(value);
  };

  const debounceInput = useDebouncedCallback((value) => {
    if (value > 0 && value <= totalPages && !isNaN(value)) {
      if (scrollRef) scrollRef.current.scrollIntoView({ behavior: "smooth" });
      else window.scrollTo(0, 0);
      onPaginationChange({
        size: pagination.size,
        page: Number(value),
      });
    }
  }, 700);

  const changePagination = (activePage, perPage) => {
    if (scrollRef) scrollRef.current.scrollIntoView({ behavior: "smooth" });
    else window.scrollTo(0, 0);
    onPaginationChange({
      size: perPage,
      page: activePage,
    });
    setPaginationBuffer(activePage);
  };

  return (
    <div>
      <PagesNavigation>
        <NavigationItem
          active={pagination.page > 1}
          onClick={() =>
            pagination.page > 1 &&
            changePagination(pagination.page - 1, pagination.size)
          }
        >
          <p>Précedent</p>
        </NavigationItem>

        <Pages>
          <ActivePage>
            <input
              type="string"
              accept="number"
              value={paginationBuffer}
              onChange={(e) => handleInput(e)}
            />
          </ActivePage>
          <MaxPage>
            <p>/</p>
            <p
              onClick={() => {
                onPaginationChange({
                  size: pagination.size,
                  page: totalPages,
                });
              }}
            >
              {totalPages}
            </p>
          </MaxPage>
        </Pages>

        <NavigationItem
          active={pagination.page < totalPages}
          onClick={() =>
            pagination.page < totalPages &&
            changePagination(pagination.page + 1, pagination.size)
          }
        >
          <p>Suivant</p>
        </NavigationItem>
      </PagesNavigation>
      <PerPageContainer>
        {sizesForPagination.map((size, i) => {
          return (
            <span key={i}>
              <PerPage
                active={pagination.size === size}
                onClick={() => changePagination(pagination.page, size)}
              >
                <p>{size}</p>
                <p>Par page</p>
              </PerPage>
              {i < 2 && <Separator active={pagination.size === size} />}
            </span>
          );
        })}
      </PerPageContainer>
    </div>
  );
};

export default withTheme(Pagination);
