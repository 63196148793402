import { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import ErrorsWrapper from "../layout/errorsWrapper/errorsWrapper";
import { customStyles, SelectContainer } from "./styledSelectStyles";

import Select from "react-select";

const StyledSelect = (props) => {
  const {
    label,
    name,
    options,
    action = () => {},
    required,
    disabled = false,
    defaultValue,
    setFieldValue = () => {},
    errors,
    touched,
    handleBlur = () => {},
    blockChange = false,
    shouldClear = false,
  } = props;

  const [selectedOption, setSelectedOption] = useState(
    defaultValue
      ? options.find((opt) => opt.key === defaultValue) || null
      : null
  );

  const init = () => {
    const opt = defaultValue
      ? options.find((opt) => opt.key === defaultValue) || null
      : null;

    if (opt) {
      setSelectedOption(opt);
      setFieldValue(name, opt.key);
    } else {
      setSelectedOption(null);

      setFieldValue(name, null);
    }
  };

  // first time
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (shouldClear) {
      setSelectedOption({
        key: "",
        value: "",
      });
      setFieldValue(name, null);
    }
  }, [shouldClear]);

  // every changing
  useEffect(() => {
    init();
  }, [options, defaultValue]);

  const handleChange = (opt) => {
    let mappedObject = {
      key: opt.label,
      value: opt.value,
    };
    action(mappedObject.key);
    if (!blockChange) {
      setSelectedOption(mappedObject);
      setFieldValue(name, mappedObject.key, true);
    }
  };

  const mapOptionsToSelectOptions = () => {
    return options.map((opt) => {
      return {
        value: opt.value,
        label: opt.key,
      };
    });
  };

  return (
    <SelectContainer disabled={disabled}>
      <label htmlFor={name}>
        {label} {required ? " *" : null}
      </label>
      <Select
        name={name}
        options={mapOptionsToSelectOptions()}
        value={{
          label: selectedOption?.key,
          value: selectedOption?.value,
        }}
        getOptionLabel={(option) => option.value}
        onChange={(opt) => handleChange(opt)}
        isDisabled={disabled}
        isLoading={false}
        isSearchable={true}
        styles={customStyles}
        onBlur={handleBlur}
        noOptionsMessage={() => "Aucune option"}
      />

      {errors && touched ? <ErrorsWrapper errors={errors} /> : null}
    </SelectContainer>
  );
};

export default withTheme(StyledSelect);
