import { store } from "../redux/store";

const { dispatch } = store;

export const ADBService = {
  runService: (action) => {
    const { type, payload, handlers = {}, controlProps = {} } = action;
    const {
      onSuccess = () => {},
      onError = () => {},
      onChange = () => {},
    } = handlers;
    dispatch({
      type,
      payload,
      onSuccess,
      onError,
      onChange,
      controlProps,
    });
  },
  combineRunServices: (services) => {
    services.forEach((service) => {
      ADBService.runService(service);
    });
  },
};
