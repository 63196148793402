//  DocumentPreview
//  Component to handle a display when there is a document to preview
//  and actions to perform on it, such as delete, upload, download, and preview
//  which opens a popup to preview the document
//  There is also a drag and drop wrapper to handle drag and drop events on the document preview
//  to upload a new document
//  Props:
//     - uploadAction: function, function to call when user uploads a new document
//     - fetchSpinnerAction: function, function that toggles a display of spinner when modifying the document
//     - processingSuccess: function, function to call when the document is successfully modified
//     - setPopupState: function, function to call to open a popup to preview the document
//     - filesRef: object, information about the file to preview
//     - imgPreviewUrl: string, url for the image to show
//     - errors: object, errors to display for the document
//     - blocked: boolean, if the document is blocked or not
//  Functions:
//     - openDocumentPreview: function, opens a popup to preview the document
//     - deleteDocument: function, calls API to delete the document
//     - downloadDocument: function, downloads the pdf version of the document from the API and saves it to the user's computer

import { pdfjs } from "react-pdf";
import DocumentSmallActions from "./documentActions/documentActions";
import FileStatus from "components/forms/synthese/fileStatus";
import { getStatutPiece } from "components/forms/utils/pieceUtils";
import documentsIcon from "assets/icons/documents.webp";
import {
  DocumentPreviewSmall,
  DocumentTextureContainer,
  FileAndInfo,
} from "pages/dossierLocation/forms/formsStyles";
import { FilePreviewAndActions } from "pages/dossierLocation/forms/formsStyles";

const DocumentPreview = ({
  uploadAction = () => {},
  downloadDocument = () => {},
  deleteDocument = () => {},
  openDocumentPreview = () => {},
  filesRef,
  imgPreviewUrl,
  errors,
  blocked,
  shouldShowActions,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const status = getStatutPiece(filesRef?.statut);
  const accepte = filesRef?.accepte;

  return (
    <div>
      {status && (
        <FileStatus status={status} message={errors} positionFix="left" />
      )}
      {accepte && status === "error" && (
        <FileStatus
          status="locked"
          positionFix="left"
          message={
            "Ce document est bloqué. Veuillez vérifier les données du formulaire dans la page situation"
          }
        />
      )}
      <FilePreviewAndActions>
        <DocumentTextureContainer>
          <img src={documentsIcon} alt="" />
        </DocumentTextureContainer>

        <FileAndInfo>
          <DocumentPreviewSmall>
            <img
              src={imgPreviewUrl}
              alt=""
              onClick={() => openDocumentPreview()}
            />
          </DocumentPreviewSmall>
        </FileAndInfo>

        {shouldShowActions && (
          <DocumentSmallActions
            openDocumentAction={() => openDocumentPreview()}
            uploadDocumentAction={(e) => uploadAction(e)}
            deleteDocumentAction={() => deleteDocument()}
            downloadDocumentAction={() => downloadDocument()}
            pieceNumerique={filesRef}
            blocked={blocked}
          />
        )}
      </FilePreviewAndActions>
    </div>
  );
};

export default DocumentPreview;
