import styled, { keyframes, css } from "styled-components";

export const SyntheseItemContainer = styled.div`
  display: flex;
  gap: 48px;
  position: relative;
  align-items: center;
  height: 240px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: ${({ theme }) => theme.margins[8]};
  }
  img {
    cursor: pointer;
  }
`;

export const SyntheseNoDocumentUploaded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.paddings[8]}
    ${({ theme }) => theme.paddings[4]};
  background: linear-gradient(160.9deg, #fdfbfb 2.15%, #eef1f5 116.37%);
  position: relative;
  cursor: pointer;
  transition: 400ms ease-in-out;

  border-radius: 8px;
  height: 100%;
  p {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
    width: 100%;
    text-align: center;
    margin-top: ${({ theme }) => theme.margins[4]};
  }
  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.primary};

    path {
      stroke: ${({ theme }) => theme.colors.textOnPrimary} !important;
    }
    color: ${({ theme }) => theme.colors.textOnPrimary};
    p {
      color: ${({ theme }) => theme.colors.textOnPrimary};
    }

    & > div > img {
      transform: rotate(15deg);
    }
  }
  & > div {
    img {
      width: 90%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    & > div {
      img {
        width: 80%;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    & > div {
      img {
        width: 90%;
      }
    }
  }
`;

export const Statuts = styled.div`
  position: absolute;
  right: ${({ positionFix }) => positionFix === "right" && "12px"};
  left: ${({ positionFix }) => positionFix === "left" && "12px"};
  top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 34px;
  width: 100%;
`;

const processingScannerAnimation = keyframes`
  0% {
    top: 16px;
    opacity: 1;   
  }

  45% {
    top: 48px;
  }
  55%{
    top: 48px;
  }
  90% {
    top: 16px;
  }
 
  100%{
    top: 16px;
    opacity: 1;
  }
`;

export const StatusOverlay = styled.div`
  position: absolute;
  top: 12px;
  right: ${({ positionFix }) => positionFix === "right" && "12px"};
  left: ${({ positionFix }) => positionFix === "left" && "12px"};

  padding: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  max-width: 34px;
  max-height: 34px;
  width: 100%;
  height: calc(100% + 3px) !important;
  transition: 400ms ease-in-out;
  z-index: 52;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.error};
  overflow: hidden;

  svg {
    width: 18px !important;
    height: 18px !important;
    transition: 400ms ease-in-out;
    path {
      transition: 300ms ease-in-out;
    }
  }

  & > ul {
    max-height: 65%;
    overflow-y: auto;
    text-overflow: ellipsis;
    padding-right: 8px;
    list-style-type: none;
    padding-left: 12px;
    margin-top: 12px;
  }

  & > ul > li {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    color: ${({ theme }) => theme.colors.error};
    font-weight: 600 !important;
    width: 100%;
    text-align: center;
    transition: 200ms ease-in-out;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    white-space: pre-wrap;
    text-align: left;
    position: relative;
    margin-bottom: 8px;
    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.error};
    }
  }

  ${({ showStatus, positionFix }) =>
    showStatus &&
    css`
      max-width: calc(100% + 4px);
      max-height: calc(100% + 4px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      transform: translate(-2px, -2px);
      border-radius: 8px;
      top: 0px !important;
      z-index: 53;
      right: ${positionFix === "right" && "0px"};
      left: ${positionFix === "left" && "0px !important"};
      background: rgba(255, 255, 255, 0.9);

      svg {
        transform: scale(1.5);
        margin-left: 16px;
        margin-top: 16px;
        transition-delay: 0ms;
      }
      & > ul > li {
        max-height: 100%;
        opacity: 1;
        transition: 400ms ease-in-out;
        transition-delay: 400ms;

        padding-left: 12px;
        padding-right: 8px;
      }
    `}

  ${({ status }) => {
    if (status === "error") {
      return css`
        & > ul > li {
          color: ${({ theme }) => theme.colors.error};
        }
        svg {
          path {
            fill: #f15962;
          }
        }
      `;
    } else if (status === "locked") {
      return css`
        top: 56px;
        border: 2px solid #f99417;

        & > ul > li {
          color: #f99417;
          &::before {
            background: #f99417;
          }
        }
        svg {
          position: relative;
          path {
            fill: #f99417;
          }
        }
      `;
    } else if (status === "valid") {
      return css`
        border: 2px solid #54b435;
        & > ul > li {
          color: #54b435;
          &::before {
            background: #54b435;
          }
        }
        svg {
          path {
            stroke: #54b435;
            stroke-width: 3px;
          }
        }
      `;
    }
  }}

   ${({ showStatus, status }) => {
    if (!showStatus && status === "error") {
      return css`
        &:hover {
          background: #f15962;
          svg {
            path {
              fill: hsl(8, 90%, 92%) !important;
            }
          }
        }
      `;
    } else if (!showStatus && status === "processing") {
      return css`
        &:hover {
          background: #0081c9;
          svg {
            path {
              fill: hsl(8, 90%, 92%) !important;
            }
          }
        }
      `;
    } else if (!showStatus && status === "valid") {
      return css`
        &:hover {
          background: #54b435;
          svg {
            path {
              stroke: hsl(8, 90%, 92%) !important;
            }
          }
        }
      `;
    } else if (showStatus && status === "processing") {
      return css`
        &:before {
          width: 38px;
          height: 2px;
          content: "";
          position: absolute;
          left: 12px;
          top: 8px;
          background: #0081c9;
          box-shadow: 0 0 8px 4px rgba(0, 129, 201, 0.4);
          z-index: 11;
          animation-name: ${processingScannerAnimation};
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          animation-delay: 800ms;
          overflow: hidden;
          opacity: 0;
        }
      `;
    }
  }}
`;
