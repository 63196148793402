import { toast } from "react-toastify";
import { call, takeLatest } from "redux-saga/effects";
import * as utilisateurLOQKApi from "utils/api/utilisateurLOQK";
import { UTILISATEURS_LOQK_LOAD, UTILISATEUR_LOQK_CREATE } from "./actions";

function* createLOQKUtilisateur(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);

    const utilisateurCreateResponse = yield call(
      utilisateurLOQKApi.createUtilisateurLOQK,
      {
        ...payload,
        typeCompte: "LOQK",
        type: "LOQK",
      }
    );
    if (utilisateurCreateResponse.status === 200) {
      onSuccess(utilisateurCreateResponse.data);
      toast.success("L'utilisateur a été créé avec succès", {
        toastId: "createLOQKUtilisateur",
        updateId: "createLOQKUtilisateur",
      });
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors de la création de l'utilisateur",
      {
        toastId: "createLOQKUtilisateurError",
        updateId: "createLOQKUtilisateurError",
      }
    );
  } finally {
    onChange(false);
  }
}

function* loadLOQKUtilisateurs(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);

    const utilisateursGetResponse = yield call(
      utilisateurLOQKApi.getUtilisateursLOQK,
      payload
    );

    if (utilisateursGetResponse.status === 200) {
      onSuccess([utilisateursGetResponse.data]);
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors du chargement des utilisateurs LOQK",
      {
        toastId: "loadLOQKUtilisateurs",
      }
    );
  } finally {
    onChange(false);
  }
}

function* watchCreateLOQKUtilisateur() {
  yield takeLatest(UTILISATEUR_LOQK_CREATE, createLOQKUtilisateur);
}

function* watchGetLOQKUtilisateurs() {
  yield takeLatest(UTILISATEURS_LOQK_LOAD, loadLOQKUtilisateurs);
}

export { watchCreateLOQKUtilisateur, watchGetLOQKUtilisateurs };
