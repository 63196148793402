import { isEmpty } from "lodash";
import * as Yup from "yup";
import {
  CODE_NAF_REGEX,
  DEPARTEMENT_REGEX,
  EMAIL_REGEX,
  SIRET_REGEX,
  TELEPHONE_REGEX,
} from "utils/constantes";
import { validateSituationProfessionnelle } from "utils/helpers/situationProHelper";

// Returns true if valid, false if not. Note that registrationNumber param is a string (usually provided by HTML imput)
const validateRegistrationNumber = (registrationNumber) => {
  if (registrationNumber.length !== 14) {
    return false;
  }
  let sum = 0;
  let digit = undefined;
  for (let i = 0; i < registrationNumber.length; i++) {
    if (i % 2 === 0) {
      digit = parseInt(registrationNumber.charAt(i), 10) * 2;
      digit = digit > 9 ? (digit -= 9) : digit;
    } else {
      digit = parseInt(registrationNumber.charAt(i), 10);
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

export const DossierLocataireValidator = Yup.object().shape({
  qualite: Yup.string().required("Le qualité est obligatoire").nullable(),
  nom: Yup.string().required("Le nom est obligatoire").nullable(),
  prenom: Yup.string().required("Le prenom est obligatoire").nullable(),
  email: Yup.string()
    .trim()
    .matches(EMAIL_REGEX, "Format de votre adresse électronique erroné")
    .required("Veuillez saisir une adresse électronique valide")
    .nullable(),
  telephone: Yup.string()
    .required("Le numéro de téléphone est obligatoire")
    .trim()
    .matches(TELEPHONE_REGEX, "Veuillez saisir un numéro FR valide")
    .nullable(),
  dateNaissance: Yup.string()
    .required("date de naissance est obligatoire")
    .nullable(),
  enfants: Yup.array()
    .of(
      Yup.number()
        .max(30, "Merci de vérifier l'age de l'enfant < 30 ans")
        .required("Age de l'enfant est obligatoire")
    )
    .nullable(),
  codeNaf: Yup.string()
    .trim()
    .matches(CODE_NAF_REGEX, "veuillez vérifier le format du code NAF/APE")
    .notRequired()
    .nullable(),
  departement: Yup.string()
    .trim()
    .matches(DEPARTEMENT_REGEX, "Veuillez saisir un departement valide")
    .required("Veuillez saisir le département de vôtre recherche")
    .nullable(),
  siret: Yup.string()
    .trim()
    .matches(SIRET_REGEX, "Veuillez saisir un numéro de Siret valide")
    .notRequired()
    .nullable(),
  dateDebutSituation: Yup.string()
    .required("Veuillez saisir une date de début de situation")
    .nullable(),
  statutActuel: Yup.string()
    .required("Veuillez saisir le statut actuel du candidat")
    .nullable(),
  statutDepuis: Yup.string()
    .required("Veuillez saisir cette information")
    .nullable(),
  situationPerso: Yup.string()
    .required("Veuillez saisir une situation personnelle")
    .nullable(),
  statutPro: Yup.string().nullable(),
  situationPro: Yup.string()
    .required("Veuillez saisir une situation professionnelle")
    .nullable(),
});

export const DemandeCandidateValidator = Yup.object().shape({
  qualite: Yup.string().required("Le qualité est obligatoire").nullable(),
  nom: Yup.string().required("Le nom est obligatoire").nullable(),
  prenom: Yup.string().required("Le prenom est obligatoire").nullable(),
  email: Yup.string()
    .trim()
    .matches(EMAIL_REGEX, "Format de votre adresse électronique erroné")
    .required("Veuillez saisir une adresse électronique valide")
    .nullable(),
  telephone: Yup.string()
    .required("Le numéro de téléphone est obligatoire")
    .trim()
    .matches(TELEPHONE_REGEX, "Veuillez saisir un numéro FR valide")
    .nullable(),
  dateNaissance: Yup.string()
    .required("date de naissance est obligatoire")
    .nullable(),
  enfants: Yup.array()
    .of(
      Yup.number()
        .max(30, "Merci de vérifier l'age de l'enfant < 30 ans")
        .required("Age de l'enfant est obligatoire")
    )
    .nullable(),
  codeNaf: Yup.string()
    .trim()
    .matches(CODE_NAF_REGEX, "veuillez vérifier le format du code NAF/APE")
    .notRequired()
    .nullable(),
  departement: Yup.string()
    .trim()
    .matches(DEPARTEMENT_REGEX, "Veuillez saisir un departement valide")
    .required("Veuillez saisir le département de vôtre recherche")
    .nullable(),
  siret: Yup.string()
    .trim()
    .matches(SIRET_REGEX, "Veuillez saisir un numéro de Siret valide")
    .notRequired()
    .nullable(),
  dateDebutSituation: Yup.string()
    .required("Veuillez saisir une date de début de situation")
    .nullable(),
  statutActuel: Yup.string()
    .required("Veuillez saisir le statut actuel du candidat")
    .nullable(),
  statutDepuis: Yup.string()
    .required("Veuillez saisir cette information")
    .nullable(),
  situationPerso: Yup.string()
    .required("Veuillez saisir une situation personnelle")
    .nullable(),
  statutPro: Yup.string().nullable(),
  situationPro: Yup.string()
    .required("Veuillez saisir une situation professionnelle")
    .nullable(),
  revenusMensuels: Yup.number()
    .required("Veuillez saisir un montant")
    .nullable()
    .min(0, "Veuillez saisir un montant positif")
    .max(99999, "Veuillez saisir un montant inférieur à 100 000"),
});

export const AdvancedDemandeCandidateValidator = (values) => {
  let errors = {};

  // validate siret value
  if (values?.siret && !validateRegistrationNumber(values?.siret)) {
    errors = {
      ...errors,
      siret: "Veuillez saisir un numéro de siret valide",
    };
  }

  if (!values?.revenusMensuels && values?.revenusMensuels !== 0) {
    errors = {
      ...errors,
      revenusMensuels: "Veuillez saisir un montant",
    };
  }

  const validator = validateSituationProfessionnelle(values);
  // if any data is displayed => depending on situation, the advanced validator will put the error
  if (validator.withDisplayedFiels) {
    if (validator.displayStatutPro && !values.statutPro)
      errors = {
        ...errors,
        statutPro: "Veuillez saisir une situation professionnelle",
      };

    if (validator.displayDateFinSituation && !values.dateFinSituation)
      errors = {
        ...errors,
        dateFinSituation: "Veuillez saisir une date de fin de situation",
      };
    if (
      validator.displayDateDernierBulletin &&
      !values.dateDernierBulletinSalaire
    )
      errors = {
        ...errors,
        dateDernierBulletinSalaire:
          "Veuillez saisir la date du dernier bulletin de salaire",
      };
    if (validator.displayCodeNaf && !values.codeNaf)
      errors = {
        ...errors,
        codeNaf: "Veuillez saisir le code APE",
      };
    if (validator.displaySiret && !values.siret)
      errors = {
        ...errors,
        siret: "Veuillez saisir le siret de vôtre entreprise",
      };
    if (
      validator.displayTravailler24DerniersMois &&
      !values.travaille24DerniersMois
    )
      errors = {
        ...errors,
        travaille24DerniersMois: "Veuillez choisir une valeur",
      };
  }
  return errors;
};

export const AdvancedDossierLocataireValidator = (values) => {
  let errors = {};

  // validate siret value
  if (values?.siret && !validateRegistrationNumber(values?.siret)) {
    errors = {
      ...errors,
      siret: "Veuillez saisir un numéro de siret valide",
    };
  }

  const validator = validateSituationProfessionnelle(values);
  // if any data is displayed => depending on situation, the advanced validator will put the error
  if (validator.withDisplayedFiels) {
    if (validator.displayStatutPro && !values.statutPro)
      errors = {
        ...errors,
        statutPro: "Veuillez saisir une situation professionnelle",
      };

    if (validator.displayDateFinSituation && !values.dateFinSituation)
      errors = {
        ...errors,
        dateFinSituation: "Veuillez saisir une date de fin de situation",
      };
    if (
      validator.displayDateDernierBulletin &&
      !values.dateDernierBulletinSalaire
    )
      errors = {
        ...errors,
        dateDernierBulletinSalaire:
          "Veuillez saisir la date du dernier bulletin de salaire",
      };
    if (validator.displayCodeNaf && !values.codeNaf)
      errors = {
        ...errors,
        codeNaf: "Veuillez saisir le code APE",
      };
    if (validator.displaySiret && !values.siret)
      errors = {
        ...errors,
        siret: "Veuillez saisir le siret de vôtre entreprise",
      };
    if (
      validator.displayTravailler24DerniersMois &&
      !values.travaille24DerniersMois
    )
      errors = {
        ...errors,
        travaille24DerniersMois: "Veuillez choisir une valeur",
      };
  }
  return errors;
};

export const canInfoPersoShowMessage = (values) => {
  const { qualite, nom, prenom, dateNaissance, telephone } = values;
  return qualite && nom && prenom && dateNaissance && telephone;
};

export const canStatutLocataireShowMessage = (values) => {
  const { statutDepuis, statutActuel, departement } = values;
  return statutDepuis && statutActuel && departement;
};

export const canSitatutPersonnelleShowMessage = (values) => {
  const { situationPerso, nombreEnfants } = values;

  return (
    situationPerso && nombreEnfants !== null && nombreEnfants !== undefined
  );
};

export const canSituationProShowMessage = (values) => {
  const { situationPro, dateDebutSituation } = values;
  const requiredFilled = situationPro && dateDebutSituation;
  const personalCaseValidation = AdvancedDossierLocataireValidator(values);
  return requiredFilled && isEmpty(personalCaseValidation);
};
