import styled from "styled-components";

export const StyledLoqkPassBanner = styled.div`
    padding: ${({theme}) => theme.paddings[4]} ${({theme}) => theme.paddings[12]};
    background-color: #FDFBFB;
    border-radius: 16px;
    border: 1px solid ${({theme}) => theme.colors.border};
    display: flex;
    max-width: max-content;
    gap: 8px;
    p{
        font: ${({theme, fontSize}) => theme.fonts.montserrat[fontSize]};
        font-weight: 600 !important;
        margin: 0;
        padding: ${({theme}) => theme.paddings[1]} 0;
        &:first-of-type{
            color: ${({theme}) => theme.colors.primary};
        }
       
    }
    input{
        font: ${({theme, fontSize}) => theme.fonts.montserrat[fontSize]};
        font-weight: 600 !important;
        margin: 0;
        padding: ${({theme}) => theme.paddings[1]} 0;
        color: ${({theme}) => theme.colors.secondary};
        border-bottom: 2px solid ${({theme}) => theme.colors.secondary};
        max-width: 177px;
        width: max-content;

    }
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.small}){
        p{
            font: ${({theme}) => theme.fonts.montserrat.heading};
        }
        input{
            font: ${({theme}) => theme.fonts.montserrat.heading};
            max-width: 120px;
        }
        padding: ${({theme}) => theme.paddings[4]} ${({theme}) => theme.paddings[12]};
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.mobile}){
        justify-content: center;
        width: 100%;
        max-width: unset;
        
    }

`