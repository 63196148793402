import styled from "styled-components";

export const DetailsPopupContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: 400ms ease-in-out;
`;

export const SyntheseReturnLink = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: 0.5;
  cursor: pointer;
  transition: 400ms ease-in-out;
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600 !important;
  margin-top: ${({ theme }) => theme.margins[12]};
  svg {
    transform: rotate(90deg);
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  &:hover {
    opacity: 1;
  }
`;

export const DetailsPopupContent = styled.div`
  padding: ${({ theme }) => theme.paddings[8]}
    ${({ theme }) => theme.paddings[12]};
  background: ${({ theme }) => theme.colors.textOnPrimary};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  max-width: 768px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  svg {
    cursor: pointer;
  }
  & > div {
    &:first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font: ${({ theme }) => theme.fonts.montserrat.heading};
        font-weight: 600;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0;
        text-align: center;
      }
    }
    &:nth-of-type(2) {
      margin-top: ${({ theme }) => theme.margins[8]};
      overflow-y: auto;
      height: 100%;
      border: 8px;
      & > div {
        padding: ${({ theme }) => theme.paddings[4]};
        overflow-y: auto;
        height: 100%;
      }
      h3 {
        font: ${({ theme }) => theme.fonts.montserrat.highlight} !important;
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0;
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 75vw;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    max-width: 100%;
    top: 144px;
    border-radius: 0;
    height: calc(100% - 144px);
    left: 0;
    transform: none;
    padding: ${({ theme }) => theme.paddings[6]}
      ${({ theme }) => theme.paddings[8]};
    & > div {
      &:nth-of-type(2) {
        & > div {
          height: 100%;
        }
      }
    }
  }
`;
