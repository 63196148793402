import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterWrapper = styled.footer`
  padding-top: ${(props) => props.theme.paddings[14]};
  padding-bottom: ${(props) => props.theme.paddings[9]};
  margin-top: auto;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(props) => props.theme.paddings[12]};
  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-right: ${(props) => props.theme.paddings[6]};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    & > div {
      margin-bottom: ${(props) => props.theme.margins[14]};
      flex-direction: column;
      gap: 24px;
      padding-right: 0;
    }
    a {
      margin-left: 0;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primaryOpaque};
  cursor: pointer;
  margin-left: ${(props) => props.theme.margins[8]};
  font: ${(props) => props.theme.fonts.inter.regular};
`;

export const LogoLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.margins[8]};
  h3 {
    font: ${(props) => props.theme.fonts.montserrat.heading};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.primary};
    strong {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
  svg {
    width: 88px;
    height: 88px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    h3 {
      display: none;
    }
  }
`;

export const Copyright = styled.p`
  font: ${(props) => props.theme.fonts.inter.regular};
  color: ${(props) => props.theme.colors.primaryOpaque};
  text-align: center;
`;
