import { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import { RenderIf } from "components";
import {
  CustomInputContainer,
  EyeIcon,
  InputWrapper,
} from "./styledInputStyles";
import { ReactComponent as EuroIcon } from "assets//icons/euro.svg";
import { ReactComponent as Warning } from "assets//icons/warning.svg";
import { ReactComponent as Tooltip } from "assets//icons/info.svg";
import ErrorsWrapper from "../layout/errorsWrapper/errorsWrapper";
import TooltipPopup from "../tooltip/tooltip";
import { mapAttachmentTypeToImage } from "./../tooltip/utils";
import { ReactComponent as Eye } from "assets//icons/eye.svg";
import { ReactComponent as EyeOff } from "assets//icons/eyeClosed.svg";
import { ReactComponent as SearchIcon } from "assets//icons/search.svg";
import Spinner from "../spinner/spinner";

const StyledInput = ({
  disabled = false,
  defaultValue = undefined,
  type,
  action = () => {},
  name,
  placeholder,
  label,
  required = false,
  min = false,
  max = false,
  errors,
  touched,
  handleBlur,
  hasTooltip = false,
  readOnly = false,
  isSubmitting = false,
  forceIcon = false,
}) => {
  const [inputType, setInputType] = useState(type ? type : "text");
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (type === "money") {
      setInputType("number");
    } else if (type === "search") {
      setInputType("text");
    } else setInputType(type);
  }, [type]);

  return (
    <>
      {hasTooltip && (
        <TooltipPopup
          showTooltip={showTooltip}
          onClose={() => setShowTooltip(false)}
          tooltipHeader={label}
          imageRef={mapAttachmentTypeToImage(name)}
        />
      )}

      <CustomInputContainer disabled={disabled}>
        {label ? (
          <label htmlFor={name}>
            {label} {required ? " *" : null}
          </label>
        ) : null}
        <InputWrapper
          hasError={touched && errors}
          hasTooltip={hasTooltip || forceIcon}
          type={type}
          readOnly={readOnly}
          disabled={disabled}
        >
          <RenderIf condition={type === "search"}>
            <SearchIcon />
          </RenderIf>
          <input
            disabled={disabled}
            value={defaultValue}
            min={min !== false ? min : null}
            max={max ? max : null}
            maxLength={max ? max : null}
            type={inputType}
            onChange={action}
            name={name}
            id={name}
            placeholder={placeholder}
            required={required}
            onBlur={handleBlur}
            readOnly={readOnly}
          />
          <RenderIf condition={type === "password"}>
            <EyeIcon
              onClick={() =>
                setInputType(inputType === "text" ? "password" : "text")
              }
            >
              <RenderIf condition={inputType === "text"}>
                <EyeOff />
              </RenderIf>
              <RenderIf condition={inputType === "password"}>
                <Eye />
              </RenderIf>
            </EyeIcon>
          </RenderIf>
          <RenderIf condition={isSubmitting}>
            <Spinner size="tiny" marginY="0" />
          </RenderIf>

          <RenderIf condition={type === "money"}>
            <p>
              <EuroIcon />
            </p>
          </RenderIf>
          {/* {touched && errors ? <Warning /> : null} */}
          {hasTooltip && <Tooltip />}
        </InputWrapper>
        {errors && touched ? (
          <div>
            <ErrorsWrapper errors={errors} />
          </div>
        ) : null}
      </CustomInputContainer>
    </>
  );
};

export default withTheme(StyledInput);
