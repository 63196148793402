//  InfosPerso
//  Form section for personal informations of the tenant candidate

import { QUALITE_ENUM } from "utils/constantes";

import { canInfoPersoShowMessage } from "../utils/dossierLocataireValidator";
import {
  FormSection,
  StyledInput,
  StyledSelect,
  StyledDatePicker,
} from "../../index";
import StyledMaskedInput from "../../styledInput/styledInputMasked";
import {
  getDateFromStringApi,
  getMajorityDate,
  getMax100yearsBirthDate,
} from "utils/dateUtils";

const InfosPersoSection = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  blocked,
}) => {
  const { qualite, nom, prenom, dateNaissance, telephone } = values;
  const majorityDate = getMajorityDate();
  const max100yearsDate = getMax100yearsBirthDate();
  return (
    <FormSection
      hasBorder
      title={"Informations Personnelles"}
      showBonjour={true}
      isValid={canInfoPersoShowMessage({
        qualite,
        nom,
        prenom,
        dateNaissance,
        telephone,
      })}
      values={{ qualite, nom, prenom, dateNaissance, telephone }}
    >
      <StyledSelect
        label="Qualité"
        name="qualite"
        required
        options={QUALITE_ENUM}
        defaultValue={values.qualite}
        setFieldValue={setFieldValue}
        action={handleChange}
        disabled={isSubmitting || blocked}
        errors={errors?.qualite}
        handleBlur={handleBlur}
        touched={touched.qualite}
      />

      <StyledInput
        category="personal"
        type="text"
        name="nom"
        placeholder="Nom du locataire"
        label="Nom"
        required
        defaultValue={values.nom}
        errors={errors?.nom}
        touched={touched.nom}
        action={handleChange}
        handleBlur={handleBlur}
        disabled={isSubmitting || blocked}
      />

      <StyledInput
        category="personal"
        type="text"
        name="prenom"
        placeholder="Prénom du locataire"
        label="Prénom"
        required
        defaultValue={values.prenom}
        errors={errors?.prenom}
        touched={touched.prenom}
        action={handleChange}
        handleBlur={handleBlur}
        disabled={isSubmitting || blocked}
      />

      <StyledInput
        category="personal"
        type="text"
        name="email"
        placeholder="Email"
        label="Email"
        required
        defaultValue={values.email}
        errors={errors?.email}
        touched={touched.email}
        action={handleChange}
        handleBlur={handleBlur}
        disabled={isSubmitting || blocked}
      />

      <StyledDatePicker
        category="personal"
        name="dateNaissance"
        placeholder="15/09/1990"
        label="Date naissance"
        defaultDate={getDateFromStringApi(values.dateNaissance)}
        required={true}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        handleBlur={handleBlur}
        errors={errors?.dateNaissance}
        touched={touched.dateNaissance}
        disabled={isSubmitting || blocked}
        maxDate={majorityDate}
        minDate={max100yearsDate}
        showMonthDropdown
        showYearDropdown
      />

      <StyledMaskedInput
        category="personal"
        type="text"
        name="telephone"
        placeholder="0606882015"
        mask={[0, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        label="Téléphone"
        required
        defaultValue={values.telephone}
        errors={errors?.telephone}
        touched={touched.telephone}
        action={handleChange}
        handleBlur={handleBlur}
        disabled={isSubmitting || blocked}
      />
    </FormSection>
  );
};

export default InfosPersoSection;
