import styled, { css } from "styled-components";

export const RevenuItemContainer = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: ${({ theme }) => theme.margins[8]};
    flex-direction: column;
    gap: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
  img {
    cursor: pointer;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 48px;
  width: 320px;
  align-items: center;
  position: relative;
  & > div {
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    max-width: 320px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: ${({ theme }) => theme.margins[4]};
    flex-direction: column;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    max-width: unset;
  }
  img {
    cursor: pointer;
  }
`;

export const PreviewWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primaryOpaque};
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  ${({ borderStyle, accepte }) => {
    if (borderStyle && !accepte) {
      return css`
        border: 2px solid ${({ theme }) => theme.colors.border};
        border-radius: 8px;
      `;
    } else if (accepte) {
      return css`
        border: 2px solid #f99417;
        border-radius: 8px;
      `;
    }
  }}
`;

export const Warning = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${({ theme }) => theme.margins[4]};
  align-items: ${({ align }) => (align ? align : "flex-start")};
  grid-area: 2/1/2/3 !important;
  & > div {
    width: 24px;
    height: 24px;
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
    width: 75%;
    color: ${({ theme }) => theme.colors.primaryOpaque};
  }
`;

export const DocumentPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  padding: ${({ theme }) => theme.paddings[4]};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const NoDocumentUploaded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.paddings[8]}
    ${({ theme }) => theme.paddings[4]};
  background: linear-gradient(160.9deg, #fdfbfb 2.15%, #eef1f5 116.37%);
  position: relative;
  cursor: pointer;
  transition: 400ms ease-in-out;
  p {
    font: ${({ theme }) => theme.fonts.inter.small};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    margin-top: ${({ theme }) => theme.margins[4]};
    font-weight: 600 !important;
    transition: 400ms ease-in-out;
  }

  svg {
    width: 40px;
    height: 40px;
    transition: 400ms ease-in-out;
  }
  & > div {
    img {
      width: 100%;
      bottom: -45%;
    }
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary};

    path {
      stroke: ${({ theme }) => theme.colors.textOnPrimary} !important;
    }
    color: ${({ theme }) => theme.colors.textOnPrimary};
    p {
      color: ${({ theme }) => theme.colors.textOnPrimary};
    }

    & > div > img {
      transform: rotate(15deg);
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    & > div {
      img {
        width: 65%;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    max-width: 360px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    & > div {
      img {
        width: 80%;
      }
    }
  }
`;

export const PreviewContainer = styled.div`
  padding: ${({ theme }) => theme.paddings[4]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primaryOpaque};
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: ${({ theme }) => theme.margins[2]} 0
      ${({ theme }) => theme.margins[3]};
    font: ${({ theme }) => theme.fonts.inter.small};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primaryOpaque};
  }
  img {
    width: 135px;
    height: 120px;
  }
`;

export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 12px;
  justify-content: space-between;

  width: 100%;
  & > div {
    input {
      width: 100%;
    }

    grid-area: ${({ whole }) => (whole ? "1/1/1/3" : "auto")};
  }
  & > p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    opacity: 0.6;
    color: ${({ theme }) => theme.colors.error};

    font-weight: 600 !important;
    grid-column: span 2;
    max-width: 500px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    max-width: 360px;
    grid-template-columns: 1fr;
  }
`;
