import { axiosInstance } from "./axiosInstance";

export const uploadDocument = (
  adbId,
  siteId,
  locationId,
  locataireId,
  pieceNumeriqueId,
  file,
  filename
) => {
  const form = new FormData();
  form.append("file", file, filename);

  return axiosInstance.post(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${locationId}/dossier-locataires/${locataireId}/dossier-numerique/piece-numeriques/${pieceNumeriqueId}`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
