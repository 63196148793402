import styled from "styled-components";

export const ActionsLine = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  grid-column: span ${({ colSpan }) => (colSpan ? colSpan : 1)};
  justify-self: ${({ justify }) => (justify ? justify : "center")};
  & > h3 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
  }
  & > div {
    &:first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: ${({ theme }) => theme.paddings[4]} 0;

      svg {
        transform: ${({ collapse }) =>
          collapse ? "rotate(180deg)" : "rotate(0deg)"};
        transition: 350ms ease-in-out;
        transform-origin: center;
      }
    }
    &:last-of-type {
      max-height: ${({ collapse }) => (!collapse ? "0px" : "768px")};
      visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
      opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
      transition: 400ms ease-in-out;
    }
  }
  & > p {
    &:first-of-type {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primaryOpaque};
      display: none;
    }
  }
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 90% !important;
    & > p {
      &:first-of-type {
        display: block;
      }
    }
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: 350ms ease-in-out;
  background: transparent;
  padding: ${({ theme }) => (theme ? theme.paddings[3] : "0")};
  justify-content: space-between;
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.hover};
  }
`;
