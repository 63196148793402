import styled from "styled-components";

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  & > label {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    color: ${({ theme }) => theme.colors.primaryOpaque};
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")} !important;
`;

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    borderBottom: "1px solid rgba(30, 39, 97,0.3)",
    boxShadow: "none",
    borderRadius: "0px",
    backgroundColor: "transparent",
    cursor: "pointer",
    "&:hover": {
      border: "none",
      borderBottom: "1px solid rgba(30, 39, 97,0.3)",
      boxShadow: "none",
      borderRadius: "0px",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: "rgba(30, 39, 97,.5)",
    backgroundColor: "transparent",
    cursor: "pointer",
    padding: "8px 24px",
    transition: "300ms ease-in-out",
    "&:hover": {
      color: "#1e2761",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#1E2761",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    fontSize: "16px",
    padding: "0px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#1E2761",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    fontSize: "16px",
    padding: "8px 0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#fefefe",
    cursor: "pointer",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    fontSize: "16px",
    zIndex: "1000",
  }),
};
