import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as PrintIcon } from "assets/icons/print.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import SidebarCategory from "./sidebarCategory";
import DragAndDropWrapper from "../../layout/dragAndDropWrapper/dragAndDropWrapper";
import documents from "assets/icons/documents.webp";
import {
  CloseToggle,
  DocumentInformationPopup,
  DropUpload,
  MainDocumentSidebar,
  MainSidebarToggle,
} from "../documentPreviewPopup/documentPreviewPopupStyles";
import Spinner from "../../spinner/spinner";
import { useEffect, useState } from "react";
import { DocumentTextureContainer } from "pages/dossierLocation/forms/formsStyles";
import RenderIf from "../../layout/renderIf/renderIf";
import {
  getCategoryPiece,
  getStatutPieceText,
  getTypePiece,
} from "./../../forms/utils/pieceUtils";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useDebouncedCallback } from "use-debounce";

const MainPreviewSidebar = ({
  togglePopup,
  uploadAction,
  deleteAction,
  downloadAction,
  file,
  documentInfo,
  isLoading,
  isFileBlocked,
  showPopup,
}) => {
  const [dragging, setDragging] = useState(false);
  const [showInfo, setShowInfo] = useState("none");
  const [collapse, setCollapse] = useState(true);

  const printPdf = () => {
    // print the pdf file we obtained from the server in arrayBuffer format
    const blob = new Blob([file], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  };

  const handleInfoShow = (info) => {
    if (showInfo === info) {
      setShowInfo("none");
    } else {
      setShowInfo(info);
    }
  };

  const infoOutsideClick = useOutsideClick(() => {
    setShowInfo("none");
  });

  const debouncedReset = useDebouncedCallback(() => {
    setCollapse(true);
    setShowInfo("none");
    setDragging(false);
  }, 600);

  useEffect(() => {
    if (!showPopup) {
      debouncedReset();
    }
  }, [showPopup]);

  return (
    <MainDocumentSidebar isCollapsed={collapse} ref={infoOutsideClick}>
      <MainSidebarToggle
        isCollapsed={collapse}
        onClick={() => setCollapse(!collapse)}
      >
        <span></span>
        <span></span>
        <span></span>
      </MainSidebarToggle>
      <div>
        <DocumentInformationPopup shouldShow={showInfo === "information"}>
          <h2>Information</h2>
          <div>
            <p>Catégorie</p>
            <p>{getCategoryPiece(documentInfo.type)}</p>
          </div>
          <div>
            <p>Type</p>
            <p>{getTypePiece(documentInfo.type)}</p>
          </div>
          <div>
            <p>Statut</p>
            <p>{getStatutPieceText(documentInfo.statut)}</p>
          </div>
        </DocumentInformationPopup>
        <DocumentInformationPopup shouldShow={showInfo === "status"}>
          <h2>Incidents</h2>
          <ul>
            {documentInfo.errors.map((error, index) => (
              <li key={index}>{error.message}</li>
            ))}
          </ul>
          {documentInfo.errors.length === 0 && <p>Aucun</p>}
        </DocumentInformationPopup>
        <CloseToggle onClick={togglePopup}>
          <CloseIcon />
          <p>Fermer</p>
        </CloseToggle>
        <SidebarCategory title="Document">
          <div onClick={() => handleInfoShow("information")}>
            <InfoIcon />
            <p>Information</p>
          </div>
          <div onClick={() => handleInfoShow("status")}>
            <InfoIcon />
            <p>Incidents</p>
            <p>{documentInfo.errors.length}</p>
          </div>
        </SidebarCategory>
        <SidebarCategory title="Actions">
          <div onClick={downloadAction}>
            <DownloadIcon />
            <p>Télecharger</p>
          </div>
          <div onClick={printPdf}>
            <PrintIcon />
            <p>Imprimer</p>
          </div>
          <RenderIf condition={!isFileBlocked}>
            <label>
              {isLoading ? (
                <Spinner size="small" marginX="0px" marginY="0px" />
              ) : (
                <>
                  <UploadIcon />
                  <input
                    type="file"
                    name="documentUpload"
                    id="documentUpload"
                    onChange={uploadAction}
                    value={null}
                  />
                  <p>Importer</p>
                </>
              )}
            </label>
            <div onClick={deleteAction}>
              <TrashIcon />
              <p>Supprimer</p>
            </div>
          </RenderIf>
        </SidebarCategory>
        <RenderIf condition={!isFileBlocked}>
          <DropUpload dragging={dragging}>
            <DragAndDropWrapper
              statut="EN_COURS"
              onDragAndDropHandler={uploadAction}
              updateDrag={setDragging}
            >
              <UploadIcon />
              <p>Déposer le fichier</p>
              <p>Max 10Mb.</p>
              <DocumentTextureContainer>
                <img src={documents} alt="document" />
              </DocumentTextureContainer>
            </DragAndDropWrapper>
          </DropUpload>
        </RenderIf>
      </div>
    </MainDocumentSidebar>
  );
};

export default MainPreviewSidebar;
