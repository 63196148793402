import { withTheme } from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  ActiveCandidate,
  Candidate,
  CandidateInformationContainer,
  CandidateOptions,
  Candidates,
  CandidateWrapper,
  SyntheseContainer,
  SyntheseInfo,
  VisaleContainer,
} from "pages//dossierLocation/forms/formsStyles";

import { ReactComponent as Valid } from "assets//icons/complete.svg";
import { ReactComponent as Issue } from "assets//icons/missingDetails.svg";
import { ReactComponent as Blocked } from "assets//icons/document_blocked.svg";
import { ReactComponent as Chevron } from "assets//icons/chevron.svg";
import { ReactComponent as VisaleIcon } from "assets//icons/visale.svg";

import { Spinner } from "components";
import SyntheseStatus from "./synthese/syntheseStatus/syntheseStatus";

import {
  selectCurrentDossierLocataire,
  selectCurrentDossierLocatairesList,
  selectCurrentDossierNumerique,
  selectDossierLocation,
  setCurrentDossierLocataire,
  setCurrentDossierNumerique,
  setCurrentDossierRevenus,
} from "redux/dossierLocation/dossierLocationReducer";
import { AJOUTER_COLOCATAIRE, SYNTHESE_LOAD } from "utils/saga/actions";
import SyntheseFilesSection from "./synthese/syntheseFilesSection";
import SyntheseRevenusSection from "./synthese/syntheseRevenusSection";
import useOutsideClick from "../../hooks/useOutsideClick";
import { abortRequestAndResetController } from "utils/api/axiosInstance";
import { DossierLocataireStatut } from "utils/Statuts";
import SyntheseIdentitySection from "./synthese/syntheseIdentitySection";
import { TYPE_CANDIDAT_ENUM } from "utils/helpers/DossierLocataireHelper";
import Garant from "./synthese/garant";
import { useDebouncedCallback } from "use-debounce";
import visaleIcon from "../../assets/icons/visale.png";

const SyntheseForm = () => {
  const [showCandidates, setShowCandidates] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);
  const candidateData = useSelector(selectCurrentDossierLocataire);
  const candidatesList = useSelector(selectCurrentDossierLocatairesList);
  const dossierLocation = useSelector(selectDossierLocation);
  const dossierNumerique = useSelector(selectCurrentDossierNumerique);
  const [loadingLocataire, setLoadingLocataire] = useState(false);
  const [renderCount, setRenderCount] = useState(0);
  const candidatesDropdownRef = useOutsideClick(() => {
    setShowCandidates(false);
  });
  const [locatairesList, setLocatairesOnlyList] = useState([]);
  const [garants, setGarants] = useState([]);

  useEffect(() => {
    let garantCandidates = candidatesList.filter(
      (candidate) => candidate.garante !== null && candidate.garante
    );
    let pureCandidates = candidatesList.filter((candidate) => {
      // check if candidate is not in list of garant candidates
      return !garantCandidates.some((garantCandidate) => {
        return garantCandidate.id === candidate.id;
      });
    });

    setGarants([...garantCandidates]);
    setLocatairesOnlyList([...pureCandidates]);
  }, [candidatesList]);

  useEffect(() => {
    dispatch({
      type: SYNTHESE_LOAD,
      payload: {
        dossierLocation,
        onChange: (isLoading) => setIsCandidateLoading(isLoading),
      },
    });
  }, []);

  // garante is the student on witch we will add garant
  const addColocataire = (typeCandidat) => {
    dispatch({
      type: AJOUTER_COLOCATAIRE,
      payload: {
        typeCandidat,
        garanteId:
          typeCandidat === TYPE_CANDIDAT_ENUM.GARANT
            ? candidateData.id
            : undefined,
      },
      onAjouterSuccess: () => navigate("/candidate/forms?category=situation"),
      onAjouterChange: (isLoading) => setIsCandidateLoading(isLoading),
    });
  };

  const debouncedLocataireChange = useDebouncedCallback((candidate) => {
    setLoadingLocataire(false);
  }, 800);

  const handleActiveCandidateChange = (candidate) => {
    abortRequestAndResetController();
    setShowCandidates(false);
    setLoadingLocataire(true);
    dispatch(setCurrentDossierLocataire(candidate));
    dispatch(setCurrentDossierNumerique(candidate.dossierNumerique));
    dispatch(setCurrentDossierRevenus(candidate.revenus));
    debouncedLocataireChange(candidate);
    setRenderCount(renderCount + 1);
  };

  const matchGarantToCandidate = (candidate) =>
    garants.find((garant) => garant?.garante.id === candidate.id);

  return (
    <>
      {isCandidateLoading ? (
        <Spinner />
      ) : (
        <SyntheseContainer ref={candidatesDropdownRef}>
          <Candidates>
            <ActiveCandidate
              showOptions={showCandidates}
              onClick={() => setShowCandidates(!showCandidates)}
            >
              <Candidate active>
                <p>{candidateData.nom}</p>
                {candidateData.statut === DossierLocataireStatut.VALIDE ? (
                  <Valid />
                ) : candidateData.statut ===
                  DossierLocataireStatut.NON_VALIDE ? (
                  <Blocked />
                ) : (
                  <Issue />
                )}
                {candidateData.visalEligible ? (
                  <VisaleContainer>
                    <VisaleIcon />
                  </VisaleContainer>
                ) : null}
              </Candidate>

              {locatairesList.length > 1 && <Chevron />}
            </ActiveCandidate>
            <CandidateOptions showOptions={showCandidates}>
              {locatairesList.map((candidate, index) => {
                const garant = matchGarantToCandidate(candidate);
                return (
                  <CandidateWrapper
                    key={index}
                    active={
                      candidateData.id === candidate.id ||
                      candidateData.id === garant?.id
                    }
                  >
                    <Candidate
                      active={candidateData.id === candidate.id}
                      onClick={() => handleActiveCandidateChange(candidate)}
                    >
                      <p>{candidate.nom + " " + candidate.prenom}</p>
                      {candidate.statut === DossierLocataireStatut.VALIDE ? (
                        <Valid />
                      ) : candidate.statut ===
                        DossierLocataireStatut.NON_VALIDE ? (
                        <Blocked />
                      ) : (
                        <Issue />
                      )}
                    </Candidate>
                    {garant && (
                      <Garant
                        garantInfo={garant}
                        activeCandidate={candidateData}
                        handleGarantSelect={handleActiveCandidateChange}
                      />
                    )}

                    {candidate.visalEligible ? (
                      <VisaleContainer>
                        <VisaleIcon />
                      </VisaleContainer>
                    ) : null}
                  </CandidateWrapper>
                );
              })}
            </CandidateOptions>
          </Candidates>
          {isCandidateLoading || loadingLocataire ? (
            <Spinner />
          ) : (
            <SyntheseInfo>
              <CandidateInformationContainer>
                <SyntheseIdentitySection
                  candidateData={candidateData}
                  dossierNumerique={dossierNumerique}
                  addColocataire={addColocataire}
                  hasGarant={
                    matchGarantToCandidate(candidateData) ||
                    candidateData.garante
                  }
                />
                <SyntheseFilesSection
                  collapseByDefault={renderCount > 0}
                  dossierNumeriqueId={dossierNumerique?.id}
                  dossierLocataire={candidateData}
                  dossierNumerique={dossierNumerique}
                />
                <SyntheseRevenusSection collapseByDefault={renderCount > 0} />
              </CandidateInformationContainer>
              <SyntheseStatus
                dossierLocation={dossierLocation}
                candidateType={candidateData.situationPro}
                addColocataire={addColocataire}
                setIsCandidateLoading={setIsCandidateLoading}
              />
            </SyntheseInfo>
          )}
        </SyntheseContainer>
      )}
    </>
  );
};

export default withTheme(SyntheseForm);
