const UTILISATEUR_LOAD = "UTILISATEUR_LOAD";
const LOAD_DOSSIERS_LOCATIONS = "LOAD_DOSSIERS_LOCATIONS";
const LOAD_ADMINISTRATEURS_BIENS = "LOAD_ADMINISTRATEURS_BIENS";
const LOAD_ADMINISTRATEUR_BIEN_BY_ID = "LOAD_ADMINISTRATEUR_BIEN_BY_ID";
const SET_CURRENT_ADMINISTRATEUR_BIEN = "SET_CURRENT_ADMINISTRATEUR_BIEN";
const SEARCH_DOSSIER_BY_PASSLOCK = "SEARCH_DOSSIER_BY_PASSLOCK";
const CREATE_OR_UPDATE_DOSSIER_LOCATAIRE = "CREATE_OR_UPDATE_DOSSIER_LOCATAIRE";
const UPDATE_REVENUS = "UPDATE_REVENUS";
const UPDATE_TYPE_PIECE_IDENTITE = "UPDATE_TYPE_PIECE_IDENTITE";
const UPLOAD_FILE = "UPLOAD_FILE";
const FETCH_PIECES = "FETCH_PIECES";
const FETCH_IMAGE = "FETCH_IMAGE";
const FETCH_DOCUMENT = "FETCH_DOCUMENT";
const DELETE_DOCUMENT = "DELETE_DOCUMENT";
const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
const PREVIEW_SYNTHESE = "PREVIEW_SYNTHESE";
const SYNTHESE_LOAD = "SYNTHESE_LOAD";
const CLIENTS_LOAD = "CLIENTS_LOAD";
const CLIENT_CREATE = "CLIENT_CREATE";
const CLIENT_UPDATE = "CLIENT_UPDATE";
const UTILISATEURS_LOAD = "UTILISATEURS_LOAD";
const UTILISATEUR_GET = "UTILISATEUR_GET";
const UTILISATEUR_UPDATE = "UTILISATEUR_UPDATE";
const UTILISATEUR_CREATE = "UTILISATEUR_CREATE";
const UTILISATEUR_ACTIVE_CHANGE = "UTILISATEUR_ACTIVE_CHANGE";
const UTILISATEUR_AFFECT_SITE = "UTILISATEUR_AFFECT_SITE";
const UTILISATEUR_SITE_CREATE = "UTILISATEUR_SITE_CREATE";
const UTILISATEURS_LOQK_LOAD = "UTILISATEUR_LOQK_LOAD";
const UTILISATEUR_LOQK_CREATE = "UTILISATEUR_LOQK_CREATE";
const LANCER_PARCOURS_NETHEOS = "LANCER_PARCOURS_NETHEOS";
const ROUVRIR_DOSSIER_PARTENAIRE = "ROUVRIR_DOSSIER_NETHEOS";
const AJOUTER_COLOCATAIRE = "AJOUTER_COLOCATAIRE";
const LOAD_DOSSIER_SUMMARY = "LOAD_DOSSIER_SUMMARY";
const ACCEPTER_DOSSIER_LOCATION = "ACCEPTER_DOSSIER_LOCATION";
const REJETER_DOSSIER_LOCATION = "REJETER_DOSSIER_LOCATION";
const ROUVRIR_DOSSIER_LOCATION = "ROUVRIR_DOSSIER_LOCATION";
const CLIENT_ADD_SITE = "CLIENT_ADD_SITE";
const GET_LOCATION_CAPACITE_DETAILS = "GET_LOCATION_CAPACITE_DETAILS";
const COURTIER_GET = "COURTIER_GET";

const COURTIER_GET_ALL = "COURTIER_GET_ALL";
const COURTIER_GET_ADBS = "COURTIER_GET_ADBS";
const COURTIER_ACTIVATE = "COURTIER_ACTIVATE";
const COURTIER_DESACTIVATE = "COURTIER_DESACTIVATE";
const COURTIER_CREATE = "COURTIER_CREATE";
const COURTIER_UPDATE = "COURTIER_UPDATE";
const COURTIER_SITE_AFFECT = "COURTIER_SITE_AFFECT";
const COURTIER_SITE_DESAFFECT = "COURTIER_SITE_DESAFFECT";
const COURTIER_UTILISATEUR_CREATE = "COURTIER_UTILISATEUR_CREATE";
const COURTIER_UTILISATEUR_SITE_AFFECT = "COURTIER_UTILISATEUR_SITE_AFFECT";
const COURTIER_UTILISATEUR_SITE_DESAFFECT =
  "COURTIER_UTILISATEUR_SITE_DESAFFECT";
const COURTIER_UTILISATEUR_GET_ADB = "COURTIER_UTILISATEUR_GET_ADB";
const COURTIER_UTILISATEURS_GET = "COURTIER_UTILISATEURS_GET";
const COURTIER_UTILISATEUR_GET = "COURTIER_UTILISATEUR_GET";
export {
  //
  // CLIENTS
  //
  CLIENTS_LOAD,
  CLIENT_CREATE,
  CLIENT_UPDATE,
  CLIENT_ADD_SITE,

  //
  // UTILISATEURS
  //
  UTILISATEURS_LOAD,
  UTILISATEUR_GET,
  UTILISATEUR_UPDATE,
  UTILISATEUR_CREATE,
  UTILISATEUR_ACTIVE_CHANGE,
  UTILISATEUR_SITE_CREATE,
  UTILISATEUR_AFFECT_SITE,
  UTILISATEUR_LOAD,

  //
  // ADB
  //
  LOAD_ADMINISTRATEURS_BIENS,
  LOAD_ADMINISTRATEUR_BIEN_BY_ID,
  SET_CURRENT_ADMINISTRATEUR_BIEN,

  //
  // FILES
  //
  DOWNLOAD_DOCUMENT,
  DELETE_DOCUMENT,
  FETCH_DOCUMENT,
  FETCH_IMAGE,
  FETCH_PIECES,
  UPLOAD_FILE,

  //
  // DOSSIER LOCATION
  //
  LOAD_DOSSIERS_LOCATIONS,
  GET_LOCATION_CAPACITE_DETAILS,
  ACCEPTER_DOSSIER_LOCATION,
  REJETER_DOSSIER_LOCATION,
  ROUVRIR_DOSSIER_LOCATION,
  ROUVRIR_DOSSIER_PARTENAIRE,
  LOAD_DOSSIER_SUMMARY,
  AJOUTER_COLOCATAIRE,
  LANCER_PARCOURS_NETHEOS,
  UPDATE_REVENUS,
  SYNTHESE_LOAD,
  PREVIEW_SYNTHESE,
  UPDATE_TYPE_PIECE_IDENTITE,
  CREATE_OR_UPDATE_DOSSIER_LOCATAIRE,
  SEARCH_DOSSIER_BY_PASSLOCK,

  //
  // LOQK
  //
  UTILISATEURS_LOQK_LOAD,
  UTILISATEUR_LOQK_CREATE,

  //
  // COURTIER
  //
  COURTIER_GET,
  COURTIER_GET_ALL,
  COURTIER_GET_ADBS,
  COURTIER_ACTIVATE,
  COURTIER_DESACTIVATE,
  COURTIER_CREATE,
  COURTIER_UPDATE,
  COURTIER_SITE_AFFECT,
  COURTIER_SITE_DESAFFECT,
  COURTIER_UTILISATEUR_CREATE,
  COURTIER_UTILISATEUR_SITE_AFFECT,
  COURTIER_UTILISATEUR_SITE_DESAFFECT,
  COURTIER_UTILISATEUR_GET_ADB,
  COURTIER_UTILISATEURS_GET,
  COURTIER_UTILISATEUR_GET,
};
