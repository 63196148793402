import { useEffect, useState } from "react";
import {
  DocumentNavigation,
  MainDocumentPreviewContainer,
  RenderedDocumentWrapper,
} from "../documentPreviewPopup/documentPreviewPopupStyles";
import DocumentActions from "./documentActions";
import { Document, Page, pdfjs } from "react-pdf";
import Spinner from "../../spinner/spinner";
import { NoDataInDocument } from "../popupStyles";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { useDebouncedCallback } from "use-debounce";
import PagesPreviewSidebar from "./pagesPreviewSidebar";
import StyledInput from "../../styledInput/styledInput";

const MainDocumentPreview = ({ file, isLoading, showPopup, documentInfo }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(null);
  const [documentOrientation, setDocumentOrientation] = useState("vertical");
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [collapsePages, setCollapsePages] = useState(true);
  const [shouldFullScreen, setShouldFullScreen] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = (props) => {
    setPage(1);
    setMaxPages(props.numPages);
  };

  const debouncedReset = useDebouncedCallback(() => {
    setZoomLevel(1);
    setPage(null);
    setMaxPages(null);
    setDocumentOrientation("vertical");
    setLoadingDoc(false);
    setCollapsePages(true);
    setShouldFullScreen(false);
  }, 600);

  const debounceShowDocument = useDebouncedCallback(() => {
    setLoadingDoc(false);
  }, 1600);

  const handlePageChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      setPage(1);
      return;
    }
    if (value > maxPages) {
      setPage(maxPages);
    } else if (value < 1) {
      setPage(1);
    } else {
      setPage(value);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setLoadingDoc(true);
    } else {
      setLoadingDoc(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!showPopup) {
      debouncedReset();
    } else {
      setLoadingDoc(true);
      debounceShowDocument();
    }
  }, [showPopup]);

  return (
    <MainDocumentPreviewContainer
      collapsePages={collapsePages}
      loadingDoc={loadingDoc}
    >
      <PagesPreviewSidebar
        file={file}
        collapsePages={collapsePages}
        setCollapsePages={setCollapsePages}
        page={page}
        setPage={setPage}
        maxPages={maxPages}
        isLoading={loadingDoc}
        showPopup={showPopup}
      />
      <DocumentActions
        modifyScale={setZoomLevel}
        scale={zoomLevel}
        changePage={handlePageChange}
        page={page}
        maxPages={maxPages}
        documentOrientation={documentOrientation}
        setDocumentOrientation={setDocumentOrientation}
        shouldFullScreen={shouldFullScreen}
        setShouldFullScreen={setShouldFullScreen}
      />
      {loadingDoc ? (
        <Spinner message="Chargement du document" />
      ) : (
        <RenderedDocumentWrapper
          layout={documentOrientation}
          shouldFullScreen={shouldFullScreen}
          onClick={() => setShouldFullScreen(false)}
          scale={zoomLevel}
        >
          <Document
            file={file}
            options={{ workerSrc: "pdf.worker.js" }}
            error={
              <NoDataInDocument>
                <WarningIcon />
                <h3>
                  Une erreur est survenue
                  <br />
                  Veuillez réessayer
                </h3>
              </NoDataInDocument>
            }
            noData={
              <NoDataInDocument>
                <WarningIcon />
                <h3>
                  Aucune donnée trouvée
                  <br />
                  Veuillez réessayer
                </h3>
              </NoDataInDocument>
            }
            loading={<Spinner message="Chargement..." />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={page}
              scale={shouldFullScreen ? 2 : zoomLevel}
              loading={<Spinner message="Chargement de la page" />}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
          <DocumentNavigation page={page} totalPages={maxPages}>
            <Chevron
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
            />
            <div>
              <StyledInput
                name="page"
                type="number"
                defaultValue={page}
                action={handlePageChange}
                min={1}
                max={maxPages}
                hasTooltip={false}
              />
              <span>/</span>
              <p>{maxPages !== null ? maxPages : "-"}</p>
            </div>
            <Chevron
              onClick={() => {
                if (page < maxPages) {
                  setPage(page + 1);
                }
              }}
            />
          </DocumentNavigation>
        </RenderedDocumentWrapper>
      )}
    </MainDocumentPreviewContainer>
  );
};

export default MainDocumentPreview;
