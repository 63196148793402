import { createSlice, createSelector } from "@reduxjs/toolkit";
import { TYPE_CANDIDAT_ENUM } from "utils/helpers/DossierLocataireHelper";

export const LOCATAIRE_INITIAL_VALUES = {
  qualite: null,
  statutActuel: null,
  statutDepuis: null,
  situationPerso: null,
  situationPro: null,
  statutPro: null,
  travaille24DerniersMois: null,
  nom: null,
  prenom: null,
  email: null,
  dateNaissance: null,
  telephone: null,
  dateDebutSituation: null,
  dateFinSituation: null,
  departement: null,
};

// IF you add a new variable, please SEE the reset object
const INITIAL_STATE = {
  listDossiersLocations: undefined,
  currentDossierLocation: undefined,
  currentDossierLocataire: {
    ...LOCATAIRE_INITIAL_VALUES,
  },
  currentDossierNumerique: undefined,
  currentDossierLocatairesList: [],
  currentSite: undefined,
  currentTypeDossierLocataire: TYPE_CANDIDAT_ENUM.REFERENT,
  currentDossierRevenus: [],
};

const DossierLocationReducer = createSlice({
  name: "dossierLocation",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentDossierLocatairesList: (state, action) => {
      state.currentDossierLocatairesList = action.payload;
    },
    setCurrentDossierLocation: (state, action) => {
      state.currentDossierLocation = action.payload;
    },
    setListDossiersLocations: (state, action) => {
      state.listDossiersLocations = action.payload;
    },
    // adding init values is mondatory to form validation
    setCurrentDossierLocataire: (state, action) => {
      state.currentDossierLocataire = {
        ...INITIAL_STATE.currentDossierLocataire,
        ...action.payload,
      };
    },
    clearCurrentDossierLocataire: (state) => {
      state.currentDossierLocataire = undefined;
    },
    changeLocataireValue: (state, { payload: { key, value } }) => {
      state = { ...state, [key]: value };
    },

    setCurrentSite: (state, action) => {
      state.currentSite = action.payload;
    },
    setCurrentDossierNumerique: (state, action) => {
      state.currentDossierNumerique = action.payload;
    },
    setCurrentDossierRevenus: (state, action) => {
      state.currentDossierRevenus = action.payload;
    },
    cleanParcourProcess: (state) => ({
      ...state,
      currentDossierLocation: undefined,
      currentDossierLocataire: undefined,
      currentDossierLocatairesList: [],
      currentDossierNumerique: undefined,
      currentSite: undefined,
      currentDossierRevenus: [],
      currentTypeDossierLocataire: TYPE_CANDIDAT_ENUM.REFERENT,
    }),
    setCurrentTypeDossierLocataire: (state, action) => {
      state.currentTypeDossierLocataire = action.payload;
    },
  },
});

const selectSelf = (state) => state.dossierLocation;

export const DossierLocationActions = DossierLocationReducer.actions;

export const selectCurrentDossierLocatairesList = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentDossierLocatairesList
);

export const selectCurrentDossierLocataireName = createSelector(
  [selectSelf],
  (dossierLocation) =>
    dossierLocation.currentDossierLocataire.nom +
    " " +
    dossierLocation.currentDossierLocataire?.prenom
);

export const selectListDossiersLocations = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.listDossiersLocations
);

export const selectDossierLocation = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentDossierLocation
);

export const selectDossierLocationId = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation?.currentDossierLocation?.id
);

export const selectCurrentDossierLocataire = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentDossierLocataire
);

export const selectCurrentTypeDossierLocataire = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentTypeDossierLocataire
);

export const selectCurrentDossierNumerique = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentDossierNumerique
);

export const selectCurrentDossierRevenus = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentDossierRevenus
);

// current currentsite
export const selectCurrentSite = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentSite
);

export const selectDossierNumeriquePieces = createSelector(
  [selectSelf],
  (dossierLocation) => dossierLocation.currentDossierNumerique.pieces
);

export const {
  setListDossiersLocations,
  setCurrentSite,
  setCurrentDossierLocation,
  setCurrentDossierLocataire,
  setCurrentDossierNumerique,
  setCurrentDossierRevenus,
  setCurrentDossierLocatairesList,
  setCurrentTypeDossierLocataire,
  cleanParcourProcess,
  clearCurrentDossierLocataire,
} = DossierLocationReducer.actions;

export default DossierLocationReducer;
