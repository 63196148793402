import { toast } from "react-toastify";
import * as courtierAPI from "../api/courtier";
import { call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  COURTIER_ACTIVATE,
  COURTIER_CREATE,
  COURTIER_DESACTIVATE,
  COURTIER_GET,
  COURTIER_GET_ALL,
  COURTIER_GET_ADBS,
  COURTIER_SITE_AFFECT,
  COURTIER_SITE_DESAFFECT,
  COURTIER_UPDATE,
} from "./actions";

function* getCourtiers(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  try {
    onChange(true);
    const { payload } = action;
    const response = yield call(courtierAPI.getCourtiers, payload);
    if (response.status === 200) {
      onSuccess(response.data);
    } else {
      onError();
      toast.error("Une erreur est survenue lors du chargement des courtiers");
    }
  } catch (error) {
    onError(error);
    toast.error("Une erreur est survenue lors du chargement des courtiers");
  } finally {
    onChange(false);
  }
}

function* activateCourtier(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  onChange(true);
  try {
    const { courtierId } = action.payload;
    const response = yield call(courtierAPI.activateCourtier, courtierId);
    if (response.status === 200) {
      onSuccess();
      toast.success("Le courtier a été activé avec succès");
    } else {
      onError();
      toast.error("Une erreur est survenue lors de l'activation du courtier");
    }
  } catch (error) {
    onError(error);
    toast.error("Une erreur est survenue lors de l'activation du courtier");
  } finally {
    onChange(false);
  }
}

function* deactivateCourtier(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  onChange(true);
  try {
    const { courtierId } = action.payload;
    const response = yield call(courtierAPI.deactivateCourtier, courtierId);
    if (response.status === 200) {
      onSuccess();
      toast.success("Le courtier a été désactivé avec succès");
    } else {
      onError();
      console.log(response);
      toast.error(
        "Une erreur est survenue lors de la désactivation du courtier"
      );
    }
  } catch (error) {
    onError(error);
    console.log(error);
    toast.error("Une erreur est survenue lors de la désactivation du courtier");
  } finally {
    onChange(false);
  }
}

function* affectCourtierSite(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  onChange(true);
  try {
    const { courtierId, siteId } = action.payload;
    const response = yield call(
      courtierAPI.affectCourtierSite,
      courtierId,
      siteId
    );
    if (response.status === 200) {
      onSuccess();
      toast.success("Le site a été affecté avec succès");
    } else {
      onError();
      console.log(response);
      toast.error("Une erreur est survenue lors de l'affectation du site");
    }
  } catch (error) {
    onError(error);
    console.log(error);
    toast.error("Une erreur est survenue lors de l'affectation du site");
  } finally {
    onChange(false);
  }
}

function* desaffectCourtierSite(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  onChange(true);
  try {
    const { courtierId, siteId } = action.payload;
    const response = yield call(
      courtierAPI.desaffectCourtierSite,
      courtierId,
      siteId
    );
    if (response.status === 200) {
      onSuccess();
      toast.success("Le site a été désaffecté avec succès");
    } else {
      onError();
      console.log(response);
      toast.error("Une erreur est survenue lors de la désaffectation du site");
    }
  } catch (error) {
    onError(error);
    console.log(error);
    toast.error("Une erreur est survenue lors de la désaffectation du site");
  } finally {
    onChange(false);
  }
}

function* createCourtier(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  onChange(true);
  try {
    const { payload } = action;
    const response = yield call(courtierAPI.createCourtier, payload);
    if (response.status === 200) {
      onSuccess(response.data.id);
      toast.success("Le courtier a été créé avec succès");
    } else {
      onError();
      console.log(response);
      toast.error("Une erreur est survenue lors de la création du courtier");
    }
  } catch (error) {
    onError(error);
    console.error(error);
    toast.error("Une erreur est survenue lors de la création du courtier");
  } finally {
    onChange(false);
  }
}

function* updateCourtier(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  onChange(true);
  try {
    const { courtier, courtierId } = action.payload;
    const response = yield call(
      courtierAPI.updateCourtier,
      courtierId,
      courtier
    );
    if (response.status === 200) {
      onSuccess(response.data.id);
      toast.success("Le courtier a été modifié avec succès");
    } else {
      onError();
      console.log(response);
      toast.error(
        "Une erreur est survenue lors de la modification du courtier"
      );
    }
  } catch (error) {
    onError(error);
    console.log(error);
    toast.error("Une erreur est survenue lors de la modification du courtier");
  } finally {
    onChange(false);
  }
}

function* getCourtierInfo(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = action;
  try {
    onChange(true);
    const { courtierId } = action;
    const response = yield call(courtierAPI.getCourtierInfo, courtierId);
    if (response.status === 200) {
      onSuccess(response.data);
    } else {
      onError();
      console.error(response);
      toast.error("Une erreur est survenue lors du chargement du courtier");
    }
  } catch (error) {
    onError(error);
    console.log(error);
    toast.error("Une erreur est survenue lors du chargement du courtier");
  } finally {
    onChange(false);
  }
}

function* getCourtierADBs(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);
    const { courtierId, searchFilters } = payload;
    const response = yield call(
      courtierAPI.getAdministrateursBiensByCourtierId,
      courtierId,
      searchFilters
    );
    if (response.status === 200) {
      onSuccess(response.data);
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors du chargement des ADBs du courtier"
    );
  } finally {
    onChange(false);
  }
}

function* watchGetCourtiers() {
  yield takeLatest(COURTIER_GET_ALL, getCourtiers);
}

function* watchActivateCourtier() {
  yield takeEvery(COURTIER_ACTIVATE, activateCourtier);
}

function* watchDeactivateCourtier() {
  yield takeEvery(COURTIER_DESACTIVATE, deactivateCourtier);
}

function* watchAffectCourtierSite() {
  yield takeEvery(COURTIER_SITE_AFFECT, affectCourtierSite);
}

function* watchDesaffectCourtierSite() {
  yield takeEvery(COURTIER_SITE_DESAFFECT, desaffectCourtierSite);
}

function* watchCreateCourtier() {
  yield takeLatest(COURTIER_CREATE, createCourtier);
}

function* watchUpdateCourtier() {
  yield takeLatest(COURTIER_UPDATE, updateCourtier);
}

function* watchGetCourtierInfo() {
  yield takeLatest(COURTIER_GET, getCourtierInfo);
}
function* watchGetCourtierADBs() {
  yield takeLatest(COURTIER_GET_ADBS, getCourtierADBs);
}

export {
  watchGetCourtiers,
  watchActivateCourtier,
  watchDeactivateCourtier,
  watchAffectCourtierSite,
  watchDesaffectCourtierSite,
  watchCreateCourtier,
  watchUpdateCourtier,
  watchGetCourtierInfo,
  watchGetCourtierADBs,
};
