//  RevenuItemContainer
//  Component that renders a revenu item with its attached piece numerique
//  Props:
//     - revenuItem: the revenu item to work with
//     - formikProps: the formik props to work with
//     - blocked: boolean to know if the form is blocked or not
//  State:
//     - showTooltip: boolean to know if the tooltip should be shown or not
//     - previewPopupData: object containing the data to show in the preview popup

import { useState } from "react";
import { getPieceLabel } from "utils/helpers/dossierNumeriqueHelper";
import { CONTRAT_TRAVAIL } from "utils/constantes";
import ModifiedRevenuItem from "./revenusItem/modifiedRevenuItem";

import TooltipPopup from "components/tooltip/tooltip";
import {
  mapAttachementToHeader,
  mapAttachmentTypeToImage,
  mapAttachmentTypeToText,
} from "./../../tooltip/utils";
import { withTheme } from "styled-components";
import { ReactComponent as TooltipIcon } from "assets/icons/info.svg";
import { RevenuItem, RevenuItemHeader } from "./revenuItemStyles";

const RevenuItemContainer = ({
  revenuItem,
  formikProps,
  blocked,
  avisAcknowledged,
  dossierNumeriqueId,
  setAvisAcknowledged = () => {},
}) => {
  const { pieceNumerique, revenus } = revenuItem;
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      {pieceNumerique && (
        <RevenuItem>
          {pieceNumerique?.type !== CONTRAT_TRAVAIL ? (
            <TooltipPopup
              showTooltip={showTooltip}
              onClose={() => setShowTooltip(false)}
              tooltipHeader={mapAttachementToHeader(pieceNumerique.type)}
              tooltipText={mapAttachmentTypeToText(pieceNumerique.type)}
              imageRef={mapAttachmentTypeToImage(pieceNumerique.type)}
            />
          ) : null}

          <div>
            <RevenuItemHeader>
              <h2>{getPieceLabel(pieceNumerique.type)}</h2>
              <TooltipIcon onClick={() => setShowTooltip(true)} />
            </RevenuItemHeader>
            <ModifiedRevenuItem
              revenus={revenus}
              formikProps={formikProps}
              attachedPieceNumerique={pieceNumerique}
              blocked={blocked}
              avisAcknowledged={avisAcknowledged}
              setAvisAcknowledged={setAvisAcknowledged}
              dossierNumeriqueId={dossierNumeriqueId}
            />
          </div>
        </RevenuItem>
      )}
    </>
  );
};

export default withTheme(RevenuItemContainer);
