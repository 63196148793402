//  ComponentWrapper
//  Component used to wrap components to have padding on sides and a margin to
//  separate them from footer
//  Props:
//     - hasMargin: boolean, if true, the component will have a margin at bottom
//     - isolate: string, if "left", the component will have no padding on left,
//                        if "right", the component will have no padding on right,
//                        if "both", the component will have no padding on both sides
//     - children: any, the component to wrap

import styled, { css, withTheme } from "styled-components";

const StyledWrapper = styled.div`
  margin-bottom: ${(props) => (props.hasMargin ? props.theme.margins[26] : 0)};
  overflow: ${(props) => (props.hideOverflow ? "hidden" : "visible")};
  padding-left: ${(props) =>
    props.isolate === "left" || props.isolate === "both"
      ? 0
      : props.theme.horizontalPaddings.default} !important;
  padding-right: ${(props) =>
    props.isolate === "right" || props.isolate === "both"
      ? 0
      : props.theme.horizontalPaddings.default} !important;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.largeDesktop}) {
    padding-left: ${(props) =>
      props.isolate === "left" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.largeDesktop} !important;
    padding-right: ${(props) =>
      props.isolate === "right" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.largeDesktop} !important;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding-left: ${(props) =>
      props.isolate === "left" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.desktop} !important;
    padding-right: ${(props) =>
      props.isolate === "right" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.desktop} !important;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-left: ${(props) =>
      props.isolate === "left" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.tablet} !important;
    padding-right: ${(props) =>
      props.isolate === "right" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.tablet} !important;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.small}) {
    margin-bottom: ${(props) =>
      props.hasMargin ? props.theme.margins[16] : 0};
    padding-left: ${(props) =>
      props.isolate === "left" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.small} !important;
    padding-right: ${(props) =>
      props.isolate === "right" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.small} !important;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: ${(props) =>
      props.hasMargin ? props.theme.margins[12] : 0};
    padding-left: ${(props) =>
      props.isolate === "left" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.mobile} !important;
    padding-right: ${(props) =>
      props.isolate === "right" || props.isolate === "both"
        ? 0
        : props.theme.horizontalPaddings.mobile} !important;
  }
  ${({ isDashboard, ignoreTopPadding }) =>
    isDashboard &&
    css`
      padding-left: 200px !important;
      padding-right: 128px !important;
      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.largeDesktop}) {
        padding-left: 200px !important;
        padding-right: 96px !important;
      }
      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.desktop}) {
        padding-left: 200px !important;
        padding-right: 96px !important;
      }
      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.tablet}) {
        padding-top: ${ignoreTopPadding ? "0" : "96px"} !important;
        padding-left: ${(props) =>
          props.theme.horizontalPaddings.tablet} !important;
        padding-right: ${(props) =>
          props.theme.horizontalPaddings.tablet} !important;
      }

      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.medium}) {
        padding-left: 72px !important;
        padding-right: 72px !important;
      }
      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.small}) {
        padding-left: 48px !important;
        padding-right: 48px !important;
      }
      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.mobile}) {
        padding-left: 32px !important;
        padding-right: 32px !important;
      }
    `}

  ${({ ignoreTopPadding }) => ignoreTopPadding && "padding-top: 0 !important;"}
`;

const ComponentWrapper = ({
  theme,
  children,
  hasMargin,
  isolate = "none",
  hideOverflow = false,
  isDashboard = false,
  ignoreTopPadding = false,
}) => {
  return (
    <StyledWrapper
      theme={theme}
      isolate={isolate}
      hasMargin={hasMargin}
      hideOverflow={hideOverflow}
      isDashboard={isDashboard}
      ignoreTopPadding={ignoreTopPadding}
    >
      {children}
    </StyledWrapper>
  );
};

export default withTheme(ComponentWrapper);
