import { axiosInstance } from "./axiosInstance";

const BASE_URL = "/courtiers";

export const getCourtiers = async (query) => {
  return axiosInstance.get(BASE_URL, {
    params: { ...query },
  });
};

// NB : get only accessible ADB by the connected user
export const getAdministrateursBiensByCourtierId = async (
  courtierId,
  searchFilters
) => {
  return axiosInstance.get(`/courtiers/${courtierId}/administrateur-biens`, {
    params: { ...searchFilters },
  });
};

export const getCourtierInfo = async (courtierId) => {
  return axiosInstance.get(`${BASE_URL}/${courtierId}`);
};

export const activateCourtier = async (courtierId) => {
  return axiosInstance.put(`${BASE_URL}/${courtierId}/activer`);
};

export const deactivateCourtier = async (courtierId) => {
  return axiosInstance.put(`${BASE_URL}/${courtierId}/desactiver`);
};

export const affectCourtierSite = async (courtierId, siteId) => {
  return axiosInstance.put(`${BASE_URL}/${courtierId}/affecter-site`, siteId, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const desaffectCourtierSite = async (courtierId, siteId) => {
  return axiosInstance.put(
    `${BASE_URL}/${courtierId}/desaffecter-site`,
    siteId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const createCourtier = async (courtierData) => {
  return axiosInstance.post(`${BASE_URL}`, courtierData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateCourtier = async (courtierId, courtierData) => {
  return axiosInstance.put(`${BASE_URL}/${courtierId}`, courtierData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createSite = async (courtierId, siteData) => {
  return axiosInstance.post(`${BASE_URL}/${courtierId}/sites`, siteData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
