import { combineReducers } from "redux";
import AdministrateurBienReducer from "./administrateurBien/administrateurBienReducer";
import DossierLocationReducer from "./dossierLocation/dossierLocationReducer";
import UserReducer from "./user/userReducer";

const reducers = combineReducers({
  user: UserReducer.reducer,
  administrateurBien: AdministrateurBienReducer.reducer,
  dossierLocation: DossierLocationReducer.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    console.log("HERE 'DESTROY_SESSION'");
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
