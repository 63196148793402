import { toast } from "react-toastify";
import * as utilisateurCourtierApi from "../api/utilisateurCourtier";
import { call, takeLatest } from "redux-saga/effects";
import {
  COURTIER_UTILISATEURS_GET,
  COURTIER_UTILISATEUR_CREATE,
  COURTIER_UTILISATEUR_GET,
  COURTIER_UTILISATEUR_GET_ADB,
  COURTIER_UTILISATEUR_SITE_AFFECT,
  COURTIER_UTILISATEUR_SITE_DESAFFECT,
} from "./actions";

function* getCourtierUtilisateurs(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);

    const utilisateursGetResponse = yield call(
      utilisateurCourtierApi.getCourtierUtilisateurs,
      payload.courtierId
    );

    if (utilisateursGetResponse.status === 200) {
      onSuccess(utilisateursGetResponse.data);
    }
  } catch (error) {
    onError(error);
    console.log(error);
    toast.error(
      "Une erreur est survenue lors du chargement des utilisateurs du courtier",
      {
        toastId: "getCourtierUtilisateursError",
      }
    );
  } finally {
    onChange(false);
  }
}

function* getCourtierUtilisateurInfo(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);
    const { utilisateurId, courtierId } = payload;
    const utilisateurGetResponse = yield call(
      utilisateurCourtierApi.getCourtierUtilisateurInfo,
      courtierId,
      utilisateurId
    );
    if (utilisateurGetResponse.status === 200) {
      onSuccess(utilisateurGetResponse.data);
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors du chargement de l'utilisateur du courtier",
      {
        toastId: "getCourtierUtilisateurInfoError",
      }
    );
  } finally {
    onChange(false);
  }
}

function* getCourtierUtilisateurAdministrateurBiens(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;

  try {
    onChange(true);
    const { courtierId, filters, paginationData } = payload;
    const response = yield call(
      utilisateurCourtierApi.getCourtierUtilisateurADBs,
      courtierId,
      filters,
      paginationData
    );
    if (response.status === 200) {
      onSuccess(response.data);
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors du chargement des administrateurs de biens du courtier",
      {
        toastId: "getCourtierUtilisateurAdministrateurBiensError",
      }
    );
  } finally {
    onChange(false);
  }
}

function* affectCourtierUtilisateurSite(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);
    const { courtierId, utilisateurId, sites } = payload;
    const response = yield call(
      utilisateurCourtierApi.affectCourtierUtilisateurSite,
      courtierId,
      utilisateurId,
      sites
    );
    if (response.status === 200) {
      onSuccess(response.data);
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors de l'affectation des sites à l'utilisateur du courtier",
      {
        toastId: "affectCourtierUtilisateurSiteError",
        updateId: "affectCourtierUtilisateurSiteError",
      }
    );
  } finally {
    onChange(false);
  }
}

function* desaffectCourtierUtilisateurSite(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);
    const { courtierId, utilisateurId, sites } = payload;
    const response = yield call(
      utilisateurCourtierApi.desaffectCourtierUtilisateurSite,
      courtierId,
      utilisateurId,
      sites
    );
    if (response.status === 200) {
      onSuccess(response.data);
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors de la désaffectation des sites à l'utilisateur du courtier",
      {
        toastId: "desaffectCourtierUtilisateurSiteError",
        updateId: "desaffectCourtierUtilisateurSiteError",
      }
    );
  } finally {
    onChange(false);
  }
}

function* createCourtierUtilisateur(action) {
  const {
    onChange = () => {},
    onSuccess = () => {},
    onError = () => {},
    payload,
  } = action;
  try {
    onChange(true);

    const { courtierId, utilisateur } = payload;
    const utilisateurCreateResponse = yield call(
      utilisateurCourtierApi.createCourtierUtilisateur,
      courtierId,
      {
        ...utilisateur,
        typeCompte: "COURTIER",
        type: "COURTIER",
      }
    );
    if (utilisateurCreateResponse.status === 200) {
      onSuccess(utilisateurCreateResponse.data.id);
      toast.success("L'utilisateur a été créé avec succès", {
        toastId: "createCourtierUtilisateur",
        updateId: "createCourtierUtilisateur",
      });
    }
  } catch (error) {
    onError(error);
    toast.error(
      "Une erreur est survenue lors de la création de l'utilisateur",
      {
        toastId: "createCourtierUtilisateurError",
        updateId: "createCourtierUtilisateurError",
      }
    );
  } finally {
    onChange(false);
  }
}

function* watchGetCourtierUtilisateurs() {
  yield takeLatest(COURTIER_UTILISATEURS_GET, getCourtierUtilisateurs);
}

function* watchGetCourtierUtilisateurInfo() {
  yield takeLatest(COURTIER_UTILISATEUR_GET, getCourtierUtilisateurInfo);
}

function* watchGetCourtierUtilisateurADBs() {
  yield takeLatest(
    COURTIER_UTILISATEUR_GET_ADB,
    getCourtierUtilisateurAdministrateurBiens
  );
}

function* watchAffectCourtierUtilisateurSite() {
  yield takeLatest(
    COURTIER_UTILISATEUR_SITE_AFFECT,
    affectCourtierUtilisateurSite
  );
}

function* watchDesaffectCourtierUtilisateurSite() {
  yield takeLatest(
    COURTIER_UTILISATEUR_SITE_DESAFFECT,
    desaffectCourtierUtilisateurSite
  );
}

function* watchCreateCourtierUtilisateur() {
  yield takeLatest(COURTIER_UTILISATEUR_CREATE, createCourtierUtilisateur);
}

export {
  watchGetCourtierUtilisateurs,
  watchGetCourtierUtilisateurInfo,
  watchGetCourtierUtilisateurADBs,
  watchAffectCourtierUtilisateurSite,
  watchDesaffectCourtierUtilisateurSite,
  watchCreateCourtierUtilisateur,
};
