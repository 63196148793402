import { withTheme } from "styled-components";

import { withFormik } from "formik";
import PersonalForm from "./personalForm/personalForm";
import {
  AdvancedDossierLocataireValidator,
  DossierLocataireValidator,
} from "./utils/dossierLocataireValidator";
import { connect } from "react-redux";
import withNavigate from "utils/hoc/withNavigate";
import { CREATE_OR_UPDATE_DOSSIER_LOCATAIRE } from "utils/saga/actions";
import { shouldDisplayTravaille24DerniersMois } from "utils/helpers/situationProHelper";

const formikEnhancer = withFormik({
  validationSchema: DossierLocataireValidator,
  validate: AdvancedDossierLocataireValidator,
  validateOnChange: false,
  validateOnMount: true,
  mapPropsToValues: (props) => {
    const { travaille24DerniersMois, situationPro } = props.dossierLocataire;
    let values = {};
    if (
      travaille24DerniersMois !== null &&
      travaille24DerniersMois !== undefined &&
      shouldDisplayTravaille24DerniersMois(situationPro)
    ) {
      values = {
        ...props?.dossierLocataire,
        travaille24DerniersMois: travaille24DerniersMois + "",
      };
    } else {
      values = {
        ...props?.dossierLocataire,
      };
    }

    return values;
  },

  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.dispatch({
      type: CREATE_OR_UPDATE_DOSSIER_LOCATAIRE,
      payload: {
        navigate: () =>
          props.navigate("/monespace/dossier/status?category=pieces"),
        setErrors,
        setSubmitting,
        values,
      },
    });
  },

  displayName: "SITUATION_PERSONNELLE_FORM",
});

export default withNavigate(connect()(formikEnhancer(withTheme(PersonalForm))));
