import { withTheme } from "styled-components";
import { StatusPageContainer } from "./statusPageStyles";
import { StyledButton } from "components";

const Unauthorized = () => {
  return (
    <StatusPageContainer>
      <div>
        <h2>
          Vous n'avez pas les autorisations nécessaires pour accéder à cette
          page.
        </h2>
        <p>Veuillez vérifier l'URL ou contactez l'administrateur du site.</p>
        <StyledButton
          link="/"
          type="link"
          themeSizeSelector={["montserrat", "regular"]}
          themeSpacingSelector={[4, 14]}
          text="Retourner à l'accueil"
        ></StyledButton>
      </div>
    </StatusPageContainer>
  );
};

export default withTheme(Unauthorized);
