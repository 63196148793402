import styled from "styled-components";
import { DossierLocationStatut } from "utils/Statuts";

export const VisaleContainer = styled.div`
  & > svg {
    height: 40px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.medium}) {
    /* display: none; */
    order: 2;
    margin-top: 1rem;
  }
`;

export const PriceCapacity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    margin: 0 !important;
  }
  h3 {
    color: ${({ theme }) => theme.colors.secondary} !important;
  }
  button {
    margin-bottom: 0 !important;
  }
`;

export const DossierStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 600 !important;
  p {
    margin: 0 !important;
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    color: ${({ theme }) => theme.colors.primaryOpaque};
  }
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.pageTitle};
    color: ${({ theme, status }) =>
      status === DossierLocationStatut.VALIDE ||
      status === DossierLocationStatut.ACCEPTE
        ? theme.colors.secondary
        : theme.colors.error};
    margin: 0;
  }
`;

export const DossierBasicInfo = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.margins[8]};
  flex-direction: column;
  gap: 16px;
  p {
    margin: 0 !important;
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font-weight: 600 !important;
    span,
    strong {
      font: ${({ theme }) => theme.fonts.montserrat.highlight};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600 !important;
    }
  }
`;

export const AcceptOrRefuse = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margins[6]} !important;
  display: flex;
  flex-direction: column;
  gap: 12px;
  h4 {
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    font-weight: 600 !important;
  }

  div {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  button {
    margin-bottom: 0 !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    div {
      flex-direction: column;
      gap: 16px;
    }
  }
`;

export const DossierExtras = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: ${({ theme }) => theme.margins[8]};
  button {
    margin-bottom: 0 !important;
  }
`;

export const StatusContainer = styled.div`
  max-width: 550px;
  width: 100%;
  margin-left: auto;
  padding: ${({ theme }) => theme.paddings[8]} 0
    ${({ theme }) => theme.paddings[18]} ${({ theme }) => theme.paddings[14]};
  border-left: 1px solid ${({ theme }) => theme.colors.border};

  position: sticky;
  top: 72px;
  height: max-content;

  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.pageTitle};
    font-weight: 600 !important;
    color: ${({ theme, status }) =>
      status === DossierLocationStatut.VALIDE ||
      status === DossierLocationStatut.ACCEPTE
        ? theme.colors.secondary
        : theme.colors.error};
    margin: 0;
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    color: ${({ theme }) => theme.colors.primaryOpaque};
    margin: 0 0 ${({ theme }) => theme.margins[5]};
    font-weight: 600 !important;
    span {
      font: ${({ theme }) => theme.fonts.montserrat.highlight};
      color: ${({ theme }) => theme.colors.primary};
      margin-top: 8px;
    }
  }
  & > div {
    margin-bottom: ${({ theme }) => theme.margins[8]};
    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-of-type(3) {
      margin-bottom: ${({ theme }) => theme.margins[4]};
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > div {
        margin-bottom: ${({ theme }) => theme.margins[4]};
      }
      & > button {
        margin-bottom: 0;
      }
    }
  }
  button {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.margins[4]};
  }
  & > span {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    color: ${({ theme }) => theme.colors.primaryOpaque};
    margin-top: ${({ theme }) => theme.margins[3]};
    display: block;
    font-weight: 600 !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 420px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    h3 {
      font: ${({ theme }) => theme.fonts.montserrat.title};
    }
    p {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
    }
  }
  @media screen and (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 55;
    width: 100%;
    background: ${({ theme }) => theme.colors.textOnPrimary};
    max-width: unset;
    top: unset;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.paddings[11]}
      ${({ theme }) => theme.paddings[12]} ${({ theme }) => theme.paddings[8]}
      ${({ theme }) => theme.paddings[12]};
    border-radius: 24px 24px 0 0;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.12);
    max-height: ${({ expand }) => (expand ? "80vh" : "156px")};
    height: unset;
    transition: 300ms ease-in-out;
    overflow-y: ${({ expand }) => (expand ? "auto" : "hidden")};

    ${DossierStatus} {
      grid-area: 1/1/1/3;
      gap: 32px;
      display: block;
      margin-bottom: ${({ expand, theme }) =>
        !expand ? theme.margins[8] : "0"};
      transition: 300ms ease-in-out;
    }
    ${PriceCapacity} {
      max-height: ${({ expand }) => (expand ? "100vh" : "0")};
      transition: 300ms ease-in-out;
      opacity: ${({ expand }) => (expand ? "1" : "0")};
      transition-delay: ${({ expand }) => (expand ? "300ms" : "0")};
      margin-top: ${({ expand, theme }) => (expand ? theme.margins[8] : "0")};
      margin-bottom: 0 !important;
    }
    ${DossierBasicInfo} {
      max-height: ${({ expand }) => (expand ? "100vh" : "0")};
      transition: 300ms ease-in-out;
      opacity: ${({ expand }) => (expand ? "1" : "0")};
      transition-delay: ${({ expand }) => (expand ? "300ms" : "0")};
      margin-top: ${({ expand, theme }) => (expand ? theme.margins[8] : "0")};
      margin-bottom: 0 !important;
    }
    ${DossierExtras} {
      max-height: ${({ expand }) => (expand ? "100vh" : "0")};

      transition: 300ms ease-in-out;
      opacity: ${({ expand }) => (expand ? "1" : "0")};
      transition-delay: ${({ expand }) => (expand ? "300ms" : "0")};
      margin-top: ${({ expand, theme }) => (expand ? theme.margins[8] : "0")};
      margin-bottom: ${({ expand, theme }) =>
        expand ? theme.margins[4] : "0"} !important;
    }

    ${AcceptOrRefuse} {
      margin: 0 !important;
      max-height: ${({ expand }) => (expand ? "100vh" : "0")};
      transition: 300ms ease-in-out;
      opacity: ${({ expand }) => (expand ? "1" : "0")};
      transition-delay: ${({ expand }) => (expand ? "300ms" : "0")};
      order: 2;
      margin-top: ${({ expand, theme }) =>
        expand ? theme.margins[4] : "0"} !important;
      div {
        flex-direction: column;
        gap: 16px;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.paddings[11]}
      ${({ theme }) => theme.paddings[6]} ${({ theme }) => theme.paddings[8]}
      ${({ theme }) => theme.paddings[6]};
    max-height: ${({ expand }) => (expand ? "70vh" : "156px")};
    h3 {
      font: ${({ theme }) => theme.fonts.montserrat.heading};
    }
  }
`;

export const ExpandButton = styled.button`
  position: absolute;
  background: ${({ theme }) => theme.colors.textOnPrimary};
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  span {
    position: absolute;
    height: 3px;
    background: ${({ theme }) => theme.colors.primary};
    transition: 300ms ease-in-out;
    &:first-of-type {
      width: 32px;
      top: 0px;
    }
    &:last-of-type {
      width: 32px;
      top: 0px;
    }
    ${({ expand }) =>
      expand &&
      `
            height: 2px;
            &:first-of-type{
                transform: rotate(45deg);
                top: 2px;
                width: 24px;
            }
            &:last-of-type{
                transform: rotate(-45deg);
                top: 2px;
                width: 24px;
            }
        `};
  }
  @media screen and (max-width: 1024px) {
    display: flex;
  }
`;
