export const splitRevenusToCategories = (revenus) => {
  if (!revenus) {
    return;
  }
  const revenusMensuel = revenus.filter((revenu) =>
    revenu.typeRevenu.includes("SALAIRE_MENSUEL")
  );
  const revenusAnnuel = revenus.filter((revenu) =>
    revenu.typeRevenu.includes("CUMUL_ANNUEL")
  );
  const revenusReference = revenus.filter((revenu) =>
    revenu.typeRevenu.includes("FISCAL_REFERENCE")
  );
  const revenusBrut = revenus.filter((revenu) =>
    revenu.typeRevenu.includes("SALAIRE_BRUT")
  );

  return {
    revenusBrut: {
      title: "Salaires Bruts mensuels",
      data: revenusBrut,
    },
    revenusMensuel: {
      title: "Salaires Nets fiscaux mensuels",
      data: revenusMensuel,
    },
    revenusAnnuel: {
      title: "Cumul Annuels salaires nets fiscaux",
      data: revenusAnnuel,
    },
    revenusReference: {
      title: "Revenu fiscal de référence",
      data: revenusReference,
    },
  };
};

export const revenusFormatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  maximumFractionDigits: 0,
});
