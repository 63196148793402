import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledReturnLink = styled(Link)`
    opacity: 0.4;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 16px;
    color: ${({theme}) => theme.colors.primary};
    font: ${({theme}) => theme.fonts.inter.regular};
    font-weight: 500 !important;
    margin-top: ${props => props.margintop};
    margin-bottom: ${props => props.marginbottom};
    max-width: max-content;
    *{
        margin: 0;
        font-weight: 600 !important;
    }
    transition: 400ms ease-in-out;
    &:hover{
        opacity: 1;
    }
    
`