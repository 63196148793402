import { ComponentWrapper, StyledButton } from "components";
import { HeroContentWrapper, HeroInfo, HeroWrapper } from "./homeHeroStyles";
import { withTheme } from "styled-components";
import { ReactComponent as VisaleWide } from "../../../../assets/icons/visale_wide.svg";

import heroImage from "assets//images/heroImage.png";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";

const HomeHero = ({ theme }) => {
  const [isolation, setIsolation] = useState("none");

  const handleResize = () => {
    setIsolation(computeIsolation());
  };

  const { keycloak, initialized } = useKeycloak();

  const login = () => {
    if (initialized) {
      keycloak.login({
        redirectUri: window.location.origin + "/dashboard",
      });
    }
  };

  const computeIsolation = () => {
    if (
      window.innerWidth > Number(theme.breakpoints.tablet.replace("px", ""))
    ) {
      return "right";
    } else if (
      window.innerWidth > Number(theme.breakpoints.tablet.replace("px", ""))
    ) {
      return "none";
    } else return "both";
  };

  useEffect(() => {
    setIsolation(computeIsolation());
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <HeroWrapper>
      <ComponentWrapper isolate={isolation}>
        <HeroContentWrapper>
          <div>
            <h1>
              LO<strong>Q</strong>K
            </h1>
            <h1>
              Sécurise et accélère la location
            </h1>
            <p>Contrôle en temps réel des pièces du dossier de location</p>
            <p>Validation de la capacité locative du candidat</p>
            <div>
              <StyledButton
                themeSpacingSelector={[5, 12]}
                themeSizeSelector={["montserrat", "highlight"]}
                themeWeight="semiBold"
                action={login}
              >
                Obtenir mon pass LO<strong>Q</strong>K
              </StyledButton>
            </div>
            <HeroInfo>
              <p>
                Indication sur l’éligibilité du dossier candidat à
                <VisaleWide />
              </p>
            </HeroInfo>
          </div>
          <img src={heroImage} alt="happy people" />
        </HeroContentWrapper>
      </ComponentWrapper>
    </HeroWrapper>
  );
};

export default withTheme(HomeHero);
