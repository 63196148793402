import { Fragment } from "react";
import {
  getQualiteLabel,
  getSituationPersonnelleLabel,
  getSituationProLabel,
  getStatutLocataireDepuisLabel,
  getStatutLocataireLabel,
  getStatutProLabel,
} from "utils/loqkEnumUtils";
import { getDateStringFromApiString } from "./dateUtils";

export const TranscrireTextFormulaire = (sectionName, data) => {
  const enfants = data.enfants;

  if (sectionName === "Informations Personnelles") {
    return (
      <Fragment>
        <strong>
          {getQualiteLabel(data.qualite)} {data.nom} {data.prenom}
        </strong>
        , vous êtes né le{" "}
        <strong>{getDateStringFromApiString(data.dateNaissance)}</strong> -
        quelle belle année !
      </Fragment>
    );
  } else if (sectionName === "Statut du Locataire") {
    return (
      <Fragment>
        Vous êtes actuellement{" "}
        <strong>
          {getStatutLocataireLabel(data.statutActuel)} depuis{" "}
          {getStatutLocataireDepuisLabel(data.statutDepuis)}.
        </strong>{" "}
        Vous cherchez une location en priorité dans le{" "}
        <strong>département {data.departement}</strong>
      </Fragment>
    );
  } else if (sectionName === "Situation Personnelle") {
    return (
      <Fragment>
        Vous êtes actuellement{" "}
        <strong>{getSituationPersonnelleLabel(data.situationPerso)}.</strong>{" "}
        Vous avez <strong>{enfants && enfants?.length} Enfant(s)</strong>
        {enfants && enfants.length === 0 ? "" : " de "}
        {enfants && enfants.length > 0
          ? enfants.map((_, i) => {
              return (
                <Fragment key={i}>
                  <strong>
                    {enfants[i]} an(s)
                    {i < enfants.length - 1 ? ", " : "."}
                  </strong>
                </Fragment>
              );
            })
          : null}
      </Fragment>
    );
  } else if (sectionName === "Situation Professionnelle") {
    return (
      <Fragment>
        <Fragment>
          Vous êtes actuellement{" "}
          <strong>
            {getSituationProLabel(data.situationPro)}
            {data.statutPro && <>, {getStatutProLabel(data.statutPro)}</>}
            {data.dateDebutSituation && data.dateDebutSituation !== null ? (
              <>
                {" "}
                depuis le {getDateStringFromApiString(data.dateDebutSituation)}
              </>
            ) : (
              ""
            )}
            {data.dateDebutSituation && data.dateFinSituation !== null ? (
              <> jusqu'au {getDateStringFromApiString(data.dateFinSituation)}</>
            ) : (
              ""
            )}
            .
          </strong>
          <br />
        </Fragment>
        <Fragment>
          {data.dateDernierBulletinSalaire &&
          data.dateDernierBulletinSalaire !== "" ? (
            <>
              Le dernier bulletin de salaire que vous avez en votre possession
              est celui de{" "}
              <strong>
                {getDateStringFromApiString(data.dateDernierBulletinSalaire)}.{" "}
              </strong>
            </>
          ) : (
            ""
          )}

          {data.travaille24DerniersMois === "true" ? (
            <Fragment>
              Vous avez travaillé <strong>plus de 12 mois</strong> (tous emplois
              confondus) sur les <strong>24 derniers mois.</strong>
            </Fragment>
          ) : null}
        </Fragment>
      </Fragment>
    );
  }
  return false;
};
