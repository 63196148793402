import { withTheme } from "styled-components"
import { StyledReturnLink } from "./returnLinkStyles"


const ReturnLink = ({to, marginTop=16, marginBottom=12, children,theme}) => {

    return(
        <StyledReturnLink theme={theme} to={to} margintop={theme.margins[marginTop]} marginbottom={theme.margins[marginBottom]} >
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 4l-8 8l8 8"/>
            </svg>
            {children}
        </StyledReturnLink>
    )
}

export default withTheme(ReturnLink)