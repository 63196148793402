import MaskedInput from "react-text-mask";
import { CustomInputContainer, InputWrapper } from "./styledInputStyles";
import { ReactComponent as Warning } from "assets//icons/warning.svg";
import { ReactComponent as Tooltip } from "assets//icons/info.svg";
import ErrorsWrapper from "../layout/errorsWrapper/errorsWrapper";
import TooltipPopup from "../tooltip/tooltip";
import { useState } from "react";
import { mapAttachmentTypeToImage } from "./../tooltip/utils";

const StyledMaskedInput = ({
  disabled = false,
  name,
  placeholder,
  label,
  required = false,
  errors,
  touched,
  handleBlur,
  action,
  defaultValue,
  mask,
  hasTooltip = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <CustomInputContainer disabled={disabled}>
      {hasTooltip && (
        <TooltipPopup
          showTooltip={showTooltip}
          onClose={() => setShowTooltip(false)}
          tooltipHeader={label}
          imageRef={mapAttachmentTypeToImage(name)}
        />
      )}
      {label ? (
        <label htmlFor={name}>
          {label} {required ? " *" : null}
        </label>
      ) : null}
      <InputWrapper hasError={touched && errors} hasTooltip={hasTooltip}>
        <MaskedInput
          disabled={disabled}
          name={name}
          id={name}
          placeholder={placeholder}
          mask={mask}
          onBlur={handleBlur}
          onChange={(e) => action(e)}
          value={defaultValue}
        />
        {touched && errors ? <Warning /> : null}
        <Tooltip onClick={() => setShowTooltip(true)} />
      </InputWrapper>
      <div>{errors && touched ? <ErrorsWrapper errors={errors} /> : null}</div>
    </CustomInputContainer>
  );
};

export default StyledMaskedInput;
