import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";

import { setCurrentAdministrateurBien } from "redux//administrateurBien/administrateurBienReducer";
import { setCurrentSite } from "redux//dossierLocation/dossierLocationReducer";
import * as api from "../api/administrateurBien";
import {
  LOAD_ADMINISTRATEURS_BIENS,
  LOAD_ADMINISTRATEUR_BIEN_BY_ID,
  SET_CURRENT_ADMINISTRATEUR_BIEN,
} from "./actions";

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* getListAdministrateursBiens(action) {
  const { onSuccess = () => {}, onChange = () => {} } = action;
  try {
    onChange(true);

    let response = yield call(api.getAdministrateursBiens, action.payload);

    onSuccess(response.data);
  } catch (error) {
    console.log(error);
    toast.error(
      "Une erreur s'est produite, Veuillez contacter vôtre administrateur pour plus d'informations."
    );
  } finally {
    onChange(false);
  }
}

function* getAdministrateurBienById(action) {
  const { onSuccess = () => {}, onChange = () => {} } = action;

  try {
    onChange(true);
    const { adbId } = action.payload;
    const response = yield call(api.getAdministrateurBien, adbId);
    yield put(setCurrentAdministrateurBien(response.data));
    yield put(setCurrentSite(response.data.sites[0]));
    onSuccess();
  } catch (error) {
    console.log(error);
    toast.error(
      "Une erreur s'est produite, Veuillez contacter vôtre administrateur pour plus d'informations."
    );
  } finally {
    onChange(false);
  }
}

function* setCurrentADB(action) {
  const { onSuccess = () => {} } = action;
  yield put(setCurrentAdministrateurBien(action.payload));
  onSuccess();
}

function* watchLoadAdministrateursBiens() {
  yield takeLatest(LOAD_ADMINISTRATEURS_BIENS, getListAdministrateursBiens);
}

function* watchRunSetCurrentADB() {
  yield takeLatest(SET_CURRENT_ADMINISTRATEUR_BIEN, setCurrentADB);
}

function* watchLoadAdministrateurBien() {
  yield takeLatest(LOAD_ADMINISTRATEUR_BIEN_BY_ID, getAdministrateurBienById);
}

export {
  watchLoadAdministrateursBiens,
  watchLoadAdministrateurBien,
  watchRunSetCurrentADB,
};
