//  CookiesPopup
//  Component for displaying cookies popup to the user to allow them to accept or reject cookies,
//  also allows the user to change their cookie preferences at any time,
//  also used to display the cookie policy to the user
//  State:
//     - cookie: object, the cookies object
//     - display: boolean, whether or not to display the popup, defaults to false
//     - activeForm: number, the index of the active form, defaults to 0
//     - cookies: object, represents the cookies preferences of the user, defaults to all true
//  Functions:
//     - updateCookie: function to update a cookie preference value
//     - authorizeAllCookiesAndRegister: function to set all cookies to a true value and register the user's preferences
//     - registerCookies: function to register the user's cookie preferences to browser cookies

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import StyledButton from "../../styledButton/styledButton";
import Cookie from "./cookie";
import { ReactComponent as CookieIcon } from "assets//icons/cookie.svg";
import { withTheme } from "styled-components";
import {
  CookiesForm,
  CookiesFormsWrapper,
  CookiesPopupWrapper,
  PopupBackground,
  ButtonsContainer,
  CookiesSwitchingContainer,
  FormsOverflowWrapper,
  IconContainer,
} from "./cookiesPopupStyles";

const CookiesPopup = () => {
  const [cookie, setCookie] = useCookies([
    "essential",
    "performance",
    "advertising",
  ]);

  const [display, setDisplay] = useState(false);
  const [activeform, setActiveForm] = useState(0);
  const [cookies, setCookies] = useState({
    essential: true,
    performance: true,
    advertising: true,
  });

  // updates a cookie preference value
  const updateCookie = (name) => {
    setCookies({ ...cookies, [name]: !cookies[name] });
  };

  // sets all cookies to true
  const authorizeAllCookiesAndRegister = () => {
    setCookies({
      essential: true,
      performance: true,
      advertising: true,
    });

    registerCookies();
  };

  const registerCookies = () => {
    // saves the cookies preferences to the browser cookies for 1 year and closes the popup,
    // resets the active form to 0 and sets the display to false to hide the popup

    if (cookies.essential) {
      setCookie("essential", true, {
        path: "/",
        secure: true,
        maxAge: 31536000,
      });
    }
    if (cookies.performance) {
      setCookie("performance", true, {
        path: "/",
        secure: true,
        maxAge: 31536000,
      });
    }
    if (cookies.advertising) {
      setCookie("advertising", true, {
        path: "/",
        secure: true,
        maxAge: 31536000,
      });
    }

    setActiveForm(0);
    setDisplay(false);
  };

  useEffect(() => {
    // whenever the cookies display state changes, we reset the active form to 0 to view the first form
    setActiveForm(0);
  }, [display]);

  useEffect(() => {
    setDisplay(!cookie || Object.keys(cookie).length === 0);
  }, [cookie]);

  return (
    <div>
      <CookiesPopupWrapper isShown={display}>
        <CookiesFormsWrapper isShown={display}>
          <h2>Gestion des cookies</h2>
          <FormsOverflowWrapper>
            <CookiesForm isActiveForm={activeform === 0 ? 1 : 0}>
              <p>
                Nous utilisons des cookies sur notre site internet afin
                d’améliorer votre expérience de navigation et réaliser des
                statistiques de visites. Pour plus d’informations sur nos
                cookies utilisés, <a href="/">cliquez ici</a>. Cliquez sur «
                Accepter et continuer » pour accepter tous les cookies et vous
                rendre directement sur le site, ou sur « Requis seulement » pour
                n’autoriser que les cookies strictement nécessaires..
              </p>
              <ButtonsContainer>
                <StyledButton
                  type="main"
                  action={() => setActiveForm(1)}
                  text={"Personnaliser mes préférences de cookies"}
                  themeSizeSelector={["montserrat", "regular"]}
                  themeSpacingSelector={[4, 12]}
                />
                <StyledButton
                  type="main"
                  action={() => authorizeAllCookiesAndRegister()}
                  text={"Tout autoriser"}
                  themeSizeSelector={["montserrat", "regular"]}
                  themeSpacingSelector={[4, 12]}
                />
              </ButtonsContainer>
            </CookiesForm>
            <CookiesForm isActiveForm={activeform === 1 ? 1 : 0}>
              <p>
                Vous pouvez modifier vos préférences de cookies à tout moment en
                cliquant sur le bouton « Gestion des cookies » en bas de page.
              </p>
              <CookiesSwitchingContainer>
                <Cookie
                  title={"Cookies strictement nécessaires"}
                  text={
                    "Ces cookies sont indispensables pour vous permettre de naviguer au sein du site et d’utiliser les fonctionnalités proposées, notamment pour accéder aux sections sécurisées. Sans ces cookies, les services demandés ne pourront pas être assurés."
                  }
                  onToggle={() => updateCookie("essential")}
                  value={cookies.essential}
                />
                <Cookie
                  title={"Cookies de performance"}
                  text={
                    "Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic sur notre site web, afin demesurer et d’améliorer les performances. Ils nous aident également à identifier les pages les plus / moins visitées et à évaluer comment les visiteurs naviguent sur le site. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n’acceptez pas ces cookies, nous ne pourrons pas savoir quand vous aurez réalisé votre visite sur notre site web, et nous ne pourrons pas mesurer sa performance."
                  }
                  onToggle={() => updateCookie("performance")}
                  value={cookies.performance}
                />
                <Cookie
                  title={"Cookies pour une publicité ciblée"}
                  text={
                    "Ces cookies peuvent être activés sur notre site web par nos partenaires publicitaires. Ils peuvent être utilisés par ces entreprises pour établir des profils sur vos intérêts, et afin de vous proposer des publicités ciblées sur d’autres sites. Ils fonctionnement uniquement en identifiant votre navigateur et votre appareil. Si vous n'acceptez pas cette catégorie de cookies, des publicités moins ciblées sur vos intérêts vous seront proposées lors de votre navigation sur d'autres sites web."
                  }
                  onToggle={() => updateCookie("advertising")}
                  value={cookies.advertising}
                />
              </CookiesSwitchingContainer>
              <ButtonsContainer>
                <StyledButton
                  type="main"
                  action={() => registerCookies()}
                  text={"Enregistrer les paramètres"}
                  themeSizeSelector={["montserrat", "regular"]}
                  themeSpacingSelector={[4, 12]}
                />
                <StyledButton
                  type="main"
                  action={() => authorizeAllCookiesAndRegister()}
                  text={"Tout autoriser"}
                  themeSizeSelector={["montserrat", "regular"]}
                  themeSpacingSelector={[4, 12]}
                />
              </ButtonsContainer>
            </CookiesForm>
          </FormsOverflowWrapper>
        </CookiesFormsWrapper>
        <PopupBackground isShown={display} />
      </CookiesPopupWrapper>
      <IconContainer isShown={display} onClick={() => setDisplay(!display)}>
        <CookieIcon />
      </IconContainer>
    </div>
  );
};

export default withTheme(CookiesPopup);
