import styled from "styled-components";

export const StyledErrorOutput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: ${({ theme }) => theme.paddings[12]} 0;
  h2 {
    font: ${({ theme }) => theme.fonts.montserrat.heading};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
    text-align: center;
    max-width: 560px;
    margin-bottom: ${({ theme }) => theme.margins[4]};
  }
`;
