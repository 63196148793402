import { isEmpty } from "lodash";
import {
  getDateFromStringApi,
  getFirstDayOfTodaySubMonths,
} from "../dateUtils";
import {
  SITUATION_AUTRES,
  SITUATION_ETUDIANT,
  SITUATION_RECHERCHE_EMPLOI,
  SITUATION_RETRAITE,
  SITUATION_SALARIE_PRIVE,
  SITUATION_SALARIE_PUBLIC,
  SITUATION_TRAVAILLEUR_NON_SALARIE,
  STATUT_CDD,
  STATUT_CDI_PERIODE_ESSAI,
  STATUT_INTERIMAIRE,
} from "../situationStatutProConstantes";

export const isTns = (situationPro) =>
  situationPro === SITUATION_TRAVAILLEUR_NON_SALARIE;

export const isSalarie = (situationPro) =>
  situationPro === SITUATION_SALARIE_PUBLIC ||
  situationPro === SITUATION_SALARIE_PRIVE;

export const shouldDisplayDateFinSituation = (statutPro) => {
  return (
    statutPro === STATUT_CDD.key ||
    statutPro === STATUT_CDI_PERIODE_ESSAI.key ||
    statutPro === STATUT_INTERIMAIRE.key
  );
};

export const shouldDisplayCodeNaf = (situationPro) => {
  return (
    situationPro === SITUATION_TRAVAILLEUR_NON_SALARIE ||
    situationPro === SITUATION_SALARIE_PRIVE
  );
};

export const shouldDisplyDateDernierBulletin = (
  dateDebutSituation,
  situationPro
) => {
  if (!dateDebutSituation) {
    return false;
  } else {
    return (
      getDateFromStringApi(dateDebutSituation) <
        getFirstDayOfTodaySubMonths(1) && isSalarie(situationPro)
    );
  }
};

export const shouldDisplayStatutPro = (situationPro) => {
  return !(
    situationPro === SITUATION_ETUDIANT ||
    situationPro === SITUATION_RETRAITE ||
    situationPro === SITUATION_RECHERCHE_EMPLOI
  );
};

export const shouldDisplayTravaille24DerniersMois = (situationPro) => {
  return (
    situationPro === SITUATION_SALARIE_PRIVE ||
    situationPro === SITUATION_SALARIE_PUBLIC ||
    situationPro === SITUATION_TRAVAILLEUR_NON_SALARIE ||
    situationPro === SITUATION_AUTRES ||
    situationPro === SITUATION_RECHERCHE_EMPLOI
  );
};

export const validateSituationProfessionnelle = (values) => {
  if (Object.keys(values).length === 0) {
    return {
      displayStatutPro: true,
      displayDateFinSituation: true,
      displayDateDernierBulletin: true,
      displayCodeNaf: true,
      displaySiret: true,
      displayTravailler24DerniersMois: true,
      withHiddenFields: true,
      withDisplayedFiels: true,
    };
  }
  const displayStatutPro = shouldDisplayStatutPro(values.situationPro);
  const displayDateFinSituation = shouldDisplayDateFinSituation(
    values.statutPro
  );
  const displayDateDernierBulletin = shouldDisplyDateDernierBulletin(
    values.dateDebutSituation,
    values.situationPro
  );
  const displayCodeNaf = shouldDisplayCodeNaf(values.situationPro);
  const displaySiret = isTns(values.situationPro);
  const displayTravailler24DerniersMois = shouldDisplayTravaille24DerniersMois(
    values.situationPro
  );
  const withHiddenFields =
    !displayStatutPro ||
    !displayDateFinSituation ||
    !displayDateDernierBulletin ||
    !displayCodeNaf ||
    !displaySiret ||
    !displayTravailler24DerniersMois;

  const withDisplayedFiels =
    displayStatutPro ||
    displayDateFinSituation ||
    displayDateDernierBulletin ||
    displayCodeNaf ||
    displaySiret ||
    displayTravailler24DerniersMois;

  const result = {
    displayStatutPro,
    displayDateFinSituation,
    displayDateDernierBulletin,
    displayCodeNaf,
    displaySiret,
    displayTravailler24DerniersMois,
    withHiddenFields,
    withDisplayedFiels,
  };

  return result;
};

export const clearValuesByValidator = (values, validator, up) => {
  if (!isEmpty(validator)) {
    if (!validator.displayStatutPro && values.statutPro) {
      up("statutPro", null);
    }

    if (!validator.displayDateFinSituation && values.dateFinSituation) {
      up("dateFinSituation", null);
    }

    if (
      !validator.displayDateDernierBulletin &&
      values.dateDernierBulletinSalaire
    ) {
      up("dateDernierBulletinSalaire", null);
    }

    if (!validator.displayCodeNaf && values.codeNaf) {
      up("codeNaf", null);
    }

    if (!validator.displaySiret && values.siret) {
      up("siret", null);
    }

    if (
      !validator.displayTravailler24DerniersMois &&
      values.travaille24DerniersMois
    ) {
      up("travaille24DerniersMois", null);
    }
  }
};
