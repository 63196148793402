//  StatutLocataire
//  Form section for the statut locataire

import {
  STATUT_LOCATAIRE_DEPUIS_ENUM,
  STATUT_LOCATAIRE_ENUM,
} from "utils/constantes";
import { canStatutLocataireShowMessage } from "../utils/dossierLocataireValidator";
import { FormSection, StyledSelect } from "../../index";
import StyledMaskedInput from "../../styledInput/styledInputMasked";

const StatutLocataire = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  blocked,
}) => {
  const { statutActuel } = values;

  return (
    <FormSection
      hasBorder
      title={"Statut du Locataire"}
      values={values}
      isValid={canStatutLocataireShowMessage(values)}
    >
      <StyledMaskedInput
        category="personal"
        type="text"
        name="departement"
        placeholder="44"
        mask={[/[0-9]/, /[0-9|a-b|A-B]/]}
        label="Département de recherche"
        required
        defaultValue={values.departement}
        errors={errors?.departement}
        touched={touched.departement}
        action={handleChange}
        setFieldValue={handleChange}
        handleBlur={handleBlur}
        disabled={isSubmitting || blocked}
      />

      <StyledSelect
        label="Statut Actuel"
        name="statutActuel"
        required
        options={STATUT_LOCATAIRE_ENUM}
        defaultValue={statutActuel}
        action={handleChange}
        setFieldValue={setFieldValue}
        disabled={isSubmitting || blocked}
        errors={errors.statutActuel}
        touched={touched.statutActuel}
        onBlur={handleBlur}
      />

      <StyledSelect
        label="Depuis"
        name="statutDepuis"
        required
        options={STATUT_LOCATAIRE_DEPUIS_ENUM}
        defaultValue={values.statutDepuis}
        action={handleChange}
        setFieldValue={setFieldValue}
        disabled={isSubmitting || blocked}
        errors={errors.statutDepuis}
        touched={touched.statutDepuis}
        onBlur={handleBlur}
      />
    </FormSection>
  );
};

export default StatutLocataire;
