import { withTheme } from "styled-components";
import {
  Bonjour,
  Completed,
  FormContainer,
  FormHeading,
  NotCompleted,
  SectionContainer,
  SectionInfo,
} from "./formSectionStyles";
import RenderIf from "components/layout/renderIf/renderIf";
import { TranscrireTextFormulaire } from "utils/transcrireTextFormulaire";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { ReactComponent as AcceptedIcon } from "assets/icons/accepted.svg";
import { useMemo } from "react";

const FormSection = ({
  title,
  hasBorder = false,
  children,
  isValid,
  values,
  showBonjour = false,
  infoMessage = "",
  shouldShowInfoMessage = false,
}) => {
  const message = useMemo(
    () => TranscrireTextFormulaire(title, values),
    [title, values]
  );

  return (
    <SectionContainer hasBorder={hasBorder}>
      <FormHeading>
        <div>
          <div>
            <h1>{title}</h1>
          </div>
          <RenderIf condition={isValid}>
            <Completed>
              <AcceptedIcon />
              <p>Complet</p>
            </Completed>
          </RenderIf>
        </div>
        <RenderIf condition={isValid}>
          <Completed>
            <Bonjour>
              {showBonjour ? <h1>Bonjour </h1> : null}
              <p>{message}</p>
            </Bonjour>
          </Completed>
        </RenderIf>
        <RenderIf condition={!isValid}>
          <NotCompleted>
            <DocumentIcon />
            <p>A compléter</p>
          </NotCompleted>
        </RenderIf>
        <RenderIf condition={shouldShowInfoMessage}>
          <SectionInfo>
            <WarningIcon />
            <p>{infoMessage}</p>
          </SectionInfo>
        </RenderIf>
      </FormHeading>
      <FormContainer>{children}</FormContainer>
    </SectionContainer>
  );
};

export default withTheme(FormSection);
