
import styled from 'styled-components'

export const CategoryLinker = styled.p`
    color: ${({theme, enabled}) => enabled ? theme.colors.primary : theme.colors.disabled};
    font: ${({theme}) => theme.fonts.montserrat.large};
    font-weight: ${({theme}) => theme.fontWeights.semiBold} !important;
    display: flex;
    align-items: center;
    transition: 300ms ease-in-out;
    opacity: ${({active, enabled}) => active || !enabled ? 1 : 0.5 };
    cursor: ${({enabled}) => enabled ? 'pointer' : 'not-allowed'};
    filter: ${({enabled}) => enabled ? 'none' : 'grayscale(100%)'};
    span{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${({theme,enabled}) => enabled ?theme.colors.primary : theme.colors.disabled};
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: ${({theme}) => theme.margins[6]};
        transition: 300ms ease-in-out;
        color: ${({theme}) => theme.colors.textOnPrimary};
        font: ${({theme}) => theme.fonts.montserrat.regular};
    }
    
  
`