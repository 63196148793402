import { useSearchParams } from "react-router-dom";
import { withTheme } from "styled-components";
import { CategoryLinker } from "./formsCategoryStyles";
const FormsCategory = ({
  category,
  active,
  index,
  linkTarget,
  enabled,
  allowNavigate,
}) => {
  const [_, setSearchParams] = useSearchParams();

  const setParam = () => {
    if (!enabled || !allowNavigate) {
      return;
    }
    // abortRequestAndResetController();
    setSearchParams({
      category: linkTarget,
    });
  };

  return (
    <CategoryLinker
      onClick={() => setParam()}
      active={active}
      enabled={enabled}
    >
      <span>{index}</span>
      {category}
    </CategoryLinker>
  );
};

export default withTheme(FormsCategory);
