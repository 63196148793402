export const ROLE_ADMIN_LOQK = "ROLE_ADMIN_LOQK";
export const ROLE_LOQK = "ROLE_LOQK";
export const ROLE_ADB = "ROLE_ADB";
export const ROLE_COURTIER = "ROLE_COURTIER";
export const ROLE_ADMIN_COURTIER = "ROLE_ADMIN_COURTIER";
export const ROLE_ADMIN_ADB = "ROLE_ADMIN_ADB";
export const ROLE_DEMANDE_MANAGER = "ROLE_DEMANDE_MANAGER";
export const ROLE_PARTICULIER = "ROLE_PARTICULIER";

// ROLE_UNITAIRE
export const ROLE_VALIDATION_DOSSIER_LOCATION =
  "ROLE_VALIDATION_DOSSIER_LOCATION";
export const ROLE_REJET_DOSSIER_LOCATION = "ROLE_REJET_DOSSIER_LOCATION";

const hasOneOfRoles = (keycloak, roles) => {
  return roles.some(r => {
      const realm = keycloak.hasRealmRole(r);
      const resource = keycloak.hasResourceRole(r);
      return realm || resource;
  });
}

export {
  hasOneOfRoles
}
