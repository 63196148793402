import { axiosInstance } from "./axiosInstance";

export const getDossiersLocations = async (adbId, filtre) => {
  return axiosInstance.get(`/administrateur-biens/${adbId}/dossier-locations`, {
    params: { ...filtre },
  });
};

export const createDossierLocation = async (adbId, siteId) => {
  return axiosInstance.post(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations`
  );
};

export const getDossierLocation = async (adbId, siteId, dossierLocationId) => {
  return axiosInstance.get(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${dossierLocationId}`
  );
};

export const getDossierLocationDetails = async (
  adbId,
  siteId,
  dossierLocationId
) => {
  return axiosInstance.get(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${dossierLocationId}`
  );
};

export const createDossierLocataire = async (
  adbId,
  siteId,
  dossierLocationId,
  dossierLocataire
) => {
  return axiosInstance.post(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${dossierLocationId}/dossier-locataires`,
    dossierLocataire
  );
};

export const modifierDossierLocataire = async (
  adbId,
  siteId,
  dossierLocationId,
  dossierLocataireId,
  dossierLocataire
) => {
  return axiosInstance.put(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${dossierLocationId}/dossier-locataires/${dossierLocataireId}`,
    dossierLocataire
  );
};

export const getDossierLocataireById = async (
  adbId,
  siteId,
  dossierLocationId,
  dossierLocataireId
) => {
  return axiosInstance.get(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${dossierLocationId}/dossier-locataires/${dossierLocataireId}`
  );
};

export const updateRevenus = async (
  adbId,
  siteId,
  dossierLocationId,
  dossierLocataireId,
  revenus
) => {
  return axiosInstance.put(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${dossierLocationId}/dossier-locataires/${dossierLocataireId}/revenus`,
    revenus
  );
};

export const updateTypePieceIdentite = async (
  dossierNumeriqueId,
  typePieceIdentite
) => {
  return axiosInstance.put(
    `/v2/dossiers-numeriques/${dossierNumeriqueId}`,
    {
      typePieceIdentite,
    }
  );
};

export const lancerDossierNetheos = async (dossierLocataireId) => {
  return axiosInstance.post(
    `/dossiers-locataires/${dossierLocataireId}/lancer-parcours`
  );
};

export const rouvrirDossierLocataire = async (dossierLocataireId) => {
  return axiosInstance.put(
    `/dossiers-locataires/${dossierLocataireId}/rouvrir-dossier`
  );
};

export const rouvrirDossierLocation = async (adbId, siteId, locationId) => {
  return axiosInstance.put(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${locationId}/rouvrir-dossier`
  );
};

export const accepteOrRejectDossierLocation = async (
  adbId,
  siteId,
  locationId,
  enpoint,
  message
) => {
  return axiosInstance.put(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${locationId}/${enpoint}`,
    {
      message,
    }
  );
};

export const getLocationCapaciteDetails = async (adbId, siteId, locationId) => {
  return axiosInstance.get(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${locationId}/details-algo`
  );
};
