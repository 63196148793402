import { useEffect, useState } from "react";
import {
  SidebarContainer,
  SidebarLogo,
  SidebarMain,
  SidebarToggle,
  SidebarUtil,
  SidebarUtilsBottom,
  SidebarWrapper,
  SupportWrapper,
} from "./sidebarStyles";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout2.svg";
import { ReactComponent as SupportIcon } from "assets/icons/support.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as UtilisateursIcon } from "assets/icons/utilisateur.svg";
import { ReactComponent as CourtierIcon } from "assets/icons/broker.svg";
import { ReactComponent as FoldersIcon } from "assets/icons/folders.svg";
import { ReactComponent as LoqkUsers } from "assets/icons/loqk_user.svg";
import { ReactComponent as DemandeIcon } from "assets/icons/demande.svg";

import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAdministrateurBien } from "redux/administrateurBien/administrateurBienReducer";
import ADBSelect from "./adbSelect/adbSelect";
import useOutsideClick from "../../../hooks/useOutsideClick";
import RenderIf from "../../layout/renderIf/renderIf";
import { useKeycloak } from "@react-keycloak/web";
import { selectActiveUser, UserActions } from "redux/user/userReducer";
import RenderIfRoles from "../../layout/renderIf/renderIfRoles";
import {
  ROLE_ADMIN_ADB,
  ROLE_ADMIN_COURTIER,
  ROLE_ADMIN_LOQK,
  ROLE_COURTIER,
  ROLE_DEMANDE_MANAGER,
  ROLE_LOQK,
} from "utils/security/keycloakUtils";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("dashboard");
  const utilisateur = useSelector(selectActiveUser);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const location = useLocation();
  const logout = () => {
    dispatch(UserActions.logOutUser({}));
    keycloak.logout({ redirectUri: window.location.origin });
    dispatch({ type: "DESTROY_SESSION" });
  };

  const administrateurBien = useSelector(selectAdministrateurBien);
  const outsideClickRef = useOutsideClick(() => {
    setIsSidebarOpen(false);
  });

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [activeSection]);

  useEffect(() => {
    if (utilisateur) {
      const { pathname } = location;
      if (pathname.includes("monespace/dashboard")) {
        setActiveSection("dashboard");
      } else if (pathname.includes("/loqk")) {
        setActiveSection("loqk");
      } else if (
        pathname.includes("courtier") &&
        utilisateur.typeCompte !== "COURTIER"
      ) {
        setActiveSection("courtier");
      } else if (pathname.includes("utilisateur")) {
        setActiveSection("utilisateurs");
      } else if (pathname.includes("administrateur-bien")) {
        setActiveSection("dossierLocations");
      } else if (pathname.includes("demande")) {
        setActiveSection("demandes");
      }
    }
  }, [location, utilisateur]);

  return (
    <SidebarWrapper isSidebarOpen={isSidebarOpen} ref={outsideClickRef}>
      <div>
        <SidebarToggle
          isSidebarOpen={isSidebarOpen}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <Chevron />
          <span></span>
        </SidebarToggle>
        <SidebarContainer isSidebarOpen={isSidebarOpen}>
          <SidebarLogo>
            <Link to="/">
              <Logo />
            </Link>
          </SidebarLogo>
          <SidebarMain>
            <ADBSelect
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              administrateurBien={administrateurBien}
              setActiveSidebarSection={setActiveSection}
              isActive={activeSection === "adb"}
            />
            <Link to="/dashboard">
              <SidebarUtil
                isSidebarOpen={isSidebarOpen}
                isActive={activeSection === "dashboard"}
                onClick={() => setActiveSection("dashboard")}
              >
                <div>
                  <DashboardIcon />
                  <p>Accueil</p>
                </div>
              </SidebarUtil>
            </Link>

            <RenderIfRoles roles={[ROLE_LOQK, ROLE_COURTIER]}>
              <RenderIf condition={administrateurBien}>
                <Link to="/monespace/administrateur-bien">
                  <SidebarUtil
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeSection === "dossierLocations"}
                    onClick={() => setActiveSection("dossierLocations")}
                  >
                    <div>
                      <FoldersIcon />
                      <p>Dossiers locations</p>
                    </div>
                  </SidebarUtil>
                </Link>
              </RenderIf>
            </RenderIfRoles>

            <RenderIfRoles roles={[ROLE_ADMIN_LOQK, ROLE_ADMIN_ADB]}>
              <RenderIf condition={administrateurBien}>
                <Link to="/utilisateur/dashboard">
                  <SidebarUtil
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeSection === "utilisateurs"}
                    onClick={() => setActiveSection("utilisateurs")}
                  >
                    <div>
                      <UtilisateursIcon />
                      <p>Utilisateurs</p>
                    </div>
                  </SidebarUtil>
                </Link>
              </RenderIf>
            </RenderIfRoles>
            <RenderIfRoles roles={[ROLE_ADMIN_LOQK]}>
              <Link to="/courtier/dashboard">
                <SidebarUtil
                  isSidebarOpen={isSidebarOpen}
                  isActive={activeSection === "courtier"}
                  onClick={() => setActiveSection("courtier")}
                >
                  <div>
                    <CourtierIcon />
                    <p>Courtiers</p>
                  </div>
                </SidebarUtil>
              </Link>
            </RenderIfRoles>
            <RenderIfRoles roles={[ROLE_ADMIN_LOQK]}>
              <Link to="/utilisateur/loqk/dashboard">
                <SidebarUtil
                  isSidebarOpen={isSidebarOpen}
                  isActive={activeSection === "loqk"}
                  onClick={() => setActiveSection("loqk")}
                >
                  <div>
                    <LoqkUsers />
                    <p>LOQK Utilisateurs</p>
                  </div>
                </SidebarUtil>
              </Link>
            </RenderIfRoles>
            <RenderIfRoles roles={[ROLE_DEMANDE_MANAGER]}>
              <RenderIf condition={administrateurBien}>
                <Link to="/demande-candidature/dashboard/manager">
                  <SidebarUtil
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeSection === "demandes"}
                    onClick={() => setActiveSection("demandes")}
                  >
                    <div>
                      <DemandeIcon />
                      <p>Demandes</p>
                    </div>
                  </SidebarUtil>
                </Link>
              </RenderIf>
            </RenderIfRoles>
            {utilisateur && utilisateur.courtier && (
              <RenderIfRoles roles={[ROLE_ADMIN_COURTIER]}>
                <Link to={`/courtier/${utilisateur.courtier.id}/utilisateurs`}>
                  <SidebarUtil
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeSection === "utilisateurs"}
                    onClick={() => setActiveSection("utilisateurs")}
                  >
                    <div>
                      <UtilisateursIcon />
                      <p>Utilisateurs</p>
                    </div>
                  </SidebarUtil>
                </Link>
              </RenderIfRoles>
            )}
          </SidebarMain>
          <SidebarUtilsBottom>
            <SidebarUtil isSidebarOpen={isSidebarOpen}>
              <SupportWrapper isSidebarOpen={isSidebarOpen}>
                <div>
                  <p>Avez-vous besoin d'aide ?</p>
                  <p>
                    <span>Appelez-nous au</span> +33 6 84 39 52 21
                  </p>
                  <p>
                    <span>E-Mail</span> support@loqk.fr
                  </p>
                </div>
                <SupportIcon />
                <p>Support</p>
              </SupportWrapper>
            </SidebarUtil>
            <SidebarUtil isSidebarOpen={isSidebarOpen} onClick={() => logout()}>
              <div>
                <LogoutIcon />
                <p>Se déconnecter</p>
              </div>
            </SidebarUtil>
            <SidebarUtil isSidebarOpen={isSidebarOpen}>
              <div>
                <UserIcon />
                <p>
                  {utilisateur?.nom} {utilisateur?.prenom}
                </p>
              </div>
            </SidebarUtil>
          </SidebarUtilsBottom>
        </SidebarContainer>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
