//  ErrorFetchingImage
//  Component used to display an error message when fetching a piece fails due to a network error or a server error,
//  includes a button to retry fetching the piece
//  Props:
//     - retryAction: function, the action to call when the retry button is clicked

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { ErrorFetchingContainer } from "pages/dossierLocation/forms/formsStyles";

const ErrorFetchingImage = ({ retryAction }) => {
  return (
    <ErrorFetchingContainer>
      <WarningIcon />
      <p>Une erreur s'est produite lors du chargement de piéce</p>
      <p onClick={() => retryAction()}>Recharger</p>
    </ErrorFetchingContainer>
  );
};

export default ErrorFetchingImage;
