import styled from 'styled-components'

export const LocalNavigationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({theme}) => theme.margins[12]};
    &>*{
        width: 240px;
        button{
            width: 100%;
        }
       
    }
    &>button{
        svg{
            transform: rotate(-90deg);
            width: 20px;
            height: 20px;
            path{
                stroke: ${({theme}) => theme.colors.textOnPrimary};
            }
        }
    }
       
    @media (max-width: ${({theme}) => theme.breakpoints.small}){
        flex-direction: column;
        gap: 24px;
        &>*{
            width: 100%;
            
        }
    }
`