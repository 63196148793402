import { useState, useEffect } from "react";
import { withTheme } from "styled-components";
import { SwitchBall, SwitchContainer } from "./switchStyles";
const ToggleSwitch = ({ value, action = function () {}, block = false }) => {
  const [active, setActive] = useState(value);

  useEffect(() => {
    setActive(value);
  }, [value]);

  return (
    <SwitchContainer
      active={active}
      block={block}
      onClick={() => {
        if (block) return;
        setActive(!active);
        action();
      }}
    >
      <SwitchBall active={active} />
    </SwitchContainer>
  );
};

export default withTheme(ToggleSwitch);
