import styled, { css } from "styled-components";

export const EyeIcon = styled.span`
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: block;
  svg {
    display: block !important;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  padding: ${({ theme }) => theme.paddings[2]}
    ${({ theme }) => theme.paddings[6]} ${({ theme }) => theme.paddings[2]} 0;
  border-bottom: 1px solid;
  border-color: ${({ hasError, theme }) =>
    hasError ? theme.colors.error : theme.colors.inputLine};
  color: ${({ hasError, theme }) =>
    hasError ? theme.colors.error : theme.colors.primary};
  transition: 300ms ease-in-out;
  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: default;
      border: none !important;
      color: ${({ theme }) => theme.colors.primary} !important;
      opacity: 1 !important;
      input,
      input::placeholder {
        color: ${({ theme }) => theme.colors.primary} !important;
        opacity: 1 !important;
      }
    `}

  input {
    flex-grow: 2;
    color: inherit;
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    outline: none;
    text-decoration: none;
    border: none;
    appearance: none !important;
    width: 100%;
    cursor: ${({ readOnly, disabled }) =>
      readOnly ? "default" : disabled ? "not-allowed" : "text"};
    -moz-appearance: textfield !important;
    -webkit-appearance: textfield !important;

    &:-webkit-autofill {
      appearance: none !important;
      -moz-appearance: textfield !important;
      -webkit-appearance: textfield !important;
      background: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      background: none;
    }
    &::placeholder {
      opacity: 0.3;
    }
  }
  span {
    width: max-content;
    height: max-content;
  }
  svg {
    cursor: pointer;
    height: 24px;
    width: 24px;
    &:last-of-type {
      display: ${({ hasTooltip, type }) =>
        hasTooltip || type === "money" ? "block" : "none"};
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  p {
    svg {
      cursor: auto;
    }
  }
  &:focus-within {
    border-color: ${({ hasError, theme }) =>
      hasError ? theme.colors.error : theme.colors.primary};
  }
`;

export const CustomInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  flex-grow: 2;
  max-width: 400px;
  & > label {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    color: ${({ theme }) => theme.colors.primaryOpaque};
    transition: 300ms ease-in-out;
  }
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
  transition: 300ms ease-in-out;
  & > label,
  & > div {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

export const SelectionInputContainer = styled.div`
  display: none;
`;

export const DateWrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    & > div > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 100%;
      }
    }
  }
`;
