import {
  DetailsPopupContainer,
  DetailsPopupContent,
  SyntheseReturnLink,
} from "./capaciteDetailsStyles";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { useDispatch } from "react-redux";
import { GET_LOCATION_CAPACITE_DETAILS } from "utils/saga/actions";
import { useEffect, useState } from "react";
import Spinner from "components/spinner/spinner";
import { JsonViewer } from "@textea/json-viewer";
import { useParams } from "react-router";
import { SecondaryNavigation } from "pages/utilisateur/modifyUtilisateur/modifyUtilisateurStyles";
import ComponentWrapper from "components/layout/componentWrapper/componentWrapper";
import { Link } from "react-router-dom";

const CapaciteDetails = () => {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [details, setDetails] = useState(null);
  const { locationId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_LOCATION_CAPACITE_DETAILS,
      payload: {
        onSuccess: (data) => {
          setDetails(data);
        },
        onProcessingChange: (processing) => {
          setLoadingDetails(processing);
        },
        dossierLocationId: locationId,
      },
    });
  }, []);

  const theme = {
    scheme: "Ocean",
    author: "Chris Kempson (http://chriskempson.com)",
    base00: "#2b303b",
    base01: "#343d46",
    base02: "#4f5b66",
    base03: "#65737e",
    base04: "#a7adba",
    base05: "#c0c5ce",
    base06: "#dfe1e8",
    base07: "#eff1f5",
    base08: "#bf616a",
    base09: "#d08770",
    base0A: "#ebcb8b",
    base0B: "#a3be8c",
    base0C: "#96b5b4",
    base0D: "#8fa1b3",
    base0E: "#b48ead",
    base0F: "#ab7967",
  };

  return (
    <ComponentWrapper hasMargin>
      <DetailsPopupContainer>
        <SecondaryNavigation>
          <Link to="/candidate/forms?category=synthese">
            <SyntheseReturnLink>
              <Chevron />
              Retour Synthèse
            </SyntheseReturnLink>
          </Link>
        </SecondaryNavigation>
        <DetailsPopupContent>
          <div>
            <h4>Détailes de la capacité de la location</h4>
          </div>
          <div>
            {loadingDetails && (
              <Spinner message="Chargement des détails de la capacité" />
            )}
            {!loadingDetails && details && (
              <JsonViewer value={details} theme={theme} />
            )}
            {!loadingDetails && !details && (
              <div>
                <p>Aucun détail disponible</p>
              </div>
            )}
          </div>
        </DetailsPopupContent>
      </DetailsPopupContainer>
    </ComponentWrapper>
  );
};

export default CapaciteDetails;
