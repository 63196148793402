import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectActiveUser } from "redux/user/userReducer";
import { hasOneOfRoles } from "utils/security/keycloakUtils";

const RenderIfNotRoles = ({ roles, children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [showComponent, setShowComponent] = useState(false);
  const utilisateur = useSelector(selectActiveUser);


  useEffect(() => {
    if (
      initialized &&
      keycloak.authenticated &&
      utilisateur &&
      !hasOneOfRoles(keycloak, roles)
    ) {
      setShowComponent(true);
    } else {
      setShowComponent(false);
    }
  }, [keycloak, initialized, utilisateur, roles]);

  return showComponent ? children : null;
};

export default RenderIfNotRoles;
