import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.header`
  padding-top: ${(props) => props.theme.paddings[7]};
  padding-bottom: ${(props) => props.theme.paddings[7]};
  box-shadow: ${(props) => props.theme.shadows.lightDark};
  position: relative;
  z-index: 9000;
  background: ${(props) => props.theme.colors.textOnPrimary};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    box-shadow: ${(props) =>
      props.collapse ? null : props.theme.shadows.lightDark};
    height: 144px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 112px;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    position: relative;
    z-index: 100;
  }
`;

export const UserLink = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: ${(props) => props.theme.margins[8]};
  cursor: pointer;
  h3 {
    font: ${(props) => props.theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    margin: 0;
    color: ${(props) => props.theme.colors.primary};
  }
  div {
    p {
      font: ${({ theme }) => theme.fonts.montserrat.small};
    }
  }

  & > p {
    display: block;
    font: ${(props) => props.theme.fonts.montserrat.regular};
    font-weight: 400 !important;
    color: ${(props) => props.theme.colors.primaryOpaque};
  }
  &:last-of-type {
    & > p {
      display: none;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: ${(props) => props.theme.paddings[4]} 0;

    & > p {
      font-weight: 600 !important;
      color: ${(props) => props.theme.colors.primary};
    }
    &:last-of-type {
      & > p {
        display: block;
      }
    }
  }
`;

export const UserHead = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.margins[12]};
  & > div {
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      & > svg {
        width: 18px;
        height: 18px;
        cursor: pointer;

        &:first-of-type {
          margin-left: auto;
        }
        &:last-of-type {
          margin-left: ${(props) => props.theme.margins[2]};
          path {
            stroke: ${(props) => props.theme.colors.primary};
            stroke-width: 2.5px;
          }
        }
      }
      & > p {
        font: ${(props) => props.theme.fonts.montserrat.small};
        font-weight: 600 !important;
        color: ${(props) => props.theme.colors.primary};
        margin-left: ${(props) => props.theme.margins[12]};
      }
    }
  }
  ${UserLink} {
    margin-left: 0;
    gap: 16px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    position: relative;
    width: 100%;
    margin-top: ${(props) => props.theme.margins[8]};
    &:before {
      content: "";
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
      height: 2px;
      border-radius: 8px;
      background: ${(props) => props.theme.colors.border};
    }
  }
`;

export const LogoLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.margins[8]};
  position: relative;
  /* &::before{
        content: '';
        position: absolute;
        top: -5px;
        left: 5px;
        width: 92px;
        height: 93px;
        background: #131B4B;
        z-index: 5;
        border-radius: 18px 12px 20px 12px;
    } */
  h3 {
    font: ${(props) => props.theme.fonts.montserrat.heading};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.primary};
    strong {
      color: ${(props) => props.theme.colors.loqkOriginal};
    }
  }
  svg {
    width: 88px;
    height: 88px;
    position: relative;
    z-index: 6;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    h3 {
      display: none;
    }
    svg {
      width: 64px;
      height: 64px;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primaryOpaque};
  cursor: pointer;
  margin-left: ${(props) => props.theme.margins[8]};
  font: ${(props) => props.theme.fonts.inter.regular};
  transition: 300ms ease-in-out;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const MonEspace = styled.div`
  position: relative;

  p {
    color: ${(props) => props.theme.colors.primaryOpaque};
    cursor: pointer;
    margin-left: ${(props) => props.theme.margins[8]};
    font: ${(props) => props.theme.fonts.inter.regular};
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
      width: 16px;
      height: 16px;
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(180deg)" : "rotate(0deg)"};
      transition: 400ms ease-in-out;
    }
  }
  & > div {
    position: absolute;
    top: 100%;
    left: 32px;
    transform: translateY(8px);
    max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
    width: max-content;
    background: ${(props) => props.theme.colors.textOnPrimary};
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.primaryOpaque};
    overflow: hidden;
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    transition: 500ms ease-in-out;
    display: flex;
    flex-direction: column;
    z-index: 100;
    a {
      padding: ${({ theme }) => theme.paddings[3]}
        ${({ theme }) => theme.paddings[4]};
      margin-left: 0;
      font: ${({ theme }) => theme.fonts.inter.regular};
      transition: 500ms ease-in-out;
      white-space: nowrap;
      &:hover {
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.textOnPrimary};
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    transition: 500ms ease-in-out;
    opacity: ${(props) => (props.collapse ? "1" : "0")};
    transform: ${(props) =>
      props.collapse ? "translateY(0)" : "translateY(20px)"};
    transition-delay: ${(props) => (props.collapse ? "800ms" : "600ms")};
    z-index: 100;
    p {
      margin-left: 0;
    }
    & > div {
      width: 100%;
      position: relative;
      top: unset;
      left: unset;
      border: none !important;
      text-align: center;
      a {
        padding: ${({ theme }) => theme.paddings[2]} 0;
        &:hover {
          background: none;
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

export const CTAButton = styled(Link)`
  margin-left: ${(props) => props.theme.margins[18]};
  padding: ${(props) => props.theme.paddings[3]}
    ${(props) => props.theme.paddings[8]};
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textOnPrimary};
  font: ${(props) => props.theme.fonts.montserrat.highlight};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  strong {
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.loqkOriginal};
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.largeDesktop}) {
    margin-left: ${(props) => props.theme.margins[12]};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-left: 0;
    margin-top: ${(props) => props.theme.margins[8]};
  }
`;

export const MainMenu = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 2;
  position: relative;
  z-index: 100;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 100%;
    box-shadow: ${(props) =>
      props.collapse ? props.theme.shadows.lightDark : null};
    background: #fff;
    z-index: 150;

    padding-left: ${(props) =>
      props.theme.horizontalPaddings.desktop} !important;
    padding-right: ${(props) =>
      props.theme.horizontalPaddings.desktop} !important;
    position: fixed;
    top: 0;
    left: 0;
    height: 144px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-left: ${(props) =>
      props.theme.horizontalPaddings.tablet} !important;
    padding-right: ${(props) =>
      props.theme.horizontalPaddings.tablet} !important;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding-left: ${(props) =>
      props.theme.horizontalPaddings.mobile} !important;
    padding-right: ${(props) =>
      props.theme.horizontalPaddings.mobile} !important;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 112px;
  }
`;

export const MenuToggle = styled.div`
  display: none;
  flex-direction: column;
  row-gap: 3px;
  cursor: pointer;
  width: 24px;
  height: 18px;
  margin-left: auto;
  position: relative;
  span {
    position: absolute;
    left: 0;
    height: 3px;
    width: 24px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.primary};
    transform-origin: center;
    transition: 500ms ease-in-out;

    &:nth-of-type(1) {
      transform: ${(props) =>
        props.collapse ? "rotate(135deg)" : "rotate(0deg)"};
      top: ${(props) => (props.collapse ? "9px" : "0px")};
    }
    &:nth-of-type(2) {
      transform: ${(props) =>
        props.collapse ? "translateX(100%)" : "translateX(0)"};
      opacity: ${(props) => (props.collapse ? "0" : "1")};
      top: 9px;
    }
    &:nth-of-type(3) {
      transform: ${(props) =>
        props.collapse ? "rotate(-135deg)" : "rotate(0deg)"};
      top: ${(props) => (props.collapse ? "9px" : "18px")};
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: flex;
  }
`;

export const CollapsibleMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 144px);
    transition: 700ms ease-in-out;
    position: fixed;
    align-items: flex-start;
    padding: ${(props) => props.theme.paddings[6]}
      ${(props) => props.theme.paddings[18]}
      ${(props) => props.theme.paddings[24]};
    top: 144px;
    left: ${(props) => (props.collapse ? "0" : "-200%")};
    background-color: #fff;
    transition-delay: ${(props) => (props.collapse ? "0s" : "0.5s")};

    & > * {
      margin-left: unset !important;
    }

    & > a {
      transition: 500ms ease-in-out;
      opacity: ${(props) => (props.collapse ? "1" : "0")};
      transform: ${(props) =>
        props.collapse ? "translateY(0)" : "translateY(20px)"};
      margin-left: 0;
      margin-bottom: ${(props) => props.theme.margins[4]};
      &:nth-of-type(1) {
        transition-delay: ${(props) => (props.collapse ? "700ms" : "800ms")};
      }
      &:nth-of-type(2) {
        transition-delay: ${(props) => (props.collapse ? "800ms" : "600ms")};
      }
      &:nth-of-type(3) {
        transition-delay: ${(props) => (props.collapse ? "900ms" : "400ms")};
      }
      &:nth-of-type(4) {
        transition-delay: ${(props) => (props.collapse ? "1000ms" : "200ms")};
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: ${(props) => props.theme.paddings[6]}
      ${(props) => props.theme.paddings[8]}
      ${(props) => props.theme.paddings[20]};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 112px;
    max-height: calc(100vh - 112px);
  }
`;
