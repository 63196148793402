import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.paddings[24]} 0;
  gap: 72px;
  border-bottom: ${({ hasBorder, theme }) =>
    hasBorder ? `1px solid ${theme.colors.border}` : "none"};
  gap: 72px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: ${({ theme }) => theme.paddings[12]} 0;
  }
`;

export const SectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${({ theme }) => theme.margins[6]};
  max-width: 480px;
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: ${({ theme }) => theme.colors.warning};
    }
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.warning};
  }
`;

export const FormHeading = styled.div`
  & > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 32px;
    & > div {
      &:first-of-type {
        max-width: 420px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        h1 {
          font: ${({ theme }) => theme.fonts.montserrat.large};
          font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
          color: ${({ theme }) => theme.colors.primary};
        }
        p {
          font: ${({ theme }) => theme.fonts.montserrat.regular};
          font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
          color: ${({ theme }) => theme.colors.primaryOpaque};
          width: 80%;
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 75%;
    & > div {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
    max-width: 400px;

    & > div {
      &:first-of-type {
        text-align: center;
        gap: 8px;
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-width: unset;
  }
`;

export const Completed = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
  color: ${({ theme }) => theme.colors.secondary};
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    text-align: center;
  }
`;

export const NotCompleted = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 24px;
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
  }
  svg {
    width: 48px;
    height: 48px;
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center !important;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  flex-grow: 2;
`;

export const Bonjour = styled.div`
  max-width: 433px;
  margin-top: ${({ theme }) => theme.margins[8]};

  h1 {
    font: ${({ theme }) => theme.fonts.montserrat.large};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.margins[3]};
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    strong {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.margins[12]};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: ${({ theme }) => theme.margins[8]};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: ${({ theme }) => theme.margins[6]};
  }
`;
