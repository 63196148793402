//  PersonalForm
//  Form for all the personal information of the locataire,
//  wraps around the InfosPerso, SituationPersonelle, SituationProfessionnelle
//  and StatutLocataire components, handles the form submission to Formik
//  State:
//     - canShowDialogLeavingPage: boolean, used to show the dialog when leaving the page
//     - showPrompt: boolean, used to show the prompt when leaving the page without saving
//     - leaveConfirmed: boolean, used to confirm the leaving of the page
//     - showConfirmError: boolean, used to show the error popup when users performs
//       an that is not allowed
//     - blockForm: boolean, used to block the form when the form is VALID or NON_VALID
//  Functions:
//     - updateFomState: function, used to update the form state in the redux store with
//                       the useDebouncedCallback hook to avoid too many updates to the store
//     - handlePersonalFormSubmit: function, used to handle the form submission, ignores submission
//                                 if the form is blocked or in case the form is valid and not dirty (no changes made)

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ConnectedFocusError } from "focus-formik-error";
import { usePrompt } from "hooks/useNavigateAway";
import {
  selectCurrentDossierLocataire,
  selectDossierLocation,
} from "redux/dossierLocation/dossierLocationReducer";
import { DossierLocataireStatut, DossierLocationStatut } from "utils/Statuts";
import SituationProfessionnelle from "./situationProfessionelle";
import StatutLocataire from "./statutLocataire";
import InfosPersoSection from "./InfosPerso";
import SituationPersonelle from "./situationPersonnelle";
import { LocalNavigation } from "../../index";
import Spinner from "../../spinner/spinner";
import ErrorAcknowledge from "components/popups/errorAcknowledge/errorAcknowledge";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { Warning } from "../revenus/revenusItem/revenusItemStyles";
import { Form } from "pages/dossierLocation/forms/formsStyles";
import { TYPE_VALIDATION_FORCE } from "utils/constantes";
import ConfirmationPopup from "components/popups/confirmationPopup/confirmationPopup";

const PersonalForm = (props) => {
  const {
    errors,
    touched,
    values,
    initialValues,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    resetForm,
    dirty,
    isValid,
    handleSubmit,
  } = props;

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [leaveConfirmed, setLeaveConfirmed] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState(false);
  const [blockForm, setBlockForm] = useState(false);
  const { statut: statutLocataire } = useSelector(
    selectCurrentDossierLocataire
  );
  const { statut: statutLocation, typeValidation } =
    useSelector(selectDossierLocation) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (
      statutLocataire === DossierLocataireStatut.VALIDE ||
      statutLocation === DossierLocationStatut.INVALIDE ||
      (statutLocation === DossierLocationStatut.VALIDE &&
        typeValidation === TYPE_VALIDATION_FORCE) ||
      isSubmitting
    ) {
      setBlockForm(true);
    } else {
      setBlockForm(false);
    }
  }, [statutLocataire, statutLocation, isSubmitting]);

  useEffect(() => {
    if (errors && errors.status === 409) {
      setShowConfirmError(errors.message);
    }
  }, [errors]);

  // toggles redirect prompt
  // when is dirty => show prompt to block navigation
  // when is submitting => hide prompt to allow navigation
  // when is not dirty => hide prompt to allow navigation
  useEffect(() => {
    if (isSubmitting) {
      setCanShowDialogLeavingPage(false);
    } else if (!blockForm) {
      setCanShowDialogLeavingPage(dirty);
    }
    setLeaveConfirmed(false);
  }, [dirty, isSubmitting, canShowDialogLeavingPage]);

  usePrompt(leaveConfirmed, setShowPrompt, canShowDialogLeavingPage);

  const handlePersonalFormSubmit = (e) => {
    e.preventDefault();

    // when the form blocked or is valid and not dirty => navigate to next page
    // as we don't need to submit the form
    // --------------------------------
    // otherwise submit the form which shows errors if any otherwise it submits
    // the form to Formik

    if (blockForm || (!dirty && isValid)) {
      navigate("/monespace/dossier/status?category=pieces");
    } else {
      handleSubmit(e);
    }
  };

  return (
    <Form>
      <ErrorAcknowledge
        errorMessage={showConfirmError}
        shouldShow={showConfirmError}
        handleConfirm={() => setShowConfirmError(false)}
        clearShow={() => setShowConfirmError(false)}
      />
      {isSubmitting ? (
        <Spinner message="Traitement et analyse des données" />
      ) : (
        <>
          <ConfirmationPopup
            showPopup={showPrompt}
            setShowPopup={() => setShowPrompt(false)}
            confirmTitle="Voulez-vous vraiment quitter cette page ?"
            confirmMessage="Attention ! Vous risquez de perdre vos données non sauvegardés !"
            handleCancel={() => {
              setShowPrompt(false);
              setLeaveConfirmed(false);
            }}
            handleConfirm={() => {
              setShowPrompt(false);
              setLeaveConfirmed(true);
              resetForm(initialValues);
            }}
          />
          <ConnectedFocusError />
          <InfosPersoSection
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            blocked={blockForm}
          />

          <StatutLocataire
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            blocked={blockForm}
          />

          <SituationPersonelle
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            blocked={blockForm}
          />

          <SituationProfessionnelle
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            blocked={blockForm}
          />
          {!isValid && (
            <Warning align="center">
              <WarningIcon />
              <p>Veuillez corriger les erreurs avant de continuer</p>
            </Warning>
          )}
          <LocalNavigation
            isSubmitting={isSubmitting}
            linkPrev={"#"}
            submitAction={handlePersonalFormSubmit}
          />
        </>
      )}
    </Form>
  );
};

export default PersonalForm;
