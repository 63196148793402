import styled from "styled-components";

export const HeroWrapper = styled.div`
  background: ${(props) => props.theme.colors.homeHeroBackground};
`;

export const HeroInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 0.5rem;
  width: 80%;
  max-width: 420px;
  margin-top: auto !important;
  & > svg {
    width: 28px;
    height: 28px;
    opacity: 0.5;
    margin-bottom: 0.5rem;
  }
  & > p {
    margin: 2rem 0 0 0 !important;
    font: ${(props) => props.theme.fonts.montserrat.highlight} !important;
    svg {
      display: "inline";
      height: 28px;
      transform: translateY(6px) translateX(-4px);
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    align-items: center !important;
    margin-top: 3rem !important;
    & > p {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;
      svg {
        display: block;
        height: 48px;
        margin-bottom: 0 !important;
      }
    }
  }
`;

export const HeroContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 72px;
  /* max-height: 65vh; */
  /* overflow: hidden; */
  & > img {
    width: 55%;
    object-fit: cover;
    object-position: center;
    max-height: 650px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    max-width: 620px;
    width: 50%;
    padding: ${(props) => props.theme.paddings[18]} 0
      ${(props) => props.theme.paddings[18]};
    color: ${(props) => props.theme.colors.primary};
    h1 {
      font-weight: 600 !important;
      font: ${(props) => props.theme.fonts.montserrat.pageTitle};
      margin-bottom: ${(props) => props.theme.margins[4]};
      strong {
        color: ${(props) => props.theme.colors.loqkOriginal};
        font-weight: ${(props) => props.theme.fontWeights.semiBold};
      }
    }
    p {
      color: ${(props) => props.theme.colors.primaryOpaque};
      font: ${(props) => props.theme.fonts.montserrat.highlight};
      margin-bottom: ${(props) => props.theme.margins[2]};
    }
    & > div {
      width: max-content;
      margin-top: ${(props) => props.theme.margins[6]};
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-top: ${(props) => props.theme.margins[6]};
      }
    }
  }
  button {
    strong {
      color: ${(props) => props.theme.colors.loqkOriginal} !important;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    max-height: unset;
    & > img {
      width: 100%;
    }
    & > div {
      text-align: center;
      align-items: center;
      width: 60%;
      max-width: unset;
      padding: ${(props) => props.theme.paddings[18]} 0
        ${(props) => props.theme.paddings[18]};
      padding-bottom: 2rem !important;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.small}) {
    & > div {
      width: 80%;
      h1 {
        font: ${(props) => props.theme.fonts.montserrat.title};
      }
      p {
        font: ${(props) => props.theme.fonts.montserrat.highlight};
      }
      & > div {
        width: 100%;
        button {
          font: ${(props) => props.theme.fonts.montserrat.highlight};
          padding: ${(props) => props.theme.paddings[6]}
            ${(props) => props.theme.paddings[12]};
        }
      }
    }
  }
`;
