import { call, select, takeEvery } from "redux-saga/effects";
import { uploadDocument } from "../api/uploadDocument";
import { UPLOAD_FILE } from "./actions";
import { selectAdministrateurBien } from "redux/administrateurBien/administrateurBienReducer";
import {
  selectCurrentDossierLocataire,
  selectCurrentSite,
  selectDossierLocationId,
} from "redux/dossierLocation/dossierLocationReducer";
// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* uploadFile(action) {
  const {
    id,
    file,
    filename,
    onUploadChange = () => {},
    onUploadSuccess = () => {},
    onUploadError = () => {},
  } = action.payload;

  try {
    onUploadChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);
    const { id: siteId } = yield select(selectCurrentSite);
    const dossierLocationId = yield select(selectDossierLocationId);
    const { id: locataireId } = yield select(selectCurrentDossierLocataire);

    if (file.size > 10485760) {
      onUploadError("Veuillez vérifier la taille du fichier ( taille < 10MB)");
      onUploadChange(false);
      return;
    }
    const response = yield call(
      uploadDocument,
      adbId,
      siteId,
      dossierLocationId,
      locataireId,
      id,
      file,
      filename
    );
    if (response.status === 200) {
      onUploadSuccess(response.data);
    } else if (response.status === 400) {
      onUploadError(response.data);
    } else {
      onUploadError("Erreur lors du téléchargement du fichier");
    }
  } catch (error) {
    console.log(error);
    onUploadError("Erreur lors du téléchargement du fichier");
  }
}

function* uploadDocumentToAPI() {
  yield takeEvery(UPLOAD_FILE, uploadFile);
}

export { uploadDocumentToAPI };
