//  ConfirmationPopup
//  Component used to display a confirmation prompt to the user before performing an action
//  Props:
//     - handleConfirm: function, the function to call when the user confirms
//     - handleCancel: function, the function to call when the user cancels
//     - confirmTitle: string, the message to display to the user
//     - confirmMessage: string, the prompt text to display to the user
//     - confirmButtonText: string, the text to display on the confirm button, defaults to "Oui"
//     - cancelButtonText: string, the text to display on the cancel button, defaults to "Non"
//     - showPopup: boolean, whether or not to show the popup
//     - hasPromptRequest: boolean, whether or not the popup has a request to input a text
//  Functions:
//     - submit: function, the function to call to display the popup to the user

import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ConfirmationPopupContent from "./confirmationPopupContent";
import { withTheme } from "styled-components";

const ConfirmationPopup = ({
  handleConfirm = () => {},
  handleCancel = () => {},
  theme,
  confirmTitle,
  confirmMessage,
  confirmButtonText = "Oui",
  cancelButtonText = "Non",
  showPopup,
  hasPromptRequest,
}) => {
  const submit = (theme) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleConfirm(),
        },
        {
          label: "No",
          onClick: () => handleCancel(),
        },
      ],
      keyCodeForClose: [8, 27],
      overlayClassName: "confirm-alert-overlay",
      afterClose: () => {
        document.body.style.overflow = "auto";
        handleCancel();
      },

      customUI: ({ onClose }) => {
        if (showPopup) {
          document.body.style.overflow = "hidden";
          return (
            <ConfirmationPopupContent
              theme={theme}
              confirmTitle={confirmTitle}
              confirmMessage={confirmMessage}
              confirmButtonText={confirmButtonText}
              cancelButtonText={cancelButtonText}
              handleConfirmClick={(message) => {
                onClose();
                handleConfirm(message);
              }}
              handleCancelClick={() => {
                onClose();
                handleCancel();
              }}
              hasPromptRequest={hasPromptRequest}
            />
          );
        }
      },
    });
  };

  useEffect(() => {
    if (showPopup) {
      submit(theme);
    }
  }, [showPopup]);

  return <></>;
};

export default withTheme(ConfirmationPopup);
