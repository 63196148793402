import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components";
import {
  FilesUnique,
  FilesUploadContainer,
} from "pages//dossierLocation/forms/formsStyles";

import { LocalNavigation, Spinner } from "components";

import {
  selectCurrentDossierNumerique,
  selectCurrentDossierRevenus,
} from "redux/dossierLocation/dossierLocationReducer";
import FilesIdentite from "./files/filesIdentite";
import FilesSalaire from "./files/filesSalaire";
import { LANCER_PARCOURS_NETHEOS } from "utils/saga/actions";

import FileUniqueContainer from "./files/fileUniqueContainer";
import { useNavigate } from "react-router";
import dossierLocationMethodsWrapper from "utils/wrappers/dossierLocationMethodsWrapper";

const FilesForm = ({
  formBlocked = false,
  identityFiles = [],
  financeFiles = [],
  otherFiles = [],
  processRunning = {},
  setProcessRunning = () => {},
  getAttachmentFileTitle = () => {},
  updateIdentity = () => {},
  typePieceIdentite = "",
  isLoading,
  setIsLoading,
  documentActions,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dossierRevenus = useSelector(selectCurrentDossierRevenus);
  const dossierNumerique = useSelector(selectCurrentDossierNumerique);
  const [message, setMessage] = useState();

  const lancerParcoursNetheos = (category) => {
    setMessage("Traitement et analyse des documents");
    dispatch({
      type: LANCER_PARCOURS_NETHEOS,
      onSuccess: () => {
        navigate(`/monespace/dossier/status?category=${category}`);
      },
      onChange: setIsLoading,
    });
  };

  return isLoading ? (
    <Spinner message={message} />
  ) : (
    <FilesUploadContainer
      categoryColumns={financeFiles && financeFiles.length > 0 ? 3 : 2}
    >
      <div>
        <FilesIdentite
          typePieceIdentite={typePieceIdentite}
          piecesIdentites={identityFiles}
          forceBlock={formBlocked}
          processRunning={processRunning}
          updateProcessing={setProcessRunning}
          getAttachmentFileTitle={getAttachmentFileTitle}
          updateIdentity={updateIdentity}
          dossierNumerique={dossierNumerique}
          documentActions={documentActions}
        />

        {financeFiles && financeFiles.length > 0 && (
          <FilesSalaire
            fichesPaie={financeFiles}
            forceBlock={formBlocked}
            processRunning={processRunning}
            updateProcessing={setProcessRunning}
            getAttachmentFileTitle={getAttachmentFileTitle}
            dossierNumerique={dossierNumerique}
            documentActions={documentActions}
          />
        )}
        <FilesUnique>
          {otherFiles &&
            otherFiles.length > 0 &&
            otherFiles.map((piece, index) => (
              <FileUniqueContainer
                key={index}
                piece={piece}
                forceBlock={formBlocked}
                processRunning={processRunning}
                updateProcessing={setProcessRunning}
                getAttachmentFileTitle={getAttachmentFileTitle}
                dossierNumerique={dossierNumerique}
                documentActions={documentActions}
              />
            ))}
        </FilesUnique>
      </div>

      <LocalNavigation
        linkPrev={"/monespace/dossier/status?category=situation"}
        isSubmitting={
          processRunning &&
          Object.values(processRunning).some((value) => value === true)
        }
        submitAction={() => {
          if (dossierRevenus && dossierRevenus.revenus?.length > 0) {
            lancerParcoursNetheos("revenus");
          } else {
            lancerParcoursNetheos("synthese");
          }
        }}
      />
    </FilesUploadContainer>
  );
};

export default withTheme(dossierLocationMethodsWrapper(FilesForm));
