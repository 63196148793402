import {
  Tooltip,
  TooltipBackground,
  TooltipContent,
  TooltipHeader,
  TooltipImage,
  TooltipPopupContainer,
  TooltipText,
} from "./tooltipStyles";

import { ReactComponent as CloseIcon } from "assets//icons/close.svg";

const TooltipPopup = ({
  showTooltip,
  onClose = () => {},
  tooltipText = false,
  tooltipHeader,
  imageRef = false,
}) => {
  return (
    <TooltipPopupContainer show={showTooltip}>
      <TooltipBackground />
      <Tooltip show={showTooltip}>
        <TooltipHeader>
          <h2>{tooltipHeader}</h2>
          <CloseIcon onClick={() => onClose()} />
        </TooltipHeader>
        <TooltipContent>
          {tooltipText && (
            <TooltipText hasImage={imageRef}>
              <h3>Que dois-je saisir ici?</h3>
              <p>{tooltipText}</p>
            </TooltipText>
          )}

          {imageRef && (
            <TooltipImage hasText={tooltipText}>
              <img src={imageRef} alt="" />
            </TooltipImage>
          )}
        </TooltipContent>
      </Tooltip>
    </TooltipPopupContainer>
  );
};

export default TooltipPopup;
