//  ErrorsWrapper
//  Component to display errors as a list
//  Props:
//     - errors: string or array of strings, the errors to display

import styled, { withTheme } from "styled-components";

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: ${({ theme }) => theme.paddings[12]};
  /* width: 80%; */
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.error};
    margin: 0 0 ${({ theme }) => theme.margins[2]} 0;
    transform: translateX(-24px);
    margin: 0;
  }
  p {
    color: ${({ theme }) => theme.colors.error} !important;
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 500 !important;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: -16px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.error};
      display: block;
    }
  }
`;

const ErrorsWrapper = ({ errors }) => {
  return (
    <>
      {errors ? (
        <ErrorsContainer>
          <h3>Veuillez résoudre ces problèmes pour continuer</h3>
          {Array.isArray(errors) ? (
            errors.map((error, index) => <p key={index}>{error}</p>)
          ) : (
            <p>{errors}</p>
          )}
        </ErrorsContainer>
      ) : null}
    </>
  );
};

export default withTheme(ErrorsWrapper);
