import styled, { css } from "styled-components";

export const StyledDragAndDropWrapper = styled.div``;

export const BlockDragAndDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 54;
  border-radius: 8px;
  transition: 300ms ease-in-out;
  transform: scale(0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  border: 2px solid transparent;
  & > svg {
    &:first-of-type {
      position: absolute;
      height: 85%;
      opacity: 0.1;
      left: 50%;
      top: 50%;
      width: auto;
      transform: translate(-50%, -50%);
      path {
        fill: ${({ theme }) => theme.colors.error};
        stroke-width: 0.4px;
        stroke: #fff;
      }
    }
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    color: ${({ theme }) => theme.colors.error};
    font-weight: 600 !important;
    text-align: center;
    width: 80%;
  }
  ${({ dragging, block }) => {
    if (dragging && block) {
      return css`
        transform: scale(1, 1);
        opacity: 1;
        border: 2px solid ${({ theme }) => theme.colors.error};
      `;
    }
  }}
`;
