//  Footer
//  Bottom navigation component of the app

import { ComponentWrapper } from "../../index";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { withTheme } from "styled-components";
import {
  Copyright,
  FooterContent,
  FooterWrapper,
  LogoLink,
  StyledLink,
} from "./footerStyles";

const Footer = () => {
  return (
    <FooterWrapper>
      <ComponentWrapper>
        <FooterContent>
          <div>
            <LogoLink to="/">
              <LogoIcon />
            </LogoLink>
            <StyledLink to="/qui-sommes-nous">Qui sommes-nous?</StyledLink>
          </div>
          <div>
            <StyledLink to="/mentions-legales">Mentions Légales</StyledLink>
            <StyledLink to="/donnees-personnelles">
              Données Personnelles
            </StyledLink>
          </div>
        </FooterContent>
        <Copyright>2023 @ LOQK, Tous les droits reservés</Copyright>
      </ComponentWrapper>
    </FooterWrapper>
  );
};

export default withTheme(Footer);
