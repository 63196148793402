//  DocumentActions
//  Component used to display available actions for an already uploaded document
//  Props:
//     - openDocumentAction: function, function to call when user clicks on "Visualiser" button
//     - deleteDocumentAction: function, function to call when user clicks on "Supprimer" button
//     - uploadDocumentAction: function, function to call when user clicks on "Ajouter un document" button
//     - downloadDocumentAction: function, function to call when user clicks on "Télécharger" button
//     - pieceNumerique: object, the document to display actions for

import { PieceNumeriqueStatut } from "utils/Statuts";
import { ReactComponent as PreviewIcon } from "assets/icons/preview.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as DocumentUploadIcon } from "assets/icons/addDocument.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { Action, Actions } from "pages/dossierLocation/forms/formsStyles";

const DocumentSmallActions = ({
  openDocumentAction = () => {},
  deleteDocumentAction = () => {},
  uploadDocumentAction = () => {},
  downloadDocumentAction = () => {},
  pieceNumerique,
  blocked,
}) => {
  const { id, statut } = pieceNumerique;

  return (
    <Actions>
      {(statut === PieceNumeriqueStatut.EN_COURS ||
        statut === PieceNumeriqueStatut.A_DEPOSER ||
        statut === PieceNumeriqueStatut.REJETEE) &&
        !blocked && (
          <>
            <Action
              text="Supprimer le document"
              onClick={() => deleteDocumentAction()}
            >
              <DeleteIcon />
            </Action>
            <Action text="Ajouter un document">
              <label htmlFor={id}>
                <input
                  accept="application/*, image/*, capture/*"
                  type="file"
                  id={id}
                  name={id}
                  onChange={(e) => uploadDocumentAction(e)}
                />
                <DocumentUploadIcon />
              </label>
            </Action>
          </>
        )}
      <Action text="Visualiser" onClick={() => openDocumentAction()}>
        <PreviewIcon />
      </Action>
      <Action text="Télécharger" onClick={() => downloadDocumentAction()}>
        <DownloadIcon />
      </Action>
    </Actions>
  );
};

export default DocumentSmallActions;
