import styled from "styled-components";

export const ErrorAcknowledgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  h2 {
    width: 80%;
    text-align: center;
    margin: 0 0 ${(props) => props.theme.margins[3]};
    font: ${(props) => props.theme.fonts.montserrat.heading};
    font-weight: 600 !important;
    color: ${(props) => props.theme.colors.primary};
  }
  p {
    text-align: center;
    color: ${(props) => props.theme.colors.primaryOpaque};
    font: ${(props) => props.theme.fonts.montserrat.regular};
    font-weight: 500 !important;
    width: 80%;
    max-width: 380px;
  }
  div {
    margin: ${(props) => props.theme.margins[8]} 0
      ${(props) => props.theme.margins[4]};
    opacity: 0.5;
    color: ${(props) => props.theme.colors.primary};
    font: ${(props) => props.theme.fonts.montserrat.regular};
    transition: 400ms ease-in-out;
    max-height: 20px;
    overflow: hidden;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
    h3 {
      font: ${(props) => props.theme.fonts.montserrat.regular};
      font-weight: 600 !important;
    }
    p {
      text-align: start;
      font-weight: 500 !important;
      color: ${(props) => props.theme.colors.primary};
    }
    &:hover {
      max-height: 128px;
    }
  }
  button {
    margin-top: ${(props) => props.theme.margins[8]};
    width: 80%;
    max-width: 256px;
  }
`;
