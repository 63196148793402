import { useState } from "react";
import { withTheme } from "styled-components";
import { FlowItem, OverlayContainer } from "./homeFlowItemStyles";

const HomeFlowItem = ({ children, hasOverlay }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <FlowItem>
      {children[0]}

      {Array.isArray(children[1].props.children) ? (
        <h2>
          {children[1].props.children[0]}
          <span
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {children[1].props.children[1]}
          </span>
        </h2>
      ) : (
        <h2>{children[1].props.children}</h2>
      )}
      {children[2]}
      {hasOverlay ? (
        <OverlayContainer isHovering={isHovering}>
          <h2>Notre charte de sécurisation de vos informations</h2>
          <p>
            Les informations et pièces collectées le sont dans le respect
            complet du cadre réglementaire qui régit non seulement l'activité de
            la gestion locative (Loi ALUR) mais également le traitement des
            informations personnelle (LOI n° 2018-493 du 20 juin 2018 relative à
            la protection des données - « RGPD »
          </p>
          <p>
            Règlement Général de Protection des Données). Toutes nos données
            sont enregistrées sur des serveurs sécurisés en Europe et sont
            supprimées sur simple demande ou systématiquement à l'issue d'une
            période maximale de 3 mois.
          </p>
        </OverlayContainer>
      ) : null}
    </FlowItem>
  );
};

export default withTheme(HomeFlowItem);
