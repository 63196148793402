import { useDispatch, useSelector } from "react-redux";
import { selectCurrentDossierNumerique } from "redux/dossierLocation/dossierLocationReducer";
import { DELETE_DOCUMENT, UPLOAD_FILE } from "utils/saga/actions";

const dossierLocationMethodsWrapper = (WrappedComponent) => {
  const HOC = (props) => {
    const dispatch = useDispatch();
    const dossierNumerique = useSelector(selectCurrentDossierNumerique);

    const deleteDocument = ({
      pieceId,
      onSuccess = () => {},
      onChange = () => {},
    }) => {
      dispatch({
        type: DELETE_DOCUMENT,
        payload: {
          numeriqueId: pieceId,
          setFileProcessing: onChange,
          onProcessingSuccess: onSuccess,
        },
      });
    };

    const uploadDocument = ({
      pieceId,
      file,
      filename,
      onSuccess = () => {},
      onChange = () => {},
      onError = () => {},
    }) => {
      dispatch({
        type: UPLOAD_FILE,
        payload: {
          id: pieceId,
          file,
          filename,
          dossierNumeriqueId: dossierNumerique?.id,
          onUploadChange: onChange,
          onUploadSuccess: onSuccess,
          onUploadError: onError,
        },
      });
    };

    return (
      <WrappedComponent
        {...props}
        documentActions={{
          handleDeleteDocument: deleteDocument,
          handleUploadDocument: uploadDocument,
        }}
      />
    );
  };

  return HOC;
};

export default dossierLocationMethodsWrapper;
