import styled, { css } from "styled-components";

export const AttachmentComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const AttachmentContainerWrapper = styled.div`
  border-radius: 8px;
  transition: 400ms ease-in-out;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  height: 100%;
  ${({ isProcessing }) => {
    if (!isProcessing) {
      return css`
        & > div {
          width: 100%;
          height: 100%;
          & > div {
            width: 100%;
            height: 100%;
            & > * {
              height: 100%;
            }
          }
        }
      `;
    }
  }}
  ${({
    hasImage,
    isProcessing,
    theme,
    isDragging,
    blocked,
    uploadNotAllowed,
    hasError,
  }) => {
    if (blocked && !hasImage && !isProcessing && !hasError) {
      return css`
        background: ${theme.colors.primary};
      `;
    }
    if (blocked && isDragging) {
      return css`
        &:hover {
          border: 2px solid ${theme.colors.border};
        }
      `;
    }
    if (isProcessing) {
      return css`
        border: 2px solid ${theme.colors.border};
      `;
    }
    if (hasImage) {
      return css`
        border-radius: 8px;
        border: 2px ${isDragging && !uploadNotAllowed ? "dashed" : "solid"}
          ${isDragging && !uploadNotAllowed
            ? theme.colors.primary
            : theme.colors.border};
        &:hover {
          border: 2px solid ${theme.colors.border};
        }
      `;
    }
    if (!hasImage && !hasError) {
      return css`
        ${!uploadNotAllowed &&
        css`
          border: 2px dashed
            ${isDragging ? theme.colors.primary : theme.colors.border};
        `}
        transition: 400ms ease-in-out;
        &:hover {
          background: ${theme.colors.primary};
        }
      `;
    }
  }}
`;
