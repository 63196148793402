import styled from "styled-components";

export const IconContainer = styled.div`
  position: fixed;
  bottom: 64px;
  right: 64px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${({ theme, isShown }) =>
    isShown ? theme.colors.textOnPrimary : theme.colors.primary};
  z-index: 6000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &,
  & * {
    transition: 0.3s ease-in-out;
  }
  svg {
    path {
      fill: ${({ theme, isShown }) =>
        isShown ? theme.colors.primary : theme.colors.textOnPrimary};
    }
  }
  &::before {
    content: "X";
    position: absolute;
    top: -4px;
    right: -4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.error};
    text-align: center;
    color: ${({ theme }) => theme.colors.textOnPrimary};
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 600 !important;
    transition: 0.3s ease-in-out;
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    visibility: ${({ isShown }) => (isShown ? "visible" : "hidden")};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    right: 24px;
    bottom: 24px;
    width: 48px;
    height: 48px;
    &::before {
      width: 20px;
      height: 20px;
      font-size: 8px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const CookiesPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isShown }) => (isShown ? "0" : "-200%")};
  width: 100%;
  height: 100%;
  z-index: 11000;
  transition: ${({ isShown }) => (isShown ? "0.5s" : "0.1s")} ease-in-out;
  transition-delay: 800ms;
`;

export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isShown }) => (isShown ? "0" : "-200%")};
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.popupBackground};
  z-index: 5001;
  transition: 0.8s ease-in-out;
  transition-delay: 200ms;
`;

export const FormsOverflowWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.paddings[4]};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    max-height: 75vh;
    padding: 0;
  }
`;

export const CookiesFormsWrapper = styled.div`
  position: absolute;
  top: ${({ isShown }) => (isShown ? "50%" : "-200%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5002;
  background: #fff;
  padding: ${({ theme }) => theme.paddings[8]}
    ${({ theme }) => theme.paddings[14]};
  width: 80%;
  max-width: 960px;
  translate: ${({ isShown }) =>
    isShown ? "translateY(0)" : "translateY(-150%)"};
  transition: 0.8s ease-in-out;
  border-radius: 12px;

  h2 {
    font: ${({ theme }) => theme.fonts.montserrat.heading};
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.margins[4]};
    font-weight: 600 !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: ${({ theme }) => theme.paddings[8]}
      ${({ theme }) => theme.paddings[8]};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    h2 {
      text-align: center;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  margin-top: ${({ theme }) => theme.margins[12]};
  & > button {
    &:first-of-type {
      background: none;
      border: 2px solid ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: ${({ theme }) => theme.margins[8]};
    & > button {
      padding: ${({ theme }) => theme.paddings[4]}
        ${({ theme }) => theme.paddings[4]};
    }
  }
`;

export const CookiesForm = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  left: ${({ isActiveForm }) => (isActiveForm ? "0" : "-200%")};
  max-height: ${({ isActiveForm }) => (isActiveForm ? "3220px" : "0")};
  overflow: hidden;
  top: 0;
  transition: 0.8s ease-in-out;
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    color: ${({ theme }) => theme.colors.primary};
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    p {
      max-height: ${({ isActiveForm }) => (isActiveForm ? "280px" : "0")};
      overflow-y: auto;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const CookiesSwitchingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CookieToggle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: ${({ theme }) => theme.paddings[6]} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      width: 100%;
      font: ${({ theme }) => theme.fonts.montserrat.highlight};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600 !important;
    }

    svg {
      margin-left: ${({ theme }) => theme.margins[4]};
      transform: ${({ isActive }) =>
        isActive ? "rotate(180deg)" : "rotate(0deg)"};
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
  }
  p {
    max-height: ${({ isActive }) => (isActive ? "720px" : "0")};
    overflow: hidden;
    transition: 0.8s ease-in-out;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    & > div {
      h3 {
        width: 70%;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    & > div {
      h3 {
        width: 100%;
      }
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      row-gap: 12px;
      h3 {
        grid-area: 1/1/1/4;
      }
      & > div {
        grid-area: 2/1/2/4;
      }
      svg {
        grid-area: 1/4/1/5;
      }
    }
  }
`;
