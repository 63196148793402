import styled, { css } from "styled-components";

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ showPopup }) => (showPopup ? "0" : "200%")};
  width: 100%;
  height: 100%;
  visibility: ${({ showPopup }) => (showPopup ? "visible" : "hidden")};
  background: ${({ theme }) => theme.colors.documentPopupBackground};
  z-index: 10000;
  transition: 600ms ease-in-out;
  overflow: hidden;
  .react-pdf__Page__annotations {
    display: none;
  }
`;

export const ZoomControls = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  & > div {
    width: 120px;
    label {
      display: none;
    }
  }
  & > svg {
    cursor: pointer;
    path {
      transition: 300ms ease-in-out;
      fill: ${({ theme }) => theme.colors.primaryOpaque};
      fill-opacity: 1;
    }
    &:hover {
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const MainDocumentActions = styled.div`
  padding: ${({ theme }) => theme.paddings[4]}
    ${({ theme }) => theme.paddings[14]};
  background: ${({ theme }) => theme.colors.textOnPrimary};
  border-radius: 8px;
  width: 100%;
  max-width: max-content;
  margin-top: ${({ theme }) => theme.margins[6]};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 24px;
  & > svg {
    cursor: pointer;
    display: none;
  }
  & > span {
    display: none;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 24px;
    & > div {
      display: flex;
      align-items: center;
      gap: 16px;
      & > svg {
        width: 28px;
        height: 28px;
      }
      &:first-of-type {
        div {
          padding-right: 0;
          padding-left: 0;
          max-width: 32px;
        }
        input {
          text-align: center;
        }

        span {
          font: ${({ theme }) => theme.fonts.montserrat.regular};
          font-weight: 600 !important;
          color: ${({ theme }) => theme.colors.primaryOpaque};
          width: 16px;
          text-align: center;
        }
        p {
          font: ${({ theme }) => theme.fonts.montserrat.regular};
          font-weight: 600 !important;
          color: ${({ theme }) => theme.colors.primary};
          min-width: 16px;
          text-align: center;
        }
      }
      &:nth-of-type(2) {
        & > svg {
          cursor: pointer;
          path {
            transition: 300ms ease-in-out;
            stroke-opacity: 1;
            stroke: ${({ theme, shouldFullScreen }) =>
              shouldFullScreen
                ? theme.colors.primary
                : theme.colors.primaryOpaque};
          }
          &:hover {
            path {
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }
        }
      }
    }
    &:nth-of-type(2) {
      svg {
        cursor: pointer;
        path {
          transition: 300ms ease-in-out;
          fill-opacity: 1;
        }
        &:hover {
          path {
            fill: ${({ theme }) => theme.colors.primary} !important;
            fill-opacity: 0.7;
          }
        }
        &:first-of-type {
          path {
            fill: ${({ theme, orientation }) =>
              orientation === "vertical"
                ? theme.colors.primary
                : theme.colors.primaryOpaque};
          }
        }
        &:last-of-type {
          path {
            fill: ${({ theme, orientation }) =>
              orientation === "horizontal"
                ? theme.colors.primary
                : theme.colors.primaryOpaque};
          }
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    position: absolute;
    top: 88px;
    left: 32px;
    z-index: 5;
    padding: ${({ theme }) => theme.paddings[4]}
      ${({ theme }) => theme.paddings[7]};
    gap: 24px;
    max-width: 88px;
    transition: 500ms ease-in-out;
    box-shadow: ${({ theme }) => theme.shadows.lightDark};
    & > svg {
      display: block;
      cursor: pointer;
      flex-shrink: 0;
      path {
        transition: 300ms ease-in-out;
        fill: ${({ theme }) => theme.colors.primaryOpaque};
      }
      &:hover {
        path {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }
    & > span {
      visibility: ${({ showActions }) => (showActions ? "visible" : "hidden")};
    }
    & > div {
      max-width: ${({ showActions }) => (showActions ? "680px" : "0")};
      width: 100%;

      transition: 500ms ease-in-out;
      &:first-of-type {
        position: absolute;
        top: -88px;
        left: calc(50vw - 32px);
        transform: translateX(-50%);
        background: ${({ theme }) => theme.colors.textOnPrimary};
        height: 64px;
        max-width: ${({ showActions }) => (showActions ? "680px" : "0")};
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        min-width: ${({ showActions }) => (showActions ? "488px" : "0")};
        justify-content: center;
        box-shadow: ${({ theme }) => theme.shadows.lightDark};
        & > * {
          opacity: ${({ showActions }) => (showActions ? "1" : "0")};
          transition: 300ms ease-in-out;
          transition-delay: ${({ showActions }) =>
            showActions ? "680ms" : "0"};
        }
        & > span:last-of-type {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    z-index: 8;
    right: 0;
    box-shadow: none;
    top: -18px !important;
    left: unset;
    max-width: ${({ showActions }) => (showActions ? "100%" : "0")};
    transition: 500ms ease-in-out;
    top: 48px !important;
    & > div {
      &:first-of-type {
        min-width: unset;
        & > div {
          &:first-of-type {
            display: none;
          }
          & > span {
            display: none;
          }
        }
      }
    }
    ${ZoomControls} {
      & > svg {
        transition: 300ms ease-in-out;
        &:first-of-type {
          opacity: ${({ scale }) => (scale === 1 ? 0.4 : 1)};
          cursor: ${({ scale }) => (scale === 1 ? "default" : "pointer")};
        }
        &:last-of-type {
          opacity: ${({ scale }) => (scale === 3 ? 0.4 : 1)};
          cursor: ${({ scale }) => (scale === 3 ? "default" : "pointer")};
        }
      }
    }
    & > div {
      &:first-of-type {
        top: 0;
        left: 0;
        transform: translateX(0);
        height: auto;
        padding: 0;
        width: 100%;
        box-shadow: none !important;
        max-width: unset;

        & > div {
          &:nth-of-type(2) {
            & > svg {
              display: none;
            }
            & > div {
              width: 196px;
              padding: 0;
              gap: 0;

              & > svg {
                padding: ${({ theme }) => theme.paddings[4]};
                width: 64px;
                height: 64px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
  }
`;

export const RenderedDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: ${({ theme }) => theme.paddings[10]} 0;
  position: relative;
  height: 100%;
  cursor: ${({ shouldFullScreen }) =>
    shouldFullScreen ? "pointer" : "default"};
  & > div {
    &:first-of-type {
      width: auto;
      max-width: 640px;
      width: 100%;
      /* max-width: 65%; */

      & > div {
        height: 100%;
        min-height: unset !important;
        min-width: unset !important;
        overflow: auto;
        max-height: 83vh;
        max-width: 100%;
        width: max-content;
        margin: 0 auto;

        & > div {
          display: none;
        }
        canvas {
          margin-left: auto;
          margin-right: auto;
          width: 100% !important;
          height: auto !important;
          transform: ${({ scale }) => `scale(${scale})`};
          transform-origin: top left;
        }
      }
    }
  }
  ${({ shouldFullScreen }) =>
    shouldFullScreen &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
      & > div {
        &:first-of-type {
          width: auto;
          max-width: 65%;
          width: unset;
          & > div {
            max-height: 85vh;
            canvas {
              height: 100% !important;
              min-height: 100% !important;
              /* min-width: 100% !important; */
              width: auto !important;
              transform: unset;
            }
          }
        }
      }
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & > div {
      &:first-of-type {
        max-width: unset;
        width: unset !important;
        & > div {
          max-height: 85vh;
          max-width: 65vw !important;
          canvas {
          }
        }
      }
    }
    margin-top: ${({ theme }) => theme.margins[16]};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: ${({ theme }) => theme.margins[28]};
    padding: ${({ theme }) => theme.paddings[10]} 0 0;
    max-height: 84vh;
    & > div {
      margin-top: ${({ theme }) => theme.margins[16]};
      width: 100% !important;
      &:first-of-type {
        overflow: auto;

        & > div {
          max-height: unset !important;
          max-width: 90vw !important;
          height: 100% !important;
          overflow: visible;
        }
      }
    }
  }
`;

export const DocumentInformationPopup = styled.div`
  position: absolute;
  top: 128px;
  left: 368px;
  width: 320px;
  background: ${({ theme }) => theme.colors.textOnPrimary};
  border-radius: 8px;
  padding: ${({ theme }) => theme.paddings[6]}
    ${({ theme }) => theme.paddings[8]};
  opacity: 0;
  visibility: hidden;
  transition: 300ms ease-in-out;
  transform: translateX(-96px);
  z-index: 1;
  box-shadow: ${({ theme }) => theme.shadows.lightDark};
  & > span {
    height: 2px;
    width: 80%;
    background: ${({ theme }) => theme.colors.border};
    margin: ${({ theme }) => theme.margins[8]} 0;
    content: "";
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.margins[4]};
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: ${({ theme }) => theme.margins[4]};
    p {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primaryOpaque};
      &:last-of-type {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  & > ul {
    padding-right: 8px;
    list-style-type: none;
    margin-top: 12px;
  }

  & > ul > li {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    color: ${({ theme }) => theme.colors.error};
    font-weight: 600 !important;
    width: 100%;
    text-align: center;
    transition: 200ms ease-in-out;
    overflow: hidden;
    text-align: left;
    position: relative;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-right: 8px;
    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.error};
    }
  }
  ${({ shouldShow }) =>
    shouldShow &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    left: 280px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    left: 0;
    width: calc(100% - 64px);
    margin: 0 ${({ theme }) => theme.margins[8]};
    top: unset;
    bottom: 16px;
  }
`;

export const DocumentNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  & > div {
    display: none;
  }
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: 500ms ease-in-out;
    path {
      stroke: ${({ theme }) => theme.colors.primaryOpaque};
      transition: 500ms ease-in-out;
    }
    &:hover {
      path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
    &:first-of-type {
      transform: rotate(90deg);
      opacity: ${({ page }) => (page === 1 ? 0 : 1)};
      cursor: ${({ page }) => (page === 1 ? "default" : "pointer")};
      pointer-events: ${({ page }) => (page === 1 ? "none" : "auto")};
    }
    &:last-of-type {
      transform: rotate(-90deg);
      opacity: ${({ page, totalPages }) => (page === totalPages ? 0 : 1)};
      cursor: ${({ page, totalPages }) =>
        page === totalPages ? "default" : "pointer"};
      pointer-events: ${({ page, totalPages }) =>
        page === totalPages ? "none" : "auto"};
    }
  }
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.paddings[12]};
  transform: translate(-50%, -50%);
  svg {
    width: 48px;
    height: 48px;
    path {
      stroke-width: 1px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 0 ${({ theme }) => theme.paddings[8]};
    svg {
      width: 24px;
      height: 24px;
    }
    justify-content: center;
    top: 0;
    & > div {
      display: flex;
      align-items: center;
      gap: 16px;
      p,
      span {
        min-width: 16px;
        text-align: center;
        font: ${({ theme }) => theme.fonts.montserrat.regular};
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primary};
      }
      & > div {
        & > div {
          max-width: 32px;
          padding-left: 0;
          padding-right: 0;
          input {
            text-align: center;
          }
        }
      }
    }
  }
`;

export const MainDocumentPreviewContainer = styled.div`
  padding-left: 320px;
  transition: 500ms ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ collapsePages }) =>
    !collapsePages &&
    css`
      padding-right: 320px;
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding-left: 260px;
    ${({ collapsePages }) =>
      !collapsePages &&
      css`
        padding-right: 260px;
      `}
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    ${({ loadingDoc }) =>
      loadingDoc &&
      css`
        padding-top: ${({ theme }) => theme.paddings[24]};
      `}
  }
`;

export const DocumentPreviewInfo = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.paddings[6]}
    ${({ theme }) => theme.paddings[12]} ${({ theme }) => theme.paddings[6]};

  background: ${({ theme }) => theme.colors.textOnPrimary};
  box-shadow: ${({ theme }) => theme.shadows.lightDark};
  gap: 72px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    p {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primaryOpaque};
    }
    h5 {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primary};
    }
    &:last-of-type {
      margin-left: auto;
      padding-right: ${({ theme }) => theme.paddings[18]};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 32px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const ActionsSeparator = styled.span`
  width: 2px;
  background: ${({ theme }) => theme.colors.border};
  height: ${({ type }) => (type === "default" ? "32px" : "16px")};
`;

export const DropUpload = styled.div`
  & > div {
    position: relative;
    overflow: hidden;
    padding: ${({ theme }) => theme.paddings[6]}
      ${({ theme }) => theme.paddings[4]};
    border: 2px ${({ dragging }) => (dragging ? "solid" : "dashed")}
      ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 500ms ease-in-out;
    cursor: pointer;
    svg {
      path {
        transition: 500ms ease-in-out;
      }
      margin-bottom: ${({ theme }) => theme.margins[4]};
      width: 32px;
      height: 32px;
    }
    p {
      transition: 500ms ease-in-out;
      &:last-of-type {
        margin-top: ${({ theme }) => theme.margins[2]};
        font: ${({ theme }) => theme.fonts.montserrat.small};
        color: ${({ theme }) => theme.colors.primaryOpaque};
      }
    }
    div {
      img {
        transform: scale(0.8);
      }
    }
    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      p {
        color: ${({ theme }) => theme.colors.textOnPrimary};
      }
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.textOnPrimary};
        }
      }
      img {
        transform: scale(0.8) rotate(10deg);
      }
    }
    ${({ dragging }) =>
      dragging &&
      css`
        background: ${({ theme }) => theme.colors.primary};
        p {
          color: ${({ theme }) => theme.colors.textOnPrimary} !important;
        }
        svg {
          path {
            stroke: ${({ theme }) => theme.colors.textOnPrimary};
          }
        }
        img {
          transform: scale(0.8) rotate(10deg) !important;
        }
      `}
  }
  margin-top: auto;
  width: 100%;
  padding: 0 ${({ theme }) => theme.paddings[8]};
`;

export const CloseToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 ${({ theme }) => theme.paddings[8]};
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.margins[4]};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
  }
`;

export const SidebarActionsCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  margin-top: ${({ theme }) => theme.margins[4]};
  transition: 500ms ease-in-out;
  max-height: ${({ show }) => (show ? "100%" : "24px")};
  overflow: hidden;
  & > div {
    &:nth-of-type(1) {
      margin-bottom: ${({ theme }) => theme.margins[4]};
      padding: 0 ${({ theme }) => theme.paddings[8]};
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      & > p {
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primaryOpaque};
      }
      svg {
        width: 16px;
        height: 16px;
        transform: ${({ show }) => (show ? "rotate(180deg)" : "rotate(0deg)")};
        transition: 500ms ease-in-out;
      }
    }
    &:nth-of-type(2) {
      transition: 500ms ease-in-out;
      opacity: ${({ show }) => (show ? "1" : "0")};
      transform: ${({ show }) =>
        show ? "translateY(0)" : "translateY(-16px)"};
      width: 100%;
      & > div,
      & > label {
        display: flex;
        padding: ${({ theme }) => theme.paddings[4]}
          ${({ theme }) => theme.paddings[8]};
        align-items: center;
        gap: 16px;
        cursor: pointer;
        width: 100%;
        transition: 500ms ease-in-out;
        svg {
          width: 24px;
          height: 24px;
          color: ${({ theme }) => theme.colors.primary};
        }
        &:hover {
          background: ${({ theme }) => theme.colors.hover};
        }

        input {
          display: none;
        }
      }
      & > div {
        &:first-of-type {
          svg {
            path {
              fill: ${({ theme }) => theme.colors.primary};
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & > div {
      &:nth-of-type(1) {
        margin: 0;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    & > div {
      &:nth-of-type(1) {
        padding: 0 ${({ theme }) => theme.paddings[12]} 0 0;
      }
      &:nth-of-type(2) {
        & > div,
        & > label {
          padding-left: ${({ theme }) => theme.paddings[2]} !important;
        }
      }
    }
  }
`;

export const SidebarToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  cursor: pointer;
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    max-width: ${({ isCollapsed }) => (isCollapsed ? "0" : "100%")};
    overflow: hidden;
    transition: 500ms ease-in-out;
    white-space: nowrap;
  }
  svg {
    transform-origin: right;
    transform: ${({ isCollapsed }) =>
      isCollapsed ? "rotate(90deg) translateX(16px)" : "rotate(0deg)"};
    transition: 500ms ease-in-out;
  }
  width: 100%;
  ${({ isCollapsed }) => {
    if (isCollapsed) {
      return css`
        padding: 0 ${({ theme }) => theme.paddings[2]};
      `;
    } else {
      return css`
        padding: 0 ${({ theme }) => theme.paddings[8]};
      `;
    }
  }}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: ${({ theme }) => theme.paddings[8]};
    p {
      max-width: unset;
    }
    svg {
      transform-origin: center;
      transform: ${({ isCollapsed }) =>
        isCollapsed ? "rotate(90deg)" : "rotate(0deg)"};
    }
  }
`;

export const AllDocumentPages = styled.div`
  & > div {
    overflow-y: auto !important;
    height: 100%;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 100%;
      align-items: center;
    }
  }

  h2 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    white-space: nowrap;
    margin: ${({ theme }) => theme.margins[4]} 0
      ${({ theme }) => theme.margins[4]};
  }
`;

export const SmallPagePreview = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.textOnPrimary};
  box-shadow: ${({ theme }) => theme.shadows.lightDark};
  width: 210px;
  cursor: pointer;
  & > div {
    min-width: unset !important;
    max-width: 210px;
    width: 100%;
    padding: ${({ theme }) => theme.paddings[1]};
    & > div {
      display: none;
    }
  }
  canvas {
    margin-right: 0;
    width: 100% !important;
  }
  border: 2px solid transparent;
  transition: 500ms ease-in-out;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 180px;
    & > div {
      max-width: 180px;
    }
  }
`;

export const PagesPreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding: ${({ theme }) => theme.paddings[10]} 0
    ${({ theme }) => theme.paddings[12]};
  background: ${({ theme }) => theme.colors.textOnPrimary};
  color: ${({ theme }) => theme.colors.primary};
  max-width: ${({ isCollapsed }) => (isCollapsed ? "72px" : "320px")};
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lightDark};
  transition: 500ms ease-in-out;
  & > div {
    &:nth-of-type(2) {
      margin: 0 ${({ theme }) => theme.paddings[8]};

      max-height: ${({ isCollapsed }) => (isCollapsed ? "0" : "100%")};
      overflow: hidden;
      transition: 500ms ease-in-out;
      opacity: ${({ isCollapsed }) => (isCollapsed ? "0" : "1")};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: ${({ isCollapsed }) => (isCollapsed ? "72px" : "260px")};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    top: unset;
    right: unset;
    left: 0;
    bottom: 0;
    max-width: unset;
    width: 100%;
    z-index: 2;
    padding-top: ${({ theme }) => theme.paddings[6]};
    padding-bottom: 0;
    max-height: ${({ isCollapsed }) => (isCollapsed ? "72px" : "335px")};
    max-width: ${({ isCollapsed }) => (isCollapsed ? "204px" : "100%")};
    border-radius: ${({ isCollapsed }) =>
      isCollapsed ? "16px 16px 0 0" : "0"};
    gap: 12px;
    ${AllDocumentPages} {
      max-height: unset;
      & > div {
        & > div {
          flex-direction: row;
          height: 210px;
          & > div {
            width: unset;
            height: 100%;
            & > div {
              min-height: unset !important;
              height: 100%;
              canvas {
                height: 100% !important;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
    max-height: ${({ isCollapsed }) => (isCollapsed ? "72px" : "275px")};
    ${AllDocumentPages} {
      & > div {
        & > div {
          height: 140px;
        }
      }
    }
  }
`;

export const MainSidebarToggle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 24px;
  height: 24px;

  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  display: none;
  span {
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    transition: 500ms ease-in-out;
    &:nth-of-type(2) {
      width: 60%;
    }
  }
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      span {
        &:nth-of-type(2) {
          width: 100%;
        }
      }
    `}
`;

export const MainDocumentSidebar = styled.div`
  padding: ${({ theme }) => theme.paddings[10]} 0
    ${({ theme }) => theme.paddings[12]};
  background: ${({ theme }) => theme.colors.textOnPrimary};
  color: ${({ theme }) => theme.colors.primary};
  max-width: 320px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lightDark};
  & > div {
    &:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 100%;
    }
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 260px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    top: ${({ isCollapsed }) => (isCollapsed ? "24px" : "0")};
    left: ${({ isCollapsed }) => (isCollapsed ? "32px" : "0")};
    width: 100%;
    z-index: 7;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 ${({ theme }) => theme.paddings[8]};
    max-width: ${({ isCollapsed }) => (isCollapsed ? "88px" : "100%")};
    max-height: ${({ isCollapsed }) => (isCollapsed ? "72px" : "100%")};
    border-radius: ${({ isCollapsed }) => (isCollapsed ? "8px" : "0")};
    transition: 500ms ease-in-out;
    gap: 0;
    z-index: 15;
    & > div {
      &:first-of-type {
        display: flex;
      }
      &:nth-of-type(2) {
        max-width: ${({ isCollapsed }) => (isCollapsed ? "0" : "100%")};
        max-height: ${({ isCollapsed }) => (isCollapsed ? "0" : "100%")};
        transition: 500ms ease-in-out;
        overflow: hidden;
        opacity: ${({ isCollapsed }) => (isCollapsed ? "0" : "1")};
      }
    }
    svg {
      width: 28px !important;
      height: 28px !important;
    }
    & > div {
      margin: 0;

      &:nth-of-type(3) {
        order: 2;
        margin: 0;
      }
      &:nth-of-type(4) {
        order: 1;
        width: 224px;
        height: 100%;
        & > div {
          justify-content: flex-end;
          height: 100%;
          & > div {
            height: 100%;
          }
        }
      }
      &:nth-of-type(5) {
        order: 3;
        height: 100%;
        & > div {
          justify-content: flex-end;
          height: 100%;
          & > div {
            height: 100%;
          }
        }
      }
    }
    ${MainSidebarToggle} {
      transition: 500ms ease-in-out;
      margin-top: ${({ theme }) => theme.margins[6]};
      margin-bottom: ${({ theme }) => theme.margins[6]};

      ${({ isCollapsed }) =>
        !isCollapsed &&
        css`
          margin-top: ${({ theme }) => theme.margins[8]};
          margin-bottom: ${({ theme }) => theme.margins[8]};
          margin-left: ${({ theme }) => theme.margins[8]};
        `}
    }
    ${DropUpload} {
      display: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    max-height: ${({ isCollapsed }) => (isCollapsed ? "72px" : "100%")};
    max-width: 100%;
    top: 0;
    left: 0;
    padding: 0;
    padding-left: ${({ theme }) => theme.paddings[8]};
    z-index: 15;
    border-radius: 0;
    & > div {
      &:nth-of-type(2) {
        gap: 8px;
      }
    }
    ${MainSidebarToggle} {
      margin-left: 0;
    }
  }
`;
