//  ErrorBoundary
//  Component used to catch errors in the app and display a message to the user instead of crashing the app

import { Component } from "react";
import { useNavigate } from "react-router";
import StyledButton from "../../styledButton/styledButton";
import { withTheme } from "styled-components";
import { StyledErrorOutput } from "./errorBoundaryStyles";

const ErrorElement = () => {
  const navigate = useNavigate();
  return (
    <StyledErrorOutput>
      <h2>
        Quelque chose s'est mal passé!
        <br />
        Veuillez réessayer!
      </h2>
      <StyledButton
        type="status"
        typeCategory="default"
        action={() => {
          window.location.reload();
        }}
        themeSizeSelector={["montserrat", "regular"]}
        themeSpacingSelector={[4, 24]}
        text="Rafraîchir la page"
      />
      <StyledButton
        type="status"
        typeCategory="default"
        action={() => {
          navigate("/dashboard");
        }}
        themeSizeSelector={["montserrat", "regular"]}
        themeSpacingSelector={[4, 24]}
        text="Retour au tableau de bord"
      />
    </StyledErrorOutput>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <ErrorElement />;
    }
    return this.props.children;
  }
}

export default withTheme(ErrorBoundary);
