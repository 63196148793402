/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import { useCallback, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

export function usePrompt(isConfirmedExit, setShowPrompt, when = true) {
  const [leaveArgs, setLeaveArgs] = useState(false);
  const confirmExit = useCallback(
    (args) => {
      setShowPrompt(!isConfirmedExit);
      setLeaveArgs(args);
    },
    [isConfirmedExit]
  );
  useConfirmExit(confirmExit, leaveArgs, isConfirmedExit, when);
}

function useConfirmExit(confirmExit, leaveArgs, isConfirmedExit, when = true) {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (args) => {
      confirmExit(args);

      if (isConfirmedExit !== false) {
        push(args);
      }
    };

    if (isConfirmedExit === true) {
      navigator.push(leaveArgs);
    }

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, isConfirmedExit, when]);
}
