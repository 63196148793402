import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import watchAll from "utils/saga/sagas";

const middlewares = [];

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Adding the saga middleware
middlewares.push(sagaMiddleware);

// add logger on developement node
if (process.env.NODE_ENV === "development") {
  //middlewares.push(logger);
}

const makeStore = () => {
  const persistConfig = {
    key: "root",
    storage,
    timeout: 6000,
    blacklist: ["utils"],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== "production",
  });

  store.__persistor = persistStore(store);

  return store;
};

export const store = makeStore();

export const persistor = persistStore(store);

sagaMiddleware.run(watchAll);
