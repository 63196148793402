import { useState } from "react";
import { withTheme } from "styled-components";
import { StyledLoqkPassBanner } from "./loqkPassBannerStyles";

const LoqkPassBanner = ({ theme, fontSize = "pageTitle" }) => {
  const [codeValue, setCodeValue] = useState("");

  const handleChange = (e) => {
    if (e.target.value.toString().length < 7 && !isNaN(e.target.value)) {
      setCodeValue(e.target.value);
    }
  };

  return (
    <StyledLoqkPassBanner theme={theme} fontSize={fontSize}>
      <p>LOQK</p>
      <input
        type="text"
        maxLength={6}
        value={codeValue}
        onChange={(e) => handleChange(e)}
      />
    </StyledLoqkPassBanner>
  );
};

export default withTheme(LoqkPassBanner);
