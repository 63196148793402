import styled from "styled-components";

export const FlowItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: ${(props) => props.theme.paddings[18]};
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 32px;
    align-items: center;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const Separator = styled.svg`
  display: block;
  align-self: center;
  path {
    stroke: ${(props) => props.theme.colors.loqkOriginal};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    transform: rotate(90deg);
  }
`;
