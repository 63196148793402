import styled from "styled-components";

export const FormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 728px;
  width: 100%;
`;

export const FormContent = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.margins[12]};
  justify-content: space-between;
  gap: 72px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const FormRow = styled.div`
  display: flex;
  gap: 48px;
`;

export const InputsContainer = styled.div`
  max-width: 728px;
  margin-right: 96px;
  width: 100%;
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    row-gap: 32px;
    width: 100%;
    & > div {
      max-width: unset;
      &:nth-of-type(1) {
        grid-area: 1/1/1/2;
      }
      &:nth-of-type(2) {
        grid-area: 1/2/1/3;
      }
      &:nth-of-type(3) {
        grid-area: 2/1/2/2;
      }
      &:nth-of-type(4) {
        grid-area: 2/2/2/3;
      }
      /* &:nth-of-type(5) {
        grid-area: 3/2/3/3;
      }
      &:nth-of-type(6) {
        grid-area: 4/1/4/2;
      } */
    }
  }

  h1 {
    font: ${({ theme }) => theme.fonts.montserrat.heading};
    margin: 0 0 ${({ theme }) => theme.margins[8]} 0;
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 568px;
    input {
      width: 100%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: unset;
    margin-right: 0;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;

export const SecondaryNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.margins[14]};
  flex-wrap: wrap;
  column-gap: 3rem;
  row-gap: 1rem;
  & > div {
    padding-top: 0 !important;
    height: max-content;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    gap: 24px;
    & > div {
      margin-top: 0;
    }
    a {
      margin-bottom: 0 !important;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    button {
      padding: ${({ theme }) => theme.paddings[4]}
        ${({ theme }) => theme.paddings[8]};

      width: 100%;
    }
  }
`;

export const UtilisateurStatusContainer = styled.div`
  padding-left: ${({ theme }) => theme.paddings[14]};
  padding-bottom: ${({ theme }) => theme.paddings[18]};
  padding-top: ${({ theme }) => theme.paddings[8]};
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.primary};
  max-width: 720px;
  width: 100%;
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.pageTitle};
    font-weight: 600 !important;
    margin: 0 0 ${({ theme }) => theme.margins[3]} 0;
    padding: 0 0 ${({ theme }) => theme.paddings[4]} 0;
  }
  h4 {
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    margin: 0 0 ${({ theme }) => theme.margins[1]} 0;
    font-weight: 600 !important;
  }
`;
