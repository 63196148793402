import { useEffect, useState } from "react";
import { DemandeCheckboxContainer } from "./../demandeDashboardStyles";
import { ReactComponent as Check } from "assets/icons/check.svg";
const DemandeCheckbox = ({ selected, selectAction = () => {} }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  return (
    <DemandeCheckboxContainer
      checked={checked}
      onClick={() => {
        setChecked(!checked);
        selectAction(!checked);
      }}
    >
      <Check />
    </DemandeCheckboxContainer>
  );
};

export default DemandeCheckbox;
