import { StyledButton } from "components";
import { StatusPageContainer } from "./statusPageStyles";

const NotFound = () => {
  return (
    <StatusPageContainer>
      <div>
        <h2>Le chemin que vous avez demandé n'existe pas.</h2>
        <p>Veuillez vérifier l'URL ou contactez l'administrateur du site.</p>
        <StyledButton
          link="/"
          type="link"
          themeSizeSelector={["montserrat", "regular"]}
          themeSpacingSelector={[4, 14]}
          text="Retourner à l'accueil"
        ></StyledButton>
      </div>
    </StatusPageContainer>
  );
};

export default NotFound;
