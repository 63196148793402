import styled, { css } from "styled-components";
import { DossierLocationStatut } from "utils/Statuts";

export const ActionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  & > svg {
    cursor: pointer;
  }
`;

export const ActionDotsWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  & > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms ease-in-out;
    &:not(:nth-of-type(2)) {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        &:first-of-type {
          left: 6px;
        }
        &:last-of-type {
          left: 18px;
        }
        &:not(:nth-of-type(2)) {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      `}
  }
`;

export const ActionPopup = styled.div`
  position: absolute;
  top: -6px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.textOnPrimary};
  border-radius: 8px;
  overflow: hidden;
  visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
  overflow: hidden;
  transition: 400ms ease-in-out;
  max-width: ${({ showMenu }) => (showMenu ? "380px" : "0px")};
  z-index: 8;
  ${({ preferSide }) =>
    preferSide === "left"
      ? css`
          left: unset;
          right: 100%;
          margin-left: unset;
          margin-right: 8px;
        `
      : css`
          left: 100%;
          right: unset;
          margin-left: 8px;
          margin-right: unset;
        `}

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints.largeDesktop}) {
    left: unset;
    right: 0;
    transform: unset;
    top: 110%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none !important;
  }
`;

export const PopupOption = styled.div`
  padding: ${({ theme }) => theme.paddings[2]}
    ${({ theme }) => theme.paddings[4]};
  cursor: pointer;
  background: ${({ theme }) => theme.colors.textOnPrimary};
  transition: 400ms ease-in-out;
  opacity: ${({ ignoreOpacity }) => (ignoreOpacity ? "1" : "0.5")};
  color: ${({ theme, colorKey }) =>
    colorKey ? theme.colors[colorKey] : theme.colors.primary};
  font: ${({ theme }) => theme.fonts.montserrat.small};
  justify-content: space-between;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  svg {
    width: 16px;
    height: 16px;
    path {
      ${({ svgFill }) => (svgFill ? svgFill : "stroke")}: ${({
        theme,
        colorKey,
      }) => (colorKey ? theme.colors[colorKey] : theme.colors.primary)};
    }
  }
  & > div {
    transform: translateY(2px) scale(0.75);
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: visible;
    `};
  &:hover {
    background: ${({ theme, colorKey }) =>
      colorKey ? theme.colors[colorKey] : theme.colors.primary};
    opacity: 1;
    color: ${({ theme }) => theme.colors.textOnPrimary};
    svg path {
      ${({ svgFill }) => (svgFill ? svgFill : "stroke")}: ${({ theme }) =>
        theme.colors.textOnPrimary};
    }
    & > div {
      color: ${({ theme }) => theme.colors.textOnPrimary} !important;

      & > div {
        color: ${({ theme }) => theme.colors.textOnPrimary} !important;
        &,
        &::before,
        &::after {
          background: ${({ theme }) => theme.colors.textOnPrimary} !important;
        }
      }
    }
  }
`;
