import styled from "styled-components";

export const TooltipPopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ show }) => (show ? 0 : "-150%")};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  transition: 0.3s ease-in-out;
  transition-delay: ${({ show }) => (show ? "0s" : "0.3s")};
  opacity: 1 !important;
`;

export const TooltipBackground = styled.div`
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Tooltip = styled.div`
  position: relative;
  padding: 40px 56px 48px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  max-width: 980px;
  width: 100%;
  transition: 0.3s ease-in-out;
  ${({ show }) => `
    transform: ${show ? "translateY(0)" : "translateY(-100%)"};
    opacity: ${show ? 1 : 0};
    transition-delay: ${show ? "0.3s" : "0s"};
  `}

  @media screen and (max-width: 980px) {
    max-width: unset;
    width: 80%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    height: 100%;
    padding: 40px 32px 32px;
  }
`;

export const TooltipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.paddings[5]};
  h2 {
    max-width: 75%;
    font: ${({ theme }) => theme.fonts.montserrat.heading};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0 !important;
  }
  svg {
    &:last-of-type {
      cursor: pointer;
    }
  }
`;

export const TooltipSub = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  svg {
    margin-right: 12px;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.margins[5]};
  justify-content: space-between;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const TooltipText = styled.div`
  max-width: ${({ hasImage }) => (hasImage ? "40%" : "80%")};
  padding-top: ${({ theme }) => theme.paddings[8]};
  h3 {
    color: ${({ theme }) => theme.colors.primary};
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    font-weight: 600 !important;
    margin: 0 0 ${({ theme }) => theme.margins[3]} !important;
  }
  p {
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 500 !important;
  }
  @media screen and (max-width: 980px) {
    max-width: 100%;
  }
`;

export const TooltipImage = styled.div`
  max-width: ${({ hasText }) => (hasText ? "50%" : "100%")};
  width: 100%;
  height: max-content;
  background: #ffffff;
  box-shadow: 2px 4px 12px 2px rgba(30, 39, 98, 0.2);
  border-radius: 16px;
  /* transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(6deg) rotateY(-11deg)
    rotateZ(-4deg) translateX(0px) translateY(0px) translateZ(0px) skewX(7deg)
    skewY(0deg); */
  padding: 24px;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 980px) {
    max-width: 100%;
    margin: 32px auto 0;
    padding: 16px;
  }
`;
