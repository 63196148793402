import styled, { css } from "styled-components";

export const AdvancedSearchContainer = styled.div``;

export const SearchContainer = styled.div`
  padding: ${({ theme, showFilters }) =>
    showFilters
      ? `
    ${theme.paddings[8]}
    ${theme.paddings[12]} ${theme.paddings[8]};
    `
      : `
    ${theme.paddings[8]}
    ${theme.paddings[12]} ${theme.paddings[8]};
    `};
  background: #fafafa;
  margin: 0 0 ${({ theme }) => theme.margins[8]};
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
    margin: 0 0 ${({ theme }) => theme.margins[4]};
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-left: auto;
      display: none;
      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.tablet}) {
        display: block;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: ${({ theme }) => css`
      ${theme.paddings[8]}
      ${theme.paddings[8]} ${theme.paddings[8]};
    `};
    h3 {
      margin: 0;
    }
    & > div {
      max-height: ${({ showFilters }) => (showFilters ? "980px" : "0")};
      visibility: ${({ showFilters }) => (showFilters ? "visible" : "hidden")};
      transition: 0.5s ease-in-out;
      transition-delay: ${({ showFilters }) => (showFilters ? "0s" : "0.5s")};
      margin: ${({ showFilters, theme }) =>
        showFilters ? `${theme.margins[4]} 0 0` : 0};
      & > * {
        opacity: ${({ showFilters }) => (showFilters ? "1" : "0")};
        transition: 0.5s ease-in-out;
      }
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-direction: column;
  & > div {
    &:nth-of-type(2) {
      display: flex;
      gap: 32px;
      justify-content: flex-start;
      & > button {
        margin-top: 0 !important;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    transition: 0.5s ease-in-out;
    max-height: ${({ showFilters }) => (showFilters ? "1280px" : "0")};
    /* overflow: hidden; */
    visibility: ${({ showFilters }) => (showFilters ? "visible" : "hidden")};
    transition-delay: ${({ showFilters }) => (showFilters ? "0s" : "0.5s")};
    & > * {
      opacity: ${({ showFilters }) => (showFilters ? "1" : "0")};
      transition: 0.5s ease-in-out;
      transition-delay: ${({ showFilters }) => (showFilters ? "0.3s" : "0s")};
    }
    & > div {
      &:nth-of-type(2) {
        & > button {
          margin-top: ${({ theme }) => theme.margins[8]};
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    & > div {
      &:nth-of-type(2) {
        flex-direction: column;
        gap: 16px;
      }
    }
  }
`;

export const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  grid-column: span ${({ colSpan }) => (colSpan ? colSpan : "auto")};
  grid-row: ${({ rowSpan }) => (rowSpan ? rowSpan : "auto")};
  label {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primaryOpaque};
  }
  div {
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid ${({ theme }) => theme.colors.primaryOpaque};
    border-radius: 8px;
    padding: ${({ theme }) => theme.paddings[3]}
      ${({ theme }) => theme.paddings[4]};
    input {
      width: 100%;
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600 !important;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: span
      ${({ colSpanDesktop }) => (colSpanDesktop ? colSpanDesktop : "auto")};
    grid-row: ${({ rowSpanDesktop }) =>
      rowSpanDesktop ? rowSpanDesktop : "auto"};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: span
      ${({ colSpanTablet }) => (colSpanTablet ? colSpanTablet : "auto")};
    grid-row: ${({ rowSpanTablet }) =>
      rowSpanTablet ? rowSpanTablet : "auto"};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-column: span
      ${({ colSpanMobile }) => (colSpanMobile ? colSpanMobile : "auto")};
    grid-row: ${({ rowSpanMobile }) =>
      rowSpanMobile ? rowSpanMobile : "auto"};
  }
`;

export const SelectContainer = styled.div`
  grid-column: span ${({ colSpan }) => (colSpan ? colSpan : "auto")};
  grid-row: ${({ rowSpan }) => (rowSpan ? rowSpan : "auto")};

  & > div {
    & > div {
      border: 1px solid ${({ theme }) => theme.colors.primaryOpaque};
      border-radius: 8px;

      & > div {
        border-bottom: none !important;
        padding: ${({ theme }) => theme.paddings[1]}
          ${({ theme }) => theme.paddings[2]};
      }
    }
  }
  /* &>div>div{
        &:first-of-type{
            border: 1px solid ${({ theme }) => theme.colors.primaryOpaque};
            border-radius: 8px;
            padding: ${({ theme }) => theme.paddings[3]} ${({ theme }) =>
    theme.paddings[4]};

        }
    } */
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: span
      ${({ colSpanDesktop }) => (colSpanDesktop ? colSpanDesktop : "auto")};
    grid-row: ${({ rowSpanDesktop }) =>
      rowSpanDesktop ? rowSpanDesktop : "auto"};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: span
      ${({ colSpanTablet }) => (colSpanTablet ? colSpanTablet : "auto")};
    grid-row: ${({ rowSpanTablet }) =>
      rowSpanTablet ? rowSpanTablet : "auto"};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-column: span
      ${({ colSpanMobile }) => (colSpanMobile ? colSpanMobile : "auto")};
    grid-row: ${({ rowSpanMobile }) =>
      rowSpanMobile ? rowSpanMobile : "auto"};
  }
`;

export const SearchFields = styled.div`
  width: 100%;
  /* max-width: 1024px; */
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, 1fr)` : "1fr"};
  column-gap: 40px;
  row-gap: 32px;
  ${SearchInput} {
    /* &:nth-of-type(1){
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &:nth-of-type(2){
            grid-column-start: 4;
            grid-column-end: 8;
        }
        &:nth-of-type(3){
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row: 2;
        }
        &:nth-of-type(4){
            grid-column-start: 4;
            grid-column-end: 8;
            grid-row: 2;
        } */
  }
  & > div {
    /* &:last-of-type{
            grid-column-start: 8;
            grid-column-end: 11;
            &>div{
                &:first-of-type{
                    border: 1px solid ${({ theme }) =>
      theme.colors.primaryOpaque};
                    border-radius: 8px;
                    padding: ${({ theme }) => theme.paddings[3]} ${({
      theme,
    }) => theme.paddings[4]};
                    
                }
               
            }
        } */
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: ${({ columnsDesktop }) =>
      columnsDesktop ? `repeat(${columnsDesktop}, 1fr)` : "1fr"};
    /* ${SearchInput}{
            &:nth-of-type(1){
                grid-column-start: 1;
                grid-column-end: 3;
            }
            &:nth-of-type(2){
                grid-column-start: 4;
                grid-column-end: 8;
            }
            &:nth-of-type(3){
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row: 2;
            }
            &:nth-of-type(4){
                grid-column-start: 3;
                grid-column-end: 6;
                grid-row: 2;
            }
        }
        &>div{
            &:first-of-type{
                grid-column-start: 1;
                grid-column-end: 4;
                grid-row: 1;
            }
            &:last-of-type{
                grid-column-start: 6;
                grid-column-end: 8;
                &>div{
                    &:last-of-type{
                        height: 100%;
                    }
                }
            }
        } */
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: ${({ columnsTablet }) =>
      columnsTablet ? `repeat(${columnsTablet}, 1fr)` : "1fr"};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;
