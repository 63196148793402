import { isEmpty } from "lodash";
import * as Yup from "yup";

export const RevenusValidator = Yup.object().shape({
  CUMUL_ANNUEL_NET_M1: Yup.number().max(9999999, "Taille maximale 7 chiffres"),

  CUMUL_ANNUEL_NET_M2: Yup.number().max(9999999, "Taille maximale 7 chiffres"),

  CUMUL_ANNUEL_NET_M3: Yup.number().max(9999999, "Taille maximale 7 chiffres"),

  SALAIRE_MENSUEL_M1: Yup.number().max(999999, "Taille maximale 6 chiffres"),

  SALAIRE_MENSUEL_M2: Yup.number().max(999999, "Taille maximale 6 chiffres"),

  SALAIRE_MENSUEL_M3: Yup.number().max(999999, "Taille maximale 6 chiffres"),

  REVENU_FISCAL_REFERENCE: Yup.number().max(
    9999999,
    "Taille maximale 7 chiffres"
  ),

  SALAIRE_BRUT_ANNUEL: Yup.number().max(9999999, "Taille maximale 7 chiffres"),
});

export const AdvancedRevenusValidator = (values) => {
  let errors = {};
  const keys = Object.keys(values);
  const salaires = [];
  const cumuls = [];

  // verify surfacique values
  for (let index = 0; index < keys.length; index++) {
    if (values[keys[index]] === null || values[keys[index]] === "") {
      errors = {
        ...errors,
        [keys[index]]: "Le champ est obligatoire",
      };
    }
  }

  if (!isEmpty(errors)) {
    return errors;
  }

  // get cumuls et salaire to verify
  for (let index = 1; index <= 3; index++) {
    if (keys.find((item) => item.match(`SALAIRE_MENSUEL_M${index}`))) {
      cumuls.push(values[`CUMUL_ANNUEL_NET_M${index}`]);
      salaires.push(values[`SALAIRE_MENSUEL_M${index}`]);
    }
  }

  // if there is more than one pivot
  const pivots = getPivots(cumuls, salaires);
  if (pivots && pivots.length > 1) {
    return getPivotErrors(pivots);
  }

  // If there is one pivot (or no pivot), we continue
  if (pivots.length === 0) {
    return verifierCumuls(salaires, cumuls, 0, cumuls.length - 1);
  } else {
    errors = verifierCumuls(salaires, cumuls, 0, pivots[0]);
    return {
      ...errors,
      ...verifierCumuls(salaires, cumuls, pivots[0] + 1, cumuls.length - 1),
    };
  }
};

const getPivots = (cumuls, salaires) => {
  const pivots = [];

  for (let index = 0; index < cumuls.length; index++) {
    if (cumuls[index] === salaires[index]) {
      pivots.push(index);
    }
  }

  return pivots;
};

const getPivotErrors = (pivots = []) => {
  const errors = {};
  for (let index = 0; index < pivots.length; index++) {
    errors[`CUMUL_ANNUEL_NET_M${index + 1}`] =
      "veuillez renseigner uniquement vôtre dernière actualité";
    errors[`SALAIRE_MENSUEL_M${index + 1}`] =
      "veuillez renseigner uniquement vôtre dernière actualité";
  }
  return errors;
};

const verifierCumuls = (salaires, cumuls, first, last) => {
  let errors = {};

  if (first > last) {
    return {};
  }

  for (let index = first; index < last; index++) {
    let calculCumul = Math.abs(
      cumuls[index] - (salaires[index] + cumuls[index + 1])
    );

    if (calculCumul > 2) {
      errors = {
        ...errors,
        [`CUMUL_ANNUEL_NET_M${index + 1}`]:
          "Veuillez vérifier le calcul des cumuls",
        [`CUMUL_ANNUEL_NET_M${index + 2}`]:
          "Veuillez vérifier le calcul des cumuls",
        [`SALAIRE_MENSUEL_M${index + 1}`]:
          "Veuillez vérifier le calcul des cumuls",
      };
    }
  }
  return errors;
};
