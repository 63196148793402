import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { ReactComponent as AdbSelect } from "assets/icons/adbSelect.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/delete.svg";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import RenderIf from "../../../layout/renderIf/renderIf";
import Spinner from "../../../spinner/spinner";
import StyledInput from "../../../styledInput/styledInput";
import { ADBSelectWrapper, NoADBResult, SidebarUtil } from "../sidebarStyles";
import { useNavigate } from "react-router";
import { setCurrentAdministrateurBien } from "redux/administrateurBien/administrateurBienReducer";
import { useKeycloak } from "@react-keycloak/web";
import { ROLE_ADB, ROLE_COURTIER, ROLE_LOQK, hasOneOfRoles } from "utils/security/keycloakUtils";
import { cleanParcourProcess } from "redux/dossierLocation/dossierLocationReducer";
import { ADBService } from "services/adbService";
import {
  COURTIER_GET_ADBS,
  LOAD_ADMINISTRATEURS_BIENS,
  SET_CURRENT_ADMINISTRATEUR_BIEN,
} from "utils/saga/actions";
import { selectActiveUser } from "redux/user/userReducer";

const ADBSelect = ({ isSidebarOpen, administrateurBien, setIsSidebarOpen }) => {
  const [activeSection, setActiveSection] = useState(false);
  const [inputValue, setInputValue] = useState(
    administrateurBien?.nom ? administrateurBien?.nom : "-"
  );
  const [listAdbs, setListAdbs] = useState([]);
  const [loadingADBs, setLoadingADBs] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const user = useSelector(selectActiveUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRunSearch = useDebouncedCallback(() => {
    if(initialized && !keycloak?.authenticated ) {
      return;
    }
    if(keycloak && hasOneOfRoles(keycloak, [ROLE_COURTIER])) {
      ADBService.runService({
        type: COURTIER_GET_ADBS,
        payload: {
          courtierId: user?.courtier.id,
          searchFilters : {nomClient: inputValue},
          page: 0,
          size: 6,
        },
        handlers: {
          onSuccess: (res) => {
            setListAdbs(res.content);
          },
          onChange: (bool) => setLoadingADBs(bool),
        },
      });

    } else if(keycloak && hasOneOfRoles(keycloak, [ROLE_LOQK])){
      ADBService.runService({
        type: LOAD_ADMINISTRATEURS_BIENS,
        payload: {
          nomClient: inputValue,
          page: 0,
          size: 6,
        },
        handlers: {
          onSuccess: (res) => {
            setListAdbs(res.content);
          },
          onChange: (bool) => setLoadingADBs(bool),
        },
      });
    }
  }, 250);

  const removeADB = () => {
    dispatch(setCurrentAdministrateurBien(null));
    dispatch(cleanParcourProcess());
    navigate("/monespace/dashboard");
  };

  const handleADBSelect = (adb) => {
    ADBService.runService({
      type: SET_CURRENT_ADMINISTRATEUR_BIEN,
      payload: adb,
      handlers: {
        onSuccess: () => {
          setActiveSection(false);
        },
      },
    });
  };

  useEffect(() => {
    if (administrateurBien) {
      setInputValue(administrateurBien.nom);
    } else {
      setInputValue("-");
    }
  }, [administrateurBien]);

  useEffect(() => {
    if (!activeSection && inputValue !== administrateurBien?.nom) {
      setInputValue(administrateurBien?.nom);
    }
    if (keycloak && hasOneOfRoles(keycloak, [ROLE_ADB])) {
      setActiveSection(false);
    }
  }, [activeSection]);

  const toggleActiveSection = () => {
    setActiveSection(!activeSection);
    setIsSidebarOpen(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleRunSearch();
  };

  useEffect(() => {
    if (!isSidebarOpen) {
      setActiveSection(false);
    }
  }, [isSidebarOpen]);

  const outsideClickRef = useOutsideClick(() => {
    setInputValue(administrateurBien?.nom);
    setActiveSection(false);
  });

  return (
    <ADBSelectWrapper
      isLoading={loadingADBs}
      isActive={activeSection}
      ref={outsideClickRef}
    >
      <SidebarUtil
        isSidebarOpen={isSidebarOpen}
        isActive={activeSection}
        isInput
        isIcon={
          administrateurBien &&
          keycloak &&
          hasOneOfRoles(keycloak, ["ROLE_LOQK"])
            ? 1
            : 0
        }
      >
        <div>
          <AdbSelect onClick={() => toggleActiveSection()} />
          <StyledInput
            type="text"
            readOnly={!activeSection}
            defaultValue={inputValue}
            action={handleInputChange}
            onClick={() => setActiveSection(true)}
          />
          <RenderIf
            condition={
              administrateurBien &&
              keycloak &&
              hasOneOfRoles(keycloak, ["ROLE_LOQK"])
            }
          >
            <RemoveIcon onClick={() => removeADB()} />
          </RenderIf>
        </div>
      </SidebarUtil>
      <div>
        <RenderIf condition={loadingADBs}>
          <Spinner size="small" marginY="8px" marginX="auto" />
        </RenderIf>
        <RenderIf condition={!loadingADBs && listAdbs.length > 0}>
          <ul>
            {listAdbs.map((adb) => (
              <li key={adb.id} onClick={() => handleADBSelect(adb)}>
                {adb.nom}
              </li>
            ))}
          </ul>
        </RenderIf>
        <RenderIf
          condition={
            !loadingADBs &&
            listAdbs.length === 0 &&
            inputValue !== administrateurBien?.nom
          }
        >
          <NoADBResult>Aucun resultats de recherche</NoADBResult>
        </RenderIf>
      </div>
    </ADBSelectWrapper>
  );
};

export default ADBSelect;
