//  SituationProfessionelle
//  Form section for professionnal situation of the locataire
//  State:
//     - accessibleFieldsBySituation: object, specified some fields that should be displayed or not
//     - listStatutPro: array, the list of statut pro for the selected situation pro
//  Functions:
//     - maxDebutSituation: function, returns the max date for the debut situation

import { useState, useEffect } from "react";
import {
  SITUATION_PRO_ENUM,
  TRAVAILLE_12_QUESTION_ENUM,
} from "utils/constantes";
import {
  getDateFromStringApi,
  getDatePlusDays,
  getFirstDayOfTodaySubMonths,
  getMaxDates,
  getToday,
} from "utils/dateUtils";
import { getStatutsProsBySituation } from "utils/loqkEnumUtils";
import {
  clearValuesByValidator,
  validateSituationProfessionnelle,
} from "utils/helpers/situationProHelper";
import { canSituationProShowMessage } from "../utils/dossierLocataireValidator";
import { FormSection, StyledDatePicker, StyledSelect } from "../../index";
import StyledMaskedInput from "../../styledInput/styledInputMasked";
import { InputsRow } from "pages/dossierLocation/forms/formsStyles";

// values contains only the situation professionnelle datas
const SituationProfessionnelle = ({
  values,
  isSubmitting,
  setFieldValue,
  handleChange,
  handleBlur,
  errors = {},
  touched = {},
  blocked = {},
}) => {
  const [accessibleFieldsBySituation, setAccessibleFieldsBySituation] =
    useState({});
  const [listStatutPro, setListStatutPro] = useState([]);

  useEffect(() => {
    setListStatutPro(getStatutsProsBySituation(values.situationPro));
    const validator = validateSituationProfessionnelle(values);
    setAccessibleFieldsBySituation(validator);
  }, [values]);

  useEffect(() => {
    // clear values if the field is not displayed
    clearValuesByValidator(values, accessibleFieldsBySituation, setFieldValue);
  }, [accessibleFieldsBySituation]);

  const maxDebutSituation = () => {
    // checks if there is not a value for dateFinSituation or if it is null
    // and if so returns current date + 60 days
    if (!values.dateFinSituation || values.dateFinSituation === null) {
      return getDatePlusDays(+60);
    }

    // otherwise returns max dates between (current + 60 days) and dateFinSituation
    return getMaxDates(
      getDatePlusDays(+60),
      getDateFromStringApi(values.dateFinSituation, accessibleFieldsBySituation)
    );
  };

  return (
    <FormSection
      title={"Situation Professionnelle"}
      values={values}
      isValid={canSituationProShowMessage(values)}
    >
      <StyledSelect
        label="Profession"
        name="situationPro"
        required
        defaultValue={values.situationPro}
        options={SITUATION_PRO_ENUM}
        setFieldValue={(name, value) => setFieldValue(name, value)}
        disabled={isSubmitting || blocked}
        errors={errors?.situationPro}
        touched={touched.situationPro}
        handleBlur={handleBlur}
      />

      {accessibleFieldsBySituation.displayStatutPro &&
      listStatutPro &&
      listStatutPro.length > 0 ? (
        <StyledSelect
          label="Statut"
          name="statutPro"
          required
          defaultValue={values.statutPro}
          options={listStatutPro}
          setFieldValue={setFieldValue}
          disabled={isSubmitting || blocked}
          handleBlur={handleBlur}
          errors={errors?.statutPro}
          touched={touched.statutPro}
        />
      ) : null}

      {accessibleFieldsBySituation.displayDateFinSituation ? (
        <InputsRow disabled={isSubmitting || blocked}>
          <label>
            Date de début{" "}
            {accessibleFieldsBySituation.displayDateFinSituation
              ? "et de fin "
              : null}{" "}
            de situation *
          </label>
          <div>
            <StyledDatePicker
              category="personal"
              name="dateDebutSituation"
              placeholder="23/09/2022"
              required={true}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              errors={errors.dateDebutSituation}
              touched={touched.dateDebutSituation}
              disabled={isSubmitting || blocked}
              defaultDate={getDateFromStringApi(values.dateDebutSituation)}
              maxDate={maxDebutSituation()}
              showMonthDropdown
              showYearDropdown
            />

            <StyledDatePicker
              category="personal"
              name="dateFinSituation"
              placeholder="26/11/2024"
              required={true}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              errors={errors.dateFinSituation}
              touched={touched.dateFinSituation}
              disabled={isSubmitting || blocked}
              defaultDate={getDateFromStringApi(values.dateFinSituation)}
              minDate={getMaxDates(
                getDateFromStringApi(values.dateDebutSituation),
                getToday()
              )}
            />
          </div>
        </InputsRow>
      ) : (
        <StyledDatePicker
          category="personal"
          name="dateDebutSituation"
          placeholder="23/09/2022"
          label="Date de début de situation"
          required={true}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          errors={errors.dateDebutSituation}
          touched={touched.dateDebutSituation}
          disabled={isSubmitting || blocked}
          defaultDate={getDateFromStringApi(values.dateDebutSituation)}
          maxDate={maxDebutSituation()}
          showMonthDropdown
          showYearDropdown
        />
      )}

      {accessibleFieldsBySituation.displayDateDernierBulletin && (
        <StyledDatePicker
          category="personal"
          label="Date du dernier bulletin de salaire"
          name="dateDernierBulletinSalaire"
          placeholder="11/2022"
          required
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          errors={errors.dateDernierBulletinSalaire}
          touched={touched.dateDernierBulletinSalaire}
          disabled={isSubmitting || blocked}
          defaultDate={getDateFromStringApi(values.dateDernierBulletinSalaire)}
          maxDate={getFirstDayOfTodaySubMonths(0)}
          minDate={getFirstDayOfTodaySubMonths(2)}
          showMonthYearPicker
          datePattern="MMMM yyyy"
          dateInputPattern="MMMM YYYY"
          withCustomInput={false}
        />
      )}

      {accessibleFieldsBySituation.displayTravailler24DerniersMois && (
        <StyledSelect
          label="Avez vous travaillé plus de 12 mois ces 24 derniers mois ? (tous emplois confondus)"
          name="travaille24DerniersMois"
          required
          defaultValue={values.travaille24DerniersMois}
          options={TRAVAILLE_12_QUESTION_ENUM}
          setFieldValue={setFieldValue}
          disabled={isSubmitting || blocked}
          handleBlur={handleBlur}
          errors={errors.travaille24DerniersMois}
          touched={touched.travaille24DerniersMois}
        />
      )}

      {accessibleFieldsBySituation.displayCodeNaf && (
        <StyledMaskedInput
          category="personal"
          type="text"
          name="codeNaf"
          placeholder="6201Z..."
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[a-z|A-Z]/]}
          label={"Code APE/NAF (cf bulletin de salaire)"}
          hasTooltip={true}
          required
          defaultValue={values.codeNaf}
          errors={errors.codeNaf}
          touched={touched.codeNaf}
          action={handleChange}
          handleBlur={handleBlur}
          disabled={isSubmitting || blocked}
        />
      )}

      {accessibleFieldsBySituation.displaySiret && (
        <StyledMaskedInput
          category="personal"
          type="text"
          name="siret"
          placeholder="Numéro de Siret (14 chiffres)"
          mask={[
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ]}
          label="SIRET"
          required
          defaultValue={values.siret}
          errors={errors.siret}
          touched={touched.siret}
          action={handleChange}
          handleBlur={handleBlur}
          disabled={isSubmitting || blocked}
        />
      )}
    </FormSection>
  );
};

export default SituationProfessionnelle;
