import React from "react";

import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { HelmetProvider } from "react-helmet-async";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { CookiesProvider } from "react-cookie";
import ErrorBoundary from "./components/layout/errorBoundary/errorBoundary";

const helmetContext = {};

const initOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
};

const AppWrapper = ({ children }) => {
  return (
    <CookiesProvider>
      <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
        {/* <React.StrictMode> */}
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <ErrorBoundary>{children}</ErrorBoundary>
              </ThemeProvider>
            </Provider>
          </BrowserRouter>
        </HelmetProvider>
        {/* </React.StrictMode> */}
      </ReactKeycloakProvider>
    </CookiesProvider>
  );
};

export default AppWrapper;
