import styled from "styled-components";

export const ConfirmationPopupContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${(props) => props.theme.shadows.lightDark};
  background: ${(props) => props.theme.colors.textOnPrimary};
  padding: ${(props) => props.theme.paddings[12]};
  border-radius: 16px;
  max-width: 720px;
  width: 100%;
  min-width: 640px;
  & > svg {
    margin: 0 0 ${(props) => props.theme.margins[8]} 0;
    width: 88px;
    height: 88px;
    min-height: 88px;
  }
  h1 {
    width: 80%;
    text-align: center;
    margin: 0 0 ${(props) => props.theme.margins[3]} 0;
    font: ${(props) => props.theme.fonts.montserrat.heading};
    font-weight: 600 !important;
    color: ${(props) => props.theme.colors.primary};
  }
  p {
    text-align: center;
    color: ${(props) => props.theme.colors.primaryOpaque};
    font: ${(props) => props.theme.fonts.montserrat.regular};
    font-weight: 500 !important;
    width: 75%;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.small}) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: ${(props) => props.theme.paddings[8]};
    min-width: unset;
    h1 {
      width: 90%;
    }
    p {
      width: 90%;
    }
  }
  @media screen and (max-height: 720px) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
  }
`;

export const ConfirmationPromptRequest = styled.div`
  margin: ${(props) => props.theme.margins[6]} 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 80%;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    &:nth-of-type(2) {
      padding: ${({ theme }) => theme.paddings[2]}
        ${({ theme }) => theme.paddings[6]};
      border-bottom: 1px solid;
      border-color: ${({ hasError, theme }) =>
        hasError ? theme.colors.error : theme.colors.inputLine};
      color: ${({ hasError, theme }) =>
        hasError ? theme.colors.error : theme.colors.primary};
      transition: 300ms ease-in-out;
      position: relative;

      &:focus-within {
        border-color: ${({ hasError, theme }) =>
          hasError ? theme.colors.error : theme.colors.primary};
      }
    }
    span {
      font: ${({ theme }) => theme.fonts.montserrat.small};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
      color: ${({ theme }) => theme.colors.primaryOpaque};
    }
  }

  textarea {
    flex-grow: 2;
    color: inherit;
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    outline: none;
    text-decoration: none;
    border: none;
    appearance: none !important;
    resize: none;
    min-height: 128px;
    max-height: 128px;
    width: 100%;
    cursor: ${({ readOnly, disabled }) =>
      readOnly ? "default" : disabled ? "not-allowed" : "text"};
    -moz-appearance: textfield !important;
    -webkit-appearance: textfield !important;

    &:-webkit-autofill {
      appearance: none !important;
      -moz-appearance: textfield !important;
      -webkit-appearance: textfield !important;
      background: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      background: none;
    }
    &::placeholder {
      opacity: 0.3;
    }
  }
  label {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    color: ${({ theme }) => theme.colors.primaryOpaque};
  }
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: ${(props) => props.theme.margins[8]} 0 0 0;
  button {
    width: 47%;
    height: 48px;
    border-radius: 8px;
    font: ${(props) => props.theme.fonts.montserrat.regular};
    font-weight: 500 !important;
    color: ${(props) => props.theme.colors.textOnPrimary};
    border: none;
    cursor: pointer;
    transition: 250ms ease-in-out;
    &:first-of-type {
      border: 1px solid ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.primary};
      &:hover {
        background: ${(props) => props.theme.colors.error};
        color: ${(props) => props.theme.colors.textOnPrimary};
        border: 1px solid ${(props) => props.theme.colors.error};
      }
    }
    &:last-of-type {
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.textOnPrimary};
      &:hover {
        background: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.textOnPrimary};
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.small}) {
    flex-direction: column;
    button {
      width: 100%;
      margin: 0 0 ${(props) => props.theme.margins[4]} 0;
    }
  }
`;
