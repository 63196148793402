import { useState, useEffect } from "react";
import { withTheme } from "styled-components";
import moment from "moment";
import {
  CustomInputContainer,
  DateWrapper,
  InputWrapper,
} from "../styledInput/styledInputStyles";
// import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.min.css";
import Calendar from "react-datepicker";
import { DATE_API_FORMAT } from "utils/constantes";
import ErrorsWrapper from "../layout/errorsWrapper/errorsWrapper";

import fr from "date-fns/locale/fr"; // the locale you want
import MaskedInput from "react-text-mask";

const StyledDatePicker = ({
  setFieldValue = () => {},
  handleBlur = () => {},
  defaultDate = undefined,
  name,
  required,
  minDate = null,
  maxDate = null,
  label = null,
  placeholder,
  disabled,
  touched,
  errors,
  showMonthDropdown,
  showYearDropdown,
  showMonthYearPicker,
  datePattern = "dd/MM/yyyy",
  dateInputPattern = "DD/MM/YYYY",
  withCustomInput = true,
}) => {
  const [value, setValue] = useState(
    defaultDate ? moment(defaultDate, DATE_API_FORMAT, true).toDate() : null
  );

  useEffect(() => {
    if (defaultDate) {
      setValue(moment(defaultDate, DATE_API_FORMAT, true).toDate());
    }
  }, [defaultDate]);

  const handleChangePicker = (e) => {
    const dateVal = moment(e, true);
    if (dateVal.isValid()) {
      const dateApiFormat = dateVal.format(DATE_API_FORMAT);
      setFieldValue(name, dateApiFormat, true);
      setValue(e);
    } else {
      setFieldValue(name, null, true);
      setValue(null);
    }
  };

  return (
    <CustomInputContainer disabled={disabled}>
      {label ? (
        <label htmlFor={name}>
          {label} {required ? " *" : null}
        </label>
      ) : null}
      <InputWrapper hasError={touched && errors}>
        <DateWrapper>
          <Calendar
            value={value}
            selected={value}
            disabled={disabled}
            name={name}
            readOnly={disabled}
            locale={fr}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            showMonthYearPicker={showMonthYearPicker}
            dropdownMode={"select"}
            onChange={(e) => handleChangePicker(e)}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={datePattern}
            onBlur={handleBlur}
            placeholderText={placeholder}
            BlorOn
            customInput={
              withCustomInput ? (
                <div>
                  <MaskedInput
                    value={
                      value ? moment(value).format(dateInputPattern) : undefined
                    }
                    disabled={disabled}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    name={name}
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                </div>
              ) : null
            }
          />
        </DateWrapper>
      </InputWrapper>
      {touched && errors ? <ErrorsWrapper errors={errors} /> : null}
    </CustomInputContainer>
  );
};

export default withTheme(StyledDatePicker);
