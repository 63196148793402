import {
  CloseIconContainer,
  PopupContainer,
  PopupContent,
} from "./popupStyles";
import { ReactComponent as CloseIcon } from "assets//icons/close.svg";
import useOutsideClick from "../../hooks/useOutsideClick";

const PopupOuterContainer = ({
  type = "regular",
  children,
  showPopup,
  setShowPopup,
  heightConstraint = "100%",
  minWidth = null,
}) => {
  const outsideClick = useOutsideClick(() => setShowPopup(false));
  return (
    <PopupContainer showPopup={showPopup} ref={outsideClick}>
      <PopupContent
        heightConstraint={heightConstraint}
        showPopup={showPopup}
        minWidth={minWidth}
      >
        <CloseIconContainer type={type} onClick={() => setShowPopup(false)}>
          <CloseIcon />
        </CloseIconContainer>
        {children}
      </PopupContent>
    </PopupContainer>
  );
};

export default PopupOuterContainer;
