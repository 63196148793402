import { useEffect, useState } from "react";
import StyledButton from "../../styledButton/styledButton";
import PopupOuterContainer from "../popups";
import { ErrorAcknowledgeContainer } from "./errorAcknowledgeStyles";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

const ErrorAcknowledge = ({
  shouldShow,
  errorMessage,
  explanation,
  handleConfirm = () => {},
  clearShow = () => {},
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (shouldShow) {
      window.scrollTo(0, 0, {
        behavior: "smooth",
      });
      setShow(true);
    }
  }, [shouldShow]);

  return (
    <PopupOuterContainer
      heightConstraint="auto"
      showPopup={show}
      setShowPopup={() => {
        setShow(false);
        clearShow();
      }}
    >
      <ErrorAcknowledgeContainer>
        <h2>Il y a eu une erreur lors du traitement de votre demande</h2>
        <p>{errorMessage}</p>
        {explanation && (
          <div>
            <InfoIcon />
            <h3>Pourquoi l'erreur s'est-elle produite ?</h3>
            <p>{explanation}</p>
          </div>
        )}
        <StyledButton
          type="status"
          typeCategory="default"
          text="Compris"
          action={(e) => {
            e.preventDefault();
            setShow(false);
            handleConfirm();
          }}
          themeSizeSelector={["montserrat", "regular"]}
          themeSpacingSelector={[4, 8]}
        />
      </ErrorAcknowledgeContainer>
    </PopupOuterContainer>
  );
};

export default ErrorAcknowledge;
