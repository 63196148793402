//  DragAndDropWrapper
//  Component that wraps file upload components and displays a drag and drop zone and handles the drag and drop event
//  also displays a padlock when the upload is not allowed
//  Props:
//     - children: node, the component to wrap
//     - isLoading: boolean, if true, the component will display a spinner
//     - onDragAndDropHandler: function, the function to call when the file is dropped
//     - accepte: boolean, if true, the component will display a padlock and indicates that the file has been accepted
//     - statut: string, the statut of the file
//  Functions:
//     - onDragHandler: function, handles the drag event

import { useEffect, useState } from "react";
import { PieceNumeriqueStatut } from "utils/Statuts";
import { ReactComponent as WarningIcon } from "assets//icons/warning.svg";
import { ReactComponent as PadlockIcon } from "assets//icons/padlock.svg";
import { withTheme } from "styled-components";
import { BlockDragAndDrop } from "./dragAndDropWrapperStyles";

const DragAndDropWrapper = ({
  children,
  onDragAndDropHandler,
  statut,
  blocked,
  updateDrag,
  hasImage,
  uploadNotAllowed,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const onDragHandler = (e) => {
    // Prevent default behavior (Prevent file from being opened) and stop propagation of the event to the parent element
    e.preventDefault();
    e.stopPropagation();
  };

  const onDropHandler = (e) => {
    // Prevent default behavior (Prevent file from being opened) and stop propagation of the event to the parent element
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // Call the function passed as a prop to handle the dropped file
      onDragAndDropHandler(e);
    }
  };

  useEffect(() => {
    updateDrag(isDragging);
  }, [isDragging]);

  return (
    <div
      block={
        (statut !== PieceNumeriqueStatut.EN_COURS &&
          PieceNumeriqueStatut.REJETEE &&
          statut !== PieceNumeriqueStatut.A_DEPOSER) ||
        blocked ||
        uploadNotAllowed
          ? 1
          : 0
      }
      onDragEnter={(e) => {
        onDragHandler(e);
        setIsDragging(true);
      }}
      onDragLeave={(e) => {
        onDragHandler(e);
        setIsDragging(false);
      }}
      onDragOver={(e) => {
        onDragHandler(e);
        setIsDragging(true);
      }}
      onDrop={onDropHandler}
    >
      {hasImage && !uploadNotAllowed && (
        <BlockDragAndDrop
          dragging={isDragging}
          block={
            (statut !== PieceNumeriqueStatut.EN_COURS &&
              statut !== PieceNumeriqueStatut.REJETEE &&
              statut !== PieceNumeriqueStatut.A_DEPOSER) ||
            blocked
              ? 1
              : 0
          }
        >
          <PadlockIcon />
          <WarningIcon />
          <p>Le document a été accepté et ne peut pas être modifié</p>
        </BlockDragAndDrop>
      )}
      {children}
    </div>
  );
};

export default withTheme(DragAndDropWrapper);
