import moment from "moment";
import 'moment/locale/fr';

import { DATE_API_FORMAT, DATE_FRONT_FORMAT, MONTH_YEAR_FORMAT } from "./constantes";

export const getMajorityDate = () => moment().subtract(16, "years").toDate();

export const getMax100yearsBirthDate = () =>
  moment().subtract(100, "years").toDate();

export const getDatePlusDays = () => moment().add(60, "days").toDate();

export const getToday = () => moment().toDate()

export const getDateFromStringApi = (dateString) => dateString &&
  moment(dateString, DATE_API_FORMAT, true).toDate();

export const getDateStringFromApiString = (dateString) => dateString &&
  moment(dateString, DATE_API_FORMAT, true).format(DATE_FRONT_FORMAT);

export const getFirstDayOfDateSubMonths = (dateString, months) =>
  moment(dateString, DATE_API_FORMAT, true)
    .subtract(months, "months")
    .set("date", 1) // for first date
    .format(MONTH_YEAR_FORMAT);

export const getFirstDayOfTodaySubMonths = (months) =>
  moment()
    .subtract(months, "months")
    .set("date", 1) // for first date
    .toDate();


export const getMaxDates = (date1, date2) => {
  if (date1 && date2) {
    return date1 > date2 ? date1 : date2;
  } else if (!date1) {
    return date2;
  } else {
    return date1;
  }
};

export const getDateFromJavaFormat = (javaDateTime) =>
  javaDateTime && new Date(javaDateTime)?.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });


export const getMinDates = (date1, date2) => {
  if (date1 && date2) {
    return date1 > date2 ? date2 : date1;
  } else if (!date1) {
    return date2;
  } else {
    return date1;
  }
};
