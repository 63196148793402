import styled, { css } from "styled-components";

export const SwitchContainer = styled.div`
  width: 48px !important;
  min-width: 48px;
  height: 24px;
  background: ${({ theme, active }) =>
    active ? "#EEF1F5" : theme.colors.disabled};
  border-radius: 16px;
  position: relative;
  &,
  & > * {
    cursor: pointer;
    transition: 400ms ease-in-out;
  }
  border: ${({ theme, active }) =>
    active
      ? `1px solid ${theme.colors.switchActive}`
      : "2px solid transparent"};
  ${({ block }) =>
    block &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `}
`;

export const SwitchBall = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: ${({ theme, active }) =>
    active ? theme.colors.switchActive : theme.colors.switchInactive};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  left: ${({ active }) => (active ? "24px" : "2px")};
`;
