import { useKeycloak } from "@react-keycloak/web";
import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import { Header, Spinner, IsAuthWrapper, RenderIf } from "./components";
import { UTILISATEUR_LOAD } from "./utils/saga/actions";
import CookiesPopup from "./components/popups/cookiesPopup/cookiesPopup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./hooks/useScrollToTop";
import CapaciteDetails from "components/forms/synthese/syntheseStatus/capaciteDetails/capaciteDetails";
import { useCallback } from "react";
import Sidebar from "components/navigation/sidebar/sidebar";
import Footer from "./components/navigation/footer/footer";
import {
  ROLE_ADB,
  ROLE_ADMIN_COURTIER,
  ROLE_ADMIN_LOQK,
  ROLE_COURTIER,
  ROLE_DEMANDE_MANAGER,
  ROLE_LOQK,
  ROLE_PARTICULIER,
} from "utils/security/keycloakUtils";
import RenderIfNotRoles from "components/layout/renderIf/renderIfNotRoles";
import { selectActiveUser } from "redux/user/userReducer";
import { TYPE_PARTICULIER_CANDIDAT } from "utils/constantes";
import { DemandesContextProvider } from "utils/context/demandeContext";
import { DemandeLayout } from "layouts/demandeLayout";
import NotFound from "pages/statusPages/notFound";
import Unauthorized from "pages/statusPages/unauthorized";

const Homepage = lazy(() => import("pages/general/homepage/home.jsx"));
const AboutUs = lazy(() => import("pages/general/about/about"));
const PersonalData = lazy(() =>
  import("pages/general/personalData/personalData")
);
const LegalPage = lazy(() => import("pages/general/legal/legal"));
const DataConsentPage = lazy(() => import("pages/consent/consent"));
const LearnMore = lazy(() => import("pages/general/learnMore/learnMore"));

const DemandeCreate = lazy(() =>
  import("./components/demandes/demandeCreate/demandeCreateForm.jsx")
);

const FormsPage = lazy(() => import("./pages/dossierLocation/forms/forms.jsx"));

const CreateDossierForm = lazy(() =>
  import("./pages/dossierLocation/createDossier/createDossierForm.jsx")
);
const DashboardAdb = lazy(() =>
  import("./pages/adb/dashboardAbd/dashboardAdb")
);
const DashboardClient = lazy(() =>
  import("./pages/client/dashboardclient/dashboardclient")
);

const DashboardUtilisateur = lazy(() =>
  import("./pages/utilisateur/dashboardUtilisateur/dashboardUtilisateur")
);

const DashboardCourtier = lazy(() =>
  import("./pages/courtier/dashboardCourtier/dashboardCourtier")
);

const CreateCourtier = lazy(() =>
  import("./pages/courtier/createCourtier/createCourtierForm")
);

const UpdateCourtier = lazy(() =>
  import("./pages/courtier/courtierModify/courtierModifyForm")
);

const SelectAdbSite = lazy(() =>
  import("./pages/adb/selectSiteAdb/selectSiteAdb")
);

const RegisterUtilisateurForm = lazy(() =>
  import("./pages/utilisateur/registerUtilisateur/registerUtilisateurForm")
);

const ModifyUtilisateurForm = lazy(() =>
  import("./pages/utilisateur/modifyUtilisateur/modifyUtilisateurForm")
);

const RegisterClientForm = lazy(() =>
  import("./pages/client/registerClient/registerClientForm")
);

const DashboardCourtierUtilisateurs = lazy(() =>
  import(
    "./pages/utilisateurCourtier/dashboardCourtierUtilisateurs/dashboardCourtierUtilisateurs"
  )
);

const CreateCourtierUtilisateurForm = lazy(() =>
  import(
    "./pages/utilisateurCourtier/createUtilisateurCourtier/createUtilisateurCourtierForm"
  )
);
const UtilisateurCourtierDetails = lazy(() =>
  import(
    "./pages/utilisateurCourtier/utilisateurCourtierDetails/utilisateurCourtierDetailsForm"
  )
);
const DemandeManagerDashboard = lazy(() =>
  import("./pages/demandes/demandeManager/demandeManagerDashboard")
);
const DemandeParticularDashboard = lazy(() =>
  import("./pages/demandes/demandeParticular/demandeParticularDashboard")
);

const DemandeDetails = lazy(() =>
  import("./pages/demandes/demandeDetails/demandeDetails")
);

const DemandeCandidateForms = lazy(() =>
  import("./pages/demandes/demandeCandidate/demandeCandidate")
);

function App() {
  const { keycloak, initialized } = useKeycloak();
  const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const connectedUser = useSelector(selectActiveUser);

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
    document.querySelector("body").style.overflow = "auto";
  }, [location]);

  const onAuthSuccess = useCallback(() => {
    dispatch({ type: UTILISATEUR_LOAD });
  }, [dispatch]);

  const setKeycloakActions = useCallback(() => {
    if (keycloak) {
      keycloak.onAuthSuccess = () => {
        onAuthSuccess();
      };

      keycloak.onTokenExpired = () => {
        keycloak.updateToken(30);
      };

      keycloak.onAuthRefreshError = () => {
        keycloak.login({
          redirectUri: window.location.origin + "/dashboard",
        });
      };

      keycloak.onAuthRefreshSuccess = () => {
        console.log("onAuthRefreshSuccess");
      };
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    setKeycloakActions();
  }, [setKeycloakActions]);

  useEffect(() => {
    const path = location.pathname;
    const pathArray = path.split("/");
    if (
      pathArray[1] === "monespace" ||
      pathArray[1] === "candidate" ||
      pathArray[1] === "utilisateur" ||
      pathArray[1] === "dashboard" ||
      pathArray[1] === "courtier" ||
      (pathArray[1] === "demande-candidature" &&
        connectedUser?.typeCompte !== TYPE_PARTICULIER_CANDIDAT)
    ) {
      setShouldDisplaySidebar(true);
    } else {
      setShouldDisplaySidebar(false);
    }
  }, [location, connectedUser]);

  return (
    <div>
      <ScrollToTop />
      <ToastContainer
        newestOnTop={true}
        pauseOnFocusLoss={false}
        limit={5}
        style={{
          zIndex: 100000,
        }}
      />
      <CookiesPopup />
      <RenderIf condition={!shouldDisplaySidebar}>
        <Header />
      </RenderIf>
      <RenderIf condition={shouldDisplaySidebar}>
        <RenderIfNotRoles roles={[ROLE_PARTICULIER]}>
          <Sidebar />
        </RenderIfNotRoles>
      </RenderIf>
      <Suspense
        fallback={<Spinner message="Chargement en cours" marginY="172px" />}
      >
        <Routes>
          <Route index path="/" element={<Homepage />} />
          <Route
            path="/consentement"
            element={
              <IsAuthWrapper roles={[ROLE_PARTICULIER]}>
                <DataConsentPage />
              </IsAuthWrapper>
            }
          />
          <Route
            path="dashboard"
            element={
              <IsAuthWrapper>
                <Spinner />
              </IsAuthWrapper>
            }
          />

          <Route path="/candidate">
            <Route
              path="dashboard"
              element={
                <IsAuthWrapper fallbackLink={"/dashboard"} roles={[ROLE_ADB]}>
                  <DashboardAdb />
                </IsAuthWrapper>
              }
            />
            <Route
              path="forms"
              element={
                <IsAuthWrapper
                  fallbackLink="/non-autorise"
                  requiredType="candidate"
                >
                  <FormsPage />
                </IsAuthWrapper>
              }
            />
          </Route>
          <Route path="/monespace">
            <Route
              path="dashboard"
              element={
                <IsAuthWrapper
                  roles={[ROLE_LOQK, ROLE_COURTIER]}
                  fallbackLink={"/dashboard"}
                >
                  <DashboardClient />
                </IsAuthWrapper>
              }
            />
            <Route
              path="administrateur-bien"
              element={
                <IsAuthWrapper>
                  <DashboardAdb />
                </IsAuthWrapper>
              }
            />
            <Route path="dossier">
              <Route
                path="create"
                element={
                  <IsAuthWrapper fallbackLink={"/dashboard"}>
                    <CreateDossierForm />
                  </IsAuthWrapper>
                }
              />
              <Route
                path="status"
                element={
                  <IsAuthWrapper
                    fallbackLink={"/monespace/login"}
                    requiredType="admin"
                  >
                    <FormsPage />
                  </IsAuthWrapper>
                }
              />
              <Route
                path="select"
                element={
                  <IsAuthWrapper fallbackLink={"/dashboard"}>
                    <SelectAdbSite />
                  </IsAuthWrapper>
                }
              />
            </Route>

            <Route path="client">
              <Route path="enregistrement" element={<RegisterClientForm />} />
              <Route path="edition" element={<RegisterClientForm modify />} />
            </Route>
          </Route>

          <Route path="/utilisateur">
            <Route
              path="dashboard"
              element={
                <IsAuthWrapper fallbackLink={"/"}>
                  <DashboardUtilisateur />
                </IsAuthWrapper>
              }
            />
            <Route
              path="register"
              element={
                <IsAuthWrapper fallbackLink={"/"}>
                  <RegisterUtilisateurForm />
                </IsAuthWrapper>
              }
            />
            <Route
              path=":id"
              element={
                <IsAuthWrapper fallbackLink={"/"}>
                  <ModifyUtilisateurForm />
                </IsAuthWrapper>
              }
            />
            <Route path="loqk">
              <Route
                path="dashboard"
                element={
                  <IsAuthWrapper fallbackLink={"/"} roles={[ROLE_ADMIN_LOQK]}>
                    <DashboardUtilisateur isLOQKAdmin />
                  </IsAuthWrapper>
                }
              />
              <Route
                path="register"
                element={
                  <IsAuthWrapper fallbackLink={"/"} roles={[ROLE_ADMIN_LOQK]}>
                    <RegisterUtilisateurForm isLOQKAdmin />
                  </IsAuthWrapper>
                }
              />
              <Route
                path=":id"
                element={
                  <IsAuthWrapper fallbackLink={"/"} roles={[ROLE_ADMIN_LOQK]}>
                    <ModifyUtilisateurForm isLOQKAdmin={true} />
                  </IsAuthWrapper>
                }
              />
            </Route>
          </Route>
          {/* Demande Layout is wrapper with IsAuthWrapper components */}
          <Route
            path="/demande-candidature"
            element={
              <DemandeLayout
                roles={[ROLE_DEMANDE_MANAGER, ROLE_PARTICULIER]}
                fallbackLink="/"
              />
            }
          >
            <Route path="create" element={<DemandeCreate />} />
            <Route path=":demandeId">
              <Route path="details" element={<DemandeDetails />} />
              <Route path="dossiers-candidature">
                <Route path=":candidatureId">
                  <Route
                    path="candidats"
                    element={<DemandeCandidateForms />}
                  ></Route>
                </Route>
              </Route>
            </Route>
            <Route path="dashboard">
              <Route
                path="manager"
                element={
                  <IsAuthWrapper
                    fallbackLink="/"
                    roles={[ROLE_DEMANDE_MANAGER]}
                  >
                    <DemandeManagerDashboard />
                  </IsAuthWrapper>
                }
              />
              <Route
                path="particular"
                element={
                  <IsAuthWrapper fallbackLink="/" roles={[ROLE_PARTICULIER]}>
                    <DemandeParticularDashboard />
                  </IsAuthWrapper>
                }
              />
            </Route>
          </Route>

          <Route
            path="location/:locationId/capacite-details"
            element={
              <IsAuthWrapper>
                <CapaciteDetails />
              </IsAuthWrapper>
            }
          />

          <Route path="/courtier">
            <Route
              path="dashboard"
              element={
                <IsAuthWrapper
                  fallbackLink="/non-autorise"
                  roles={[ROLE_ADMIN_LOQK]}
                >
                  <DashboardCourtier />
                </IsAuthWrapper>
              }
            />
            <Route
              path="create"
              element={
                <IsAuthWrapper
                  fallbackLink="/non-autorise"
                  roles={[ROLE_ADMIN_LOQK]}
                >
                  <CreateCourtier />
                </IsAuthWrapper>
              }
            />

            <Route path=":courtierId">
              <Route
                path=""
                element={
                  <IsAuthWrapper
                    fallbackLink="/non-autorise"
                    roles={[ROLE_ADMIN_LOQK]}
                  >
                    <UpdateCourtier />
                  </IsAuthWrapper>
                }
              />
              <Route path="utilisateurs">
                <Route
                  path=""
                  element={
                    <IsAuthWrapper
                      fallbackLink="/non-autorise"
                      roles={[ROLE_ADMIN_LOQK, ROLE_ADMIN_COURTIER]}
                    >
                      <DashboardCourtierUtilisateurs />
                    </IsAuthWrapper>
                  }
                />
                <Route
                  path=":utilisateurId"
                  element={
                    <IsAuthWrapper
                      fallbackLink="/non-autorise"
                      roles={[ROLE_ADMIN_LOQK, ROLE_ADMIN_COURTIER]}
                    >
                      <UtilisateurCourtierDetails />
                    </IsAuthWrapper>
                  }
                />

                <Route
                  path="create"
                  element={
                    <IsAuthWrapper
                      fallbackLink="/non-autorise"
                      roles={[ROLE_ADMIN_LOQK]}
                    >
                      <CreateCourtierUtilisateurForm />
                    </IsAuthWrapper>
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path="/qui-sommes-nous" element={<AboutUs />} />
          <Route path="/donnees-personnelles" element={<PersonalData />} />
          <Route path="/mentions-legales" element={<LegalPage />} />
          <Route path="/en-savoir-plus" element={<LearnMore />} />
          <Route path="/non-autorise" element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

      <RenderIf condition={!shouldDisplaySidebar}>
        <Footer />
      </RenderIf>
    </div>
  );
}

export default App;
