import { useSelector } from "react-redux";
import {
  CollapsibleContainer,
  InformationHeading,
  SituationInformationContainer,
  Value,
} from "pages/dossierLocation/forms/formsStyles";
import { selectCurrentDossierRevenus } from "redux/dossierLocation/dossierLocationReducer";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { Link } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import {
  revenusFormatter,
  splitRevenusToCategories,
} from "../utils/revenusSplitter";

const SyntheseRevenusSection = ({ collapseByDefault }) => {
  const dossierRevenus = useSelector(selectCurrentDossierRevenus);
  const { revenus } = dossierRevenus || {};
  const data = splitRevenusToCategories(revenus);
  const componentRef = useRef(null);
  const [collapse, setCollapse] = useState(false);
  useEffect(() => {
    if (collapseByDefault) {
      setCollapse(true);
    }
  }, []);

  return revenus && revenus.length > 0 ? (
    <CollapsibleContainer collapsed={collapse} ref={componentRef}>
      <InformationHeading collapsed={collapse}>
        <div>
          <div
            onClick={() => {
              setCollapse(!collapse);
              window.scrollTo(0, componentRef.current.offsetTop - 24);
            }}
          >
            <Chevron />
            <h3>Revenus</h3>
          </div>
          <Link to="/candidate/forms?category=revenus">
            <EditIcon />
          </Link>
        </div>
      </InformationHeading>
      <SituationInformationContainer>
        {data.revenusMensuel.data.length > 0 ? (
          <Value>
            <p>{data.revenusMensuel.title}</p>
            <p>
              {data.revenusMensuel.data.map((revenu, index) => {
                return (
                  `${revenusFormatter.format(revenu.value)}` +
                  (index === data.revenusMensuel.data.length - 1 ? "" : ", ")
                );
              })}
            </p>
          </Value>
        ) : null}
        {data.revenusAnnuel.data.length > 0 ? (
          <Value>
            <p>{data.revenusAnnuel.title}</p>
            <p>
              {data.revenusAnnuel.data.map((revenu, index) => {
                return (
                  `${revenusFormatter.format(revenu.value)}` +
                  (index === data.revenusAnnuel.data.length - 1 ? "" : ", ")
                );
              })}
            </p>
          </Value>
        ) : null}
        {data.revenusReference.data.length > 0 ? (
          <Value>
            <p>{data.revenusReference.title}</p>
            <p>
              {data.revenusReference.data.map((revenu, index) => {
                return (
                  `${revenusFormatter.format(revenu.value)}` +
                  (index === data.revenusReference.data.length - 1 ? "" : ", ")
                );
              })}
            </p>
          </Value>
        ) : null}
        {data.revenusBrut.data.length > 0 ? (
          <Value>
            <p>{data.revenusBrut.title}</p>
            <p>
              {data.revenusBrut.data.map((revenu, index) => {
                return (
                  `${revenusFormatter.format(revenu.value)}` +
                  (index === data.revenusBrut.data.length - 1 ? "" : ", ")
                );
              })}
            </p>
          </Value>
        ) : null}
      </SituationInformationContainer>
    </CollapsibleContainer>
  ) : null;
};

export default SyntheseRevenusSection;
