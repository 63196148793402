import React from "react";
import { useNavigate, useParams } from "react-router";

const withNavigate = (WrappedComponent) => {
  const HOC = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    return <WrappedComponent {...props} navigate={navigate} params={params} />;
  };

  return HOC;
};

export default withNavigate;
