import { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import {
  ActiveCategory,
  CategoriesContainer,
  CategoryOptions,
  CustomNavigation,
  Separator,
} from "pages//dossierLocation/forms/formsStyles";

import { ReactComponent as Chevron } from "assets//icons/chevron.svg";

import {
  ComponentWrapper,
  FormsCategory,
  ReturnLink,
  StyledButton,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentDossierNumerique,
  selectDossierLocation,
  selectCurrentDossierRevenus,
  selectCurrentDossierLocataire,
} from "redux//dossierLocation/dossierLocationReducer";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ROUVRIR_DOSSIER_PARTENAIRE } from "utils/saga/actions";
import { DossierLocationStatut, DossierNumeriqueStatut } from "utils/Statuts";

const FormsHeader = ({ activeCategory, user, setIsLoading }) => {
  const [showFormsOptions, setShowFormsOptions] = useState(false);
  const dossierNumerique = useSelector(selectCurrentDossierNumerique);
  const currentDossierLocation = useSelector(selectDossierLocation);
  const dossierRevenus = useSelector(selectCurrentDossierRevenus);
  const dossierLocataire = useSelector(selectCurrentDossierLocataire);
  const dispatch = useDispatch();
  const outsideClickRef = useOutsideClick(() => {
    setShowFormsOptions(false);
  });

  const getIsAdmin = () => {
    return user && user.profil.includes("ADMIN");
  };

  const rouvrirDossier = () => {
    dispatch({
      type: ROUVRIR_DOSSIER_PARTENAIRE,
      onChange: setIsLoading,
    });
  };

  const getAllowNavigate = (category) => {
    if (
      dossierLocataire?.statut === DossierNumeriqueStatut.VALIDE ||
      dossierLocataire?.statut === DossierNumeriqueStatut.BLOQUE ||
      currentDossierLocation?.statut === DossierLocationStatut.VALIDE ||
      currentDossierLocation?.statut === DossierLocationStatut.INVALIDE
    ) {
      return true;
    }
    const allCategories = ["situation", "pieces", "revenus", "synthese"];
    const currentCategoryIndex = allCategories.indexOf(activeCategory);
    const categoryIndex = allCategories.indexOf(category);
    return categoryIndex <= currentCategoryIndex;
  };

  useEffect(() => {
    setShowFormsOptions(false);
  }, [activeCategory]);

  return (
    <>
      <ComponentWrapper isDashboard ignoreTopPadding>
        <CustomNavigation ignoreMargin="bottom">
          <ReturnLink to={"/monespace/administrateur-bien"}>
            Retour Tableau de Bord
          </ReturnLink>
          {dossierNumerique &&
            dossierNumerique.statut === DossierNumeriqueStatut.INVALIDE &&
            currentDossierLocation.statut !== DossierLocationStatut.INVALIDE &&
            currentDossierLocation.statut !== DossierLocationStatut.VALIDE &&
            activeCategory === "pieces" && (
              <StyledButton
                type="status"
                typeCategory="error"
                text="Modifier documents"
                action={rouvrirDossier}
                themeSizeSelector={["montserrat", "regular"]}
                themeSpacingSelector={[4, 12]}
              />
            )}
        </CustomNavigation>
      </ComponentWrapper>

      <CategoriesContainer showOptions={showFormsOptions} ref={outsideClickRef}>
        <ActiveCategory
          showOptions={showFormsOptions}
          onClick={() => setShowFormsOptions(!showFormsOptions)}
        >
          {["situation", "pieces", "revenus", "synthese"].map(
            (category, index) => {
              if (category === activeCategory) {
                return (
                  <FormsCategory
                    key={index}
                    index={index + 1}
                    active={1}
                    linkTarget={category}
                    category={
                      category.charAt(0).toUpperCase() +
                      category.substr(1).toLowerCase()
                    }
                    enabled={true}
                    allowNavigate={getAllowNavigate(category)}
                  />
                );
              }
              return undefined;
            }
          )}
          <Chevron />
        </ActiveCategory>
        <CategoryOptions showOptions={showFormsOptions}>
          <FormsCategory
            index={1}
            active={activeCategory === "situation"}
            linkTarget={"situation"}
            category={"Situation"}
            enabled={dossierLocataire}
            allowNavigate={getAllowNavigate("situation")}
          />
          <Separator active={activeCategory === "pieces"} />
          <FormsCategory
            index={2}
            active={activeCategory === "pieces"}
            linkTarget={"pieces"}
            category={"Pièces"}
            enabled={dossierNumerique}
            allowNavigate={getAllowNavigate("pieces")}
          />
          <Separator active={activeCategory === "revenus"} />

          <FormsCategory
            index={3}
            active={activeCategory === "revenus"}
            linkTarget={"revenus"}
            category={"Revenus"}
            enabled={dossierRevenus && dossierRevenus.revenus?.length > 0}
            allowNavigate={getAllowNavigate("revenus")}
          />
          <Separator active={activeCategory === "synthese"} />

          <FormsCategory
            index={getIsAdmin() ? 4 : 3}
            active={activeCategory === "synthese"}
            linkTarget={"synthese"}
            category={"Synthèse"}
            enabled={currentDossierLocation}
            allowNavigate={getAllowNavigate("synthese")}
          />
        </CategoryOptions>
      </CategoriesContainer>
    </>
  );
};

export default withTheme(FormsHeader);
