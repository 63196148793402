import { createSlice, createSelector } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  currentUser: undefined,
};

const UserReducer = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    logOutUser: (state, action) => {
      state = {
        currentUser: undefined,
      };
    },
  },
});

const selectSelf = (state) => state.user;

export const UserActions = UserReducer.actions;

export const selectActiveUser = createSelector(
  [selectSelf],
  (user) => user.currentUser
);

export const { setCurrentUser } = UserReducer.actions;

export default UserReducer;
