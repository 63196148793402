//  DossierSummary
//  Component used to display the summary of a dossier and list its locataires
//  Props:
//     - handlePreviewCall: function, calls the preview action
//     - dossierData: object, the data of the dossier to display
//     - isLoading: boolean, if true, the component is loading
//     - showSummary: boolean, if true, the summary is displayed
//     - summaryLoadError: boolean, if true, an error occured while loading the summary

import { lazy, Suspense } from "react";
import { DossierLocationStatut } from "utils/Statuts";
import { ReactComponent as WarningIcon } from "assets//icons/warning.svg";
import { ReactComponent as AdminIcon } from "assets//icons/admin.svg";
import { ReactComponent as VisaleIcon } from "assets//icons/visale.svg";
import Spinner from "../spinner/spinner";
import { withTheme } from "styled-components";
import {
  DossierStatusContainer,
  DossierValidationActionInfo,
  ErrorSummary,
  LocataireDataWrapper,
  Raison,
  StatusValues,
  SummaryContainer,
} from "./dossierSummaryStyles";
import { STATUT_DOSSIER_LOCATION } from "utils/constantes";
import RenderIfRoles from "components/layout/renderIf/renderIfRoles";
import { ROLE_ADMIN_LOQK } from "utils/security/keycloakUtils";

const LocataireSummary = lazy(() =>
  import("./locataireSummary/locataireSummary")
);

const DossierSummary = ({
  handlePreviewCall = () => {},
  dossierData,
  isLoading,
  showSummary,
  summaryLoadError,
}) => {
  return (
    <SummaryContainer opened={showSummary}>
      {summaryLoadError && (
        <ErrorSummary opened={showSummary}>
          <WarningIcon />
          <h4>
            Une erreur est survenue lors du chargement des données du dossier
          </h4>
        </ErrorSummary>
      )}
      {isLoading && <Spinner message="Chargement des données" />}
      {Object.keys(dossierData).length > 0 && (
        <LocataireDataWrapper opened={showSummary}>
          <DossierStatusContainer>
            <StatusValues
              valid={dossierData.statut === DossierLocationStatut.VALIDE}
            >
              <h3>Statut du dossier</h3>
              <h5>{dossierData.statut}</h5>
            </StatusValues>
            {dossierData.capaciteLocative ? (
              <StatusValues
                valid={dossierData.statut === DossierLocationStatut.VALIDE}
              >
                <h3>Capacite Locativé</h3>
                <h5>{dossierData.capaciteLocative} €</h5>
              </StatusValues>
            ) : null}

            {dossierData.visalEligible ? (
              <StatusValues>
                <h3>Visale Eligible</h3>
                <VisaleIcon />
              </StatusValues>
            ) : null}
          </DossierStatusContainer>

          {dossierData.validateur && (
            <>
              <DossierValidationActionInfo>
                <AdminIcon />
                <div>
                  <p>
                    Ce dossier a été{" "}
                    {STATUT_DOSSIER_LOCATION.find(
                      (statut) => statut.key === dossierData.statut
                    )?.value.toLowerCase()}{" "}
                    par {dossierData.validateur.nom}{" "}
                    {dossierData.validateur.prenom}
                  </p>
                  <RenderIfRoles roles={[ROLE_ADMIN_LOQK]}>
                    {dossierData.causeAction && (
                      <Raison status={dossierData.statut}>
                        <p>{dossierData.causeAction}</p>
                      </Raison>
                    )}
                  </RenderIfRoles>
                </div>
              </DossierValidationActionInfo>
            </>
          )}
          <Suspense fallback={<Spinner />}>
            {dossierData &&
              dossierData.dossierLocataires.map((locataire) => (
                <LocataireSummary
                  dossierData={dossierData}
                  locataire={locataire}
                  key={locataire.id}
                  handlePreviewCall={handlePreviewCall}
                />
              ))}
          </Suspense>
        </LocataireDataWrapper>
      )}
    </SummaryContainer>
  );
};

export default withTheme(DossierSummary);
