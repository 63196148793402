import { axiosInstance } from "./axiosInstance";

const sendDemandeToUser = (adbId, siteId, data) => {
  return axiosInstance.post(
    `/administrateurs-biens/${adbId}/sites/${siteId}/demandes-candidatures`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getDemandesCandidatures = (adbId, filters, pagination) => {
  let filtersCopy = { ...filters };
  Object.keys(filtersCopy).forEach((key) => {
    if (filtersCopy[key] === "") {
      delete filtersCopy[key];
    }
  });

  return axiosInstance.get(
    `/administrateurs-biens/${adbId}/demandes-candidatures`,
    {
      params: {
        ...filtersCopy,
        ...pagination,
      },
    }
  );
};

export const getParticularDemandesCandidatures = () => {
  return axiosInstance.get("/utilisateurs-particuliers");
};

export const createDemandeCandidatur = (demandeId) => {
  return axiosInstance.post(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures`
  );
};

export const getDemandeCandidature = (demandeId, candidatureId) => {
  return axiosInstance.get(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}`
  );
};

export const getDemandeDetails = (demandeId) => {
  return axiosInstance.get(`/demandes-candidatures/${demandeId}`);
};

export const createDemandeCandidat = (demandeId, candidatureId, data) => {
  return axiosInstance.post(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}/dossiers-candidats`,
    data
  );
};

export const updateDemandeCandidat = (
  demandeId,
  candidatureId,
  candidatId,
  data
) => {
  return axiosInstance.put(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}/dossiers-candidats/${candidatId}`,
    data
  );
};

export const finalizeDemande = (demandeId, candidatureId) => {
  return axiosInstance.put(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}/finaliser`
  );
};

export const deleteDemandeCandidat = (demandeId, candidatureId, candidatId) => {
  return axiosInstance.delete(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}/dossiers-candidats/${candidatId}`
  );
};

export const importDemande = (adbId, demandeId) => {
  return axiosInstance.post(
    `/administrateurs-biens/${adbId}/demandes-candidatures/${demandeId}/importer`
  );
};

export const deleteDemandeCandidatPiece = (
  demandeId,
  candidatureId,
  candidateId,
  candidateNumeriqueId,
  pieceId
) => {
  return axiosInstance.delete(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}/dossiers-candidats/${candidateId}/dossiers-numeriques/${candidateNumeriqueId}/pieces-numeriques/${pieceId}`
  );
};

export const uploadDemandeCandidatPiece = (
  demandeId,
  candidatureId,
  candidateId,
  candidateNumeriqueId,
  pieceId,
  file,
  filename
) => {
  const formData = new FormData();
  formData.append("file", file, filename);

  return axiosInstance.post(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}/dossiers-candidats/${candidateId}/dossiers-numeriques/${candidateNumeriqueId}/pieces-numeriques/${pieceId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const rouvrirDemande = (demandeId, candidatureId) => {
  return axiosInstance.put(
    `/demandes-candidatures/${demandeId}/dossiers-candidatures/${candidatureId}/rouvrir`
  );
};

export default sendDemandeToUser;
