import { useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components";
import {
  ACCEPTER_DOSSIER_LOCATION,
  REJETER_DOSSIER_LOCATION,
  ROUVRIR_DOSSIER_LOCATION,
} from "utils/saga/actions";

import StyledButton from "components/styledButton/styledButton";
import { ReactComponent as ValidateIcon } from "assets/icons/document_accept.svg";
import { ReactComponent as CancelIcon } from "assets/icons/document_refuse.svg";
import { ReactComponent as EditDocumentIcon } from "assets/icons/document_edit.svg";
import { ReactComponent as VisaleIcon } from "assets/icons/visale_wide.svg";
import {
  AcceptOrRefuse,
  DossierBasicInfo,
  DossierExtras,
  DossierStatus,
  ExpandButton,
  PriceCapacity,
  StatusContainer,
  VisaleContainer,
} from "./syntheseStatusStyles";
import ConfirmationPopup from "components/popups/confirmationPopup/confirmationPopup";
import {
  DOSSIER_LOCATION_VALIDE,
  DOSSIER_LOCATION_INVALIDE,
} from "utils/helpers/statutDossiers";
import RenderIfRoles from "components/layout/renderIf/renderIfRoles";
import {
  ROLE_ADMIN_LOQK,
  ROLE_REJET_DOSSIER_LOCATION,
  ROLE_VALIDATION_DOSSIER_LOCATION,
} from "utils/security/keycloakUtils";
import { DossierLocationStatut } from "utils/Statuts";
import { TYPE_CANDIDAT_ENUM } from "utils/helpers/DossierLocataireHelper";
import { useEffect } from "react";
import { selectCurrentDossierLocatairesList } from "redux/dossierLocation/dossierLocationReducer";
import { getDateFromJavaFormat, getDateFromStringApi } from "utils/dateUtils";
import {
  AVIS_IMPOSITION,
  STATUT_DOSSIER_LOCATION,
  TYPE_VALIDATION_FORCE,
} from "utils/constantes";
import CapaciteLocative from "./capaciteLocative/capaciteLocative";

const SyntheseStatus = ({
  dossierLocation,
  addColocataire,
  setIsCandidateLoading,
}) => {
  const [expand, setExpand] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [showAddConjoint, setShowAddConjoint] = useState(false);
  const dossiersLocataires = useSelector(selectCurrentDossierLocatairesList);
  const dispatch = useDispatch();
  const { statut: statutDossierLocation, typeValidation } =
    dossierLocation || {};

  const handleRefuserLocationRequest = (message) => {
    dispatch({
      type: REJETER_DOSSIER_LOCATION,
      payload: {
        message,
        onChange: setIsCandidateLoading,
      },
    });
  };

  useLayoutEffect(() => {
    // get window width, if less than 1280, handle overflow, otherwise set to auto

    const handleWindowResize = () => {
      if (window.innerWidth < 1024) {
        document.querySelector("body").style.overflow = expand
          ? "hidden"
          : "auto";
      } else {
        document.querySelector("body").style.overflow = "auto";
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [expand]);

  const handleRouvrirLocationRequest = () => {
    dispatch({
      type: ROUVRIR_DOSSIER_LOCATION,
      onChange: setIsCandidateLoading,
    });
  };

  const handleAccepterLocationRequest = (message) => {
    dispatch({
      type: ACCEPTER_DOSSIER_LOCATION,
      payload: {
        message,
        onChange: setIsCandidateLoading,
      },
    });
    setShowConfirm(false);
  };

  const enumStatusChangement = useCallback(() => {
    if (confirmationType === "accepter") {
      return "Valider";
    }
    if (confirmationType === "rejeter") {
      return "Refuser";
    }
    if (confirmationType === "rouvrir-dossier") {
      return "Rouvrir";
    }
  }, [confirmationType]);

  const handleConfirmation = (message) => {
    if (confirmationType === "accepter") {
      handleAccepterLocationRequest(message);
    } else if (confirmationType === "rejeter") {
      handleRefuserLocationRequest(message);
    } else if (confirmationType === "rouvrir-dossier") {
      handleRouvrirLocationRequest(message);
    }
  };

  useEffect(() => {
    // the candidat referent must have AVIS_IMPOSITON to ADD CONJOINT WITH THE SAME AVIS IMPOSITION
    // AND NOT CONJOINT HAS BEEN ADDED BEFORE
    if (dossiersLocataires && dossiersLocataires.length > 0) {
      const referent = dossiersLocataires.find(
        (locataire) => locataire.typeCandidat === TYPE_CANDIDAT_ENUM.REFERENT
      );
      const { revenus } = referent.revenus || {};
      const haveAvisImpot = revenus?.some(
        (revenuItem) => revenuItem.typePiece === AVIS_IMPOSITION
      );
      const conjointExist = dossiersLocataires.some(
        (locataire) => locataire.typeCandidat === TYPE_CANDIDAT_ENUM.CONJOINT
      );
      setShowAddConjoint(haveAvisImpot && !conjointExist);
    }
  }, [dossiersLocataires]);


  return (
    <StatusContainer status={statutDossierLocation} expand={expand}>
      <ConfirmationPopup
        confirmTitle={`Raison du changement de statut a ${enumStatusChangement()}`}
        confirmMessage={
          confirmationType === "rouvrir-dossier"
            ? "Confirmez-vous la rouverture du dossier ?"
            : "Veuillez indiquer la raison du changement de statut"
        }
        handleConfirm={handleConfirmation}
        handleCancel={() => {
          setShowConfirm(false);
        }}
        showPopup={showConfirm}
        hasPromptRequest={
          confirmationType === "accepter" || confirmationType === "rejeter"
        }
        confirmButtonText="Confirmer"
        cancelButtonText="Annuler"
      />
      <ExpandButton
        onClick={() => {
          setExpand(!expand);
          if (window.innerWidth < 1024)
            document.querySelector("body").style.overflow = expand
              ? "auto"
              : "hidden";
        }}
        expand={expand}
      >
        <span></span>
        <span></span>
      </ExpandButton>
      {dossierLocation.visalEligible && (
        <VisaleContainer>
          <VisaleIcon />
        </VisaleContainer>
      )}
      {typeValidation !== TYPE_VALIDATION_FORCE &&
        (statutDossierLocation !== DOSSIER_LOCATION_VALIDE ||
          statutDossierLocation !== DOSSIER_LOCATION_INVALIDE) && (
          <AcceptOrRefuse>
            <>
              <h4>Validation</h4>

              <div>
                <RenderIfRoles roles={[ROLE_VALIDATION_DOSSIER_LOCATION]}>
                  {statutDossierLocation !== DOSSIER_LOCATION_VALIDE &&
                    typeValidation !== TYPE_VALIDATION_FORCE && (
                      <StyledButton
                        type="status"
                        typeCategory="success"
                        themeSizeSelector={["montserrat", "regular"]}
                        themeSpacingSelector={[3, 4]}
                        isFlex
                        svgType="fill"
                        action={() => {
                          setConfirmationType("accepter");
                          setShowConfirm(true);
                        }}
                      >
                        <ValidateIcon />
                        Accepter
                      </StyledButton>
                    )}
                </RenderIfRoles>

                <RenderIfRoles roles={[ROLE_REJET_DOSSIER_LOCATION]}>
                  {statutDossierLocation !== DOSSIER_LOCATION_INVALIDE &&
                    typeValidation !== TYPE_VALIDATION_FORCE && (
                      <StyledButton
                        type="status"
                        typeCategory="error"
                        themeSizeSelector={["montserrat", "regular"]}
                        themeSpacingSelector={[3, 4]}
                        isFlex
                        svgType="fill"
                        action={() => {
                          setConfirmationType("rejeter");
                          setShowConfirm(true);
                        }}
                      >
                        <CancelIcon />
                        Refuser
                      </StyledButton>
                    )}
                </RenderIfRoles>
              </div>

              <RenderIfRoles roles={[ROLE_ADMIN_LOQK]}>
                {statutDossierLocation === DOSSIER_LOCATION_INVALIDE &&
                  typeValidation !== TYPE_VALIDATION_FORCE && (
                    <StyledButton
                      type="status"
                      typeCategory="default"
                      themeSizeSelector={["montserrat", "regular"]}
                      themeSpacingSelector={[3, 10]}
                      isFlex
                      svgType="fill"
                      action={() => {
                        setConfirmationType("rouvrir-dossier");
                        setShowConfirm(true);
                      }}
                    >
                      <EditDocumentIcon />
                      Ré-ouvrir
                    </StyledButton>
                  )}
              </RenderIfRoles>
            </>
          </AcceptOrRefuse>
        )}
      <DossierStatus status={statutDossierLocation}>
        <p>Statut du dossier</p>
        <h3>{statutDossierLocation}</h3>
      </DossierStatus>
      <DossierBasicInfo>
        <p>
          Référence dossier de location:
          <br />
          <span>{dossierLocation.reference}</span>
        </p>
        <p>
          Créé le{" "}
          <strong>
            {getDateFromJavaFormat(dossierLocation.creationDate)}{" "}
          </strong>
          par <strong>{dossierLocation.createur}</strong>
        </p>
        {dossierLocation.validateur && (
          <p>
            {
              STATUT_DOSSIER_LOCATION.find(
                (statut) => statut.key === dossierLocation.statut
              )?.value
            }{" "}
            par{" "}
            <strong>
              {dossierLocation.validateur.nom}{" "}
              {dossierLocation.validateur.prenom}
            </strong>
          </p>
        )}
        {dossierLocation.causeAction && (
          <p>
            Cause de l'action: <br />
            <strong>{dossierLocation.causeAction}</strong>
          </p>
        )}
      </DossierBasicInfo>
      <>
        {Number.isInteger(dossierLocation.capaciteLocative) ? (
          <PriceCapacity>
            <p>Capacité Locative:</p>

            <CapaciteLocative
              capaciteLocative={dossierLocation.capaciteLocative}
              dossierStatut={statutDossierLocation}
            />
            <RenderIfRoles roles={[ROLE_ADMIN_LOQK]}>
              <StyledButton
                type="outline"
                typeCategory="default"
                text="Afficher les détails de l'algo"
                themeSizeSelector={["montserrat", "regular"]}
                themeSpacingSelector={[3, 10]}
                link={`/location/${dossierLocation.id}/capacite-details`}
              />
            </RenderIfRoles>
          </PriceCapacity>
        ) : null}

        {((statutDossierLocation === DossierLocationStatut.VALIDE &&
          typeValidation !== TYPE_VALIDATION_FORCE) ||
          statutDossierLocation === DossierLocationStatut.INCOMPLET) && (
          <DossierExtras>
            <>
              {showAddConjoint && (
                <StyledButton
                  type="status"
                  typeCategory="default"
                  text="Ajout d’un colocataire ayant le méme avis d’impôt"
                  themeSizeSelector={["montserrat", "regular"]}
                  themeSpacingSelector={[3, 10]}
                  action={() => addColocataire(TYPE_CANDIDAT_ENUM.CONJOINT)}
                />
              )}

              <StyledButton
                type="status"
                typeCategory="default"
                text="Ajout d’un colocataire "
                themeSizeSelector={["montserrat", "regular"]}
                themeSpacingSelector={[3, 10]}
                action={() => addColocataire(TYPE_CANDIDAT_ENUM.COLOCATAIRE)}
              />
            </>
          </DossierExtras>
        )}
      </>
    </StatusContainer>
  );
};

export default withTheme(SyntheseStatus);
