import {
  AUTRES_DOCUMENTS,
  AVIS_IMPOSITION,
  BULLETIN_SALAIRE_M1,
  BULLETIN_SALAIRE_M2,
  BULLETIN_SALAIRE_M3,
  CARTE_ETUDIANT,
  CARTE_IDENTITE_RECTO,
  CARTE_IDENTITE_TYPE,
  CARTE_IDENTITE_VERSO,
  CONTRAT_TRAVAIL,
  PASSPORT,
  PASSPORT_TYPE,
  PIECES_ENUM,
  REVENUS_ENUM,
  TITRE_SEJOUR_RECTO,
  TITRE_SEJOUR_TYPE,
  TITRE_SEJOUR_VERSO,
} from "../constantes";

const getPiecesIdentites = (dossierNumerique) => {
  let piecesIndentite;
  if (dossierNumerique?.typePieceIdentite === CARTE_IDENTITE_TYPE.key) {
    piecesIndentite = dossierNumerique.pieces.filter((piece) => piece.type === CARTE_IDENTITE_RECTO || piece.type === CARTE_IDENTITE_VERSO);
  } else if (dossierNumerique?.typePieceIdentite === TITRE_SEJOUR_TYPE.key) {
    piecesIndentite = dossierNumerique.pieces.filter((piece) => piece.type === TITRE_SEJOUR_RECTO || piece.type === TITRE_SEJOUR_VERSO);
  } else if (dossierNumerique?.typePieceIdentite === PASSPORT_TYPE.key) {
    piecesIndentite = dossierNumerique.pieces.filter((piece) => piece.type === PASSPORT);
  }

  return piecesIndentite;
};

const getBulletinsSalaire = (dossierNumerique) => {
  return (
    dossierNumerique.pieces.filter(
      (piece) =>
        piece.type === BULLETIN_SALAIRE_M3 ||
        piece.type === BULLETIN_SALAIRE_M2 ||
        piece.type === BULLETIN_SALAIRE_M1
    ) || []
  );
};

const getPiecesOtherThanSalaires = (dossierNumerique) => {
  return dossierNumerique.pieces.filter(
    (piece) =>
      piece.type === CARTE_ETUDIANT ||
      piece.type === CONTRAT_TRAVAIL ||
      piece.type === AVIS_IMPOSITION ||
      piece.type === AUTRES_DOCUMENTS
  );
};

const getPieceLabel = (typePiece) => {
  return PIECES_ENUM.find((pieceEnum) => pieceEnum.key === typePiece).value;
};

const getRevenuLabel = (typeRevenu) => {
  return REVENUS_ENUM.find((revenuEnum) => revenuEnum.key === typeRevenu).value;
};

export const getRevenusWithPieces = (revenus = [], pieces = []) => {
  const page = {};
  if (!revenus) {
    return {};
  }
  for (let index = 0; index < revenus.length; index++) {
    const revenuItem = revenus[index];
    const { typePiece } = revenuItem;

    if (!page[typePiece]) {
      page[typePiece] = {
        revenus: [revenuItem],
        pieceNumerique: pieces.find((piece) => piece.type === typePiece),
      };
    } else {
      page[typePiece] = {
        revenus: [...page[typePiece].revenus, revenuItem],
        pieceNumerique: pieces.find((piece) => piece.type === typePiece),
      };
    }
  }

  return page;
};

export const mapRevenusToFormValues = (revenus = []) => {
  let form = {};

  if (!revenus) {
    return {};
  }

  for (let index = 0; index < revenus.length; index++) {
    // getting name of attribut and value
    let attributName = revenus[index].typeRevenu;
    let attributValue = revenus[index].value;
    form = {
      ...form,
      [attributName]: attributValue,
    };
  }
  return form;
};

export const mapFormValuesToRevenus = (form = {}, revenus = []) => {
  let keys = Object.keys(form);
  let revenusCopy = [];
  for (let index = 0; index < keys.length; index++) {
    // getting name of attribut and value
    const revenuIndex = revenus.findIndex(
      (item) => keys[index] === item.typeRevenu
    );
    revenusCopy = [
      ...revenusCopy,
      {
        ...revenus[revenuIndex],
        value: form[keys[index]],
      },
    ];
  }
  return revenusCopy;
};

export {
  getPiecesIdentites,
  getBulletinsSalaire,
  getPiecesOtherThanSalaires,
  getPieceLabel,
  getRevenuLabel,
};
