import { axiosInstance } from "./axiosInstance";

export const getDossierNumerique = (dossierNumeriqueId) => {
  return axiosInstance.get(`/v2/dossiers-numeriques/${dossierNumeriqueId}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const fetchDocumentForPreview = (
  dossierNumeriqueId,
  pieceNumeriqueId
) => {
  return axiosInstance.get(
    `/v2/dossiers-numeriques/${dossierNumeriqueId}/pieces-numeriques/${pieceNumeriqueId}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const fetchImageForPreview = (dossierNumeriqueId, pieceNumeriqueId) => {
  return axiosInstance.get(
    `/v2/dossiers-numeriques/${dossierNumeriqueId}/pieces-numeriques/${pieceNumeriqueId}?image=true`,
    {
      responseType: "blob",
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const deleteDocument = (
  adbId,
  siteId,
  lcoationId,
  locataireId,
  pieceNumeriqueId
) => {
  return axiosInstance.delete(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${lcoationId}/dossier-locataires/${locataireId}/dossier-numerique/piece-numeriques/${pieceNumeriqueId}`
  );
};
