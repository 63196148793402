import styled from "styled-components";

export const PerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.margins[10]};
  & > span {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 8px;
    & > span {
      flex-direction: column;
      gap: 8px;
    }
  }
`;

export const PerPage = styled.div`
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  transition: 300ms ease-in-out;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    &:last-of-type {
      max-width: ${({ active }) => (active ? "96px" : "0")};
      overflow: hidden;
      transition: 300ms ease-in-out;
      white-space: nowrap;
    }
  }
`;

export const MaxPage = styled.div`
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  display: flex;
  gap: 16px;
  color: ${({ theme }) => theme.colors.primary};
  p {
    &:last-of-type {
      cursor: pointer;
    }
  }
`;

export const ActivePage = styled.div`
  input {
    width: 32px;
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    appearance: none;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    padding: ${({ theme }) => theme.paddings[1]} 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    text-align: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const Separator = styled.div`
  width: 2px;
  height: 24px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  opacity: ${({ active }) => (active ? "1" : "0")};
  margin: 0 ${({ theme, active }) => (active ? theme.margins[4] : 0)};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 80%;
    height: 2px;
  }
`;

export const PagesNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.margins[14]};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 16px;
    align-items: center;
    & > div {
      &:first-of-type {
        text-align: center;
        order: 3;
      }
      &:nth-of-type(2) {
        order: 1;
      }
      &:last-of-type {
        text-align: center;
        order: 2;
      }
    }
  }
`;

export const NavigationItem = styled.div`
  transition: 300ms ease-in-out;

  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 120px;
  width: 100%;
  p {
    visibility: ${({ active }) => (active ? "visible" : "hidden")};
    max-width: ${({ active }) => (active ? "120px" : "0")};
    width: 100%;
    transition: 300ms ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
`;

export const Pages = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Page = styled.p`
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  transition: 300ms ease-in-out;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;
