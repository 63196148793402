import {
  BULLETIN_SALAIRE_M1,
  BULLETIN_SALAIRE_M2,
  BULLETIN_SALAIRE_M3,
  CONTRAT_TRAVAIL,
  PIECES_ENUM,
  PIECES_STATUT_ENUM,
} from "utils/constantes";
import { getFirstDayOfDateSubMonths } from "utils/dateUtils";
import { getPieceLabel } from "utils/helpers/dossierNumeriqueHelper";
import { PieceNumeriqueStatut } from "utils/Statuts";

export const getStatutPiece = (statut) => {
  if (statut === PieceNumeriqueStatut.VALIDEE) {
    return "valid";
  }
  if (
    statut === PieceNumeriqueStatut.INVALIDEE ||
    statut === PieceNumeriqueStatut.REJETEE
  ) {
    return "error";
  }
};

export const getStatutPieceText = (statut) => {
  return PIECES_STATUT_ENUM.find((item) => item.key === statut)?.value;
};

export const getCategoryPiece = (type) => {
  if (
    type.includes("TITRE_SEJOUR") ||
    type.includes("CARTE_IDENTITE") ||
    type.includes("PASSPORT")
  ) {
    return "Pièce d'identité";
  }
  if (type.includes("BULLETIN_SALAIRE") || type === CONTRAT_TRAVAIL) {
    return "Piéce de salaire";
  }

  return "Autres";
};

export const getTypePiece = (type) => {
  return PIECES_ENUM.find((item) => item.key === type)?.value;
};

export const getAttachmentTitle = (piece, dateDernierBulletinSalaire) => {
  const { type } = piece || {};
  let title = getPieceLabel(type);

  if (type === BULLETIN_SALAIRE_M1)
    title = getFirstDayOfDateSubMonths(dateDernierBulletinSalaire, 0);
  else if (type === BULLETIN_SALAIRE_M2)
    title = getFirstDayOfDateSubMonths(dateDernierBulletinSalaire, 1);
  else if (type === BULLETIN_SALAIRE_M3)
    title = getFirstDayOfDateSubMonths(dateDernierBulletinSalaire, 2);
  return title;
};
