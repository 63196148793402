import {
  ALL_STATUT_PRO_ENUM,
  PROFILE_ENUM,
  QUALITE_ENUM,
  SITUATION_PERSO_ENUM,
  SITUATION_PRO_ENUM,
  STATUT_LOCATAIRE_DEPUIS_ENUM,
  STATUT_LOCATAIRE_ENUM,
  STATUT_PRO_ENUM,
} from "./constantes";

const getQualiteLabel = (key) => {
  if (!key) {
    return null;
  }
  const qualiteObject = QUALITE_ENUM.find((qualite) => qualite.key === key);
  return qualiteObject.value;
};

const getStatutLocataireDepuisLabel = (key) => {
  if (!key) {
    return null;
  }
  const statutObject = STATUT_LOCATAIRE_DEPUIS_ENUM.find(
    (statut) => statut.key === key
  );
  return statutObject.value;
};

const getStatutLocataireLabel = (key) => {
  if (!key) {
    return null;
  }
  const statutObject = STATUT_LOCATAIRE_ENUM.find(
    (statut) => statut.key === key
  );
  return statutObject.value;
};

const getProfilType = (key) => {
  if (!key) {
    return null;
  }
  const profilObject = PROFILE_ENUM.find((profil) => profil.key === key);
  return profilObject.value;
};

const getSituationPersonnelleLabel = (key) => {
  if (!key) {
    return null;
  }
  const statutObject = SITUATION_PERSO_ENUM.find(
    (statut) => statut.key === key
  );
  return statutObject.value;
};

const getStatutsProsBySituation = (situation) => {
  if (!situation) {
    return [];
  }
  return STATUT_PRO_ENUM.find((statut) => statut.key === situation)?.values;
};

const getSituationProLabel = (key) => {
  if (!key) {
    return "";
  }
  const situationObject = SITUATION_PRO_ENUM.find(
    (situation) => situation.key === key
  );
  return situationObject.value;
};

const getStatutProLabel = (key) => {
  if (!key) {
    return "";
  }
  const statutObject = ALL_STATUT_PRO_ENUM.find((statut) => statut.key === key);
  return statutObject.value;
};

export {
  getStatutLocataireDepuisLabel,
  getStatutLocataireLabel,
  getSituationPersonnelleLabel,
  getStatutsProsBySituation,
  getSituationProLabel,
  getStatutProLabel,
  getQualiteLabel,
  getProfilType,
};
