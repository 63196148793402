import styled from "styled-components";
import { DossierLocationStatut } from "utils/Statuts";

export const DashboardHeading = styled.div`
  margin: ${({ theme }) => theme.margins[14]} 0
    ${({ theme }) => theme.margins[8]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font: ${({ theme }) => theme.fonts.inter.heading};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      display: none;
    }
  }
  button {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    gap: 24px;
    h1 {
      width: 100%;

      svg {
        display: block;
        cursor: pointer;
      }
    }
    & > a,
    & > a button {
      width: 100%;
    }
  }
`;

export const AdminAction = styled.div`
  position: absolute;
  right: 15%;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const VisaleIconContainer = styled.div`
  display: block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const LineContainer = styled.div`
  position: relative;
  display: grid;
  cursor: pointer;
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, 1fr)` : "1fr"};
  margin-bottom: ${({ theme, type }) =>
    type === "header" ? theme.margins[4] : null};
  padding: ${({ theme, type }) => (type !== "header" ? theme.paddings[6] : 0)}
    ${({ theme, type }) => theme.paddings[6]}
    ${({ theme, type }) => (type !== "header" ? theme.paddings[6] : 0)}
    ${({ theme, type }) => theme.paddings[16]};
  border-bottom: 1px solid
    ${({ theme, type, showSummary }) =>
      type !== "header" && !showSummary ? theme.colors.border : "transparent"};
  transition: 350ms ease-in-out;
  transition-delay: ${({ collapse }) => (!collapse ? "350ms" : "0")};
  align-items: center;
  column-gap: 16px;
  &:hover {
    background: ${({ theme, type }) =>
      type !== "header" ? theme.colors.hover : null};
  }
  & > div {
    &:nth-of-type(6) {
      align-items: center;
      display: flex;
      gap: 16px;
      a {
        height: 28px;
        width: 28px;
        font: ${({ theme }) => theme.fonts.montserrat.regular};
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primary};
      }
      svg {
        cursor: pointer;
      }
    }
    &:nth-of-type(7) {
      display: none;
    }
  }

  & > div {
    p {
      &:last-of-type {
        max-width: 25ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &:nth-of-type(4) {
      color: ${({ theme, status, type }) =>
        type === "header"
          ? theme.colors.primary
          : status === DossierLocationStatut.VALIDE
          ? theme.colors.secondary
          : status === DossierLocationStatut.INCOMPLET
          ? theme.colors.primary
          : status === DossierLocationStatut.INVALIDE
          ? theme.colors.error
          : theme.colors.primaryOpaque};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    & > div {
      &:nth-of-type(1) {
        grid-area: 1/1/1/7;
      }
      &:nth-of-type(2) {
        display: none;
      }
      &:nth-of-type(3) {
        grid-area: 1/7/1/10;
      }
      &:nth-of-type(4) {
        grid-area: 1/10/1/14;
      }
      &:nth-of-type(5) {
        grid-area: 1/14/1/18;
      }
      &:nth-of-type(6) {
        grid-area: 1/18/1/20;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & > div {
      &:nth-of-type(1) {
        grid-area: 1/1/1/7;
      }
      &:nth-of-type(3) {
        display: none;
        /* grid-area: 1/6/1/10; */
      }
      &:nth-of-type(4) {
        grid-area: 1/7/1/12;
      }
      &:nth-of-type(5) {
        grid-area: 1/12/1/18;
      }
      &:nth-of-type(6) {
        grid-area: 1/18/1/20;
      }
    }
  }

  @media screen and (max-width: 860px) {
    padding: ${({ theme, type }) => (type !== "header" ? theme.paddings[6] : 0)}
      0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: repeat(8, 1fr);
    display: ${({ type }) => (type === "header" ? "none" : "grid")};
    max-height: ${({ type, collapse }) =>
      type === "header" ? "auto" : !collapse ? "78px" : "768px"};
    row-gap: 48px;
    padding: ${({ theme, type }) =>
        type !== "header" ? theme.paddings[6] : null}
      ${({ theme, type }) => theme.paddings[4]};
    & > div {
      width: 80%;
      transition: 400ms ease-in-out;
      &:nth-of-type(1) {
        grid-area: 1/1/1/7;
        justify-self: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        p:first-of-type {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: block;
        grid-area: 2/1/2/9;
        width: 90%;
        visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
        opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
        p:first-of-type {
          margin-bottom: ${({ theme }) => theme.margins[2]};
        }
      }
      &:nth-of-type(3) {
        display: block;
        grid-area: 3/1/3/5;
        visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
        opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
        p:first-of-type {
          margin-bottom: ${({ theme }) => theme.margins[2]};
        }
      }
      &:nth-of-type(4) {
        display: block;
        grid-area: 3/5/3/9;
        visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
        opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
        p:first-of-type {
          margin-bottom: ${({ theme }) => theme.margins[2]};
        }
      }
      &:nth-of-type(5) {
        display: block;
        grid-area: 4/1/4/5;
        visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
        opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
        p:first-of-type {
          margin-bottom: ${({ theme }) => theme.margins[2]};
        }
      }
      /* &:nth-of-type(6) {
        grid-area: 4/5/4/9;
        visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
        opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
      } */
      &:nth-of-type(6) {
        display: none;
      }
      &:nth-of-type(7) {
        display: flex;
        grid-area: 1/7/1/9;
        justify-content: flex-end;
        svg {
          cursor: pointer;
          display: block;
          transform: ${({ collapse }) =>
            collapse ? "rotate(180deg)" : "rotate(0deg)"};
          transition: 350ms ease-in-out;
        }
      }
      &:nth-of-type(8) {
        grid-area: 5/1/5/9;
        visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
        opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
        gap: 0;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    & > div {
      width: 90%;
      &:nth-of-type(3) {
        grid-area: 3/1/3/9;
      }
      &:nth-of-type(4) {
        grid-area: 4/1/4/9;
      }
      &:nth-of-type(5) {
        grid-area: 5/1/5/9;
      }

      &:nth-of-type(8) {
        grid-area: 6/1/6/9;
      }
    }
  }
`;

export const LocataireShowToggle = styled.span`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme, showSummary }) =>
    showSummary ? theme.colors.error : theme.colors.secondary};
  left: 16px;
  transition: 350ms ease-in-out;
  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 1.5px;
    background-color: ${({ theme }) => theme.colors.textOnPrimary};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 1px;
    transition: 350ms ease-in-out;
    background-color: ${({ theme }) => theme.colors.textOnPrimary};
    top: 50%;
    left: 50%;
    transform: ${({ showSummary }) =>
      !showSummary
        ? "translate(-50%, -50%) rotate(90deg)"
        : "translate(-50%, -50%) rotate(0deg)"};
    display: block;
  }
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

export const HeaderSection = styled.div`
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  cursor: pointer;
  grid-column: span ${({ colSpan }) => (colSpan ? colSpan : 1)};
  justify-self: ${({ justify }) => (justify ? justify : "center")};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  gap: 16px;
  transition: 350ms ease-in-out;
  width: 100%;
  color: ${({ active, theme }) =>
    active ? `${theme.colors.secondary}` : `${theme.colors.primary}`};

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      transition: 350ms ease-in-out;
      height: 16px;
      width: 16px;

      &:first-of-type {
        transform: rotate(180deg);
        opacity: ${({ active, sortDirection }) =>
          sortDirection === "ASC" && active ? 1 : 0.4};
        path {
          stroke: ${({ active, sortDirection, theme }) =>
            active && sortDirection === "ASC"
              ? theme.colors.secondary
              : theme.colors.primaryOpaque};
        }
      }
      &:last-of-type {
        transform: rotate(0deg);
        opacity: ${({ active, sortDirection }) =>
          sortDirection === "DESC" && active ? 1 : 0.4};
        path {
          stroke: ${({ active, sortDirection, theme }) =>
            active && sortDirection === "DESC"
              ? theme.colors.secondary
              : theme.colors.primaryOpaque};
        }
      }
    }
  }
`;

export const LineValue = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  grid-column: span ${({ colSpan }) => (colSpan ? colSpan : 1)};
  justify-self: ${({ justify }) => (justify ? justify : "center")};
  width: ${({ colSpan }) => (colSpan === 0 ? "0" : "100%")};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  & > p {
    &:first-of-type {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primaryOpaque};
      display: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    & > p {
      &:first-of-type {
        display: block;
      }
    }
  }
`;
