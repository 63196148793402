export const DossierNumeriqueStatut = {
  EN_COURS: "EN_COURS",
  VALIDE: "VALIDE",
  INVALIDE: "INVALIDE",
  BLOQUE: "BLOQUE",
  A_DEPOSER: "A_DEPOSER",
  REJETEE: "REJETEE",
};

export const DossierLocataireStatut = {
  INCOMPLET: "INCOMPLET",
  VALIDE: "VALIDE",
  NON_VALIDE: "NON_VALIDE",
};

export const DossierLocationStatut = {
  INCOMPLET: "INCOMPLET",
  VALIDE: "VALIDE",
  INVALIDE: "INVALIDE",
};

export const DemandeCandidateStatut = {
  COMPLET: "COMPLET",
  INCOMPLET: "INCOMPLET",
};

export const DemandeCandidatureStatut = {
  INCOMPLET: "INCOMPLETE",
  FINALISE: "FINALISEE",
  IMPORTEE: "IMPORTEE",
  COMPLETE: "COMPLETE",
};

export const PieceNumeriqueStatut = {
  A_DEPOSER: "A_DEPOSER",
  EN_COURS: "EN_COURS",
  REJETEE: "REJETEE",
  VALIDEE: "VALIDEE",
  INVALIDEE: "INVALIDEE",
};
