//  FileStatus
//  Component to display statut value of a file
//  Props:
//     - status: the status of the file
//     - message: the message to display
//     - positionFix: the position of the message, default is right
//  State:
//     - showStatus: boolean to display the message
//  Refs:
//     - outsideClickRef: ref to detect outside click, to close the message

import { StatusOverlay } from "./syntheseAttachmentStyles";
import { ReactComponent as ErrorIcon } from "assets/icons/warning.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as ValidIcon } from "assets/icons/confirm.svg";

import { useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";

const FileStatus = ({ status, message, positionFix = "right" }) => {
  const [showStatus, setShowStatus] = useState(false);
  const outsideClickRef = useOutsideClick(() => {
    setShowStatus(false);
  });

  return (
    <StatusOverlay
      ref={outsideClickRef}
      showStatus={showStatus}
      status={status}
      onClick={() => {
        if (message.length > 0) {
          setShowStatus(!showStatus);
        }
      }}
      positionFix={positionFix}
    >
      {status === "error" && <ErrorIcon />}
      {status === "valid" && <ValidIcon />}
      {status === "locked" && <LockIcon />}
      <ul>
        {status === "valid" && <li>Document validé</li>}
        {Array.isArray(message) ? (
          message.map((msg, index) => (
            <li key={index}>{`[${msg.code}] - ${msg.message}`}</li>
          ))
        ) : (
          <li>{message}</li>
        )}
      </ul>
    </StatusOverlay>
  );
};

export default FileStatus;
