import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  CategorySeparator,
  SituationInformationContainer,
  SyntheseErrors,
  Value,
  ValuesGroup,
  InformationHeading,
  CollapsibleContainer,
} from "pages/dossierLocation/forms/formsStyles";
import {
  ALL_STATUT_PRO_ENUM,
  SITUATION_PERSO_ENUM,
  SITUATION_PRO_ENUM,
  STATUT_LOCATAIRE_DEPUIS_ENUM,
  STATUT_LOCATAIRE_ENUM,
  TRAVAILLE_12_QUESTION_ENUM,
  TYPE_VALIDATION_FORCE,
} from "utils/constantes";
import { validateSituationProfessionnelle } from "utils/helpers/situationProHelper";
import { getQualiteLabel } from "utils/loqkEnumUtils";
import StyledButton from "../../styledButton/styledButton";
import { ReactComponent as AddUser } from "assets/icons/userAdd.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import { getDateStringFromApiString } from "utils/dateUtils";
import { TYPE_CANDIDAT_ENUM } from "utils/helpers/DossierLocataireHelper";
import { useSelector } from "react-redux";
import { selectDossierLocation } from "redux/dossierLocation/dossierLocationReducer";
import { DossierLocationStatut } from "utils/Statuts";

const SyntheseIdentitySection = ({
  candidateData,
  dossierNumerique,
  hasGarant,
  addColocataire,
  hideActions = false,
  editLink = "/candidate/forms?category=situation",
}) => {
  // this field is used to show only the required fields for professsional situation
  // example for student no salary data....
  const [display, setDisplay] = useState({});
  const [collapse, setCollapse] = useState(false);
  const componentRef = useRef();
  const navigate = useNavigate();
  const { statut: statutDossierLocation, typeValidation } =
    useSelector(selectDossierLocation) || {};

  useEffect(() => {
    setCollapse(false);

    if (candidateData) {
      setDisplay(validateSituationProfessionnelle(candidateData));
    }
  }, [candidateData]);

  const errors = [
    ...((dossierNumerique && dossierNumerique.errors) || []),
    ...((candidateData && candidateData.errors) || []),
  ];

  return (
    <>
      <CollapsibleContainer
        collapsed={collapse}
        hasButton={candidateData.situationPro === "ETUDIANT" && !hasGarant}
        ref={componentRef}
      >
        <InformationHeading collapsed={collapse}>
          <div>
            <div
              onClick={() => {
                setCollapse(!collapse);
                window.scrollTo(0, componentRef.current.offsetTop - 24);
              }}
            >
              <Chevron />

              <h3>Situation</h3>
            </div>
            <EditIcon onClick={() => navigate(editLink)} />
          </div>
          {!hideActions &&
            candidateData.situationPro === "ETUDIANT" &&
            !hasGarant &&
            statutDossierLocation !== DossierLocationStatut.INVALIDE &&
            typeValidation !== TYPE_VALIDATION_FORCE && (
              <StyledButton
                type="status"
                typeCategory="default"
                themeSizeSelector={["montserrat", "regular"]}
                themeSpacingSelector={[3, 10]}
                action={() => addColocataire(TYPE_CANDIDAT_ENUM.GARANT)}
                isFlex
              >
                <AddUser />
                Ajouter un Garant
              </StyledButton>
            )}
        </InformationHeading>
        <SituationInformationContainer>
          <Value>
            <p>Qualité</p>
            <p>{getQualiteLabel(candidateData.qualite)}</p>
          </Value>
          <ValuesGroup>
            <Value>
              <p>Prénom</p>
              <p>{candidateData.prenom}</p>
            </Value>
            <Value>
              <p>Nom</p>
              <p>{candidateData.nom}</p>
            </Value>
          </ValuesGroup>
          <ValuesGroup>
            <Value>
              <p>Téléphone</p>
              <p>{candidateData.telephone}</p>
            </Value>
            <Value>
              <p>Date naissance</p>
              <p>{getDateStringFromApiString(candidateData.dateNaissance)}</p>
            </Value>
          </ValuesGroup>
          <CategorySeparator />
          <Value>
            <p>Département de recherche de location</p>
            <p>{candidateData.departement}</p>
          </Value>
          <ValuesGroup>
            <Value>
              <p>Statut Actuel</p>
              <p>
                {candidateData && candidateData.statutActuel
                  ? STATUT_LOCATAIRE_ENUM.find(
                      (item) => item.key === candidateData.statutActuel
                    ).value
                  : "-"}
              </p>
            </Value>
            <Value>
              <p>Depuis</p>
              <p>
                {candidateData && candidateData.statutDepuis
                  ? STATUT_LOCATAIRE_DEPUIS_ENUM.find(
                      (item) => item.key === candidateData.statutDepuis
                    ).value
                  : "-"}
              </p>
            </Value>
          </ValuesGroup>
          <CategorySeparator />
          <ValuesGroup>
            <Value>
              <p>Situation Personnelle</p>
              <p>
                {candidateData && candidateData.situationPerso
                  ? SITUATION_PERSO_ENUM.find(
                      (item) => item.key === candidateData.situationPerso
                    ).value
                  : "-"}
              </p>
            </Value>
            <Value>
              <p>NB Enfants</p>
              <p>
                {candidateData && candidateData.enfants.length
                  ? candidateData.enfants.length
                  : "-"}
              </p>
            </Value>
          </ValuesGroup>
          {candidateData && candidateData.enfants.length ? (
            <ValuesGroup>
              {candidateData.enfants.map((enfant, index) => {
                return (
                  <Value key={index}>
                    <p>Enfant {index + 1}</p>
                    <p>{enfant !== null ? enfant : "-"}</p>
                  </Value>
                );
              })}
            </ValuesGroup>
          ) : null}

          <CategorySeparator />
          <ValuesGroup>
            <Value>
              <p>Profession</p>
              <p>
                {candidateData &&
                candidateData.situationPro &&
                SITUATION_PRO_ENUM.find(
                  (item) => item.key === candidateData.situationPro
                )
                  ? SITUATION_PRO_ENUM.find(
                      (item) => item.key === candidateData.situationPro
                    ).value
                  : "-"}
              </p>
            </Value>
            {display.displayStatutPro && (
              <Value>
                <p>Situation</p>
                <p>
                  {candidateData &&
                  candidateData &&
                  candidateData.statutPro &&
                  ALL_STATUT_PRO_ENUM.find(
                    (item) => item.key === candidateData.statutPro
                  )
                    ? ALL_STATUT_PRO_ENUM.find(
                        (item) => item.key === candidateData.statutPro
                      ).value
                    : "-"}
                </p>
              </Value>
            )}
          </ValuesGroup>
          {candidateData.revenusMensuels ? (
            <ValuesGroup>
              <Value>
                <p>Revenus Mensuels</p>
                <p>{candidateData.revenusMensuels} €</p>
              </Value>
            </ValuesGroup>
          ) : null}
          <Value>
            <p>
              Date debut {display.displayDateFinSituation && "et de fin "}de
              situation{" "}
            </p>
            <p>
              <Calendar />
              {candidateData && candidateData.dateDebutSituation
                ? getDateStringFromApiString(candidateData.dateDebutSituation)
                : "-"}
              {display.displayDateFinSituation && (
                <>
                  <Arrow />
                  <Calendar />
                  {candidateData && candidateData.dateFinSituation
                    ? getDateStringFromApiString(candidateData.dateFinSituation)
                    : "-"}
                </>
              )}
            </p>
          </Value>
          <ValuesGroup>
            {display.displayDateDernierBulletin && (
              <Value>
                <p>Dernier bulletin de salaire</p>
                <p>
                  {candidateData && candidateData.dateDernierBulletinSalaire
                    ? getDateStringFromApiString(
                        candidateData.dateDernierBulletinSalaire
                      )
                    : "-"}
                </p>
              </Value>
            )}
            {display.displayTravailler24DerniersMois && (
              <Value>
                <p>Antécédents de travail</p>
                <p>
                  {candidateData &&
                  candidateData.travaille24DerniersMois !== null
                    ? TRAVAILLE_12_QUESTION_ENUM.find(
                        (item) =>
                          item.key ===
                          `${candidateData.travaille24DerniersMois}`
                      ).value
                    : "-"}
                </p>
              </Value>
            )}
          </ValuesGroup>
          {(display.displaySiret || display.displayCodeNaf) && (
            <ValuesGroup>
              {display.displaySiret && (
                <Value>
                  <p>Siret</p>
                  <p>
                    {candidateData && candidateData.siret
                      ? candidateData.siret
                      : "-"}
                  </p>
                </Value>
              )}
              {display.displayCodeNaf && (
                <Value>
                  <p>Code APE/NAF</p>
                  <p>
                    {candidateData && candidateData.codeNaf
                      ? candidateData.codeNaf
                      : "-"}
                  </p>
                </Value>
              )}
            </ValuesGroup>
          )}
          {errors && errors.length > 0 && (
            <SyntheseErrors>
              <h3>Veuillez résoudre les erreurs suivantes</h3>
              <div>
                {errors.map((error, index) => (
                  <p key={index}>
                    {error.code} - {error.message}
                  </p>
                ))}
              </div>
            </SyntheseErrors>
          )}
        </SituationInformationContainer>
      </CollapsibleContainer>
    </>
  );
};

export default SyntheseIdentitySection;
