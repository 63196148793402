import styled, { css } from "styled-components";

export const DashboardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margins[8]};
  justify-content: space-between;
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.heading};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: ${({ theme }) => theme.margins[16]};
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    & > a {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
`;

export const DashboardWrapper = styled.div``;

export const DemandesDashboardWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    margin-top: ${({ theme }) => theme.margins[8]};
  }
`;

export const DashboardHeading = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.demandeDashboardHeader};
  padding: ${({ theme }) => theme.paddings[8]};
  border-radius: 12px 12px 0 0;
  justify-content: space-between;
`;

const dashboardLayout = css`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  gap: 24px;
  padding: 0 ${({ theme }) => theme.paddings[4]};

  & > div {
    padding: ${({ theme }) => theme.paddings[8]}
      ${({ theme }) => theme.paddings[4]};
    p {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primary};
    }
    &:nth-of-type(1) {
      grid-column: 1/2;
    }
    &:nth-of-type(2) {
      grid-column: 2/4;
    }
    &:nth-of-type(3) {
      grid-column: 4/6;
    }
    &:nth-of-type(4) {
      grid-column: 6/8;
    }
    &:nth-of-type(5) {
      grid-column: 8/10;
    }
    &:nth-of-type(6) {
      grid-column: 10/13;
    }
    &:nth-of-type(7) {
      grid-column: 13/16;
    }
    &:nth-of-type(8) {
      grid-column: 16/17;
      justify-self: center;
    }
  }
`;

export const DashboardData = styled.div`
  ${dashboardLayout};
  background: ${({ theme }) => theme.colors.demandeDashboardItem};
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const DashboardSearch = styled.div`
  display: flex;
  gap: 12px;
  padding: ${({ theme }) => theme.paddings[3]}
    ${({ theme }) => theme.paddings[5]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  width: 40%;
  justify-self: center;
  input {
    flex-grow: 2;
    border: none;
    outline: none;
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const FiltersToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  width: 252px;
  justify-content: flex-end;
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const DemandeDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryOpaque};
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  strong {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
  }
`;

export const DashboardDataHeading = styled.div`
  ${dashboardLayout};
  background: ${({ theme }) => theme.colors.textOnPrimary};
`;

export const DemandeActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

export const ActionPopup = styled.div`
  position: absolute;
  top: 8px;
  left: 48px;
  margin-left: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.textOnPrimary};
  border-radius: 8px;
  overflow: hidden;
  visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
  transition: 400ms ease-in-out;
  max-width: ${({ showMenu }) => (showMenu ? "280px" : "0px")};
  z-index: 8;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    left: 50%;
    transform: translateX(-50%);
    top: 110%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none !important;
  }
`;

export const DemandeDots = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  & > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms ease-in-out;
    &:not(:nth-of-type(2)) {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        &:first-of-type {
          left: 6px;
        }
        &:last-of-type {
          left: 18px;
        }
        &:not(:nth-of-type(2)) {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      `}
  }
`;

export const ActionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  & > svg {
    cursor: pointer;
  }
`;

export const ActionDotsWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  & > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms ease-in-out;
    &:not(:nth-of-type(2)) {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        &:first-of-type {
          left: 6px;
        }
        &:last-of-type {
          left: 18px;
        }
        &:not(:nth-of-type(2)) {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      `}
  }
`;

export const ActionsLine = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  grid-column: span ${({ colSpan }) => (colSpan ? colSpan : 1)};
  justify-self: ${({ justify }) => (justify ? justify : "center")};
  & > h3 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
  }
  & > div {
    &:first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      svg {
        transform: ${({ collapse }) =>
          collapse ? "rotate(180deg)" : "rotate(0deg)"};
        transition: 350ms ease-in-out;
        transform-origin: center;
      }
    }
    &:last-of-type {
      max-height: ${({ collapse }) => (!collapse ? "0px" : "768px")};
      visibility: ${({ collapse }) => (!collapse ? "hidden" : "visible")};
      opacity: ${({ collapse }) => (!collapse ? "0" : "1")};
      transition: 400ms ease-in-out;
    }
  }
  & > p {
    &:first-of-type {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primaryOpaque};
      display: none;
    }
  }
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 90% !important;
    & > p {
      &:first-of-type {
        display: block;
      }
    }
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: 350ms ease-in-out;
  background: transparent;
  padding: ${({ theme }) => (theme ? theme.paddings[3] : "0")};
  svg {
    width: 28px;
    height: 28px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.hover};
  }
`;

export const DemandeCheckboxContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  position: relative;
  transition: 400ms ease-in-out;
  svg {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    opacity: 0 !important;
    transition: 400ms ease-in-out;
    width: 28px !important;
    height: 28px !important;
  }
  ${({ checked }) =>
    checked &&
    css`
      background: ${({ theme }) => theme.colors.primary};
      svg {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 28px !important;
        height: 28px !important;
        opacity: 1 !important;
      }
    `}
`;
