import { ReactComponent as Chain } from "assets/icons/chain.svg";
import { DemandeCandidateStatut, DossierLocataireStatut } from "utils/Statuts";
import { ReactComponent as Valid } from "assets/icons/complete.svg";
import { ReactComponent as Issue } from "assets/icons/missingDetails.svg";
import { ReactComponent as Blocked } from "assets/icons/document_blocked.svg";
import { GarantWrapper } from "pages/dossierLocation/forms/formsStyles";
const Garant = ({
  garantInfo,
  activeCandidate,
  handleGarantSelect = () => {},
}) => {
  return (
    <GarantWrapper
      active={activeCandidate?.id === garantInfo?.id}
      onClick={() => {
        handleGarantSelect(garantInfo);
      }}
    >
      <Chain />
      <div>
        <p>
          <span>Garant</span>
          {garantInfo.nom} {garantInfo.prenom}
        </p>
        {garantInfo.statut === DossierLocataireStatut.VALIDE ||
        garantInfo.statut === DemandeCandidateStatut.COMPLET ? (
          <Valid />
        ) : garantInfo.statut === DossierLocataireStatut.NON_VALIDE ? (
          <Blocked />
        ) : (
          <Issue />
        )}
      </div>
    </GarantWrapper>
  );
};

export default Garant;
