//  Cookie
//  Component to display a cookie with a title, a text and a switch
//  to toggle the cookie on or off
//  Props:
//     - onToggle: function, the function to call when the user toggles the cookie
//     - title: string, the title of the cookie
//     - text: string, the text of the cookie
//     - value: boolean, the value of the cookie, true if the cookie is on, false otherwise
//  State:
//     - collapse: boolean to toggle the collapse of the cookie

import { useState } from "react";
import Switch from "components/switch/switch";
import { ReactComponent as Chevron } from "assets//icons/chevron.svg";
import { CookieToggle } from "./cookiesPopupStyles";
const Cookie = ({ onToggle = () => {}, title, text, value }) => {
  const [collapse, setCollapse] = useState(false);
  return (
    <CookieToggle isActive={collapse}>
      <div>
        <h3>{title}</h3>

        <Switch value={value} action={() => onToggle()} />
        <Chevron onClick={() => setCollapse(!collapse)} />
      </div>
      <p>{text}</p>
    </CookieToggle>
  );
};

export default Cookie;
