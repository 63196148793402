const STATUT_CDI_PERIODE_ESSAI = {
  key: "CDI_PERIODE_ESSAI",
  value: "CDI en période d'essai",
};

const STATUT_CDI_CONFIRME = {
  key: "CDI_CONFIRME",
  value: "CDI confirmé",
};

const STATUT_CDD = {
  key: "CDD",
  value: "CDD",
};

const STATUT_INTERIMAIRE = {
  key: "INTERIMAIRE",
  value: "Intérimaire",
};

const STATUT_INTERMITTENT = {
  key: "INTERMITTENT",
  value: "Intermittent",
};

const STATUT_ASSISTANTE_MATERNELLE = {
  key: "ASSISTANTE_MATERNELLE",
  value: "Assistante maternelle",
};

const STATUT_TITULAIRE = {
  key: "TITULAIRE",
  value: "Titulaire",
};

const STATUT_NON_TITULAIRE = {
  key: "NON_TITULAIRE",
  value: "Non Titulaire",
};

const STATUT_ETUDIANT = {
  key: "ETUDIANT",
  value: "Etudiant",
};

const STATUT_CREATEUR_ENTREPRISE = {
  key: "CREATEUR_ENTREPRISE",
  value: "Créateur d'entreprise",
};

const STATUT_GERANT_MAJORITAIRE = {
  key: "GERANT_MAJORITAIRE",
  value: "Gérant majoritaire",
};

const STATUT_ARTISAN = {
  key: "ARTISAN",
  value: "Artisan",
};

const STATUT_COMMERCANT = {
  key: "COMMERCANT",
  value: "Commercant",
};

const STATUT_PROFESSION_LIBERALE = {
  key: "PROFESSION_LIBERALE",
  value: "Profession libérale",
};

const STATUT_INVALIDE = {
  key: "INVALIDE",
  value: "Invalide",
};

const STATUT_DIPLOMATE = {
  key: "DIPLOMATE",
  value: "Diplômate",
};

const STATUT_PERSONNEL_AMBASSADE = {
  key: "PERSONNEL_AMBASSADE",
  value: "Personnel ambassade",
};

const STATUT_RENTIER = {
  key: "RENTIER",
  value: "Rentier",
};

const STATUT_AUTRE = {
  key: "AUTRE",
  value: "Autre",
};

export const SITUATION_SALARIE_PRIVE = "SALARIE_PRIVE";
export const SITUATION_SALARIE_PUBLIC = "SALARIE_PUBLIC";
export const SITUATION_TRAVAILLEUR_NON_SALARIE = "TRAVAILLEUR_NON_SALARIE";
export const SITUATION_ETUDIANT = "ETUDIANT";
export const SITUATION_RETRAITE = "RETRAITE";
export const SITUATION_RECHERCHE_EMPLOI = "RECHERCHE_EMPLOI";
export const SITUATION_AUTRES = "AUTRES";

export {
  STATUT_CDI_PERIODE_ESSAI,
  STATUT_CDI_CONFIRME,
  STATUT_CDD,
  STATUT_ARTISAN,
  STATUT_ASSISTANTE_MATERNELLE,
  STATUT_AUTRE,
  STATUT_COMMERCANT,
  STATUT_CREATEUR_ENTREPRISE,
  STATUT_DIPLOMATE,
  STATUT_GERANT_MAJORITAIRE,
  STATUT_INTERIMAIRE,
  STATUT_INTERMITTENT,
  STATUT_INVALIDE,
  STATUT_NON_TITULAIRE,
  STATUT_PERSONNEL_AMBASSADE,
  STATUT_PROFESSION_LIBERALE,
  STATUT_RENTIER,
  STATUT_TITULAIRE,
  STATUT_ETUDIANT,
};
