import styled from "styled-components";
import { DossierLocationStatut } from "utils/Statuts";

export const CapaciteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: ${(props) => props.theme.margins[2]} !important;
  padding: ${({ theme }) => theme.paddings[4]}
    ${({ theme }) => theme.paddings[12]};
  background-color: #fdfbfb;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  gap: 24px;
  width: max-content;
  & > div {
    max-width: 260px;
    position: relative;
    display: flex;
    gap: 12px;
    align-items: center;
    font: ${({ theme }) => theme.fonts.montserrat.title};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    color: ${({ theme }) => theme.colors.primary};
    input {
      font: ${(props) => props.theme.fonts.montserrat.title};
      color: ${(props) =>
        props.editMode
          ? props.theme.colors.primary
          : props.dossierStatut === DossierLocationStatut.INVALIDE
          ? props.theme.colors.error
          : props.dossierStatut === DossierLocationStatut.INCOMPLET
          ? props.theme.colors.error
          : props.theme.colors.secondary};

      font-weight: 600 !important;
      width: 100%;
      transition: 300ms ease-in-out;
      border: none;
      &&::-webkit-outer-spin-button,
      &&::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &::before {
      background: ${(props) => props.theme.colors.inputLine};
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -4px;
      left: 0;
      display: block;
      content: "";
      transform: ${({ editMode }) => (editMode ? "scaleX(1)" : "scaleX(0)")};
      transition: 0.3s ease-in-out;
    }
    &:focus-within::before {
      background: ${(props) => props.theme.colors.primary};
    }
  }
  svg {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: block;
    flex-shrink: 0;
    path {
      stroke: ${(props) => props.theme.colors.primary};
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.paddings[4]};
    align-items: center;
    gap: 16px;
    width: 100%;
  }
`;
