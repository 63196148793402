import { axiosInstance } from "./axiosInstance";

export const getClients = () => {
  return axiosInstance.get("/utilisateurs/connected");
};

export const createSite = (adbId, nom) => {
  return axiosInstance.post(`/administrateur-biens/${adbId}/sites`, {
    nom,
  });
};

export const createClient = (clientData) => {
  return axiosInstance.post("/administrateur-biens", clientData);
};

export const updateClient = (adbId, clientData) => {
  return axiosInstance.put(`/administrateur-biens/${adbId}`, clientData);
};
