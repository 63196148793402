import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import {
  UTILISATEURS_LOAD,
  UTILISATEUR_ACTIVE_CHANGE,
  UTILISATEUR_AFFECT_SITE,
  UTILISATEUR_CREATE,
  UTILISATEUR_GET,
  UTILISATEUR_SITE_CREATE,
  UTILISATEUR_UPDATE,
} from "./actions";
import * as utilisateurApi from "../api/utilisateur";
import { selectAdministrateurBien } from "redux//administrateurBien/administrateurBienReducer";
import { toast } from "react-toastify";

function* loadUtilisateurs(action) {
  const { onLoadChange, onLoadSuccess } = action;
  try {
    onLoadChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);

    const utilisateurs = yield call(
      utilisateurApi.getAllUtilisateurs,
      adbId,
      action.payload
    );

    if (utilisateurs.status === 200) {
      onLoadSuccess([utilisateurs.data]);
    }
  } catch (error) {
    toast.error("Une erreur est survenue lors du chargement des utilisateurs");
  } finally {
    onLoadChange(false);
  }
}

function* saveUtilisateurModifications(action) {
  const { onChange = () => {}, onSuccess = () => {}, payload } = action;
  try {
    onChange(true);
    let newData = { ...payload };

    // delete newData["profil"];
    delete newData["typeCompte"];
    delete newData["username"];
    delete newData["actif"];
    delete newData["isRegistered"];

    const utilisateur = yield call(utilisateurApi.updateUtilisateur, newData);
    if (utilisateur.status === 200) {
      onSuccess(utilisateur.data);
      toast.success("Les modifications ont été sauvegardées avec succès");
    }
  } catch (error) {
    toast.error(
      "Une erreur est survenue lors de la sauvegarde des modifications"
    );
  } finally {
    onChange(false);
  }
}

function* createUtilisateur(action) {
  const { onChange, onSuccess, payload } = action;
  try {
    onChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);

    const utilisateur = yield call(
      utilisateurApi.createUtilisateur,
      adbId,
      payload
    );
    if (utilisateur.status === 200) {
      onSuccess(utilisateur.data);
      toast.success("L'utilisateur a été créé avec succès");
    }
  } catch (error) {
    toast.error("Une erreur est survenue lors de la création de l'utilisateur");
  } finally {
    onChange(false);
  }
}

function* toggleUtilisateurActive(action) {
  const { onChange, onSuccess } = action;
  try {
    const { id, actif } = action.payload;
    onChange(true);
    const utilisateur = yield call(
      utilisateurApi.changeUtilisateurActive,
      id,
      actif
    );
    if (utilisateur.status === 200) {
      yield put(onSuccess(utilisateur.data));
      toast.success("L'état de l'utilisateur a été changé avec succès");
    }
  } catch (error) {
    toast.error("Une erreur est survenue lors du changement d'état");
  } finally {
    onChange(false);
  }
}

function* getUtilisateurInfo(action) {
  const { onLoadChange, onLoadSuccess } = action;
  try {
    onLoadChange(true);
    const utilisateur = yield call(utilisateurApi.getUtilisateur, action.id);
    if (utilisateur.status === 200) {
      onLoadSuccess(utilisateur.data);
    }
  } catch (error) {
    console.log(error);
    toast.error("Une erreur est survenue lors du chargement de l'utilisateur");
  } finally {
    onLoadChange(false);
  }
}

function* createSite(action) {
  const { onCreateChange, onCreateSuccess, data } = action;
  try {
    onCreateChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);
    const site = yield call(utilisateurApi.createSite, adbId, data);
    if (site.status === 200) {
      onCreateSuccess(site.data);
      toast.success("Le site a été créé avec succès");
    }
  } catch (error) {
    toast.error("Une erreur est survenue lors de la création du site");
  } finally {
    onCreateChange(false);
  }
}

function* affectSiteToUtilisateur(action) {
  const { onChange, onSuccess } = action;
  try {
    onChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);

    const { siteId, affect, utilisateurId } = action.payload;
    const responseAffect = yield call(
      utilisateurApi.affecterSite,
      adbId,
      siteId,
      utilisateurId,
      affect
    );
    if (responseAffect.status === 200) {
      onSuccess(responseAffect.data);
    }
  } catch (error) {
    toast.error("Une erreur est survenue lors de l'affectation du site");
  } finally {
    onChange(false);
  }
}

function* affectSiteToUtilisateurCall() {
  yield takeEvery(UTILISATEUR_AFFECT_SITE, affectSiteToUtilisateur);
}

function* getUtilisateurInfoCall() {
  yield takeLatest(UTILISATEUR_GET, getUtilisateurInfo);
}

function* loadUtilisateursCall() {
  yield takeLatest(UTILISATEURS_LOAD, loadUtilisateurs);
}

function* saveUtilisateurModificationsCall() {
  yield takeLatest(UTILISATEUR_UPDATE, saveUtilisateurModifications);
}

function* createUtilisateurCall() {
  yield takeLatest(UTILISATEUR_CREATE, createUtilisateur);
}

function* toggleUtilisateurActiveCall() {
  yield takeLatest(UTILISATEUR_ACTIVE_CHANGE, toggleUtilisateurActive);
}

function* utilisateurCreateSiteCall() {
  yield takeEvery(UTILISATEUR_SITE_CREATE, createSite);
}

export {
  loadUtilisateursCall,
  createUtilisateurCall,
  saveUtilisateurModificationsCall,
  toggleUtilisateurActiveCall,
  getUtilisateurInfoCall,
  utilisateurCreateSiteCall,
  affectSiteToUtilisateurCall,
};
