import styled, { css, keyframes } from "styled-components";
import { DossierLocationStatut } from "utils/Statuts";

export const SummaryContainer = styled.div`
  background: ${(props) => props.theme.colors.summary};
  padding: ${({ theme, opened }) =>
    opened
      ? `
    ${theme.paddings[12]} ${theme.paddings[12]} ${theme.paddings[16]} ;
  `
      : `
      0 ${theme.paddings[12]} 0
      `};
  border-radius: 12px;
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  /* border: 1px solid ${(props) => props.theme.colors.border}; */
  max-height: ${({ opened }) => (opened ? "100%" : "0")};
  overflow: hidden;
  transition: 400ms ease-in-out;
  margin-bottom: ${({ theme, opened }) => opened && theme.margins[6]};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding
  }
  padding: ${({ theme, opened }) =>
    opened
      ? `
    ${theme.paddings[8]} ${theme.paddings[6]} ${theme.paddings[12]} ;
  `
      : `
      0 ${theme.paddings[8]} 0
      `};
  @media screen and (max-width: 860px){
    display: none;
  }
`;

export const GarantOfLocataire = styled.div`
  color: ${(props) => props.theme.colors.garant};
  font: ${(props) => props.theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  /* margin: ${(props) => props.theme.margins[4]} 0 0; */
`;

export const DossierStatusContainer = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const StatusValues = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.margins[6]};
  h3 {
    font: ${(props) => props.theme.fonts.montserrat.highlight};
    color: ${(props) => props.theme.colors.primaryOpaque};
    font-weight: 600 !important;
  }
  h5 {
    font: ${(props) => props.theme.fonts.montserrat.heading};
    color: ${(props) =>
      props.valid
        ? props.theme.colors.secondary
        : props.theme.colors.error} !important;
    font-weight: 600 !important;
  }
  svg {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
  }
`;

export const Raison = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    &:first-of-type {
      font: ${(props) => props.theme.fonts.montserrat.highlight};
      color: ${(props) => props.theme.colors.primaryOpaque};
      font-weight: 600 !important;
    }
    &:last-of-type {
      font: ${(props) => props.theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme, status }) =>
        status === DossierLocationStatut.ACCEPTE
          ? theme.colors.secondary
          : status === DossierLocationStatut.REJETEE
          ? theme.colors.error
          : theme.colors.primaryOpaque};
    }
  }
`;

export const LocataireLine = styled.div`
  padding: ${(props) => props.theme.paddings[4]}
    ${(props) => props.theme.paddings[8]};
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme, opened }) => (!opened ? theme.colors.border : "transparent")};
  transition: 300ms ease-in-out;
  &,
  div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  p {
    font: ${(props) => props.theme.fonts.montserrat.regular};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600 !important;
  }

  & > div {
    & > p {
      &:last-of-type {
        span {
          margin-right: ${(props) => props.theme.margins[3]};
        }
      }
    }
    &:nth-of-type(2) {
      & > div {
        &:nth-of-type(1) {
          gap: 48px;
          padding-right: ${(props) => props.theme.paddings[14]};
          & > svg {
            width: 24px;
            height: 24px;
            padding: 4px;
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            transform: rotate(${({ opened }) => (opened ? "180deg" : "0deg")});
          }
          @media screen and (max-width: ${({ theme }) =>
              theme.breakpoints.tablet}) {
            gap: 24px;
            padding-right: ${(props) => props.theme.paddings[6]};
            & > p {
              &:nth-of-type(2) {
                display: none;
              }
            }
          }
          @media screen and (max-width: ${({ theme }) =>
              theme.breakpoints.small}) {
            gap: 16px;
            padding-right: 0;
            & > p {
              &:nth-of-type(3) {
                display: none;
              }
            }
          }
        }
        &:nth-of-type(2) {
          div {
            cursor: pointer;
            gap: 8px;
            p {
              color: ${(props) => props.theme.colors.switchActive};
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.paddings[4]};
  }
`;

const showDataAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const LocataireDataWrapper = styled.div`
  opacity: ${({ opened }) => (opened ? "1" : "0")};
  max-height: ${({ opened }) => (opened ? "100%" : "0px")};
  transition: all 0.4s ease-in-out;
  transition-delay: ${({ opened }) => (opened ? "0.8s" : "0s")};
  & > * {
    opacity: 0;
    animation: ${showDataAnimation} 0.4s ease-in-out forwards;
    animation-delay: ${({ opened }) => (opened ? "0.35s" : "0s")};
  }
`;

export const LocataireSummaryContainer = styled.div`
  border-radius: 12px;
  overflow: hidden;
  max-height: ${({ opened }) => (opened ? "100%" : "0")};
  transition: all 0.4s ease-in-out;
  transition-delay: ${({ opened }) => (opened ? "0s" : "0.4s")};

  padding: ${({ opened, theme }) =>
    opened
      ? `
    ${theme.paddings[8]}
    ${theme.paddings[12]}
    ${theme.paddings[12]}
  `
      : 0};
  /* border: 1px solid
    ${({ theme, opened }) => (opened ? theme.colors.border : "transparent")}; */
  border-radius: 12px;
  &:first-child {
    margin-bottom: ${({ theme, opened }) =>
      !opened &&
      `
    -${theme.margins[24]}
  `};
  }
  * {
    opacity: ${({ opened }) => (opened ? "1" : "0")};
    transition: all 0.4s ease-in-out;
    transition-delay: ${({ opened }) => (opened ? "0.2s" : "0s")};
    max-height: ${({ opened }) => (opened ? "100%" : "0px")};
  }
  h2 {
    font: ${(props) => props.theme.fonts.montserrat.heading};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600 !important;
  }
`;

export const LocataireInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 48px;
  max-width: 560px;
  margin-top: ${(props) => props.opened && props.theme.margins[4]};
  p {
    color: ${(props) => props.theme.colors.primaryOpaque};
    font: ${(props) => props.theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  svg {
    path {
      fill: ${(props) => props.theme.colors.primaryOpaque};
    }
  }
`;

export const DossierValidationActionInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  font: ${(props) => props.theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  margin-bottom: ${(props) => props.theme.margins[6]};
  width: 80%;
  max-width: 960px;
  div {
    display: flex;
    gap: 2px;
    flex-direction: column;
  }
  svg {
    width: 40px;
    height: 40px;

    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const SummaryInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: ${({ opened, theme }) => opened && theme.margins[7]};
  & > div {
    &:first-of-type {
      display: flex;
      gap: 24px;
      align-items: center;
      justify-content: space-between;
      & > div {
        color: ${(props) => props.theme.colors.switchActive};
        font: ${(props) => props.theme.fonts.montserrat.regular};
        font-weight: 600 !important;
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: ${(props) => props.theme.paddings[6]};
        margin-left: ${(props) => props.theme.paddings[6]};
        cursor: pointer;
      }
      h3 {
        font: ${(props) => props.theme.fonts.montserrat.large};
        color: ${(props) => props.theme.colors.primary};
        font-weight: 600 !important;
      }
    }
    &:nth-of-type(2) {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.desktop}) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.tablet}) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

export const SummaryBox = styled.div`
  padding: ${(props) => props.theme.paddings[6]}
    ${(props) => props.theme.paddings[6]} ${(props) => props.theme.paddings[6]}
    ${(props) => props.theme.paddings[8]};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.summaryBox};
  min-width: 240px;
  max-width: 360px;
  flex-grow: 2;
  @media screen and (min-width: 2100px) {
    max-width: unset;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 100%;
  }
  div {
    display: flex;
    align-items: center;
  }
  & > div {
    &:first-of-type {
      justify-content: space-between;
      align-items: flex-start;
      & > div {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        &:nth-of-type(2) {
          svg {
            width: 28px;
            height: 28px;
            padding: 4px;
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid #f99417;
            transition: all 0.4s ease-in-out;
            &:hover {
              background: #f99417;
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
    &:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-top: ${(props) => props.theme.margins[4]};
    }
  }
  h4 {
    font: ${(props) => props.theme.fonts.montserrat.regular};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600 !important;
  }
  p {
    font: ${(props) => props.theme.fonts.montserrat.small};
    color: ${(props) => props.theme.colors.primaryOpaque};
    font-weight: 600 !important;
  }
`;

export const RevenusList = styled.p`
  font: ${(props) => props.theme.fonts.montserrat.regular} !important;
  color: ${(props) => props.theme.colors.primary} !important;
  font-weight: 600 !important;
`;

export const StyledActionContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  p {
    font: ${(props) => props.theme.fonts.montserrat.regular};
    color: ${(props) => props.theme.colors[props.colorName]};
    font-weight: 600 !important;
  }
  svg {
    path {
      stroke: ${(props) => props.theme.colors[props.colorName]};
    }
  }
`;

const statusStyles = css`
  display: flex;
  gap: 12px;
  align-items: center;
  p {
    font: ${(props) => props.theme.fonts.montserrat.regular};
    font-weight: 600 !important;
  }
`;

export const StatusError = styled.div`
  ${statusStyles}
  p {
    color: ${(props) => props.theme.colors.error};
  }
  svg {
    width: auto;
    height: 28px;
    path {
      stroke: ${(props) => props.theme.colors.error};
    }
  }
`;

export const StatusValid = styled.div`
  ${statusStyles}
  p {
    color: ${(props) => props.theme.colors.secondary};
  }
  svg {
    path {
      stroke: ${(props) => props.theme.colors.secondary};
    }
  }
`;

export const RedirectAction = styled.div`
  ${statusStyles}
  cursor: pointer;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background: ${(props) => props.theme.colors.switchActive};
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
    border-radius: 8px;
  }
  &:hover::before {
    transform: scaleX(1);
  }
  p {
    color: ${(props) => props.theme.colors.switchActive};
  }
  svg {
    path {
      stroke: ${(props) => props.theme.colors.switchActive};
    }
  }
`;

export const ErrorSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  padding: ${({ theme, opened }) => (opened ? theme.paddings[6] : 0)};
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  transition: all 0.3s ease-in-out;
  transition-delay: ${({ opened }) => (opened ? "0.3s" : "0s")};

  h4 {
    font: ${(props) => props.theme.fonts.montserrat.highlight};
    color: ${(props) => props.theme.colors.error};
    max-width: 420px;
    text-align: center;
    font-weight: 600 !important;
  }
`;
