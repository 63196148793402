import { createSlice, createSelector } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  currentAdministrateurBien: undefined,
};

const AdministrateurBienReducer = createSlice({
  name: "administrateurBien",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentAdministrateurBien: (state, action) => {
      state.currentAdministrateurBien = action.payload;
    },
  },
});

const selectSelf = (state) => state.administrateurBien;

export const AdministrateurBienActions = AdministrateurBienReducer.actions;

export const selectAdministrateurBien = createSelector(
  [selectSelf],
  (administrateurBien) => administrateurBien.currentAdministrateurBien
);

export const { setCurrentAdministrateurBien } =
  AdministrateurBienReducer.actions;

export default AdministrateurBienReducer;
