//  DashboardAction
//  Component used to display the actions available for a location
//  Props:
//     - locationData: object, the data of the location to display
//     - status: string, the status of the location
//  State:
//     - showMenu: boolean, if true, the menu is displayed
//  Functions:
//     - handlePreviewCall: function, calls the preview action
//  Refs:
//     - clickRef: ref, used to detect clicks outside the component and if so, close the menu

import { useEffect, useRef, useState } from "react";
import useOutsideClick from "hooks/useOutsideClick";
import { ReactComponent as ActionDots } from "assets/icons/actionDots.svg";

import { withTheme } from "styled-components";
import {
  ActionContainer,
  ActionDotsWrapper,
  ActionPopup,
} from "./dashboardActionStyles";

const DashboardAction = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const clickRef = useOutsideClick(() => {
    setShowMenu(false);
  });
  const popupRef = useRef(null);
  const [position, setPosition] = useState("right");

  // check if the popup exceeds the screen width
  // if so, change the position to the left

  useEffect(() => {
    const handleResize = () => {
      const { right } = popupRef.current.getBoundingClientRect();
      const { innerWidth } = window;
      if (right > innerWidth - 240) {
        setPosition("left");
      } else {
        setPosition("right");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ActionContainer ref={clickRef}>
      <ActionDotsWrapper
        isOpen={showMenu}
        onClick={() => setShowMenu(!showMenu)}
      >
        <ActionDots />
        <ActionDots />
        <ActionDots />
      </ActionDotsWrapper>

      <ActionPopup showMenu={showMenu} ref={popupRef} preferSide={position}>
        {children}
      </ActionPopup>
    </ActionContainer>
  );
};

export default withTheme(DashboardAction);
